import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Navigation() {
  const navigate = useNavigate();
  useEffect(() => {
    window.open('https://dashboard.pickrr.com/sign-in', '_self');
  }, []);

  return <></>;
}
