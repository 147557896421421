import React, { useEffect, useState } from "react";
import {
  Switch,
  Form,
  Checkbox,
  message,
  Radio,
  Row,
  Col,
  Collapse,
} from "antd";
import {
  CustomCollapse,
  CustomPanel,
  FunctionalityContainer,
  CouponContainer,
  CouponBadge,
  CouponName,
  CouponDescription,
  TermsCondition,
} from "./style";
import { FlexBox, SpaceBetweenContainer } from "components/UIElements";
import {
  CustomFont,
  CustomInput,
  CustomTextArea,
  LabelText,
  DownloadButton,
} from "../styled";
import {
  CopyOutlined,
  DownloadOutlined,
  RightOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { getCoupons } from "./script";
import { useQuery } from "react-query";
import {
  summary,
  activeTabKey,
  activeSwitchTabKey,
  displayCouponDetails,
} from "./helper";

const Functionality = ({
  data,
  discountMethod,
  discountType,
  selectTypes,
  discountConfig,
  form,
  couponId,
  sellerConfigValues,
  activeKey,
  setActiveKey,
  activeSwitch,
  setActiveSwitch
}: any) => {
  const [dataLinkValue, setDataLinkValue] = useState<string>("");
  const [bulkType, setBulkType] = useState<string>("prefix");
  // const [activeKey, setActiveKey] = useState<any>([1]);
  const [couponList, setCouponList] = useState<any>();
  const [capping, setCapping] = useState<string>();
  const [showTerms, setShowTerms] = useState<boolean>(true);
  const [couponDetail, setCouponDetails] = useState<any>({
    title: "",
    description: "",
    termsAndCondition: "",
    showApplicable : false
  });

  const paymentModes= [
    { label: 'UPI', value: 'UPI' },
    { label: 'Credit/Debit Card', value: 'CARDS' },
    { label: 'Net Banking', value: 'NETBANKING' },
    { label: 'Pay Later', value: 'BNPL' },
    { label: 'Rewards/Loyalty programs', value: 'REWARDS' },
    { label: 'Credpay', value: 'CREDPAY' },
    { label: 'Pay with EMI', value: 'EMI' },
    { label: 'Wallets', value: 'WALLETS' },
    {label: 'Simpl', value: 'SIMPL'},
    {label:'Settle',value:'POTLEE'},
    {label:'SimplPi3',value:'SIMPLPI3'},
    // {label:'BharatX',value:'BHARATX'}
]

  // const [activeSwitch, setActiveSwitch] = useState<any>({
  //   visibility: true,
  //   combination: false,
  //   discountlink: false,
  //   caping: false,
  //   bulkgenerate: false,
  // });

  // for coupon update
  useEffect(() => {
    if (data && Object.keys(data)?.length !== 0 && couponId) {
      setActiveKey([...activeTabKey(data, activeKey)]);
      setActiveSwitch({ ...activeSwitchTabKey(data, activeSwitch) });

      if (data?.discountFunctionality?.capping?.value > 0) {
        setCapping(data?.discountFunctionality?.capping?.value);
      }
    } else if (data?.discountFunctionality) {
      setActiveKey([...activeTabKey(data)]);
    }

    if (data?.discountFunctionality?.visibility && couponId) {
      const couponValues = displayCouponDetails(data);
      setCouponDetails({
        ...couponDetail,
        ...couponValues,
      });
    }
  }, []);
  // end for coupon update

  // setting coupon combination list
  const sellerId = sellerConfigValues?.seller_id;
  const discountTypeValue = discountType;
  const couponCode = data?.discountMethod?.couponCode;

  const { data: coupons, isLoading: couponsLoading } = useQuery(
    ["coupon", { sellerId, discountTypeValue, couponCode}],
    getCoupons
  );

  useEffect(() => {
    if (coupons) {
      const options = coupons?.map((value: any) => {
        return { label: value, value: value };
      });
      setCouponList(options);
    }
  }, [coupons, couponsLoading]);
  // end setting coupon combination list
  // handlers
  const switchHandler = (checked: boolean, switchName: any) => {
    setActiveSwitch({ ...activeSwitch, [switchName]: checked });
  };

  const openPanelHandler = (key: string | number) => {
    if (activeKey.includes(Number(key))) {
      setActiveKey(activeKey.filter((item: any) => Number(item) !== key));
    } else if (!activeKey.includes(key)) {
      setActiveKey([...activeKey, key]);
    }
  };

  const bulkTypeHandler = (e: any) => {
    setBulkType(e.target.value);
  };

  const copyUrlHandler = () => {
    if (dataLinkValue) {
      message.info("Copied SuccessFully");
      navigator.clipboard.writeText(dataLinkValue);
    }
  };

  const couponDetailsHandler = (e: any, fieldName: string) => {
    setCouponDetails({ ...couponDetail, [fieldName]: e.target.value });
  };

  const cappingHandler = (e: any) => {
    setCapping(e.target.value);
  };

  useEffect(() => {
    const descriptionBase = discountConfig?.discountConfiguration;
    const configValue = descriptionBase.config;
    let description: string;

    if (discountType === "prdAmt" || discountType === "amtCart") {
      const offType =
        descriptionBase.configType === "flat"
          ? `flat ${configValue} off`
          : `${configValue}% off`;

      description = `Apply this code and get ${offType} ${
        capping?.length > 0 ? `discount upto ${capping} Rs` : ""
      }`;
      setCouponDetails({ ...couponDetail, description: description });
    }
  }, [capping]);

  useEffect(() => {
    const [description, condition] = summary(discountConfig, discountType);

    form.setFieldsValue({
      title: discountConfig?.code,
      description: description,
      tnc: condition,
      discountLink: `${sellerConfigValues?.domain_name}?fastrrCouponCode=${discountConfig?.code}`,
    });

    setCouponDetails({
      ...couponDetail,
      title: discountConfig?.code,
      description: description,
      termsAndCondition: condition,
    });

    setDataLinkValue(
      `${sellerConfigValues?.domain_name}?fastrrCouponCode=${discountConfig?.code}`
    );
  }, [discountConfig]);

  const { Panel } = Collapse;
  const panelHeading = (title: string, key: number) => {
    return (
      <SpaceBetweenContainer style={{ width: "100%" }}>
        <CustomFont size="14px" weight="600">
          {title.replace(/([a-z])([A-Z])/, "$1 $2")}
        </CustomFont>
        <div>
          <SpaceBetweenContainer>
            <Form.Item
              name={`${title.replace(/\s/g, "")}Active`}
              style={{ marginBottom: 0 }}
              initialValue={
                activeSwitch[title.replace(/\s/g, "").toLocaleLowerCase()]
              }
              valuePropName="checked"
            >
              <Switch
                style={{ marginLeft: "10px" }}
                defaultChecked={
                  title.replace(/\s/g, "").toLocaleLowerCase() === "visibility"
                }
                onChange={(checked) => {
                  switchHandler(
                    checked,
                    `${title.replace(/\s/g, "").toLocaleLowerCase()}`
                  );
                  if (
                    activeSwitch[
                      title.replace(/\s/g, "").toLocaleLowerCase()
                    ] &&
                    activeKey.includes(Number(key))
                  ) {
                    openPanelHandler(key);
                  } else if (
                    !activeSwitch[
                      title.replace(/\s/g, "").toLocaleLowerCase()
                    ] &&
                    !activeKey.includes(Number(key))
                  ) {
                    openPanelHandler(key);
                  }
                }}

                // onClick={() => openPanelHandler(key)}}
              />
            </Form.Item>
            <div
              onClick={() => openPanelHandler(key)}
              style={{ marginLeft: 10 }}
            >
              {key && !activeKey.includes(Number(key)) ? (
                /* @ts-ignore */
                <RightOutlined />
              ) : (
                /* @ts-ignore */
                <DownOutlined />
              )}
            </div>
          </SpaceBetweenContainer>
        </div>
      </SpaceBetweenContainer>
    );
  };

  return (
    <FunctionalityContainer>
      {/*// @ts-ignore */}
      <CustomCollapse
        bordered={false}
        activeKey={activeKey}
        // expandIconPosition="end"
        expandIcon={() => <></>}
      >
        {/* {discountMethod !== "automaticDiscount" &&
          discountType !== "aftersell" && ( */}
            
            {((discountMethod !== "automaticDiscount" &&
          discountType !== "aftersell") || discountType === "bankOffer") && (
              
              <CustomPanel header={panelHeading("Visibility", 1)} key="1">
                <Row gutter={[16, 16]}>
                  <Col
                    span={13}
                    // style={{ width: "280px" }}
                  >
                    <Form.Item
                      name="title"
                      label="Title"
                      // rules={[
                      //   {
                      //     required: activeSwitch.visibility ? true : false,
                      //     message: "Discount title is required",
                      //   },
                      // ]}
                    >
                      <CustomInput
                        readOnly
                        placeholder="Enter your discount title"
                        onChange={(e) => couponDetailsHandler(e, "title")}
                        value={couponDetail.title}
                      />
                    </Form.Item>
                    <Form.Item
                      name="description"
                      label="Description"
                      rules={[
                        {
                          required: activeSwitch.visibility,
                          message: "Description is required",
                        },
                      ]}
                    >
                      <CustomInput
                        placeholder="Enter your discount description"
                        onChange={(e) => couponDetailsHandler(e, "description")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="tnc"
                      label="Terms & Conditions"
                      // rules={[
                      //   {
                      //     required: activeSwitch.visibility,
                      //     message: "T&C are required",
                      //   },
                      // ]}
                    >
                      <CustomTextArea
                        rows={4}
                        cols={20}
                        placeholder="Enter your discount T&C"
                        readOnly
                        onChange={(e) =>
                          couponDetailsHandler(e, "termsAndCondition")
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="showApplicable"
                      valuePropName="checked"
                    >
                      <Checkbox defaultChecked={false}>Show when applicable</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <CouponContainer>
                      <CouponBadge>Save ₹/- on this order</CouponBadge>
                      <CouponName>
                        <div className="couponName">
                          {couponDetail.title}
                          {/* FASTRR */}
                        </div>
                        <div className="applyButton">Apply</div>
                      </CouponName>
                      <div className="dottedLine"></div>
                      <CouponDescription>
                        <div className="description">
                          {couponDetail.description}
                          {/* Apply this code and get 10% Off up to ₹250 on selected products. */}
                        </div>
                        <div
                          className="hideButton"
                          onClick={() => setShowTerms(!showTerms)}
                        >
                          Hide Details &nbsp;
                          {/* @ts-ignore */}
                          {showTerms ? <UpOutlined /> : <DownOutlined />}
                        </div>
                      </CouponDescription>
                      <TermsCondition>
                        <div className="termsHeading">Terms and conditions</div>
                        <div className="terms">
                          {showTerms && couponDetail?.termsAndCondition}
                        </div>
                      </TermsCondition>
                    </CouponContainer>
                  </Col>
                </Row>
              </CustomPanel>
             )}

              {discountType !== "aftersell" &&
              <CustomPanel header={panelHeading("Payment Modes", 6)} key="6">
                <CustomFont size="14px" style={{ marginBottom: 16 }}>
                  {couponList?.length > 0 &&
                    "Select payment modes for which the discount is applicable"}
                </CustomFont>
                <Form.Item
                  name="paymentModes"
                  rules={[
                    {
                      required: activeSwitch.paymentmodes,
                      message: "Payment modes are required",
                    },
                  ]}
                >
                    <Checkbox.Group options={paymentModes} />
                </Form.Item>
              </CustomPanel>
              }

            {discountType !== "aftersell" &&
              <CustomPanel header={panelHeading("Combination", 2)} key="2">
                <CustomFont size="14px" style={{ marginBottom: 16 }}>
                  {couponList?.length > 0 &&
                    "This Discount can be combined with"}
                </CustomFont>
                <Form.Item
                  name="combination"
                  rules={[
                    {
                      required: activeSwitch.combination,
                      message: "Combination are required",
                    },
                  ]}
                >
                  {couponList?.length > 0 ? (
                    <Checkbox.Group options={couponList} />
                  ) : (
                    "No coupon find for combination"
                  )}
                </Form.Item>
              </CustomPanel>
              }
              
              {((discountMethod !== "automaticDiscount" &&
          discountType !== "aftersell")  || discountType === "bankOffer" )&&(
              <CustomPanel header={panelHeading("Discount link", 3)} key="3">
                <Form.Item
                  name="discountLink"
                  rules={[
                    {
                      required: activeSwitch.discountlink,
                      message: "Discount link is required",
                    },
                  ]}
                  // initialValue = {dataLinkValue}
                >
                  <CustomInput
                    readOnly
                    // onChange={(e: any) => setDataLinkValue(e.target.value)}
                    // value={dataLinkValue}
                    placeholder="Discount link"
                    suffix={
                      /* @ts-ignore */
                      <CopyOutlined
                        style={{ color: "#0C64DB" }}
                        onClick={copyUrlHandler}
                      />
                    }
                  />
                </Form.Item>
              </CustomPanel>
            
           )} 

        {discountType !== "bxgy" &&
          (selectTypes.configType !== "flat" ||
            selectTypes.configType !== "flatValue") && (
            <>
              {/*// @ts-ignore */}
              <CustomPanel header={panelHeading("Caping", 4)} key="4">
                <Form.Item
                  name="caping"
                  label="Maximum discount Amount  ₹"
                  rules={[
                    {
                      required: activeSwitch.caping,
                      pattern: new RegExp(/^(([1-9]+[.]?[0-9]*))$/),
                      message: "Please enter valid amount",
                    },
                  ]}
                >
                  <CustomInput
                    onChange={cappingHandler}
                    placeholder="Enter Amount"
                    style={{ width: 256 }}
                    maxLength={6}
                  />
                </Form.Item>
              </CustomPanel>
            </>
          )}

        {/*// @ts-ignore */}
        {discountMethod !== "automaticDiscount" &&
          discountType !== "aftersell" &&  discountType !== "bankOffer" && (
            <>
              <CustomPanel header={panelHeading("BulkGenerate", 5)} key="5">
                <Form.Item name="bulkType" initialValue={bulkType}>
                  <Radio.Group onChange={bulkTypeHandler}>
                    <Radio value={"prefix"}>Prefix</Radio>
                    <Radio value={"suffix"}>Suffix</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="affixValue"
                  rules={[
                    {
                      required: activeSwitch.bulkgenerate,
                      message: "Prefix/Suffix is required",
                    },
                  ]}
                >
                  <CustomInput
                    placeholder="Enter prefix/suffix"
                    style={{ width: 256 }}
                  />
                </Form.Item>

                <div style={{ marginBottom: 10 }}>
                  <LabelText>No. of Codes</LabelText>
                  <FlexBox style={{ gap: 16 }}>
                    <Form.Item
                      name="noOfCodes"
                      rules={[
                        {
                          required: activeSwitch.bulkgenerate,
                          pattern: new RegExp(/^(([1-9]{1}[0-9]*))$/),
                          message: "Please enter numeric value",
                        },
                      ]}
                    >
                      <CustomInput
                        placeholder="Enter Codes"
                        style={{ width: 225 }}
                        maxLength={6}
                      />
                    </Form.Item>

                    <Form.Item
                      name="bulkEmail"
                      rules={[
                        {
                          required: activeSwitch.bulkgenerate,
                          message: "Please enter a email",
                        },
                      ]}
                    >
                      <CustomInput placeholder="Email" style={{ width: 225 }} />
                    </Form.Item>

                    {/* <DownloadButton icon={<DownloadOutlined />}>
                  Download
                </DownloadButton> */}
                  </FlexBox>
                </div>
              </CustomPanel>
            </>
          )}
      </CustomCollapse>
    </FunctionalityContainer>
  );
};

export default Functionality;
