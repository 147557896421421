import React from "react";
import { CustomDashboardTable, SpaceAroundContainer } from "components/UIElements";
import UPIDetailsList from "./UPIDetailsList";
import { PaymentGatewayEnum } from "context/Enums";


const TableTab = ({ 
    data, 
    columns, 
    pageNumber, 
    setPageNumber, 
    pageSize, 
    setPageSize, 
    total,
    expandableTable
  }: any) => {

  const PAYMENT_MODE_COMPARISON_COLUMNS: object[] = [
    {
      title: "Payment initiated with mode",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      width: '35%',
      render: (data:any, rowData: any) => (
        <>
         {/*// @ts-ignore */}
          {PaymentGatewayEnum[data?.toUpperCase()] ? PaymentGatewayEnum[data?.toUpperCase()] : (data||'-')}
        </>
        )

      // align: 'center',
    },
    {
      title: "Checkouts",
      dataIndex: "checkouts",
      key: "checkouts",
      width: '15%',
      // align: 'center',
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key:"orders",
      width: '15%'
      // align: 'center',
    },
    {
      title: "GMV",
      dataIndex: "gmvOrder",
      key:'gmvOrder',
      width: '15%',
      // align: 'center',
      render: (data:any, rowData: any) => (
        <>
          ₹{data}
        </>
        )
    },
    {
      title: "Percentage Completion",
      dataIndex: "percentConversion",
      key: 'percentConversion',
      // align: 'center',
      width: '20%',
      render: (data:any, rowData: any) => (
        <>
          {data ? `${data}%` : '-'}
        </>
        )
    }
];


  const paymentListExist:any = data?.filter((value:any) => value?.paymentMethod?.toUpperCase() === "UPI");



  return (

    <>
      <CustomDashboardTable 
        dataSource={data} 
        columns={PAYMENT_MODE_COMPARISON_COLUMNS} 
        bordered={true}
        pagination={
           {
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions : ['5', '10', '20', '30'], 
            total: total,
            current: pageNumber,
            onChange: (page, size) => {
              setPageNumber(page);
              setPageSize(size);
            },
            position: ["bottomRight"],
          }
        }
        expandable={
          expandableTable && 

          {
          defaultExpandAllRows: paymentListExist?.[0]?.paymentModeDataResponseList?.length ? true : false,
          expandedRowRender: (record:any) => (
            <div
            >
              {
                record?.paymentModeDataResponseList?.length > 0 ? 
                <div>
                {
                  <UPIDetailsList 
                    paymentModeDataResponseList = {record?.paymentModeDataResponseList} 
                  />
                }
              </div> : ''
}
            </div>
          ),
          rowExpandable: (record:any) => record?.paymentMethod === 'UPI',
        }
      
    }

      />
    </>
  );
};

export default TableTab;
