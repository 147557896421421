import React, { useContext, useEffect, useState } from "react";
import { CustomTabs, WalletContainer } from "./style";
import WalletRecharge from "components/Wallet/WalletRecharge";
import RechargeHistory from "components/Wallet/RechargeHistory";
import moment from 'moment'
import { CustomDatePicker } from "components/UIElements";
import { message } from "antd";
import DeductionHistory from "components/Wallet/DeductionHistory";
import { useLocation, useSearchParams } from "react-router-dom";
import RechargeSuccessModal from "components/Wallet/RechargeSuccessModal";
import { DataContext } from "context/DataProvider";
import NewDeductionHistory from "components/Wallet/DeductionHistory/NewDeductionHistory";
import NewRechargeHistory from "components/Wallet/RechargeHistory/NewRechargeHistory";
import { ToastWarningExclamation } from "images/icons";

const { TabPane } = CustomTabs;
const Wallet = () => {
    const location = useLocation()
    const [fromDate, setFromDate] = useState<string | null>(moment().subtract(6, 'days').format('DD-MM-YYYY'))
    const [toDate, setToDate] = useState<string | null>(moment().format('DD-MM-YYYY'))
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeKey, setActiveKey] = useState("1");
    const [rechargeSuccessModalVisible,setRechargeSuccessModalVisible] = useState(false)
    const [rechargeSuccessModalData,setRechargeSuccessModalData] = useState('')
    const { walletBalance,fetchingWalletDetails,walletDetails} = useContext(DataContext);
    useEffect(()=>{
        if(location.pathname.includes('/callback') && searchParams.get("status")){
            const code = searchParams.get("status")
            setRechargeSuccessModalData(code)
            setRechargeSuccessModalVisible(true)
            searchParams.delete('status')
            setSearchParams(searchParams)
        }
    },[location.pathname])


    const handleTabChange = (activeKey: any) => {
        setActiveKey(activeKey);
      };

    const disabledDates = (current: any) => {
        return current > moment()
    }
    
    return (
        <WalletContainer>
        <CustomTabs
        defaultActiveKey="2"
        onChange={handleTabChange}
        tabBarExtraContent={
            activeKey &&
              <CustomDatePicker
                allowClear={false}
                onChange={(dates: Array<moment.Moment>) => {
                  if (dates[1].diff(dates[0], "days") > 60) {
                    message.error(
                      "Date range can not exceed 60 days. Please select date range less than or equal to 60 days only.",
                      1.5
                    );
                  } else {
                    dates && dates[0]
                      ? setFromDate(dates[0].format("DD-MM-YYYY"))
                      : setFromDate(null);
                    dates && dates[1]
                      ? setToDate(dates[1].format("DD-MM-YYYY"))
                      : setToDate(null);
                  }
                }}
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(6, "d"), moment()],
                  "This Month": [moment().startOf("month"), moment()],
  
                  "Last 30 Days": [moment().subtract(29, "days"), moment()],
                }}
                defaultValue={
                  [
                  moment(fromDate, "DD-MM-YYYY"),
                  moment(toDate, "DD-MM-YYYY"),
                ]
              }
                disabledDate={disabledDates}
              />
          }
        >
            {/* <TabPane tab="RECHARGE" key="1">
                <WalletRecharge />
            </TabPane> */}
            <TabPane tab="DEDUCTION HISTORY" key="2">
                {/* <DeductionHistory fromDate={fromDate} toDate={toDate}/> */}
                <NewDeductionHistory fromDate={fromDate} toDate={toDate}/>

            </TabPane>
            <TabPane tab="RECHARGE HISTORY" key="3">
                {/* <RechargeHistory fromDate={fromDate} toDate={toDate}/> */}
                <NewRechargeHistory fromDate={fromDate} toDate={toDate}/>

            </TabPane>
        </CustomTabs>
        {rechargeSuccessModalVisible && 
        <RechargeSuccessModal
        rechargeSuccessModalVisible={rechargeSuccessModalVisible}
        setRechargeSuccessModalVisible={setRechargeSuccessModalVisible}
        rechargeSuccessModalData={rechargeSuccessModalData}
        setRechargeSuccessModalData={setRechargeSuccessModalData}
        />
        }
        </WalletContainer>
    )
}

export default Wallet