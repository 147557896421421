import axios from "axios";
import {GetAuthToken} from 'globalQuries'
const auth: any = GetAuthToken();

export const createDiscount = async (data:any) => {
    const auth: any = GetAuthToken();
    const response = await axios.post(`${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount`,
      data,
      {
        headers: {
          'X-AUTH-TOKEN': auth,   
        },
      }
    );
    // console.log('checking the data');
    return response.data.data;
};

export const updateDiscount = async (data:any,) => {
    const auth: any = GetAuthToken();
    const response = await axios.put(`${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount`,
      data,
      {
        headers: {
          'X-AUTH-TOKEN': auth, 
        },
      }
    );
    return response.data.data;

};

  export const getEditCouponDetails = async ({queryKey}:any) => {
    const auth: any = GetAuthToken();
    const [unusedArgument, couponId] = queryKey;
    if(couponId?.couponId){
      const response = await axios.get(`${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount/fetch?id=${couponId?.couponId}`,
      { 
        headers: {
          'X-AUTH-TOKEN': auth,   
        },
      }
    );
      return response.data.data;
    
    }


  };