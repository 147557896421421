import axios from 'axios';
import {GetAuthToken} from 'globalQuries'

const auth: any = GetAuthToken();
export const getCoupons = async({queryKey}:any) => {
    const auth: any = GetAuthToken();
    const [unusedArgument, {sellerId, discountTypeValue, couponCode}] = queryKey;

    if(sellerId) {
        const response = await axios.get(`${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount/automaticCoupons`, {
        params: {
            sellerId: sellerId,
            discountType: discountTypeValue, 
            coupon: couponCode
        },
        headers:{
            'X-Auth': auth  
        }       
        }) 
        return response?.data?.data;
    }
}

