import React from 'react'

const CustomInput = (props:any) => {
    const {width, input_type='text'} = props;    
    
  return (
    <input className={`h-10 ${width ? `w-${width}`: `w-[20rem]`} text-searchColor border rounded-lg border-outline shadow-sm pl-4 pr-2 focus:outline-none focus:border-focusPurple hover:border-focusPurple`} type={input_type} {...props} />
  )
}

export default CustomInput