import React, { useState, useEffect } from "react";
import { Row, Col, notification, Switch, Select, Input } from "antd";
import CreateDiscountModal from "./CreateDiscountModal";
import { useMutation, useQuery } from "react-query";
import { SearchOutlined} from '@ant-design/icons'
import { CustomInput } from "../../../pages/Orders/OrdersTab/styled.ts";
import {
  HeaderContainer,
  HeaderContent,
  HeaderTitle,
  FlexRow,
  CustomButton,
  CustomModal,
} from "./styled";

import {
  fetchDiscountData,
  fetchSuggestedDiscountData,
  getGeneralDetails,
  updateSellerCheckoutConfig,
  updateSuggestedDiscountPriority,
} from "../GeneralSettings/script";
import {
  DateTimeText,
  DiscountDescription,
  DiscountName,
  FlexStartContainer,
  ListContainer,
  TagsDiv,
} from "./DiscountCard/styled";

import { CustomTabs } from "../Shipping/style";
import DiscountTable from "./DiscountCard/DiscountTable";
import SuccessModal from "./CreateDiscountModal/successModal.js";
import ShopifySyncDiscountModal from "./ShopifySyncDiscountModal";
import { AddOnBeforeText, TableContainer } from "pages/Finance/styled";

const { TabPane } = CustomTabs;
const { Option } = Select


const Discount = () => {
  const [searchKey,setSearchKey]=useState("Coupon Code")
  const [searchTerm,setSearchTerm]=useState("")
  const [prevPageNumber,setPrevPageNumber]=useState(1)
  const [activeKey, setActiveKey] = useState("1");
  const [discountList, setDiscountList] = useState([]);
  const [editData, setEditData] = useState(null);
  const [key, setKey] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [editKey, setEditKey] = useState(null);
  const [isDiscountDone, setIsDiscountDone] = useState(false);
  const [isDiscountModalVisible, setIsDicountModalVisible] = useState(false);
  const handleTabChange = (activeKey) => {
    setActiveKey(activeKey);
  };
  const [suggestedDataSource, setSuggestedDataSource] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isShow, setIsShow]=useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOpenImportDiscountModal, setIsOpenImportDiscountModal] = useState(false);
  const [inputValue,setInputValue]=useState('')
  const { data: configData } = useQuery(["generalSettings"], getGeneralDetails);
  const id = configData?._id;

  const sellerConfigValues = {
    seller_id: configData?._id,
    domain_name: configData?.domain_name, 
    channel: configData?.platform_details?.channel,
  }

  const { isLoading: isLoadingAllDiscount, data: dataSource } = useQuery(
    ["fetchDiscountData", sellerConfigValues?.seller_id, pageSize, pageNumber],
    fetchDiscountData,
  );
  const { isLoading: isLoadingSuggestedDiscount } = useQuery(
    ["fetchSuggestedDiscountData", sellerConfigValues?.seller_id],
    fetchSuggestedDiscountData,
    {
      onSuccess: setSuggestedDataSource,
    }
  );

  const mutation = useMutation(updateSuggestedDiscountPriority, {
    onMutate: (variables) => {
      // A mutation is about to happen!
      // Optionally return a context containing data to use.
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      notification.success({ message: "Suggested Discounts updation failed." });
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: "Updated Suggested Discounts details." });
    },
  });

  const handleSave = (data) => {
    const discountIds = data?.discounts?.map((e) => {
      return e.id;
    });
    const postData = {
      sellerId: id,
      discountIds: discountIds,
    };
    mutation.mutate(postData);
    setIsShow(false);
  };

  const handleEdit = (couponId, index) => {
    setEditData(couponId);
    setIsDicountModalVisible(true);
    setEditKey(Math.random() * 10001);
    setKey(`${Math.random()}`);
  };

  const handleCancel = () => {
    setEditData(null);
    setIsDicountModalVisible(false);
    setIsDiscountDone(false);
    setKey(`${Math.random()}`);
  };

  const handleCreate = () => {
    setIsDicountModalVisible(true);
  };

  const handleOpenImportDiscountModal = () => {
    setIsOpenImportDiscountModal(true)
  }

  const onSearchKeyChange=(value)=>{
    setSearchKey(value)
  }

  // const selectBefore = (
  //   <Select 
  //       value={searchKey}
  //       className="select-before" 
  //       style={{width:'150px', textAlign:'left', marginLeft:'10px', marginTop:'0.05px'}}
  //       onChange={onSearchKeyChange}

  //   >
  //     <Option value="CouponId">Coupon Code</Option>
  //   </Select>
  // )

  const onSearch = (value) => {
    setSearchTerm(value)
    setPageNumber(1)
}

  const props = {
    dataSource: dataSource,  
    pageNumber: pageNumber,
    setPageNumber: setPageNumber,
    handleEdit: handleEdit,
    isLoading:
      activeKey === "1" ? isLoadingAllDiscount : isLoadingSuggestedDiscount,
    pageSize: pageSize,
    setPageSize: setPageSize,
    suggestedDataSource: suggestedDataSource,
    setSuggestedDataSource: setSuggestedDataSource,
    setIsShow: setIsShow,
    isShow: isShow,
    sellerConfigValues,
    searchTerm:searchTerm,
    setSearchTerm:setSearchTerm,
    setInputValue:setInputValue
  };

  return (
    <>
      <Row gutter={[16, 16]} style={{ background: "#f0f2f5" }}>
        <Col span={24}>
          <HeaderContainer
            style={{
              marginRight: "20px",
              height: "auto",
            }}
          >
            <HeaderContent
              style={{
                borderRadius: "10px 10px 0px 0px",
                background: "#F5F9FF",
              }}
            >
              <FlexRow>
                <HeaderTitle>Discounts</HeaderTitle>
                <FlexRow style={{gap: 20}}>
                    <CustomButton 
                       onClick={handleOpenImportDiscountModal}
                       style={{
                        background: 'transparent',
                        color: '#0c64db',
                      }}
                    >
                      <span style={{display:"flex",alignItems:'center'}}>
                      <img 
                        src= "https://pickrr.s3.amazonaws.com:443/2023-06-09T17:54:02.946240_shopify_icon.svg" 
                        alt = "shopify-icon"
                        style={{width: '16px', verticalAlign: 'middle', marginLeft: '-5px', marginRight: '3px'}}
                      />
                        Import Discount
                        </span>
                    </CustomButton>
                    <CustomButton onClick={handleCreate}>Create</CustomButton>
                </FlexRow>
              </FlexRow>
            </HeaderContent>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TableContainer>
                  <FlexRow>
                    <FlexRow >
                        <SearchOutlined style={{zIndex:'1', marginLeft:'10px', color: '#0C3784'}}/>
                        <CustomInput
                          addonBefore={<AddOnBeforeText>Coupon Code</AddOnBeforeText>}
                          style={{marginLeft:'-25px'}}
                          placeholder="Search..."
                          onPressEnter={(e) => {
                            if(!inputValue) {
                                setPrevPageNumber(pageNumber)
                            }
                            if(e.target.value) {
                                onSearch(e.target.value)
                              }
                            
                        }
                          }
                          onChange={(e) => {
                            setInputValue(e.target.value)
                            if(!e.target.value) {
                              onSearch('')
                              setPageNumber(prevPageNumber)
                            }
                        }}
                        value = {inputValue}
                        />
                    </FlexRow>
                  </FlexRow>
                </TableContainer>
              </Col>

            <CustomTabs
              defaultActiveKey={activeKey}
              onChange={handleTabChange}
              key={activeKey}
            >
              <TabPane tab="All Discount" key="1">
                <DiscountTable {...props} isSortVisible={false} />
              </TabPane>
              <TabPane tab="Suggested Discounts" key="2">
                <DiscountTable
                  {...props}
                  isSortVisible={true}
                  handleSave={handleSave}
                />
              </TabPane>
            </CustomTabs>
          </HeaderContainer>
        </Col>
      </Row>

      <CustomModal
        title={null}
        centered
        visible={isDiscountModalVisible}
        // visible={true}
        footer={null}
        width={836}
        onCancel={handleCancel}
        closable={false}
        key = {key}
        keyboard= {true}
      >
        <CreateDiscountModal
          handleCancel={handleCancel}
          // handleAddDiscount={handleAddDiscount}
          // handleUpdate={handleUpdate}
          couponKey={key}
          couponId={editData}
          editKey = {editKey}
          isDiscountDone={isDiscountDone}
          setIsSuccessModalOpen = {setIsSuccessModalOpen}
          sellerConfigValues = {sellerConfigValues}
        />
      </CustomModal>
      <SuccessModal 
        isSuccessModalOpen = {isSuccessModalOpen}
        setIsSuccessModalOpen = {setIsSuccessModalOpen}
        main_heading={"Discount code successfully configured !"}
      />
      <ShopifySyncDiscountModal 
        sellerConfigValues = {sellerConfigValues}
        isOpenImportDiscountModal = {isOpenImportDiscountModal} 
        setIsOpenImportDiscountModal = {setIsOpenImportDiscountModal}
        shopifyKey = {Math.random() * 10.1} 
      />
    </>
  );
};

export default Discount;