import React, { useState, useRef, useEffect} from 'react'
import { Space, Divider } from 'antd'
import { CustomCheckbox, ApplyButton, ResetButton } from './styled'
import { FlexRow } from 'components/Settings/Discount/styled'

interface IFilter {
    filters: string[],
    apply: React.Dispatch<React.SetStateAction<string[]>>
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    presentFilter: string[],
    allowMultiple?: boolean,
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
}


const Filter = ({filters, apply, setVisible, presentFilter, allowMultiple, setPageNumber}: IFilter) => {

    const [checkedList, setCheckedList] = useState([])

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
          function handleClickOutside(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setVisible(false)
              setCheckedList(presentFilter)
            }
          }
          window.onmousedown=handleClickOutside;
          return () => {
            window.onmousedown=()=>{}
          };
        }, [ref, presentFilter]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const onChange = (value: string) => {
        if(allowMultiple) {
            !checkedList.includes(value) ?
            setCheckedList([...checkedList, value]) :
            setCheckedList(checkedList.filter(filter => filter !== value))
        } else {
            setCheckedList([value])
        }
    }

    return (
        <div style={{padding:'20px'}} ref={wrapperRef}>
           <CustomCheckbox.Group value={checkedList}>
               <Space direction="vertical">
                   {
                       filters.map((filter) => {
                           return (
                            <CustomCheckbox 
                                value={filter} 
                                onChange={(e) => onChange(e.target.value)}
                            >
                            {filter[0].toUpperCase() + filter.substring(1)}
                            </CustomCheckbox>
                           )
                       })
                   }
                </Space>
           </CustomCheckbox.Group>
           <Divider />
           <FlexRow style={{marginTop:'-10px'}}>
               <ResetButton
               onClick={() => {
                   if(checkedList.length) {
                        setCheckedList([])
                    }
                    if(presentFilter.length) {
                        setPageNumber(1)
                        apply([])
                        setVisible(false)
                    }
                }}
               >
                   Reset
               </ResetButton>
               <ApplyButton 
                onClick={() => {
                    if(checkedList.length) {
                        setPageNumber(1)
                        apply(checkedList)
                        setVisible(false)
                    }
                }}>
                   Apply
                </ApplyButton>
           </FlexRow> 
        </div>   
    )
}

export default Filter