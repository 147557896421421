import { PickrrCard } from "components/UIElements";
import styled from "styled-components";

export const CustomChannelIcon = styled.div`
  margin: 10px;
  border-radius: 10px;
  background-color: #0c64db1a;
  width: 155px;
  height: 155px;
  padding: 2px;
`;
export const ImgBox = styled.div`
  background-color: white;
  width: 151px;
  height: 115px;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
`;
export const ChannelText = styled.div`
font-size: 16px;
    text-align: center;
    color: #263F97;
    font-weight: 600;
    margin-top: 6px;

`
