import React, { useEffect, useState } from 'react'
import { Tabs, message,Select } from 'antd'
import { CustomDatePicker, FlexBox } from 'components/UIElements'
import moment from 'moment'
import OrdersTab from './OrdersTab'
import { useLocation } from 'react-router-dom'
import OrdersTabNew from './OrdersTab/OrdersTabNew'
import CustomSelectTw from 'UiComponents/Select/dropdown'
//import ErrorOrdersTab from './ErrorOrdersTab'

const { TabPane } = Tabs

const Orders = () => {
  const { Option } = Select

  const [fromDate, setFromDate] = useState<string | null>(moment().subtract(6, 'days').format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState<string | null>(moment().format('YYYY-MM-DD'))
  const [source, setSource] = useState<string>('all')
  const { state } : any = useLocation();
  const addOnBefore = [
    { value: 'all', label: 'All' },
    { value: 'fastrr', label: 'Fastrr' },
    { value: 'online-store', label: 'Online Store' },
    { value: 'others', label: 'Others' }
];




  useEffect(()=>{
    if(state){
      setFromDate(state?.fromDate)
      setToDate(state?.toDate)
      setSource(state?.source)
    }
  },[state])

  const disabledDates = (current: any) => {
    return current > moment()
  }

  const onSourceChange = (value: string) => {
    setSource(value);
  }

  return (
    <>
      <Tabs
        tabBarExtraContent={{
          left: (
            <div className='flex items-center'>
              <div
              className="flex bg-white border border-outline h-10 w-24 rounded-l-md justify-center items-center text-sortByColor"
              >
                Channel
              </div>
              <CustomSelectTw addOnBefore={addOnBefore} onSearchKeyChange={onSourceChange} searchKey={source}/>
              {/* <Select
              id="source"
                value={source}
                style={{
                  width: "150px",
                  textAlign: "left",
                }}
                onChange={onSourceChange}
              >
                <Option value="fastrr">Fastrr</Option>
                <Option value="online-store">Online-Store</Option>
                <Option value="others">Others</Option>
                <Option value="all">All</Option>
              </Select> */}
            </div>
          ),
          right: (
            <CustomDatePicker
              allowClear={false}
              onChange={(dates: Array<moment.Moment>) => {
                if (dates[1].diff(dates[0], "days") > 60) {
                  message.error(
                    "Date range can not exceed 60 days. Please select date range less than or equal to 60 days only.",
                    1.5
                  );
                } else {
                  dates && dates[0]
                    ? setFromDate(dates[0].format("YYYY-MM-DD"))
                    : setFromDate(null);
                  dates && dates[1]
                    ? setToDate(dates[1].format("YYYY-MM-DD"))
                    : setToDate(null);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, "days"),
                  moment().subtract(1, "days"),
                ],
                "Last 7 Days": [moment().subtract(6, "d"), moment()],
                "This Month": [moment().startOf("month"), moment()],

                "Last 30 Days": [moment().subtract(29, "days"), moment()],
              }}
              defaultValue={state ?
                [
                  moment(state?.fromDate, "YYYY-MM-DD"),
                moment(state?.toDate, "YYYY-MM-DD"),
                ]
                :
                [
                moment(fromDate, "YYYY-MM-DD"),
                moment(toDate, "YYYY-MM-DD"),
              ]
            }
              disabledDate={disabledDates}
            />
          ),
        }}
      >
        <TabPane tab="" key="1">
          <OrdersTabNew fromDate={fromDate} toDate={toDate} source={source} state = {state}/>
        </TabPane>
        {/* <TabPane tab="Error Orders" key="2">
          <ErrorOrdersTab />
        </TabPane> */}
      </Tabs>
    </>
  );
}

export default Orders
