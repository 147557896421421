import React, { useState, useEffect, useContext } from "react";
import { Divider, Layout, Menu, Skeleton, notification } from "antd";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { FlexBox, HeaderIcon, PickrrHeading } from '../UIElements';

import { ContentX, CustomDivider, LogoContainer, CustomMenu, NavLinks, RechargeButton, SkeletonX, PricingButton } from './style';
import { useQuery } from 'react-query';
import { GetAuthToken } from 'globalQuries';
import axios from 'axios';
import { DataContext } from 'context/DataProvider';
import RechargeWalletModal from 'components/CommonComponents/RechargeWalletModal';
import PricingModal from 'components/CommonComponents/PricingModal';
import PricingModalNew from 'components/CommonComponents/PricingModalNew';
import { fetchRatesDetail } from 'components/CommonComponents/PricingModal/script';
import { is_local, routePermission} from "utils/functions";
import SrLogin from "pages/SrLogin";

const { Header, Sider, Content } = Layout;

const FastrrLayout = ({ children, header }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [defaultSelectedKey, setDefaultSelectedKey] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [openKey, setOpenkey] = useState([]);
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);
  const [isViewPricingModalVisible, setIsViewPricingModalVisible] =
    useState(false);

  const [srDomainList, setSrDomainList] = useState();
  const [cid,setCid] = useState()
  const [srLoginError, setSrLoginError] = useState();
  const urlSearchParams = new URLSearchParams(window.location.search);    
  const token = urlSearchParams.get('token');


  const handleToggle = () => {
    setCollapsed((prev) => !prev);
  };

  const {
    onboardVersion,
    isRateApproved,
    walletBalance,
    fetchingWalletDetails,
    walletDetails,
    walletError,
    sellerDomain, 
    channelPlatForm,
    platFormDetails
  } = useContext(DataContext);
   

  const domainName = sellerDomain
  const { data : ratesData, isLoading, isError } = useQuery(
    ['rateDetails', {domainName}],
    fetchRatesDetail,
    {enabled : !token}
  ); 
  useEffect(()=>{
    if(ratesData && (ratesData?.COD?.length > 0 || ratesData?.prepaid?.length > 0)){
      setIsPricingModalVisible(true)
    }else{
      setIsPricingModalVisible(false)
    }

  },[ratesData])
  const SiderIcon = ({ src, alt, submenu }) => {
    return <img src={src} alt={alt} className={submenu ? "submenu" : null} />;
  };
  useEffect(() => {
    if (location.pathname.includes("settings")) {
      setDefaultSelectedKey("settings");
      is_local();
    } else if (location.pathname === "/") {
      let routes = localStorage?.getItem('panel')?.length && JSON?.parse(localStorage?.getItem('panel'))?.slice(1, -1)?.split(',');
      routes = routes?.map(function(item) {
          return item?.trim();
      });

      if(routes?.includes('dashboard')){
        setDefaultSelectedKey("/dashboard");
        navigate("dashboard");
      }else {
        setDefaultSelectedKey(`/${routes[0]}`);
        navigate(`${routes[0]}`);
      }
    } else {
      setDefaultSelectedKey(location.pathname);
    }
  }, [location.pathname]);

  const { SubMenu } = Menu;

  useEffect(() => {
    // setDefaultSelectedKey(location.pathname);
    location.pathname === "/finance" ||
    location.pathname === "/refunds" ||
    location.pathname === "/wallet" ||
    location.pathname === "/settlementhistory"
      ? setOpenkey(["sub1"])
      : setOpenkey([]);
  }, [location.pathname]);



  const fetchSrAccounts = async (token) => {
    try{
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/fetch-sr-checkout-accounts`,
      {
        headers: {
          token : token
        },
      }
    );

    if(response){
      setSrDomainList(response?.data?.data?.account_info_list);   
      setCid(response?.data?.data?.company_id)
    }
    }catch(error){
      setSrLoginError('something went wrong')
    }
  };

  useEffect(() => {
    if(token){
      fetchSrAccounts(token);
    }
  }, [token])



  return (
    <>
      {((!walletError || channelPlatForm !== 'custom') || is_local() || fetchingWalletDetails ) && !token ? (
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <CustomDivider>
              <a href="/">
                <LogoContainer>
                  {collapsed ? (
                    <img
                      src="https://d10srchmli830n.cloudfront.net/1619706125024_ws-logo.svg"
                      style={{
                        height: "32px",
                        margin: "16px 10px",
                      }}
                      alt="logo"
                    />
                  ) : (
                    <img
                      src="https://pickrr.s3.amazonaws.com:443/2022-03-24T12:41:57.890245_Layer_1.svg"
                      style={{
                        height: "32px",
                        margin: "16px 10px",
                      }}
                      alt="logo"
                    />
                  )}
                </LogoContainer>
              </a>
              <Divider />
            </CustomDivider>
            <CustomMenu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["/settings/general-settings"]}
              selectedKeys={[defaultSelectedKey]}
              openKeys={openKey}
              onOpenChange={(keys) => setOpenkey(keys)}
            >
              {/* <Menu.Item
            key="dashboard"
            icon={
              <Icon
                component={() => (
                  <SiderIcon
                    src={
                      defaultSelectedKey === 'dashboard'
                        ? 'https://pickrr.s3.amazonaws.com:443/2022-03-24T12:32:40.983390_Group 323.svg'
                        : 'https://pickrr.s3.amazonaws.com:443/2022-03-24T13:47:16.558222_Home.svg'
                    }
                    alt="dashboard"
                    submenu={true}
                  />
                )}
                className="menu-icon"
              />
            }
          >
            <Link to="/dashboard"> Dashboard</Link>
          </Menu.Item> */}
              {/* <Menu.Item
            key="dashboard"
            icon={
              <Icon
                component={() => (
                  <SiderIcon
                    src={
                      defaultSelectedKey === 'dashboard'
                        ? 'https://pickrr.s3.amazonaws.com:443/2022-03-24T12:32:40.983390_Group 323.svg'
                        : 'https://pickrr.s3.amazonaws.com:443/2022-03-24T13:47:16.558222_Home.svg'
                    }
                    alt="dashboard"
                    submenu={true}
                  />
                )}
                className="menu-icon"
              />
            }
          >
           <Link to="/settings/dashboard"> Dashboard</Link>   
          </Menu.Item> */}
              {/* <Menu.Item
            key="orders"
            style={
              selectedKey === 'orders'
                ? { background: '#fff', color: '#000B34' }
                : null
            }
            icon={
              <Icon
                component={() => (
                  <SiderIcon
                    src={
                      defaultSelectedKey === '/orders'
                        ? 'https://pickrr.s3.amazonaws.com:443/2022-03-24T13:48:10.962082_Group (6).svg'
                        : 'https://pickrr.s3.amazonaws.com:443/2022-03-24T12:54:07.470433_Group (5).svg'
                    }
                    alt="dashboard"
                    submenu={true}
                  />
                )}
                className="menu-icon"
              />
            }
          >
            <Link to="/orders">Orders</Link>
          </Menu.Item> */}
            {routePermission('dashboard') &&
              <Menu.Item
                key="/dashboard"
                style={
                  selectedKey === "/dashboard"
                    ? { background: "#fff", color: "#000B34" }
                    : null
                }
                icon={
                  <Icon
                    component={() => (
                      <SiderIcon
                        src={
                          defaultSelectedKey === "/dashboard"
                            ? "https://d10srchmli830n.cloudfront.net/1653463611605_9141cc26-5471-48f2-b83e-00ef1730b3fc_HomeDashboardSelectedIcon.svg"
                            : "https://d10srchmli830n.cloudfront.net/1653463751635_f37542cd-e4cc-4b33-97e4-0ca8bd59344b_HomeDashboardIcon.svg"
                        }
                        alt="orders"
                        //submenu={true}
                      />
                    )}
                    className="menu-icon"
                  />
                }
              >
                <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
            }

              {(channelPlatForm === 'custom' && platFormDetails?.custom?.login) ? 
              <Menu.Item
                key="/userdata"
                style={
                  selectedKey === "/userdata"
                    ? { background: "#fff", color: "#000B34" }
                    : null
                }
                icon={
                  <Icon
                    component={() => (
                      <SiderIcon
                        src={
                          defaultSelectedKey === "/userdata"
                            ? "https://d10srchmli830n.cloudfront.net/1651044306804_GroupActiveOrders.svg"
                            : "https://d10srchmli830n.cloudfront.net/1651044379845_GroupOrders.svg"
                        }
                        alt="userdata"
                        //submenu={true}
                      />
                    )}
                    className="menu-icon"
                  />
                }
              >
                <Link to="/userdata">User Data</Link>
              </Menu.Item>
              : ''}

              
            {(!platFormDetails?.custom?.login)? 
              <>
              {routePermission('orders') && 
                <Menu.Item
                  key="/orders"
                  style={
                    selectedKey === "/orders"
                      ? { background: "#fff", color: "#000B34" }
                      : null
                  }
                  icon={
                    <Icon
                      component={() => (
                        <SiderIcon
                          src={
                            defaultSelectedKey === "/orders"
                              ? "https://d10srchmli830n.cloudfront.net/1651044306804_GroupActiveOrders.svg"
                              : "https://d10srchmli830n.cloudfront.net/1651044379845_GroupOrders.svg"
                          }
                          alt="orders"
                          //submenu={true}
                        />
                      )}
                      className="menu-icon"
                    />
                  }
                  disabled={
                    !fetchingWalletDetails &&
                    walletBalance < walletDetails?.current_tolerance
                  }
                >
                  <Link to="/orders">Orders</Link>
                </Menu.Item>
              }
                <>  
                {(routePermission('finance') || 
                routePermission('refunds') || 
                routePermission('wallet') || 
                routePermission('settlementhistory')) &&
                  <SubMenu
                    icon={
                      <Icon
                        component={() => (
                          <SiderIcon
                            src={
                              defaultSelectedKey === "/finance"
                                ? "https://pickrr.s3.amazonaws.com:443/2022-06-19T23:42:01.874858_finance-icon.svg"
                                : "https://pickrr.s3.amazonaws.com:443/2022-06-19T23:42:01.874858_finance-icon.svg"
                            }
                            alt="finance"
                            submenu={true}
                          />
                        )}
                        className="menu-icon"
                      />
                    }
                    title={"Finance"}
                    key="sub1"
                    submenu={true}
                  >
                    {routePermission('finance') && 
                    <Menu.Item
                      key="/finance"
                      style={
                        selectedKey === "/finance"
                          ? { background: "#fff", color: "#000B34" }
                          : null
                      }
                      icon={
                        <Icon
                          component={() => (
                            <SiderIcon
                              src={
                                "https://pickrr.s3.amazonaws.com:443/2022-06-20T12:34:51.890207_Ellipse 2668.svg"
                              }
                              alt="finance"
                              //submenu={true}
                            />
                          )}
                          className="menu-icon"
                        />
                      }
                    >
                      <Link to="/finance">Customer Finance</Link>
                    </Menu.Item>
                    }
                   {routePermission('refunds') &&
                    <Menu.Item
                      key="/refunds"
                      style={
                        selectedKey === "/refunds"
                          ? { background: "#fff", color: "#000B34" }
                          : null
                      }
                      icon={
                        <Icon
                          component={() => (
                            <SiderIcon
                              src={
                                "https://pickrr.s3.amazonaws.com:443/2022-06-20T12:34:51.890207_Ellipse 2668.svg"
                              }
                              alt="finance"
                              //submenu={true}
                            />
                          )}
                          className="menu-icon"
                        />
                      }
                    >
                      <Link to="/refunds">Refunds</Link>
                    </Menu.Item>
                    }
                    {routePermission('wallet') &&
                    <Menu.Item
                      key="/wallet"
                      style={
                        selectedKey === "/wallet"
                          ? { background: "#fff", color: "#000B34" }
                          : null
                      }
                      icon={
                        <Icon
                          component={() => (
                            <SiderIcon
                              src={
                                "https://pickrr.s3.amazonaws.com:443/2022-06-20T12:34:51.890207_Ellipse 2668.svg"
                              }
                              alt="wallet"
                              //submenu={true}
                            />
                          )}
                          className="menu-icon"
                        />
                      }
                    >
                      <Link to="/wallet">Wallet</Link>
                    </Menu.Item>
                    }
                    {routePermission('settlementhistory') &&
                    <Menu.Item
                      key="/settlementhistory"
                      style={
                        selectedKey === "/settlementhistory"
                          ? { background: "#fff", color: "#000B34" }
                          : null
                      }
                      icon={
                        <Icon
                          component={() => (
                            <SiderIcon
                              src={
                                "https://pickrr.s3.amazonaws.com:443/2022-06-20T12:34:51.890207_Ellipse 2668.svg"
                              }
                              alt="wallet"
                              //submenu={true}
                            />
                          )}
                          className="menu-icon"
                        />
                      }
                    >
                      <Link to="/settlementhistory">Settlement History</Link>
                    </Menu.Item>
                    }

                  </SubMenu>
                }
                </>
              </>
              : ""}

           {routePermission('user-management') && 
               <Menu.Item
                key="/user-mangement"
                style={
                  selectedKey === "/user-mangement"
                    ? { background: "#fff", color: "#000B34" }
                    : null
                }
                icon={
                  <Icon
                    component={() => (
                      <SiderIcon
                        src={
                          defaultSelectedKey === "/user-mangement"
                            ? "https://d10srchmli830n.cloudfront.net/1651044306804_GroupActiveOrders.svg"
                            : "https://d10srchmli830n.cloudfront.net/1651044379845_GroupOrders.svg"
                        }
                        alt="orders"
                        //submenu={true}
                      />
                    )}
                    className="menu-icon"
                  />
                }
              >
                <Link to="/user-mangement">UserMangement</Link>
              </Menu.Item>
             }  

              {onboardVersion && onboardVersion !== "v3" && (
                <>
                  <Menu.Item
                    key="/rto"
                    style={
                      selectedKey === "/rto"
                        ? { background: "#fff", color: "#000B34" }
                        : null
                    }
                    icon={
                      <Icon
                        component={() => (
                          <SiderIcon
                            src={
                              defaultSelectedKey === "/rto"
                                ? "https://pickrr.s3.amazonaws.com:443/2022-03-24T14:53:00.481370_return-product 2 3 (2).svg"
                                : "https://pickrr.s3.amazonaws.com:443/2022-03-24T12:57:48.529236_return-product 2 3 (1).svg"
                            }
                            alt="dashboard"
                            submenu={true}
                          />
                        )}
                        className="menu-icon"
                      />
                    }
                  >
                    <Link to="/rto">RTO Tracker</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="/edd-tracker"
                    style={
                      selectedKey === "/edd-tracker"
                        ? { background: "#fff", color: "#000B34" }
                        : null
                    }
                    icon={
                      <Icon
                        component={() => (
                          <SiderIcon
                            src={
                              defaultSelectedKey === "/edd-tracker"
                                ? "https://pickrr.s3.amazonaws.com:443/2022-03-24T14:53:35.733644_Vector (4).svg"
                                : "https://pickrr.s3.amazonaws.com:443/2022-03-24T12:58:53.807457_ic_outline-watch-later.svg"
                            }
                            alt="edd"
                            submenu={true}
                          />
                        )}
                        className="menu-icon"
                      />
                    }
                  >
                    <Link to="/edd-tracker">EDD Tracker</Link>
                  </Menu.Item>
                </>
              )}

              {routePermission('abandoned-cart') &&
                <Menu.Item
                  key="/abandoned-cart"
                  style={
                    selectedKey === "/abandoned-cart"
                      ? { background: "#fff", color: "#000B34" }
                      : null
                  }
                  icon={
                    <Icon
                      component={() => (
                        <SiderIcon
                          src={
                            defaultSelectedKey === "/abandoned-cart"
                              ? "https://pickrr.s3.amazonaws.com:443/2022-03-24T14:54:08.369868_Buy (1).svg"
                              : "https://pickrr.s3.amazonaws.com:443/2022-03-24T13:01:32.241886_Buy.svg"
                          }
                          alt="abandoned"
                          submenu={true}
                        />
                      )}
                      className="menu-icon"
                    />
                  }
                  disabled={
                    !fetchingWalletDetails &&
                    walletBalance < walletDetails?.current_tolerance
                  }
                >
                  <Link to="/abandoned-cart">Abandoned Carts</Link>
                </Menu.Item>
              }

              {onboardVersion && onboardVersion !== "v3" && (
                <Menu.Item
                  key="/customers"
                  style={
                    selectedKey === "/customers"
                      ? { background: "#fff", color: "#000B34" }
                      : null
                  }
                  icon={
                    <Icon
                      component={() => (
                        <SiderIcon
                          src={
                            defaultSelectedKey === "/customers"
                              ? "https://pickrr.s3.amazonaws.com:443/2022-03-24T14:54:42.433966_Vector (5).svg"
                              : "https://pickrr.s3.amazonaws.com:443/2022-03-24T13:02:24.816393_Vector (3).svg"
                          }
                          alt="customers"
                          submenu={true}
                        />
                      )}
                      className="menu-icon"
                    />
                  }
                >
                  <Link to="/customers">Customers</Link>
                </Menu.Item>
              )}

              {((!platFormDetails?.custom?.login) || routePermission('settings/general-settings')) ?  
              <Menu.Item
                key="settings"
                style={
                  selectedKey === "settings"
                    ? { background: "#fff", color: "#000B34" }
                    : null
                }
                icon={
                  <Icon
                    component={() => (
                      <SiderIcon
                        src={
                          defaultSelectedKey === "settings"
                            ? "https://pickrr.s3.amazonaws.com:443/2022-03-24T14:57:53.537649_Setting.svg"
                            : "https://pickrr.s3.amazonaws.com:443/2022-03-24T13:09:49.546053_Setting.svg"
                        }
                        alt="dashboard"
                        submenu={true}
                      />
                    )}
                    className="menu-icon"
                  />
                }
              >
                <Link to="/settings/general-settings">Settings</Link>
              </Menu.Item>
              : ''}
            </CustomMenu>
          </Sider>
          <Layout className="site-layout">
            {
              header?.title?.toUpperCase() === "DASHBOARD" &&
              <div style={{
                display: 'flex', 
                background:'#FFF2D2',
                justifyContent:'space-between', 
                width:'100%', 
                height:'48px', 
                alignItems:'center',
                padding:'10px'
              }}>
              <div style={{display:'flex', alignItems:'center'}}>
                <h3 style={{margin:0}}>
                  See how Shiprocket checkout simplifies purchase journey for your customers.
                </h3>
                <div style={{
                    border:'2px solid #263F97', 
                    borderRadius:'5px',
                    color:'white',
                    background:'#263F97',
                    padding:'0px 5px',
                    fontWeight:'600',
                    cursor: 'pointer',
                    marginLeft:'5px'
                }} 
                onClick={() => {
                  window.open("https://calendly.com/checkoutsupport/shiprocket-checkout-training-1", "_blank")
                  }}
                >
                    Reserve your spot now
                </div>
              </div>
              <div style={{margin:'10px 4px 10px 0px'}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                  <span style={{color:'#263F97',fontWeight:'700'}}>Support Email:</span>
                  <span style={{marginTop:'-5px'}}>checkoutsupport@shiprocket.com</span>

                </div>
              </div>
            </div>
            }
            <Header
              className="site-layout-background"
              style={{ padding: 0, background: "#fff" }}
            >
              {/* {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: handleToggle,
            }
          )} */}

              {header ? (
                <FlexBox
                  style={{
                    alignItems: "center",
                    padding: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <HeaderIcon>
                <img
                  src={header?.iconLink && header?.iconLink}
                  alt="icon"
                  style={{
                    width: '48px',
                    height: '48px',
                  }}
                />
              </HeaderIcon> */}
                  <PickrrHeading size={3} style={{ padding: "8px" }}>
                    {header?.title}
                  </PickrrHeading>
                  <NavLinks
                    style={{
                      marginRight: "24px",
                    }}
                    color={walletBalance < 200 ? "red" : "#263f97"}
                  >
                    {((walletDetails?.wallet_balance<walletDetails?.current_tolerance)&&(walletDetails.
days_left_for_redirection<=15)&& (!platFormDetails?.custom?.login)) && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "white",
                          background: "red",
                          fontSize: "16px",
                          fontWeight: 600,
                          padding: "5px",
                          marginRight: "10px",
                          borderRadius: "4px",
                        }}
                      >
                       {walletDetails.
days_left_for_redirection>0? `Attention: Negative wallet threshold reached. Recharge within ${walletDetails.
  days_left_for_redirection} days to ensure seamless checkout`:`Negative wallet threshold reached.! Please recharge to ensure seamless checkout`}
                      </div>
                    )}
                    {(!platFormDetails?.custom?.login) &&
                    <>
                    <span
                      style={{
                        border: "0.5px solid #C5CDE3",
                        height: "20px",
                        marginRight: "20px",
                      }}
                    ></span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginRight: "16px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            lineHeight: 0.9,
                          }}
                        >
                          {`₹${walletBalance}`} 
                        </span>
                      </div>

                      {(!platFormDetails?.custom?.login) &&
                      <>
                        <PricingButton
                          icon={
                            <img
                              src="https://d10srchmli830n.cloudfront.net/1687763526253_5407ca44-a12d-4569-bd04-7327389ca3f5_search-1.svg"
                              alt="view pricing"
                              style={{ marginRight: "5px" }}
                            />
                          }
                          onClick={() => {
                            setIsViewPricingModalVisible(true);
                          }}
                        >
                          View Pricing
                        </PricingButton>
                        <RechargeButton
                          onClick={() => {
                            setWalletModalVisible(true);
                          }}
                        >
                          <img
                            src="https://d10srchmli830n.cloudfront.net/1638360947385_light.svg"
                            alt="charge"
                            style={{ marginRight: "10px" }}
                          />
                          Recharge
                        </RechargeButton>
                      </>
                      }
                    </div>
                    </>
                    }
                  </NavLinks>
                  {/* {header?.helperLink && (
                        <Link
                          style={{
                            color: '#263F97',
                            textDecoration: 'underline',
                            fontSize: '16px',
                            textDecorationLine: 'underline',
                            marginLeft: '16px',
                          }}
                          to={header?.helperLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            mixpanel.track('#HowItWorks', {
                              'Page URL': window.location.href,
                              'To URL': header?.helperLink,
                            });
                          }}
                        >
                          ?
                        </Link>
                      )} */}
            </FlexBox>
          ) : (
            <div></div>
          )}
        </Header>
        {fetchingWalletDetails ?
          <SkeletonX>
            <Skeleton style={{padding : '20px'}}/>
          </SkeletonX>
          :
          <ContentX className="site-layout-background">{children}</ContentX>
        }
        
        {(walletModalVisible && (!platFormDetails?.custom?.login)) &&
          <RechargeWalletModal walletModalVisible = {walletModalVisible} setWalletModalVisible={setWalletModalVisible} />
        }
      </Layout>
    </Layout>
      )
    :
    <>
      {token ? <SrLogin srAccountList={srDomainList} srLoginError = {srLoginError} cid = {cid}/> : 
      <div style={{display : 'flex', width :'100%', color : 'red', fontSize : '24px', fontWeight : 600, marginTop: '20px', justifyContent : 'center'}}>
        An error occurred! Please try again later.
      </div>
      }
    </>
    }
    {(ratesData && (!platFormDetails?.custom?.login)) &&
      <PricingModal
        isPricingModalVisible={isPricingModalVisible}
        setIsPricingModalVisible={setIsPricingModalVisible}
        ratesData={ratesData} 
        isLoading={isLoading} 
        isError={isError}
        />
    }
    {(isViewPricingModalVisible && (!platFormDetails?.custom?.login)) && 
      <PricingModalNew
      isViewPricingModalVisible={isViewPricingModalVisible}
      setIsViewPricingModalVisible={setIsViewPricingModalVisible}
      />
    }  
    </>
  );
};

export default FastrrLayout;
