import axios from "axios";
import { downloadFile } from "components/UIElements/function";
import { GetAuthToken } from "globalQuries";

const auth: any = GetAuthToken();

export const fetchCustomersData = async ({ queryKey }: any) => {
  const auth: any = GetAuthToken();
  const [
    unusedArgument,
    {
      pageNumber,
      fromDate,
      toDate,
      searchTerm,
      searchKey,
      rtoFilter,
      deliveryFilter,
      paymentFilter,
    },
  ] = queryKey;

  const response = await axios.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/customers`,
    {
      params: {
        page: pageNumber - 1,
        from: fromDate,
        to: toDate,
        ...(searchTerm && { key: searchKey }),
        ...(searchTerm && { value: searchTerm }),
        ...(deliveryFilter.length && {
          "addFilter.dMethod": deliveryFilter.join(","),
        }),
        ...(paymentFilter.length && { "addFilter.pMode": paymentFilter[0] }),
        ...(rtoFilter.length && { "addFilter.rtoPredict": rtoFilter[0] }),
      },
      headers: {
        "X-Auth": auth,
      },
    }
  );
  return response.data.data;
};

export const handleSingleDownload = async (
  rowId: string,
  fromDate: string,
  toDate: string
) => {
  const auth: any = GetAuthToken();
  const response = await axios.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=customer&type=CUSTOMER`,
    {
      params: {
        from: fromDate,
        to: toDate,
        value: rowId,
      },
      headers: {
        "X-Auth": auth,
      },
    }
  );
  downloadFile(response.data, "customerdata.csv");
};

export const handleMultipleDownload = async (
  fromDate: string,
  toDate: string,
  keys: string
) => {
  const auth: any = GetAuthToken();
  const response = await axios.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=customer&type=CUSTOMER`,
    {
      params: {
        from: fromDate,
        to: toDate,
        value: keys,
      },
      headers: {
        "X-Auth": auth,
      },
    }
  );
  downloadFile(response.data, "customerdata.csv");
};

export const handleAllDownload = async (
  fromDate: string,
  toDate: string,
  searchKey: string,
  searchTerm: string,
  rtoFilter: string[],
  deliveryFilter: string[],
  paymentFilter: string[]
) => {
  const auth: any = GetAuthToken();
  const response = await axios.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=CUSTOMER`,
    {
      params: {
        from: fromDate,
        to: toDate,
        ...(searchTerm && { key: searchKey }),
        ...(searchTerm && { value: searchTerm }),
        ...(deliveryFilter.length && {
          "addFilter.dMethod": deliveryFilter.join(","),
        }),
        ...(paymentFilter.length && { "addFilter.pMode": paymentFilter[0] }),
        ...(rtoFilter.length && { "addFilter.rtoPredict": rtoFilter[0] }),
      },
      headers: {
        "X-Auth": auth,
      },
    }
  );
  downloadFile(response.data, "customerdata.csv");
};
