import React from 'react';
import {
  Col,
  Row,
  Collapse,
  Badge,
  Divider,
  Input,
  Switch,
  Checkbox,
  Button,
  Radio,
  Form,
  Select,
  Upload,
  message,
} from 'antd';
import {
  DownOutlined,
  UpOutlined,
  PlusSquareOutlined,
  UploadOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Card,
  Heading,
  GenerateOTPButton,
  SubHeading,
  Text,
  FooterText,
  CouponInput,
  SelectionCard,
  DescriptionText,
  SwitchText,
  CustomPickrrButton,
  SelectedAddressText,
  CustomRadioGroup,
  CheckoutText,
  HelpText,
  GlobalStyle,
  DetailHeadingBackground,
  PageTitle,
  BackButton,
} from './style';
import {
  CustomInput,
  FlexBox,
  SpaceBetweenContainer,
  FlexColContainer,
  PickrrButton,
  FlexEndContainer,
  FlexContainer,
  PickrrHeading,
  CustomForm,
} from 'components/UIElements';
const OrderSummary = () => {
  const orderSummary = [
    {
      image:
        'https://cdn.shopify.com/s/files/1/1380/9925/products/images.jpg?v=1468259231',
      title: 'weee',
      price: 899,
      total_price: 8993,
    },
  ];

  return (
    <>
      <Card>
        <SpaceBetweenContainer style={{ alignItems: 'flex-start' }}>
          <Heading collapse={true} className="heading">
            Order Summary
          </Heading>
          <UpOutlined
            style={{ fontSize: '20px' }}
            // onClick={() => setCollapse(true)}
          />
          {/* )} */}
        </SpaceBetweenContainer>
        <SubHeading className="heading">Items</SubHeading>
        {orderSummary &&
          orderSummary?.items?.map((item, index) => (
            <Row gutter={16} className="mb-16" key={index}>
              <Col xl={20} lg={20} md={20} sm={16} xs={16}>
                <FlexBox style={{ alignItems: 'flex-start' }}>
                  <div className="mr-12">
                    <Badge count={item.quantity} color="#292A2C">
                      {/* <Image url="https://cdn.shopify.com/s/files/1/1380/9925/products/images.jpg?v=1468259231" /> */}
                      <img
                        src={item.image}
                        alt=""
                        style={{
                          height: '66px',
                          width: '66px',
                          border: '1px solid #d8d8d8',
                        }}
                      />
                    </Badge>
                  </div>
                  <FlexColContainer>
                    <Text className="body-element">{item.title}</Text>
                    {item?.properties && item?.properties['Coffee Grind'] && (
                      <Text
                        style={{
                          marginTop: '4px',
                          opacity: '0.7',
                          fontSize: '12px',
                        }}
                      >
                        Coffee Grind: {item?.properties['Coffee Grind']}
                      </Text>
                    )}
                  </FlexColContainer>
                  {/* <Text>{item.product_description}</Text> */}
                </FlexBox>
              </Col>
              <Col xl={4} lg={4} md={4} sm={8} xs={8}>
                <FlexEndContainer>
                  <Text>₹{(item.price / 100).toFixed(2)}</Text>
                </FlexEndContainer>
              </Col>
            </Row>
          ))}
        <Divider />
        {true && (
          <>
            <Row className="mb-24">
              <Col xl={16} lg={16} md={14} sm={24} xs={24}>
                <CouponInput
                  placeholder="Enter Coupon Code"
                  size="large"
                  className="mr-12"
                  disabled
                />
              </Col>
              <Col xl={8} lg={8} md={10} sm={24} xs={24}>
                <GenerateOTPButton
                  color="primary"
                  size="large"
                  style={{ width: '100%' }}
                  // loading={couponLoading}
                  className="btn-color body-element"
                >
                  Apply
                </GenerateOTPButton>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <SpaceBetweenContainer className="mb-16">
          <Text className="body-element">Subtotal</Text>
          <Text bold="true" className="body-element">
            ₹ 2000
          </Text>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer className="mb-16 body-element">
          <Text>Tax</Text>
          <Text>₹50</Text>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer className="mb-16 body-element">
          <Text>COD charges</Text>
          <Text>₹40</Text>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer className="mb-16 body-element">
          <Text>Prepaid Discount</Text>
          <Text>- ₹50</Text>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer className="mb-16 body-element">
          <Text>Coupon Discount</Text>
          <Text>- ₹40</Text>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer className="body-element">
          <Text>Shipping Charges</Text>
          <Text>₹434</Text>
        </SpaceBetweenContainer>

        <Divider />
        <SpaceBetweenContainer
          className="mb-24"
          style={{ alignItems: 'flex-start' }}
        >
          <FlexColContainer className="body-element">
            <Text bold="true">Total</Text>

            <Text style={{ fontSize: '12px' }}>Including ₹ 800 in taxes</Text>
          </FlexColContainer>
          <Text bold="true" className="body-element">
            ₹ 2434
          </Text>
        </SpaceBetweenContainer>
        <FlexContainer>
          <FlexColContainer
            style={{ alignItems: 'center', justifyContent: 'center' }}
          >
            <img
              src="https://d10srchmli830n.cloudfront.net/1642750810700_Fastrr-Logo.svg"
              alt=""
              className="mb-8"
              style={{ width: '55px' }}
            />
            <FooterText>Fastrr Powered Checkout</FooterText>
          </FlexColContainer>
        </FlexContainer>
      </Card>
    </>
  );
};

export default OrderSummary;
