import styled from 'styled-components';
import {Button, Table} from 'antd';


export const ModalHeading = styled.div`
    color: #000B34;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; 
    margin-right: 28px;
    
    .seeResponseHeading{
        margin-left: 10px;
    }
`

export const ExportButton = styled(Button)`
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    font-weight: 600;
    background: #FFF;
    font-size:12px;
    color: #344054;
    padding: 2px 10px;
`


export const CustomTable = styled(Table)`
    .ant-spin-container{
        border-radius: 6px;
        border: 1px solid #EDEBEB;
        background: #FFF;
        padding: 4px 12px;
        overflow:hidden;
    }

    .ant-table-thead > tr > th{
        color:#0C3784 !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 140%; 
    }

    .ant-table-tbody > tr > td{
        font-size:12px;
    }

`