import { Form, Radio, Select, Upload } from "antd";
// import { CustomInput, FlexBox } from "components/UIElements";
import { CustomInput } from "../styled";


import React, { useState } from "react";
import PhoneNumberSearch from "./PhoneNumberSearch";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  CustomerCriteriaContainer,
  CustomSelect,
  FastrrButton,
  HeadingText,
  Text,
} from "./styled";
import PinCodeSearch from "./PinCodeSearch";
const { Option } = Select;

const CustomerCriteria = ({
  type,
  customerType,
  setCustomerType,
  selectedPhoneNumbers,
  setSelectedPhoneNumbers,
  selectedPinCode,
  setSelectedPinCode,
  setOrderCount,
  orderCount
}: any) => {
  const uploadProps = {
    // onRemove: (file) => {
    //   setUploadFile([]);
    // },
    // beforeUpload: (file) => {
    //   setUploadFile([file]);
    //   return false;
    // },
  };
  const Regex = new RegExp(/^(0|[1-9]\d*)$/);
  const [isError,setIsError]=useState<Boolean>(false)
  const handleValidate=(e:any)=>{
    if (Regex.test(e.target.value)){
      setOrderCount(parseInt(e.target.value))
      setIsError(false)
    }
    else{
      setOrderCount()
      setIsError(true)
    }
  }

  return (
    <CustomerCriteriaContainer width={type === "amtCart" ? "95" : "45"}>
      <HeadingText>Select Customer type</HeadingText>
      <Form.Item
        name={"customerDiscount"}
        initialValue={"allCustomer"}
        // rules={[
        //   {
        //     required: true,
        //     message: "Please select the Customer type",
        //   },
        // ]}
      >
        <Radio.Group
          className="customer_radio"
          onChange={(e) => {
            setCustomerType(e.target.value);
          }}
        >
          <Radio value="allCustomer">All Customer</Radio>
          <Radio value="specificCustomers">Specific Customers</Radio>
          <Radio value="customer_with_x_orders">Customers with x orders</Radio>
          {/* <Radio value="risk">
            Risk
          </Radio>
          {customerType === "risk" && (
              <>
                <br />
                <Form.Item name="riskType">
                  <CustomSelect
                    height="small"
                    placeholder="risk type"
                    defaultValue={'lowRisk'}
                    // onChange
                  >
                    <Option value="lowRisk">Low Risk</Option>
                    <Option value="highRisk">High Risk</Option>
                  </CustomSelect>
                </Form.Item>
              </>
            )} */}
          {/* {!(type === "amtCart") && ( */}
            {/* <> */}
              {/* <Radio value="customer_in_pincode">Customers in Pincode</Radio> */}
            {/* </> */}
          {/* )} */}
        </Radio.Group>
      </Form.Item>
      {customerType === "specificCustomers" && (
        <>
          <PhoneNumberSearch
            selectedPhoneNumbers={selectedPhoneNumbers}
            setSelectedPhoneNumbers={setSelectedPhoneNumbers}
          />

          {/* <HeadingText className="mt-35">Upload Phone no. List</HeadingText>
          <FlexBox className="mb-16">
            <Upload {...uploadProps} showUploadList={false}>
              <FastrrButton
                color="light-blue"
                style={{ textTransform: "none" }}
                icon={<UploadOutlined />}
                className="mr-12"
                // loading={loading}
              >
                Upload CSV
              </FastrrButton>
            </Upload>
            <a
              href="https://d10srchmli830n.cloudfront.net/1632747241375_Sample PinCode File.csv"
              download
            >
              <Text>
                <DownloadOutlined className="mr-8" /> Download Sample CSV File
              </Text>
            </a>
          </FlexBox> */}
        </>
      )}
      {customerType==="customer_in_pincode" && (
        <PinCodeSearch selectedPinCode={selectedPinCode} setSelectedPinCode={setSelectedPinCode}/>
      )}
      {
        customerType==="customer_with_x_orders" && (
          <>
          <div style={{color:"#38446d"}}>Enter quantity : </div>
          <CustomInput 
            maxLength={6}  
            value={orderCount} 
            onChange={handleValidate}/>
          {isError && (
        <>
          <h1 style={{ color: "red", fontSize: "14px" }}>
            Please enter valid quantity.
          </h1>
        </>
      )}
          
          </>
        ) 
      }
    </CustomerCriteriaContainer>
  );
};

export default CustomerCriteria;
