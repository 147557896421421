
import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Select, Button, Checkbox, Tooltip, message } from 'antd'
import {TableContainer, CustomInput,Text} from './styled'
import { FlexRow } from 'components/Settings/Discount/styled'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import { CustomDatePicker, CustomTable, SpaceAroundContainer } from 'components/UIElements'
import moment from 'moment'
import { AlignType } from 'rc-table/lib/interface'
import {fetchAbandonedData, handleAllDownload} from './script'
import { useQuery } from 'react-query'
import { DataContext } from 'context/DataProvider'

const { Option } = Select


interface ICustomDetails {
  city: string
  country: string
  lastName: string
  name: string
  orders: number,
  phone: string,
  pincode: string,
  rtoOrders:number, 
  shippingAddress: string,
  email:string,
  invoiceValue: string
}


interface IUserDetails {
  createdAt: string
  userName: string
  phoneNumber: string
  email: string
  address: string,
  cartId: string,
  addressSource: string
  customerDetails?: ICustomDetails,
  stage: number
}

const CustomAbandonedCart = () => {
    const {sellerId,sellerDomain } = useContext(DataContext)
    const [inputValue, setInputValue] = useState('')
    const [tableData, setTableData] = useState<IUserDetails[]>([])
    const [selectedRows, setSelectedRows] = useState<IUserDetails[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [searchKey, setSearchKey] = useState<string>('phone')
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0)
    const {onboardVersion} = useContext(DataContext)
    const [fromDate, setFromDate] = useState<string | null>(moment().subtract(6, 'days').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState<string | null>(moment().format('YYYY-MM-DD'))
    const disabledDates = (current: any) => {
      return current > moment()
    }

    const {
        data: userData,
        isLoading,
      } = useQuery(
        ['fetchAbandonedData', { pageNumber, fromDate, toDate, searchTerm, ...(searchTerm && {searchKey})}],
        fetchAbandonedData,{cacheTime:0},
      )
      useEffect(() => {
        setTableData(userData?.logins)
      }, [userData])



    const onSelect = (record: IUserDetails, selected: boolean) => {
        if (selected) {
            setSelectedRowKeys((keys) => [...keys, record.cartId])
            setSelectedRows((rows) => [...rows, record])
        } else {
            setSelectedRowKeys((keys) => [...keys.filter(key => key !== record.cartId)])
            setSelectedRows((rows) => [...rows.filter(row => row.cartId !== record.cartId)])
        }
    }

    const onSelectAll = () => {
        setSelectedRowKeys((keys) => keys.length === tableData?.length ? [] : [...tableData?.map((key) => key.cartId)])
        setSelectedRows((rows) => rows.length === tableData?.length ? [] : [...tableData])
    }

    const onSearchKeyChange = (value: string) => {
        setSearchKey(value)
    }

    const onSearch = (value: string) => {
        setSearchTerm(value)
        setPageNumber(1)
    }

    // const sourceBackground:any = {
    //     'shiprocket':'#6526971A',
    //     'new':'#399F50',
    //     'pending':'#125CCC',
    //     'failed':'#D65745'
    //   }
    
    //   const sourceTextColor:any = {
    //     'shiprocket':'#652697',
    //     'success':'#ecfaef',
    //     'pending':'#125ccc1a',
    //     'failed':'#fff0ee'
    //   }

    const textSplit = (text:string) => {
        const split = text?.split('_');
        return split?.join(' ')
    }

    const stages:any = {
      25: 'Address Screen', 
      35: 'Custom Login'
    }



    let columns: object[] = [
        {
          title: 'Date | Time',
          key: 'createdAt',
          align: 'left' as AlignType,
          width:100, 
          render: (rowData: IUserDetails) => (
            <Text>
              {moment(rowData?.createdAt).format('DD-MM-YYYY') ?? '-'} <br />
              {moment(rowData?.createdAt).format('h:mm:ss') ?? '-'}
            </Text>  
          )
        },
        {
          title: 'Phone number',
          key: 'phone',
          align: 'left' as AlignType,
          render: (rowData: IUserDetails) => (
            <Text>{rowData?.customerDetails?.phone ?? '-'}</Text>  
          )
        },
        {
          title: 'Customer Name',
          key: 'userName',
          width:200, 
          align: 'left' as AlignType,
          render: (rowData: IUserDetails) => (
            <Text>{`${rowData?.customerDetails?.name ?? '-'} ${rowData?.customerDetails?.lastName ?? '-'}`}</Text>  
          )
        },

        // {
        //   title: 'Invoice Value (₹)',
        //   key: 'Invoice value',
        //   align: 'left' as AlignType,
        //   render: (rowData: IUserDetails) => (
        //     <Text>{rowData?.customerDetails?.invoiceValue ?? '-'}</Text>  
        //   )
        // },
        {
          title: 'Checkout Stage',
          key: 'Checkout_Stage',
          align: 'left' as AlignType,
          render: (rowData: IUserDetails) => (
            <Text>{rowData?.stage}</Text>  
          )
        },
        // {
        //   title: 'Action',
        //   fixed:'right',
        //   key: 'action',
        //   width: 210,
        //   align: 'center' as AlignType,
        //   render: (rowData: IUserDetails) => (
        //           <div onClick={(e) => {
        //                   e.stopPropagation()
        //                   // handleSingleDownload(rowData.cartId, fromDate, toDate)
        //               }} style= {{cursor: 'pointer'}}
        //           >
        //               <img
        //                   src="https://d10srchmli830n.cloudfront.net/1650360846221_Download.svg"
        //                   alt="download"
        //               />
        //               <Text
        //                   style={{ color: '#0C64DB', whiteSpace: 'nowrap' }}
        //               >
        //                   Download
        //               </Text>
        //           </div>
        // )
        // },
      ]

    const selectBefore = (
        <Select 
            value={searchKey}
            className="select-before" 
            style={{width:'150px', textAlign:'left', marginLeft:'10px'}}
            onChange={onSearchKeyChange}

        >
          <Option value="phoneNumber">Phone Number</Option>
        </Select>
      )

    return (
        <>

        <div style={{display:'flex', justifyContent:'flex-end', marginBottom: 20}}>
        <CustomDatePicker
              allowClear={false}
              onChange={(dates: Array<moment.Moment>) => {
                if (dates[1].diff(dates[0], "days") > 60) {
                  message.error(
                    "Date range can not exceed 60 days. Please select date range less than or equal to 60 days only.",
                    1.5
                  );
                } else {
                  dates && dates[0]
                    ? setFromDate(dates[0].format("YYYY-MM-DD"))
                    : setFromDate(null);
                  dates && dates[1]
                    ? setToDate(dates[1].format("YYYY-MM-DD"))
                    : setToDate(null);
                }
              }}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, "days"),
                  moment().subtract(1, "days"),
                ],
                "Last 7 Days": [moment().subtract(6, "d"), moment()],
                "This Month": [moment().startOf("month"), moment()],

                "Last 30 Days": [moment().subtract(29, "days"), moment()],
              }}
              defaultValue={
                [
                moment(fromDate, "YYYY-MM-DD"),
                moment(toDate, "YYYY-MM-DD"),
              ]
            }
              disabledDate={disabledDates}
            />

        </div>

        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <TableContainer>
                    <FlexRow>
                        <FlexRow>
                          {/* @ts-ignore */}
                            <SearchOutlined
                                style={{zIndex:'1', marginLeft:'10px', color: '#0C3784'}}
                            />
                            <CustomInput 
                                addonBefore={selectBefore}
                                style={{marginLeft:'-45px'}}
                                placeholder="Search..."
                                onPressEnter={(e: any) => {
                                    if(!searchTerm) {
                                        setPrevPageNumber(pageNumber)
                                    }
                                    if(e.target.value) {
                                        onSearch(e.target.value)
                                    }
                                }}
                                onChange={(e: any) => {
                                    setInputValue(e.target.value)
                                    if(!e.target.value) {
                                        onSearch('')
                                        setPageNumber(prevPageNumber)
                                    }
                                }}
                                value = {inputValue}
                            />
                        </FlexRow>
                        { tableData?.length > 0 &&
                        <Button type="primary" 
                        /* @ts-ignore */
                            icon={<DownloadOutlined />}
                            style={{background:'#0C64DB', borderRadius:'6.5px'}}
                            // onClick={() => {
                            //     selectedRowKeys.length ?
                            //     handleMultipleDownload(fromDate, toDate, selectedRowKeys.join(',')) :
                            //     handleAllDownload(fromDate, toDate, searchKey, searchTerm)
                            // }}

                            onClick={() => {handleAllDownload(fromDate, toDate, searchTerm, searchKey)}}


                        >
                          Download
                            {/* {selectedRowKeys.length ? `Download Selected (${selectedRowKeys.length})` : 'Download All'} */}
                        </Button>
                        }
                    </FlexRow>
                    <CustomTable
                        loading={isLoading}
                        dataSource={tableData}
                        columns={columns} 
                        style={{marginTop:'20px',}}
                        // rowSelection={{
                        //     selectedRowKeys,
                        //     type: 'checkbox',
                        //     onSelect: onSelect,
                        //     columnTitle: 
                        //     <Checkbox 
                        //         checked={tableData?.length && selectedRowKeys.length === tableData?.length} 
                        //         onChange={onSelectAll}
                        //         disabled={tableData?.length ? false : true}
                        //     />
                        // }}
                        rowKey={(record: IUserDetails) => (record.cartId)}
                        pagination={{
                            pageSize: 20,
                            showSizeChanger: false,
                            total: userData?.total,
                            current: pageNumber,
                            onChange: (page) => {
                              setPageNumber(page);
                            },
                            position: ['bottomRight'],
                          }}
                        //   onRow={(record:ITableRowData) => ({ 
                        //     onClick: () => { 
                        //         setDrawerData(record)
                        //         setDrawerKey(Math.random())
                        //         setDrawerVisible(true)
                        //         setActiveKey(1)
                        //      } 
                        //   })}
                          // scroll={{x : 2000}}
                    />
                </TableContainer>
            </Col>
        </Row>
        </>
    )
}

export default CustomAbandonedCart


