import React, {useState, useEffect} from 'react'
import { Container, FunnelContainer, BoxContainer, Title } from './styled'
import { FunnelChart, Funnel, ResponsiveContainer } from 'recharts'
import StageInfoView from './StageInfoView'
import DropOffView from './DropOffView'
import { IConversion, IDropOff } from '../data'
import EmptyView from 'components/UIElements/EmptyView'

interface IProps {
    conversionData: IConversion[]
    dropOffData: IDropOff[]
    fromDate: string,
    toDate: string
}

const FunnelView = ({conversionData, dropOffData, fromDate, toDate}: IProps) => {

    const funnelColors = ['#0C64DB', '#2179F0', '#3F97ED', '#54ACFF', '#6BC3FF', '#7FD7FF']

    const [funnelData, setFunnelData] = useState<object[]>([])

    useEffect(() => {
        const data = conversionData?.map((stats: IConversion, index: number) => {
            return {
                value: stats?.curValue,
                fill: funnelColors[index]
            }
        })
        setFunnelData(data)
    }, [conversionData])

    return (
        <>
            <Container>
                <Title>Login with Shiprocket</Title>
                { 
                !conversionData?.length || conversionData[0]?.curValue <= 0 ?
                <EmptyView/> :
                <>
                <BoxContainer>
                    {
                        conversionData?.map((stageData: IConversion) => {
                            return (
                                <StageInfoView 
                                    stageData={stageData} 
                                    fromDate={fromDate}
                                    toDate={toDate}
                                />
                            )
                        })
                    }
                </BoxContainer>
                <FunnelContainer>
                    <ResponsiveContainer height="100%" width={300}>
                        <FunnelChart
                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        >
                            <Funnel
                                dataKey="value"
                                data={funnelData}
                                isAnimationActive
                            >
                            </Funnel>
                        </FunnelChart>
                    </ResponsiveContainer>
                </FunnelContainer>
                <BoxContainer>
                    {
                        dropOffData?.map((dropData: IDropOff, index: number) => {
                            return (
                                <DropOffView
                                    LineColor={funnelColors[index]}
                                    dropData={dropData}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                />
                            )
                        })
                    }
                </BoxContainer>
                </>
            }
            </Container>
        </>
    )
}

export default FunnelView