export const TRAFFIC_ACQUISITION_COLUMNS: object[] = [
    {
      title: "Source/Medium",
      dataIndex: "source",
      key: "source",
      // align: 'center',
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      // align: 'center',
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key:"orders"
      // align: 'center',
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key:'revenue'
      // align: 'center',
    },
    {
      title: "Conversion",
      dataIndex: "conversion",
      key: 'conversion'
      // align: 'center',
      
    }
  ];


export const PAYMENT_MODE_COMPARISON_COLUMNS: object[] = [
    {
      title: "Payment initiated with mode",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      // align: 'center',
    },
    {
      title: "Checkouts",
      dataIndex: "checkouts",
      key: "checkouts",
      // align: 'center',
    },
    {
      title: "Orders",
      dataIndex: "orders",
      key:"orders"
      // align: 'center',
    },
    {
      title: "GMV",
      dataIndex: "gmvOrder",
      key:'gmvOrder'
      // align: 'center',
    },
    {
      title: "Percentage Completion",
      dataIndex: "percentConversion",
      key: 'percentConversion'
      // align: 'center',
    }
];



export const FASTRR_CHECKOUT_CONVERSION: object[] = [
  {
    title: "Steps",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    // align: 'center',
  },
  {
    title: "Checkout Initiated",
    dataIndex: "checkouts",
    key: "checkouts",
    // align: 'center',
  },
  {
    title: "Logged In",
    dataIndex: "orders",
    key:"orders"
    // align: 'center',
  },
  {
    title: "Address Added",
    dataIndex: "gmvOrder",
    key:'gmvOrder'
    // align: 'center',
  },
  {
    title: "Payment Initiated",
    dataIndex: "percentConversion",
    key: 'percentConversion'
    // align: 'center',
  },
  {
    title: "Orders placed",
    dataIndex: "percentConversion",
    key: 'percentConversion'
    // align: 'center',
  },
  {
    title: "Conversion",
    dataIndex: "percentConversion",
    key: 'percentConversion'
    // align: 'center',
  }
];
