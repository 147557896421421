import styled from 'styled-components'


export const TableContainer = styled.div`
    background: #FFF;
    height: auto;
    border-radius: 6.5px;
    padding: 20px;

    .ant-input-group-addon {
        background: #F0F6FD;
        border-top-left-radius: 6.5px;
        border-bottom-left-radius: 6.5px;
    }

    .ant-select-selection-item {
        color: #0C3784 !important;
        font-weight: 600;
        opacity: 1;
    }

    .ant-select-arrow {
        color: #0C3784 !important;
    }

    .ant-input {
        border-radius: 6.5px;
    }

    .ant-table-row {
        vertical-align: baseline;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        background: #0C64DB1A;
        border:none;
        outline:none;
        border-radius:8px;
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) span, 
    .ant-radio-button-wrapper span{
        color: #000 !important;
        font-weight:600;
    }


    // .ant-radio-button-wrapper:not(:first-child)::before{
    //     width: 0;
    // }

    

    .ant-radio-button-wrapper{
        border:none
    }
   

    // .ant-tabs-nav{
    //     min-height: 52px;
    // }


`