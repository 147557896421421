import { notification } from 'antd';
import axios from 'axios';


export const fetchWebhookList =async ({queryKey}) => {
    const [unusedArgument,{sellerId}] = queryKey;
    try {
        const response = await axios.get(
        `${process.env.REACT_APP_AGGREGATOR}/api/ve1/aggregator-service/seller/webhook`,
        {
            headers: {
                sId: sellerId,
            },
        }
        );
        return response.data.data;
    } catch (error) {
        notification.error({
        message: error.message,
        });
    }
};