import styled from 'styled-components'
import { Table } from 'antd'

export const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: normal;
  color: #263f97;
  font-size: 14px;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  width: 37px;
  height: 37px;
  background: #e9ecf5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #263f97;
  font-size: 20px;
`;

export const CustomTable = styled(Table)`

    th {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    color: #0C3784! important;
    opacity: 0.5;
    }

    // .ant-table {
    //     width: fit-content;
    // }

    th {
        &:before {
            content: none!important;
        }
    }
`

export const RuleName = styled.h6`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #263f97;
`

export const Criteria = styled.h6`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #263f97;
`