import styled from "styled-components";
import {Card} from "antd"

export const Container = styled.div`
    background: #EDF0F9;
    min-height: 100vh; 
    display:flex; 
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding: 30px
`

export const DomainListContainer = styled.div`
    display: flex;
    min-width: 746px;
    width:746px;
    flex-wrap: wrap;
    justify-content: space-between;
`
export const CustomCard = styled(Card)`
    width: 354px;
    height: 176px;
    text-align: center;
    margin-bottom: 36px;
    border-radius: 8px

`

export const LoaderContainer = styled.div`
    display:flex; 
    justifyContent:center; 
    alignItems:center; 
    margin: 0 auto;
`