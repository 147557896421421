import styled from 'styled-components';
import { Input, Radio } from 'antd';
import media from 'components/UIElements/media';
import { PickrrButton } from 'components/UIElements';

export const Container = styled.div`
  background: #fff;
  min-height: 72vh;
  border-radius: 12px;
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
    border: 1px solid #263f97;
  }
  .ant-form-vertical .ant-form-item-label > label {
    color: #263f97;
    font-weight: bold;
  }
  .ant-upload.ant-upload-select {
    width: 100%;
    height: 100%;
  }
  .ant-switch-checked {
    background-color: #ff756c;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff756c;
    border-color: #ff756c;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 15px;
  padding: 24px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  box-shadow: 6px 6px 16px 0 rgba(0, 0, 0, 0.2),
    -6px -6px 16px 0 rgba(255, 255, 255, 1);
  .ant-divider-horizontal {
    margin: 11px 0;
  }
  ${media.mobile`
     padding: 16px;

     .ant-btn{
       margin-top: 8px;
     }
  `}
`;

export const Heading = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #292a2c;
  margin-bottom: 24px;

  ${media.mobile`
    font-size: 20px;
  `}
`;

export const GenerateOTPButton = styled(PickrrButton)`
  width: 100%;
  /* height: 47.14px; */
  background-color: #292a2c !important;
  font-size: 12px;
  border-radius: 0 8px 8px 0;
  padding: 4px;
  ${media.tablet`
    border-radius: 8px;
  `}
`;

export const SubHeading = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #292a2c;
  margin-bottom: 22px;
`;
export const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #38446d;
  font-weight: ${(props) => (props.bold === 'true' ? 'bold' : 'normal')};
`;
export const FooterText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #292a2c;
`;

export const CouponInput = styled(Input)`
  border: 2px solid #eaeaea !important;
  border-radius: 8px;
`;

export const SelectionCard = styled.div`
  border: ${(props) =>
    props.selected === 'true' ? '2px solid #292a2c' : '2px solid #d8d8d8'};
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
`;

export const DescriptionText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #38446d;
  opacity: 0.8;
`;

export const SwitchText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #292a2c;
`;

export const CustomPickrrButton = styled(PickrrButton)`
  min-width: 100px;
  background-color: #292a2c !important;
  ${media.mobile`
     width: 100%;
  `}
`;

export const SelectedAddressText = styled.span`
  font-style: normal;
  /* font-weight: bold; */
  font-size: 14px;
  line-height: 160%;
  color: #292a2c;
  /* opacity: 0.6; */
`;

export const CustomRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    width: 250px;
    text-align: center;
  }
  .ant-radio-button-wrapper {
    background: #edf0f9;
    border: 1px solid #d8dbe8;
  }
  .ant-radio-button-wrapper-checked: not(.ant-radio-button-wrapper-disabled) {
    background: #273e97;
    color: #ffffff;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 10px 0px 0px 10px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 10px 10px 0px;
  }
  .ant-radio-button-wrapper-checked: not(.ant-radio-button-wrapper-disabled) {
    border-color: #273d97;
    border-right: #273d97;
    border-left: #273d97;
  }
`;

export const CheckoutText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #292a2c;

  ${media.mobile`
    font-size: 24px;
  `}
`;

export const HelpText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #38446d;

  ${media.mobile`
    font-size: 12px;
    margin-top: 8px;
  `}
`;

export const GlobalStyle = styled.div`
  @font-face {
    font-family: ${(props) => props.headFonts};

    src: ${(props) => `URL(${props.headFontUrl}) format('truetype')`};
  }
  @font-face {
    /* font-family: ${(props) => props.headFonts}; */
    font-family: ${(props) => props.bodyFonts};
    src: ${(props) => `URL(${props.bodyFontUrl}) format('truetype')`};
    /* src: ${(props) => `URL(${props.headFontUrl}) format('truetype')`}; */
  }

  font-family: ${(props) => props.fontFamily}, serif !important;
  .heading {
    font-family: ${(props) => props?.headFonts}, sans-serif !important;
  }
  .highlights-color {
    background: ${(props) => props?.highlightsColor};
  }
  .body-element {
    font-family: ${(props) => props?.bodyFonts}, sans-serif !important;
  }
  .btn-color {
    border: none;
    background-color: ${(props) => props?.buttonColor}!important;
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-switch-checked {
    background-color: ${(props) => props?.highlightsColor}!important;
    border-color: ${(props) => props?.highlightsColor}!important;
  }
  .links {
    color: ${(props) => props?.highlightsColor}!important;
  }
`;

export const DetailHeadingBackground = styled.div`
  background-image: url('https://d10srchmli830n.cloudfront.net/1619690201338_dots.svg');
  width: 100%;
  height: auto;
  background-size: contain;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f9fafe;
`;

export const PageTitle = styled.div`
  font-size: 30px;
  padding: 22px 20px;
  color: #263f97;
  line-height: 24px;
  font-weight: bold;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  color: #263f97;
  font-size: 14px;
  padding-bottom: 20px;
  display: inline-block;
  cursor: pointer;
  padding: 18px;
  padding-bottom: 0px;
`;

export const styles = {
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
};

export const Subtext = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
`;

export const MutedNotiText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(38, 63, 151, 0.4);
`;
