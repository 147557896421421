import { Checkbox, Col, Form, Input, Row, Switch, notification } from "antd";
import React, { useEffect, useState } from "react";
import { CancelButton, GatewayFormContainer, ModalSubHeading } from "./style";
import { CustomForm, PickrrButton } from "components/UIElements";
import { useMutation, useQueryClient } from "react-query";
import { UpdatePaymentGateway } from "../script";

const CheckboxGroup = Checkbox.Group;
const CashfreeGatewayForm = ({gatewayData,gatewayConfig,sellerId,setIsVisible,viewOnly, setViewOnly,setGatewayData , setSelectedGatewayConfig, setUpdateState}) => {

    const [checkedList, setCheckedList] = useState([]);
    const [paymentModeList,setPaymentmodeList] = useState([])
    const queryClient = useQueryClient();

    useEffect(()=>{
        if(gatewayData?.pgDetail){
            form.setFieldsValue({
                cashfree_payment_details : {
                    client_instance_id : gatewayData?.pgDetail?.client_instance_id ?? '',
                    isActive : gatewayData?.active 
                }
            })
        }
        if(gatewayData?.paymentMode){
            const SelectedPaymentModes =  gatewayData?.paymentMode?.map((paymentModesDetails)=>{
                return paymentModesDetails?.mode
            })
            setCheckedList(SelectedPaymentModes)
        }
        if(!gatewayData?.pgDetail){
            form.setFieldsValue({
                cashfree_payment_details : {
                    isActive : true
                }
            })
          }
    },[gatewayData])

    const mutation = useMutation(UpdatePaymentGateway, {
      onMutate: (variables) => {
        return { id: 1 };
      },
      onError: (error, variables, context) => {
        notification.error({ message: 'Something went wrong.' });
      },
      onSuccess: (message, variables, data) => {
        queryClient.invalidateQueries('userDetails');
        setUpdateState((prev)=>!prev)
        notification.success({ message: 'Updated Payment details.' });
        setGatewayData({})
        setSelectedGatewayConfig({})
        setViewOnly(false)
        setIsVisible(false)
      },
    });

    useEffect(()=>{
        setPaymentmodeList(
            Object.keys(gatewayConfig?.payment_mode)?.map(function (key, index) {
                return key
            })
        )
    },[gatewayConfig])

    const onChange = (list) => {
        setCheckedList(list);
      };

    const [form] = Form.useForm();

    const submitForm = () => {
        let uncheckedList = paymentModeList?.filter( function( el ) {
            return checkedList.indexOf( el ) < 0;
          } );
        let postData = { ...form.getFieldsValue() };
        let gatewayKeys = {
            client_instance_id: postData.cashfree_payment_details.client_instance_id,
            is_active:`${postData.cashfree_payment_details?.isActive}`,
        }
        const reqPayload = {
            "seller_id":sellerId,
            "payment_platform":"Cashfree",
            "gateway_keys":JSON.stringify(gatewayKeys),
            "enabled_payment_methods":postData.cashfree_payment_details?.isActive ? checkedList : [],
            "disabled_payment_methods":postData.cashfree_payment_details?.isActive ?uncheckedList : paymentModeList
        }
        mutation.mutate(reqPayload)
    
    }

    return(
        <GatewayFormContainer>
        <CustomForm layout="vertical" form={form} onFinish={submitForm}>
        <Row gutter={[20,0]}>
        <Col span={24} style={{display : 'flex', justifyContent : 'flex-end'}}>
          <Form.Item
            name={['cashfree_payment_details', 'isActive']}
            rules={[
              { 
                required: true, 
                message: "Status for payment gateway is mandatory",
            }
            ]}
          >
            <Switch
            defaultChecked={gatewayData?.active ?? true}
            style={{marginRight : '10px'}} 
            checkedChildren="Active" unCheckedChildren="Inactive"
            disabled={viewOnly}
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} x={24}>
        <Form.Item
            label="Merchant ID"
            name={['cashfree_payment_details', 'client_instance_id']}
            rules={[
            { 
                required: true, 
                message: "Please enter the required value in correct format.",
                pattern: new RegExp(/^[^\s]*$/g)
            }
            ]}
        >
            <Input disabled={viewOnly}/>
        </Form.Item>
        </Col>
        </Row>
        <ModalSubHeading>
            Payment Modes
        </ModalSubHeading>
        <Row gutter={[5,5]} style={{marginBottom : '12px'}}>
        <CheckboxGroup disabled={viewOnly} options={paymentModeList} value={checkedList} onChange={onChange} />
        </Row>
        {!viewOnly &&
        <>
        <CancelButton
        onClick={()=>{
            setGatewayData({})
            setIsVisible(false)
            setSelectedGatewayConfig({})
            setViewOnly(false)
        }}
        >
            Discard
        </CancelButton>
        <PickrrButton
        htmlType="submit"
        style={{width : '100px', border : '0.742px',textTransform : 'none'}}
        color="checkout-blue"
        >
            Save
        </PickrrButton>
        </>
        }
        </CustomForm>
        </GatewayFormContainer>
    )


}

export default CashfreeGatewayForm;