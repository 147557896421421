import React, { useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import Pagination from "./Pagination"
import { DownIconTable, NoDataTableImage, UpDownIcon } from 'images/icons';
import CustomSkeleton from 'UiComponents/CustomSkeleton';
const CustomReactTable = ({ data, loading, loadingRows = 3, columns, pageNumber, setPageNumber, totalPages, checkboxes, onSelectAll, pageSize, selectedRowsKeys, defaultSortBy, newState, handleRowSelect, isChecked, setHoveredRowIndex, tableMinHeight="250px" }) => {
    const [hoveredRow, setHoveredRow] = useState("")
    const [hoveredColumn, setHoveredColumn] = useState("")
    const handleMouseEnter = (index) => {
        //setHoveredRow(index)
        setHoveredRowIndex(index)
    }
    const handleMouseLeave = () => {
        setHoveredRow("")
        setHoveredRowIndex(null)
    }
    const handleMouseEnterColumn = (index) => {
        setHoveredColumn(index)
    }
    const handleMouseLeaveColumn = () => {
        setHoveredColumn("")
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0
                // , sortBy: defaultSortBy
            },
            autoResetSortBy: false,
            disableSortRemove: false
        },
        useSortBy,
    );
    const handlePageChange = (pageNumber) => {
        setPageNumber(pageNumber)
    };

    let columnsToLoad = checkboxes ? columns.length + 1 : columns.length;

    return (
        <>
            <div className='pr-4 bg-white overflow-auto mt-4' style={{minHeight: tableMinHeight}}>

                <table {...getTableProps()} className='w-full '>

                    <thead>
                        {headerGroups?.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {checkboxes && <th className='bg-tableBorder px-6 py-2'>
                                    <div className='w-5 flex items-center justify-center'>
                                    <input
                                        className='h-4 w-4 accent-purpleText'
                                        type="checkbox"
                                        onChange={() => { onSelectAll(rows) }}
                                        checked={selectedRowsKeys?.length > 0 && selectedRowsKeys?.length === rows?.length}
                                    />
                                    </div>
                                </th>
                                }
                                {headerGroup.headers?.map((column, columnIndex) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className='pr-2 py-2 bg-tableBorder' onMouseEnter={() => { handleMouseEnterColumn(columnIndex) }} onMouseLeave={handleMouseLeaveColumn}>

                                        <div className='flex items-center justify-start pr-6 min-w-max text-tableHeader'>
                                            <span>{column.render('Header')}</span>
                                            <span className='-mt-6 ml-1'>
                                                {!column.disableSortBy && <span className={`ml-2 text-clear ${hoveredColumn === columnIndex ? '' : "invisible"}`}><UpDownIcon /></span>}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {loading ?([...Array(loadingRows).keys()].map(() => {
                            return <tr>
                                {[...Array(columnsToLoad).keys()].map(() => {
                                    return <td><CustomSkeleton /></td>
                                })}
                            </tr>
                        })):
                        (rows?.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className='text-start hover:bg-tableHover' onMouseEnter={() => { handleMouseEnter(rowIndex) }} onMouseLeave={handleMouseLeave}>
                                    {checkboxes && <td className='px-6 py-2'>
                                        <div className='w-5 flex items-center justify-center'>
                                        <input
                                            type="checkbox"
                                            onChange={() => handleRowSelect(row)}
                                            className='w-4 h-4 accent-purpleText'
                                            checked={selectedRowsKeys.some((key) => {
                                                return isChecked(key, row.original)
                                            })
                                            }
                                        />
                                        </div>
                                    </td>}
                                    {row?.cells?.map(cell => (
                                        <td {...cell.getCellProps()} className='border-y border-tableBorder pr-8 '>
                                            <div className={`flex gap-2 items-center ${cell?.column?.sticky ? cell?.column?.sticky : ''}`}>
                                                {cell.render('Cell')}
                                            <div className={`${(hoveredRow === rowIndex && cell?.column?.tooltip) ? '' : "invisible"}`}> 
                                                <DownIconTable isDown={true} /> 
                                            </div>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            );
                        }))
                        }
                    </tbody>
                </table>
                {!loading && !rows.length ? <div className='w-full flex items-center justify-center'><NoDataTableImage /></div>:null}
            </div>
            {totalPages > pageSize &&
                <div className='flex items-end justify-end bg-white pr-2 pb-2 mt-2'>
                    <Pagination
                        totalPages={totalPages}
                        currentPage={pageNumber}
                        onPageChange={handlePageChange}
                        setPageNumber={setPageNumber}
                        pageSize={pageSize}
                    />
                </div>
            }
        </>
    );
};

export default CustomReactTable;
