// @ts-nocheck
import React, { useState, useEffect, ChangeEvent } from 'react';
import { CustomDatePicker } from 'components/UIElements';
import { IItem } from './data';
import moment from 'moment';
import {
  fetchFinanceData,
  handleAllDownload,
  handleMultipleDownload,
} from './script';
import { useQuery } from 'react-query';
import { CustomButton, CustomDownloadButton, FlexRowTw, TableContainerTw, TextStyle } from 'pages/Orders/OrdersTab/styles';
import { AmountOfCard, WalletCard } from 'UiComponents/Cards';
import { CustomReactInput } from 'UiComponents/Select';
import CustomReactTable from 'UiComponents/Table';
import { ExportIcon } from 'images/icons';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import "../Loader.css"

const FinanceNew = () => {
  const [fromDate, setFromDate] = useState<string | null>(
    moment().subtract(6, 'days').format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState<string | null>(
    moment().format('YYYY-MM-DD')
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [inputValue, setInputValue] = useState<string>("")
  const [viewMoreState, setViewMoreState] = useState<any>()
  const [tableData, setTableData] = useState<IItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<IItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('order');
  const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0);
  const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)


  const { data: data, isLoading } = useQuery<any>(
    [
      'financeData',
      {
        pageNumber,
        fromDate,
        toDate,
        searchKey,
        searchTerm,
      },
    ],
    fetchFinanceData
  );
  const [defaultSortBy, setDefaultSortBy] = useState<any>([{ id: '', desc: false }]);

  useEffect(() => {
    setPageNumber(1);
    setSelectedRowKeys([]);
  }, [fromDate, toDate]);

  useEffect(() => {
    setTableData(data?.orders);
  }, [data]);

  const disabledDates = (current: any) => {
    return current > moment();
  };

  const onSelect = (row: any) => {
    const rowIndex = selectedRows.findIndex((r: any) => {
      return r?.original?.orderId === row?.original?.orderId
    });
    if (rowIndex === -1) {
      setSelectedRows([...selectedRows, row]);
      setSelectedRowKeys([...selectedRowKeys, row?.original?.orderId])
    }
    else {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.splice(rowIndex, 1);
      setSelectedRows(updatedSelectedRows);
      setSelectedRowKeys([...selectedRowKeys.filter(ele => ele != row?.original?.orderId)]);
    }
  };

  const onSelectAll = (pageData: any) => {
    setSelectedRowKeys((keys) => keys.length === pageData?.length ? [] : [...pageData?.map((key: any) => {
      return key?.original?.orderId
    })])
    setSelectedRows((rows: any) => rows?.length === pageData?.length ? [] : [...pageData])
  }

  const onSearch = (value: string) => {
    setSearchTerm(value);
    setPageNumber(1);
  };

  const addOnBefore = [
    { value: 'order', label: 'Client Order ID' }
  ];

  const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!searchTerm) {
      setPrevPageNumber(pageNumber)
    }
    if (event.target.value) {
      onSearch(event.target.value)
    }
  }

  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(event.target.value)
    if (!event.target.value) {
      onSearch('')
      setPageNumber(prevPageNumber)
    }
  }

  const onSearchKeyChange = (value: string) => {
    setSearchKey(value)
  }

  const isChecked = (key: any, row: any) => {
    return key === row?.orderId
  }
  const columns: any = [
    {
      Header: 'PG Order ID',
      accessor: 'orderId',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className='min-w-24'>{value}</TextStyle>
      ),
      width: 200,
    },
    {
      Header: 'Date & Time',
      accessor: 'date',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className='min-w-32'>{moment(value).format('Do MMMM ,h:mm a')}</TextStyle>
      ),
    },
    // {
    //   Header: 'Payment Gateway',
    //   accessor: 'gateway',
    //   disableSortBy: true,
    //   Cell: ({ value }: any) => (
    //     <TextStyle className='min-w-36'>
    //       {value ? value.toUpperCase() : '-'}
    //     </TextStyle>
    //   ),
    //   width: 200,
    // },
    {
      Header: 'Prepaid Transaction ID',
      accessor: 'gatewayId',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className='min-w-44'>{value ? value : '-'}</TextStyle>
      ),
    },

    {
      Header: 'Customer',
      accessor: 'customerPhone',
      disableSortBy: true,
      Cell: ({ value, row }: any) => {
        const randomNumber = Math.random()
        return <>
          <TextStyle className='min-w-36' data-tip data-tooltip-id={`${randomNumber}`}>
            {value ?? '-'}
          </TextStyle>
          <ReactTooltip id={`${randomNumber}`} border={'1px solid #EAEAEA'} place='bottom-start' opacity={1} noArrow clickable={true} style={{ background: "white", alignContent: 'left', borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>

            <div className="w-fit px text-clear" onClick={(e) => {
              e.stopPropagation()
            }}>
              {/*// @ts-ignore */}
              <div className='max-w-40'>Phone no. {row?.original?.phone} </div>
            </div>
          </ReactTooltip>
        </>
      },
    },
    {
      Header: 'Payment Method',
      accessor: 'paymentMode',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className='min-w-44'>
          {value ?? '-'}
        </TextStyle>
      ),
    },
    {
      Header: 'Payment Amount',
      accessor: 'orderValue',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className='min-w-24'>
          ₹{value ? value.toLocaleString('en-IN') : '-'}
        </TextStyle>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-4 py-4 min-h-screen" style={{ backgroundColor: "#F1F5F9" }}>
        <div className='flex justify-between'>
          <div className='flex gap-8 flex-wrap'>
            <WalletCard heading={'No. of Payments'} amount={data?.totalCount ?? 0} />
            <AmountOfCard heading={'Payment Amount'} amount={data?.prepaidAmount ?? 0} />

          </div>
          <div>
            <CustomDatePicker
              allowClear={false}
              onChange={(dates: Array<moment.Moment>) => {
                dates && dates[0]
                  ? setFromDate(dates[0].format('YYYY-MM-DD'))
                  : setFromDate(null);
                dates && dates[1]
                  ? setToDate(dates[1].format('YYYY-MM-DD'))
                  : setToDate(null);
              }}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, 'days'),
                  moment().subtract(1, 'days'),
                ],
                'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                'This Month': [moment().startOf('month'), moment()],

                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              }}
              defaultValue={[
                moment(fromDate, 'YYYY-MM-DD'),
                moment(toDate, 'YYYY-MM-DD'),
              ]}
              disabledDate={disabledDates}
            />
          </div>
        </div>

        <TableContainerTw>
          <FlexRowTw className='flex justify-between'>
            <FlexRowTw>
              <CustomReactInput addOnBefore={addOnBefore} onChangeHandler={onChangeHandler} onPressEnterHandler={onPressEnterHandler} onSearchKeyChange={onSearchKeyChange} searchKey={searchKey} defaultValue={'Client Order ID'} inputValue={inputValue} setSearchTerm={setSearchTerm} />
            </FlexRowTw>

            <div className="flex items-center gap-3 font-bold py-4 h-8 w-max ml-8">
              <CustomDownloadButton onClick={() => {
                setIsDataDownloading(true)
                selectedRowKeys.length
                  ? handleMultipleDownload(
                    fromDate,
                    toDate,
                    selectedRowKeys.join(','),
                    setIsDataDownloading
                  )
                  : handleAllDownload(
                    fromDate,
                    toDate,
                    searchKey,
                    searchTerm,
                    setIsDataDownloading
                  );
              }} className='items-center' disabled={isDataDownloading}>
                {
                  !isDataDownloading ?
                    <>
                      <span className=''>
                        <ExportIcon />
                      </span>
                      <span>{selectedRowKeys.length ? `Download Selected (${selectedRowKeys.length})` : 'Download All'}</span>
                    </> : <><div className='loader'></div></>
                }
              </CustomDownloadButton>
            </div>
          </FlexRowTw>

          {/* {selectedRowKeys?.length > 0 &&
            <div className="flex items-center gap-3 font-bold text-idColor mt-4 h-10 w-max ml-8">
              <span>
                {selectedRowKeys.length} selected
              </span>
              <CustomButton onClick={() => {
                selectedRowKeys.length
                  ? handleMultipleDownload(
                    fromDate,
                    toDate,
                    selectedRowKeys.join(',')
                  )
                  : handleAllDownload(
                    fromDate,
                    toDate,
                    searchKey,
                    searchTerm
                  );
              }} className='pl-4'>
                <ExportIcon />
                Export
              </CustomButton>
            </div>} */}

          <CustomReactTable
            loading={isLoading}
            data={tableData || []}
            pageNumber={pageNumber}
            columns={columns}
            setPageNumber={setPageNumber}
            totalPages={data?.totalCount}
            checkboxes={true}
            onSelectAll={onSelectAll}
            pageSize={20}
            selectedRowsKeys={selectedRowKeys}
            defaultSortBy={defaultSortBy}
            newState={viewMoreState}
            handleRowSelect={onSelect}
            isChecked={isChecked}
            setHoveredRowIndex={()=>{}}

          />
        </TableContainerTw>
      </div>
    </>
  );
};

export default FinanceNew;
