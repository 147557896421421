import React, { useEffect } from "react";
import { CustomForm } from "components/UIElements";
import { Col, Row, Form, Input, Space, notification } from "antd";
import { PickrrButton } from "components/UIElements";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
} from "components/Settings/GeneralSettings/script";
import { FormContainer } from "../styled";

const WigzoTrackingPage = ({ tabKey }: { tabKey: string }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data } = useQuery("generalSettings", getGeneralDetails);
  const mutation = useMutation(updateSellerCheckoutConfig, {
    onError: () => {
      notification.error({ message: "Something went wrong." });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('generalSettings');
      notification.success({ message: "Updated seller details." });
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        wigzo_id: data?.marketing_config?.wigzo?.wigzo_id,
      });
    }
  }, [data, tabKey]);

  const submitForm = () => {
    const postData = { ...form.getFieldsValue() };
    mutation.mutate({
      marketing_config: {
        ...data?.marketing_config,
        wigzo: {
          wigzo_id: postData.wigzo_id,
        },
      },
    });
  };

  return (
    <FormContainer>
      <CustomForm layout="vertical" form={form} onFinish={submitForm}>
        <Row gutter={[50, 0]}>
          <Col span={10}>
            <Form.Item label="Wigzo ID" name="wigzo_id">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Space>
          <PickrrButton htmlType="submit" color="checkout-blue">
            Save
          </PickrrButton>
        </Space>
      </CustomForm>
    </FormContainer>
  );
};

export default WigzoTrackingPage;
