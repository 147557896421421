import React, { useContext, useState } from "react"
import { CustomTabs } from "../Shipping/style"
import { Container } from "../GeneralSettings/style"
import Header from "components/CommonComponents/Header"
import ManageUPIPayment from "./UPI"
import ManageOtherPayment from "./Others"
import { useQuery } from "react-query"
import { getGeneralDetails } from "../GeneralSettings/script"
import ManageUPIPaymentNew from "./NewUPI"
import { DataContext } from "context/DataProvider"
import { FetchPaymentConfig } from "./script"

const { TabPane } = CustomTabs;

const ManagePayment = () => {

    const { data, isLoading, isSuccess } = useQuery(
        'generalSettings',
        getGeneralDetails
    );

    const [tabKey, setTabKey] = useState<string>("1")

    const onTabChange = (key: string) => {
        setTabKey(key)
    }

    return (
        <Container>
        {/*// @ts-ignore */}
        <Header
          title="Payment"
        />
        <CustomTabs defaultActiveKey={tabKey} onChange={onTabChange}>
            <TabPane tab="FASTRR CHECKOUT" key="1">
                {/* <ManageUPIPayment data={data} isLoading={isLoading}/> */}
                <ManageUPIPaymentNew />
            </TabPane>
            <TabPane tab="OTHERS" key="2">
                <ManageOtherPayment data={data} isLoading={isLoading} />
            </TabPane>
        </CustomTabs>
        </Container>
    )
}

export default ManagePayment