import React, { useEffect, useState } from "react";
import { Tabs, message, Select, Radio } from "antd";
import {CloudDownloadOutlined} from '@ant-design/icons'
import moment from "moment";
import TableTab from "./TableTab";
import { useLocation } from "react-router-dom";
//import ErrorOrdersTab from './ErrorOrdersTab'
import { TableContainer } from "./styled";
import type { RadioChangeEvent } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import {ExportButton} from '../styled'
import TrafficAcquisitionTable from './TrafficAcquisitionTable'
import {ConversionContainer} from '../styled'
import {downloadTrafficAcq, downloadPaymentModeConversion} from '../script'

const { TabPane } = Tabs;

const TableView = ({
  tabTitle1,
  tabTitle2,
  columnsData2,
  columns2,
  tabPage2,
  setTabPage2,
  tabPageSize2,
  setTabPageSize2,
  tabTotal2,
  fromDate,
  toDate
}:any) => {
  const { Option } = Select;

  const { state }: any = useLocation();

  const [size, setSize] = useState<SizeType>("small");
  const [key, setKey] = useState('1')



  const onChange = (e: RadioChangeEvent) => {
    console.log(e.target.value, 'e')
    setSize(e.target.value);
  };


  function callback(key:string) {
    console.log(key, 'checking string')
    setKey(`${key}`)
  }
  const [filter, setFilter] = useState<string[]>(['source','medium','campaign'])
  
  const handleDownload = () => {
    if(key  === "1"){
      downloadTrafficAcq(fromDate, toDate, filter)
    }else {
      downloadPaymentModeConversion(fromDate, toDate);
    }
  }

  return (
    <ConversionContainer>
      <TableContainer>
        <Tabs
          onChange={callback}
          activeKey= {key}
          tabBarExtraContent={{
            right: (
              true && (
                <>
              <ExportButton 
              /* @ts-ignore */
                icon={<CloudDownloadOutlined />}
                onClick = {handleDownload}
              > 
                Export
              </ExportButton>
            </>
            )
            ),
          }}
        > 
          <TabPane tab={tabTitle1} key="1">
            <TrafficAcquisitionTable 
              fromDate= {fromDate}
              toDate= {toDate}
              filter = {filter}
              setFilter = {setFilter}
            />
          </TabPane>

          <TabPane tab={tabTitle2} key="2">
            <TableTab
              data = {columnsData2}
              columns = {columns2}
              pageNumber = {tabPage2}
              setPageNumber = {setTabPage2} 
              pageSize ={tabPageSize2}
              setPageSize = {setTabPageSize2}
              total  = {tabTotal2}
              expandableTable = {true}
            />
          </TabPane>
        </Tabs>
      </TableContainer>
    </ConversionContainer>
  );
};

export default TableView;
