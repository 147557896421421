import { CustomDashboardTable } from 'components/UIElements';
import Filter from 'pages/Orders/OrdersTab/Filter';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchTrafficAcquisitionData } from '../script';



        

const TrafficAcquisitionTable = ({fromDate,toDate, filter, setFilter}:any) => {
    
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);


    const columns: object[] = [
        {
          title: "Source/Medium/Campaign",
          dataIndex: "source",
          key: "source",
          render: (data:any, rowData: any) => (
            <>
                <div>{rowData?.source ?? '-'}</div>
                <div>{rowData?.medium ?? '-'}</div>
                <div>{rowData?.campaign ?? '-'}</div>
           </>
          ),
          filterDropdown: (
        <Filter 
            filters={['source/medium', 'campaign','source/medium/campaign']} 
            presentFilter={filter} 
            setVisible={setIsFilterVisible} 
            apply={setFilter} 
            allowMultiple={false}
            setPageNumber={setPageNumber}
        />
        ), 
          filterDropdownVisible: isFilterVisible,
          filterIcon: (
            <div>
                <img 
                    src={
                        filter.length ?
                        "https://d10srchmli830n.cloudfront.net/1650527517870_VectorAppliedFilterIcon.svg" :
                        "https://d10srchmli830n.cloudfront.net/1650470283482_VectorfilterIcon.svg"
                    }
                    alt="download"
                    onClick={() => setIsFilterVisible(true)}
                />
            </div>
            ),
        },
        {
          title: "Checkouts",
          dataIndex: "sessions",
          key: "sessions",
        },
        {
          title: "Orders",
          dataIndex: "orders",
          key:"orders"
        },
        {
          title: "Revenue",
          dataIndex: "revenue",
          key:'revenue',
          render: (data:any, rowData: any) => (
            <>
              ₹{data}
            </>
          )
        },
        {
          title: "Conversion",
          dataIndex: "conversion",
          key: 'conversion',
          render: (data:any, rowData: any) => (
          <>
            {data ? `${data}%` : '-'}
          </>
          )
        }
      ];


      

      const {
        data: trafficAcquisitionData,
        isLoading,
      } = useQuery(
        ['trafficAcquisition', { fromDate, toDate, filter, pageNumber, pageSize}],
        fetchTrafficAcquisitionData
      )

      return (
        <CustomDashboardTable 
            dataSource={trafficAcquisitionData?.trafficAcquisitionReportDataList} 
            columns={columns} 
            bordered={true}
            loading = {isLoading}
            pagination={
            {
                pageSize: pageSize,
                showSizeChanger: true,
                pageSizeOptions : ['5', '10', '20'], 
                total: trafficAcquisitionData?.totalPages,
                current: pageNumber,
                onChange: (page, size) => {
                setPageNumber(page);
                setPageSize(size);
                },
                position: ["bottomRight"],
            }
            }
        />
      )
    
} 

export default TrafficAcquisitionTable;