import React from 'react';
import {Row, Col, Input} from 'antd'
import {InputContainer} from '../../styled';


const CustomerEmail  = ({customerEmailList, setCustomerEmailList}) => {
    console.log(customerEmailList, 'checking customer email list')
    const onChange = (e) =>{
        setCustomerEmailList(() => [e.target.value]);
        console.log(e.target.value)
    }
    return (
        <Row gutter={[16, 16]}>
            <Col>
                <InputContainer>
                <div style={{color: '#0c3784', fontWeight: 600, marginBottom: 6}}>Enter the email id of customer accounts already created in your store.</div>
                    <Input
                        placeholder='Enter Customer Email'
                        value={customerEmailList}
                        onChange={onChange}
                    />
                </InputContainer>
            </Col>
        </Row>
    )
}
export default CustomerEmail;