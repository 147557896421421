import { GetAuthToken } from 'globalQuries';
import axios from 'axios';
export const UpdateConfig = async (data : any) => {
    const auth = GetAuthToken();
    const response = await axios.put(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
        data,
        {
            headers: {
              Authorization: auth,
            },
          }
    )
    return response.data.data;
}