import styled from "styled-components";

export const Card = styled.div`
    height: 100px;
    width: 100px;
    background: rgba(12, 100, 219, 0.06);
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Label = styled.h6`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #0C3784;
    margin-top: 10px;
`

export const Heading = styled.h1`
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #0C3784;
    margin-bottom: 40px;
`