import React, { useState } from "react";
import { Tabs, message } from "antd";
import CustomersTab from "./CustomersTab";
import moment from "moment";
import CustomerDetailsView from "./CustomersTab/CustomerDetailsView";
import { ICustomerDetails } from "./CustomersTab/data";
const { TabPane } = Tabs;

const Customers = () => {
  const [fromDate, setFromDate] = useState<string | null>(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const [showCustomerDetails, setShowCustomerDetails] =
    useState<boolean>(false);
  const [customerPageNumber, setCustomerPageNumber] = useState<number>(1);
  const [customerRowData, setCustomerRowData] =
    useState<ICustomerDetails>(null);
  const changeShowCustomerDetails = () => {
    setShowCustomerDetails(!showCustomerDetails);
  };
  const changeCustomerPageNumber = (page: number) => {
    setCustomerPageNumber(page);
  };
  const changeCustomerRowData = (rowData: any) => {
    setCustomerRowData(rowData);
  };
  return (
    <>
      
          {showCustomerDetails ? (
            <CustomerDetailsView
              customerRowData={customerRowData}
              changeShowCustomerDetails={changeShowCustomerDetails}
            />
          ) : (
            <CustomersTab
              changeShowCustomerDetails={changeShowCustomerDetails}
              changeCustomerRowData={changeCustomerRowData}
              fromDate={fromDate}
              toDate={toDate}
              customerPageNumber={customerPageNumber}
              changeCustomerPageNumber={changeCustomerPageNumber}
            />
          )}
       
    </>
  );
};
export default Customers;
