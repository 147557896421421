import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  min-height: 70vh;
  border-radius: 12px;
  padding :0px 40px 0px 12px;
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
    border: 1px solid #263f97;
  }
  .ant-form-vertical .ant-form-item-label > label {
    color: #263f97;
    font-weight: bold;
  }
  .ant-table-thead > tr > th{
    background: var(--accent-2, rgba(12, 100, 219, 0.10));
    text-align: center;
  }
  .ant-table-tbody > tr > td{
    text-align: center;
  }
  .ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius : 8px;
    border-right : 0px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child{
    border-top-right-radius : 8px;
    border-left : 0px !important;
  }
  .ant-table > .ant-table-container > .ant-table-content > table > thead > tr > th{
    border-top: 1px solid var(--Accent-1,#CCD3DE);
    border-bottom: 1px solid var(--Accent-1,#CCD3DE);
    color: var(--text, #0C3784);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.4%; /* 21.056px */
    letter-spacing: 0.28px;
  }
  .ant-table > .ant-table-container > .ant-table-content > table > thead > tr > th:first-child{
    border-left : 1px solid var(--Accent-1,#CCD3DE);
  }
  .ant-table > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child{
    border-right : 1px solid var(--Accent-1,#CCD3DE);
  }
  .ant-table-tbody > tr:last-child > td:first-child{
    border-bottom-left-radius : 10px;
  }
  .ant-table-tbody > tr > td:first-child{
    border-left : 1px solid #f0f0f0 !important;
  }
  .ant-table-tbody > tr > td:last-child{
    border-right : 1px solid #f0f0f0 !important;
  }
  .ant-table-tbody > tr:last-child > td:last-child{
    border-bottom-right-radius : 10px;
  }
`;

export const TableDataContainer = styled.div`
  //display : flex;
  flex-direction : column;
  width : 100%;
  justify-content : center;
  align-items : center;
  gap : 10px;
  .ant-switch-checked{
    background-color : #0051BF;
  }
`

export const ModalHeading = styled.div`
    color: #000;
    font-family: Proxima Nova;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    border-bottom : 4px solid #0C64DB;
    width : max-content;
    padding-bottom : 4px;
    margin-bottom : 8px;
`

export const PaymentGatewayListItem = styled.div`
  display : flex;
  width : 100%;
  align-items : center;
  color: var(--text, #0C3784);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  &:hover {
    background: rgba(12, 100, 219, 0.10);
  }
  height : 50px;
  cursor : pointer;
  padding : 15px 20px;
`

export const ModalSubHeading = styled.div`
  color: var(--text, #0C3784);
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-bottom : 8px;
`

export const GatewayFormContainer = styled.div`
  .ant-form-item-label > label {
    color: var(--text, #0C3784);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
  }
  .ant-input{
    border-radius: 5.936px;
    border: 0.742px solid var(--accent-3, #DFE0EB) !important;
    background: #FFF !important;
    height : 40px;
  }
  .ant-checkbox + span{
    color: var(--headings, #000B34);
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 14.88px */
    letter-spacing: -0.36px;
  }
  .ant-switch-checked {
    background : #0C64DB !important;
  }
`

export const CancelButton = styled(Button)`
  border-radius: 8px;
  border: 0.742px solid var(--Primary, #0C64DB) !important;
  background: #FFF !important;
  color: var(--Primary, #0C64DB) !important;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right : 6px;
  width  :100px;
`

export const StatusContainerActive = styled.div`
  display: flex;
  padding: 7px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: rgba(85, 171, 104, 0.10);
  color: #399F50;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const StatusContainerInactive = styled.div`
  display: flex;
  padding: 7px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: rgba(214, 87, 69, 0.10);
  color: #D65745;
  text-align: center;
  font-family: Proxima Nova;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`