// @ts-nocheck

import React, { useState, useContext, ChangeEvent, useEffect } from 'react';
import { message } from 'antd';
import moment from 'moment';
import { CustomDatePicker, PaymentStatus } from './styled';
import { TableContainer } from '../../Orders/OrdersTab/styled';
import { useQuery } from 'react-query'
import { fetchRefundData, handleAllDownload, handleMultipleDownload } from './script';
import { DataContext } from 'context/DataProvider';
import { CustomReactInput } from 'UiComponents/Select';
import { CustomButton, CustomDownloadButton, FlexRowTw, TableContainerTw, TextStyle } from 'pages/Orders/OrdersTab/styles';
import CustomReactTable from 'UiComponents/Table';
import { ExportIcon } from 'images/icons';
import "../../Loader.css"
import { PaymentGatewayEnum } from 'context/Enums';

const RefundsNew = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [fromDate, setFromDate] = useState<string | null>(moment().subtract(6, 'days').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState<string | null>(moment().format('YYYY-MM-DD'));
    const [searchKey, setSearchKey] = useState<string>('clientOrderId');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0);
    const [pageNumber, setPageNumber] = useState<number>(1)
    const { sellerId } = useContext(DataContext)
    const [defaultSortBy, setDefaultSortBy] = useState<any>([{ id: '', desc: false }]);
    const [viewMoreState, setViewMoreState] = useState<any>()
    const [selectedRows, setSelectedRows] = useState<any>([])
    const [inputValue, setInputValue] = useState<any>('')
    const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)
    const disabledDates = (current: any) => {
        return current > moment()
    }
    const {
        data: refundData,
        isLoading,
    } = useQuery<any>(
        ['ordersRefundData', { pageNumber, sellerId, fromDate, toDate, searchTerm, ...(searchTerm && { searchKey }) }],
        fetchRefundData, { cacheTime: 0 },
    )
    const refundTextColor: any = {
        'success': '#399F50',
        'pending': '#125CCC',
        'failed': '#D65745'
    }

    const refundBackgroundColor: any = {
        'success': '#ecfaef',
        'pending': '#125ccc1a',
        'failed': '#fff0ee'
    }

    const onSelect = (record: any) => {
        const rowIndex = selectedRows.findIndex((r: any) => {
            return r?.original?.ref_id === record?.original?.ref_id
        });
        if (rowIndex === -1) {
            setSelectedRows([...selectedRows, record]);
            setSelectedRowKeys([...selectedRowKeys, record?.original?.ref_id])
        }
        else {
            const updatedSelectedRows = [...selectedRows];
            updatedSelectedRows.splice(rowIndex, 1);
            setSelectedRows(updatedSelectedRows);
            setSelectedRowKeys([...selectedRowKeys.filter(ele => ele != record?.original?.ref_id)]);
        }
    }

    const onSelectAll = (pageData: any) => {
        setSelectedRowKeys((keys) => keys.length === pageData?.length ? [] : [...pageData?.map((key: any) => {
            return key?.original?.ref_id
        })])
        setSelectedRows((rows: any) => rows?.length === pageData?.length ? [] : [...pageData])
    }

    const columns: any = [
        {
            Header: 'Client Order ID',
            accessor: 'client_order_id',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>{value? value: '-'}</TextStyle>
            )
        },

        {
            Header: <span>Refund Transaction ID </span>,
            accessor: 'pg_refund_txn_id',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const refund_txn_id = row?.original?.pg_refund_txn_id || ''
                return(
                    <>
                        <TextStyle>{ refund_txn_id? refund_txn_id: '-' }</TextStyle>
                    </>
                )
                
        },
        },
        {
            Header: <span> Refund Date & Time</span>,
            accessor: 'pg_refund_txn_Date',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const updated_at = row.original.updated_at;
                return(
                    
                    <TextStyle>{updated_at?moment(updated_at).format('Do MMMM , h:mm a'): '-'}</TextStyle>
                
                )
                
            },
        },

        {
            Header: 'Refunded Amount',
            accessor: 'amount',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-28'>{value? `${value.toLocaleString("en-IN", {style:"currency", currency:"INR"})}`: '-'}</TextStyle>
            )
        },

        {
            Header: 'Refund initiated by',
            accessor: 'initiated_by',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-28'>{value? value: '-'}</TextStyle>
            )
        },
        {
            Header: 'Payment status',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                return <span className='mb-1'>
                    <PaymentStatus
                        textColor={refundTextColor[row.original.status.toLowerCase()]}
                        bgColor={refundBackgroundColor[row.original.status.toLowerCase()]}
                    >
                        {`${row.original.status[0].toUpperCase()}${row.original.status.slice(1).toLowerCase()}`}
                    </PaymentStatus>
                </span>
            }
        },
        {
            Header: 'Payment Gateway',
            accessor: 'payment_platform',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-28'> {PaymentGatewayEnum[value?.toUpperCase()]? PaymentGatewayEnum[value?.toUpperCase()] : (value || '-')} </TextStyle>
            )
        },

        {
            Header: <span>Payment Transaction ID </span>,
            accessor: 'pg_transaction_id',
            disableSortBy: true,
            Cell: ({ row }: any) => {
                const pg_transaction_id = row.original.pg_transaction_id
                return (
                    <TextStyle>{pg_transaction_id|| '-'}</TextStyle>
                )  
        },
        },
        {
            Header: <span>Payment Date & Time</span>,
            accessor: 'pg_transaction_date',
            disableSortBy: true,
            Cell: ({ row }: any) => (
                <>
                    <TextStyle className='min-w-36'> {moment(row.original.pg_transaction_date).format('Do MMMM ,h:mm a')}</TextStyle>
                </>
            ),
        },
        {
            Header: 'Payment Total',
            accessor: 'payment_amount',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>₹{value ? value.toFixed(2): '-'}</TextStyle>
            )
        },

    ];


    const onSearchKeyChange = (value: string) => {
        setSearchKey(value)
    }

    const onSearch = (value: string) => {
        setSearchTerm(value)
        setPageNumber(1)
    }
    const isChecked = (key: any, row: any) => {
        return key === row?.ref_id
    }

    const addOnBefore = [
        { value: 'clientOrderId', label: 'Client Order ID' },
        { value: 'pgTxnId', label: 'Payment Transaction ID' },
        { value: 'pgRefundTxnId', label: 'Refund Transaction ID' },
    ];

    const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        setInputValue(event.target.value)
        if (!event.target.value) {
            onSearch('')
            setPageNumber(prevPageNumber)
        }
    }
    const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        if (!searchTerm) {
            setPrevPageNumber(pageNumber)
        }
        if (event.target.value) {
            onSearch(event.target.value)
        }
    }

    return (
        <>
            <div className='flex justify-end mb-4'>
                <CustomDatePicker
                    allowClear={false}
                    onChange={(dates: Array<moment.Moment>) => {
                        if (dates[1].diff(dates[0], "days") > 60) {
                            message.error(
                                "Date range can not exceed 60 days. Please select date range less than or equal to 60 days only.",
                                1.5
                            );
                        } else {
                            dates && dates[0]
                                ? setFromDate(() => { return dates[0].format("YYYY-MM-DD") })
                                : setFromDate(null);
                            dates && dates[1]
                                ? setToDate(() => dates[1].format("YYYY-MM-DD"))
                                : setToDate(null);
                        }
                    }}
                    ranges={{
                        Today: [moment(), moment()],
                        Yesterday: [
                            moment().subtract(1, "days"),
                            moment().subtract(1, "days"),
                        ],
                        "Last 7 Days": [moment().subtract(6, "d"), moment()],
                        "This Month": [moment().startOf("month"), moment()],

                        "Last 30 Days": [moment().subtract(29, "days"), moment()],
                    }}
                    defaultValue={
                        [
                            moment(fromDate, "YYYY-MM-DD"),
                            moment(toDate, "YYYY-MM-DD"),
                        ]
                    }
                    disabledDate={disabledDates}
                />
            </div>


            <TableContainerTw>
                <FlexRowTw className='flex justify-between'>
                <FlexRowTw>
                    <CustomReactInput addOnBefore={addOnBefore} onChangeHandler={onChangeHandler} onPressEnterHandler={onPressEnterHandler} onSearchKeyChange={onSearchKeyChange} searchKey={searchKey} defaultValue={'Client Order ID'} inputValue={inputValue} setSearchTerm={setSearchTerm}/>
                </FlexRowTw>

                <div className="flex items-center gap-3 font-bold py-4 h-8 w-max ml-8">
                    <CustomDownloadButton onClick={() => {
                        setIsDataDownloading(true)
                        selectedRowKeys?.length ?
                            handleMultipleDownload(fromDate, toDate, selectedRowKeys?.join(','),setIsDataDownloading) :
                            handleAllDownload(fromDate, toDate, searchKey, searchTerm,setIsDataDownloading)
                    }} className='items-center' disabled={isDataDownloading || !refundData?.refunds?.length}>
                        {
                            !isDataDownloading?
                        <>
                        <span className=''>
                            <ExportIcon />
                        </span>
                        <span>{selectedRowKeys.length ? `Download Selected (${selectedRowKeys.length})` : 'Download All'}</span></>:<div className='loader'/>}
                    </CustomDownloadButton>
                </div>
                </FlexRowTw>


                {/* {selectedRowKeys?.length > 0 &&
                    <div className="flex items-center gap-3 font-bold text-idColor mt-4 h-10 w-max ml-8">
                        <span>
                            {selectedRowKeys.length} selected
                        </span>
                        <CustomButton onClick={() => {
                            selectedRowKeys?.length ?
                                handleMultipleDownload(fromDate, toDate, selectedRowKeys?.join(',')) :
                                handleAllDownload(fromDate, toDate, searchKey, searchTerm)
                        }} className='pl-4'>
                            <ExportIcon />
                            Export
                        </CustomButton>
                    </div>} */}

                <CustomReactTable
                    loading={isLoading}
                    data={refundData?.refunds || []}
                    pageNumber={pageNumber}
                    columns={columns}
                    setPageNumber={setPageNumber}
                    totalPages={refundData?.total_refunds}
                    checkboxes={true}
                    onSelectAll={onSelectAll}
                    pageSize={20}
                    selectedRowsKeys={selectedRowKeys}
                    defaultSortBy={defaultSortBy}
                    newState={viewMoreState}
                    handleRowSelect={onSelect}
                    isChecked={isChecked}
                    setHoveredRowIndex={()=>{}}

                />

            </TableContainerTw>
        </>
    )
}

export default RefundsNew;