import styled from "styled-components";

export const ModalTitle = styled.span`
    display : flex;
    font-size: 18px ;
    font-weight : 600;
`

export const DateContainer = styled.div`
    display: flex;
    color : #0C64DB;
    font-weight : 600;
    font-size: 16px;
    line-height : 22.4px;
    margin-bottom : 10px;
`

export const GatewayContainer = styled.div`
    background : #0C64DB1A ;
    font-weight : 500 ;
    font-size : 16px;
    color : #0C3784;
    width: fit-content;
    padding: 0px 10px;
    border-radius : 4px;
`
export const CrossButtonModal = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: -51px;
    font-size: 20px;
    margin-bottom: 14px;
    color: white;
    margin-left: 15px;
`

export const DatePickerContainer = styled.div`
    display : flex;
    width : 100%;
    .ant-picker{
        width : 100%;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        display: inline-flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height : 45px !important;
    }
`
export const SelectContainer = styled.div`
    display : flex;
    width : 100%;
    .ant-select{
        width : 100% !important;
    }
    .ant-select-selector{
        border-radius: 8px !important;
        border: 1px solid #D0D5DD !important;
        background: #FFFFFF !important;
        display: inline-flex !important;
        padding: 10px 16px !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 8px !important;
        height : 45px !important;
    }
`