import React, { useContext, useState } from "react";
import { CustomDashboardTable } from "components/UIElements";
import moment from "moment";
import { fetchDashboardData } from "../script";
import { useQuery } from "react-query";
import { DataContext } from "context/DataProvider";
import { ConversionTitle } from "./constant";

function percentage(partialValue: number, totalValue: number) {
  const percent = (partialValue / totalValue) * 100;
  return percent ? `${percent?.toFixed(2)}%` : "-";
}


const FastrrConversionTable = ({ fromDate, toDate, timeDuration,channelSource,shopifySessionData }: any) => {
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const{showSessions}=useContext(DataContext)
  
  const column: object[] = [
    {
      title: "Steps",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (data: any, rowData: any) => (
        <>
          {`${moment(rowData?.startDate).format("DD-MMM")} - ${moment(
            rowData?.endDate
          ).format("DD-MMM")}`}
        </>
      ),
    },
    {
      title: "Checkout Initiated",
      dataIndex: "checkouts",
      key: "checkouts",
      render: (data: any, rowData: any) => (
        <>{`${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.checkoutInititated)?.curValue ?? '-'}`}</>
      ),
    },
    {
      title: "Logged In",
      dataIndex: "orders",
      key: "orders",
      render: (data: any, rowData: any) => (
        <>
          {`${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.loggedIn)?.curValue ?? '-'}`}&nbsp;
          {rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.loggedIn)?.delta
            ? `(${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.loggedIn)?.delta}%)`
            : ""}
        </>
      ),
    },
    {
      title: "Address Added",
      dataIndex: "gmvOrder",
      key:'gmvOrder',
      render: (data:any, rowData: any) => (
        <>
           {`${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.orderScreen)?.curValue ?? '-'}`}&nbsp;
           {rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.orderScreen)?.delta
            ? `(${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.orderScreen)?.delta}%)`
            : ""}
       </>
      ),
      // align: 'center',
    },
    {
      title: "Payment Initiated",
      dataIndex: "percentConversion",
      key: "percentConversion",
      render: (data: any, rowData: any) => (
        <>
          {`${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.paymentScreen)?.curValue ?? '-'}`}&nbsp;
          {rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.paymentScreen)?.delta
            ? `(${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.paymentScreen)?.delta}%)`
            : ""}
        </>
      ),
    },
    {
      title: "Orders placed",
      dataIndex: "percentConversion",
      key: "percentConversion",
      render: (data: any, rowData: any) => (
        <>
          {`${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.orderPlaced)?.curValue ?? '-'}`}&nbsp;
          {rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.orderPlaced)?.delta
            ? `(${rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.orderPlaced)?.delta}%)`
            : ""}
        </>
      ),
    },
    {
      title: "Conversion",
      dataIndex: "percentConversion",
      key: "percentConversion",
      render: (data: any, rowData: any) => (
        <div
          style={{
            background: "#ECFDF3",
            color: "#037847",
            borderRadius: 4,
            padding: "2px 6px",
            display: "inline-block",
          }}
        >
          {percentage(
            rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.orderPlaced)?.curValue,
            rowData?.checkoutConversionStats?.find((conversionStats : any)=>conversionStats?.title?.toUpperCase() === ConversionTitle.checkoutInititated)?.curValue
          )}
        </div>
      ),
    },
  ];
  
  const { data, isLoading } = useQuery(
    ["conversionData", { fromDate, toDate, timeDuration, channelSource,shopifySessionData }],
    fetchDashboardData
  );

  return (
    <CustomDashboardTable
      dataSource={data?.combinedCheckoutConversionStats}
      columns={column}
      bordered={true}
      loading={isLoading}
      pagination={{
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20"],
        total: data?.length,
        current: pageNumber,
        onChange: (page, size) => {
          setPageNumber(page);
          setPageSize(size);
        },
        position: ["bottomRight"],
      }}
    />
  );
};

export default FastrrConversionTable;
