import styled from 'styled-components'
import {Button} from 'antd';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
`

export const SkeletonContainer = styled.div`
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ExportButton  = styled(Button)`
    border-radius: 8px
`
export const ConversionContainer = styled.div`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 8px;
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
        height: 80%;
        padding: 4px 10px;
        background: rgba(12, 100, 219, 0.10);
        border-radius: 10px;
        margin-bottom: 5px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        padding: 2px 14px;
        color: #000000;
    }

    .ant-tabs-nav-list{
        border: 1px solid rgba(12, 100, 219, 0.10);
        padding: 4px 6px;
        border-radius: 8px;
    }


    
`