import React, { useContext, useEffect } from 'react';
import { DataContext } from 'context/DataProvider';
import OldDashboard from 'pages/Dashboard';
import CustomDashboard from 'pages/CustomDashboard';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
const navigate = useNavigate();
const {platFormDetails, channelPlatForm, sellerConfig} = useContext(DataContext);

useEffect(() => {
    if(sellerConfig?.is_native_shopify_functions_checkout){
        navigate('/charge-rule');
    }
}, [])

return (
    <>
        {
            (channelPlatForm || localStorage.getItem('isOnboarding')) &&  (
                channelPlatForm === 'custom' && platFormDetails?.custom?.login && !localStorage.getItem('isOnboarding') ? 
            <CustomDashboard /> : <OldDashboard />
            )
        }
    </>
)

}

export default Dashboard;