import { CustomTabs } from "pages/Wallet/style";
import React, { useContext, useState } from "react"
import LandingPageLogin from "./LandingPage";
import LoginPage from "./LoginPage";
import { DataContext, DataProvider } from "context/DataProvider";
import { useMutation } from "react-query";
import { UpdateConfig } from "./script";
import { toast } from "react-toastify";
import { ToastErrorCross, ToastSuccessTick } from "images/icons";
const { TabPane } = CustomTabs;
const FastrrLoginPage = () => {
    const {sellerConfig}=useContext<any>(DataContext)
    const sellerLogo=sellerConfig?.logo_url
    const popupConfigList=sellerConfig?.user_event_config?.popup_config_list?.[0]?.uiConfig
    const [activeKey, setActiveKey] = useState<string>("1");
    const [subheaderText, setSubheaderText] = useState<string>(popupConfigList?.subText||"")
    const [themeColor, setThemeColor] = useState<string>(popupConfigList?.primaryColor||"")
    const [discountText, setDiscountText] = useState<string>(popupConfigList?.discountText||"")
    const [discountCode, setDiscountCode] = useState<string>(popupConfigList?.discountCode||"")
    const [landingPageIcon,setLandingPageIcon]=useState<any>(popupConfigList?.imgUrl||"")
    const[showOnLanding,setShowOnLanding]=useState<boolean>(sellerConfig?.user_event_config?.popup_config_list?.[0]?.enabled || false)
    const[loginPageSubText,setLoginPageSubtext]=useState<string>(popupConfigList?.subTextLogin||"")
    const[loginPageTheme,setLoginPageTheme]=useState<string>(popupConfigList?.loginPageTheme||"")
    const[loginPageIcon,setLoginPageIcon]=useState<any>(popupConfigList?.imgUrlLogin||"")
    const [currentUploadLogin,setCurrentUploadLogin]=useState<any>([])
    const[currentUploadLanding,setCurrentUploadLanding]=useState<any>([])

    const mutation = useMutation(UpdateConfig, {
        onMutate: (variables) => {    
          return { id: 1 };
        },
        onError: (error, variables) => {
            toast.error('Something went wrong', {
                icon: ToastErrorCross
              });
        },
        onSuccess: (message, variables, data) => {
            toast.success('Details Saved', {
                icon: ToastSuccessTick
              });
        },
      });
    const handleTabChange = (activeKey: any) => {
        setActiveKey(activeKey);
      };

    const handleClick=()=>{
        const postData={...sellerConfig}
        postData['user_event_config']={
            "events_enabled": sellerConfig?.user_event_config?.events_enabled==false?false:true,
            "popup_config_list": [
                {
                    "type": "all",
                    "pageType": "all",
                    "enabled": showOnLanding,
                    "uiConfig": {
                        "subText":subheaderText,
                        "subTextLogin":loginPageSubText,
                        "discountText":discountText,
                        "theme": "light",
                        "primaryColor": themeColor,
                        "imgUrl": landingPageIcon,
                        "imgUrlLogin":loginPageIcon,
                        "discountCode": discountCode,
                        "loginPageTheme":loginPageTheme
                    }
                }
            ]
        }
        mutation.mutate(postData)
    }
    return (<>
         <div className="font-bold text-xl bg-white rounded-lg px-4 py-4">Customisation</div>
        <div className="bg-white h-full w-full px-6 py-5 rounded-lg mt-4">

            <CustomTabs
                defaultActiveKey="1"
                onChange={handleTabChange}
                activeKey={activeKey}
            >
                <TabPane tab="Landing Page" key="1">
                    <LandingPageLogin showOnLanding={showOnLanding} 
                    setShowOnLanding={setShowOnLanding} 
                    subheaderText={subheaderText} 
                    setSubheaderText={setSubheaderText} 
                    themeColor={themeColor} 
                    setThemeColor={setThemeColor} 
                    discountText={discountText} 
                    setDiscountText={setDiscountText} 
                    discountCode={discountCode} 
                    setDiscountCode={setDiscountCode} 
                    filesUploaded={landingPageIcon} 
                    setFilesUploaded={(url:any)=>{
                       if(activeKey=="1") setLandingPageIcon(url) 
                       else  setLoginPageIcon(url)}} 
                    sellerLogo={sellerLogo}
                    currUpload={currentUploadLanding}
                    setCurrentUpload={(url:any)=>{
                        if (activeKey=='1'){
                            setCurrentUploadLanding(url)
                        }
                        else{
                            setCurrentUploadLogin(url)
                        }
                    }}
                    />
                </TabPane>
                <TabPane tab="Login Page" key="2">
                    <LoginPage 
                    loginPageSubText={loginPageSubText} 
                    setLoginPageSubtext={setLoginPageSubtext} 
                    loginPageCTA={loginPageTheme} setLoginPageCTA={setLoginPageTheme} 
                    filesUploaded={loginPageIcon} 
                    setFilesUploaded={(url:any)=>{
                        if(activeKey=="2") setLoginPageIcon(url) 
                        else  setLandingPageIcon(url)
                    }
                }
                    currUpload={currentUploadLogin}
                    setCurrentUpload={(url:any)=>{
                        if (activeKey=='2')setCurrentUploadLogin(url)
                        else setCurrentUploadLanding(url)
                    }}
                    sellerLogo={sellerLogo}
                    />
                </TabPane>
            </CustomTabs>
            <div className="flex gap-4 mt-6">
            <button className="px-8 py-1 bg-purpleText rounded-lg text-white" onClick={handleClick}>Save</button>
        </div>
        </div>
    </>)
}
export default FastrrLoginPage