import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { FlexBox, FlexContainer } from "components/UIElements";
import {
  ProductItem,
  ProductHeading,
  VariantSelected,
  ButtonEdit,
  ButtonClose,
  Text,
} from "../styled";
import { convertToObject } from "typescript";
import { ValueText } from "pages/Dashboard/PieView/styled";

import { InputNumber } from "antd";

const SearchedProductList = ({
  selectedItems,
  setIsProductModalOpen,
  setEditItemId,
  setSelectedItems,
  type,
  dynamicBundle,
  discountType,
  setSelectProductId,
}: any) => {
  // const[inputValue,setInputValue]=useState<number>(0)

  const editItemHandler = (itemId: number | string) => {
    if (itemId) {
      setSelectProductId()
      setEditItemId(itemId);
      setIsProductModalOpen(true);
    }
  };

  const deleteItemHandler = (itemId: string | number) => {
    setSelectedItems(selectedItems.filter((item: any) => item.id !== itemId));
  };


  const qtyChangeHandler = (qty: number, itemId: string | number) => {
    const updatedItems = [...selectedItems];
    updatedItems.forEach((item:any) => {
        if(item.id === itemId){
        item.quantity = qty
        item.focus=true
      }
      else{
        item.focus=false
      }
    })
    setSelectedItems([...updatedItems]);

  }

  return (
    <div style={{ marginBottom: 36 }}>
      {selectedItems?.length > 0 &&
        selectedItems?.map((value: any, index: number) => {
          return (
            <ProductItem key ={index}>
              <div style={{maxWidth: '64%'}}>
                <ProductHeading>
                <FlexBox style={{gap: 4}}>
                  <span>{`${index + 1}.`}</span>
                  <span>{`${value?.name} ( ID: ${value?.id} )`}</span>
                </FlexBox>
                   
                </ProductHeading>
                {type === "product" && (
                  <VariantSelected>
                    {value?.name} ({
                      value?.selectedItems?.length ? value?.selectedItems?.length : value?.checked ? value?.variantCount : value?.variants?.length} out{" "}
                    {value?.variantCount ? value?.variantCount : value?.variants?.length} variants selected)
                  </VariantSelected>
                )}
              </div>

              <FlexContainer style={{ gap: 16 }}>
                {((discountType === "bundle" && type === "product" && !dynamicBundle)|| (discountType==='freebie')) && 
                <>
                  <Text>Qty</Text>
                  <InputNumber
                    onChange={(qty:number) => qtyChangeHandler(qty, value?.id)}
                    autoFocus={value.focus}
                      defaultValue={value?.quantity}
                      precision={0}
                      min={1}
                        style={{
                          maxWidth: 76,
                          border: "1px solid #DFE0EB",
                          borderRadius: 6,
                        }}
                    />
                  </>
                }

                {type === "product" && (
                  <ButtonEdit onClick={() => editItemHandler(value?.id)}>
                    Edit
                  </ButtonEdit>
                )}

                <ButtonClose>
                  {/* @ts-ignore */}
                  <CloseOutlined onClick={() => deleteItemHandler(value?.id)} />
                </ButtonClose>
              </FlexContainer>
            </ProductItem>
          );
        })}
    </div>
  );
};

export default SearchedProductList;
