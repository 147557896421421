import React from "react";
import { BadgeContainer, Container, TableContainer, TableContentContainer, Title } from "./style";
import {Heading} from '../AddressPrefill/style'
import EmptyView from "components/UIElements/EmptyView";
import { Table } from "antd";
import { AlignType } from 'rc-table/lib/interface';
import { ExportButton } from "../styled";
import { FlexStartContainer } from "components/Settings/Discount/DiscountCard/styled";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { downloadDiscount } from "../script";

const Discount = ({discountData, discountPage, setDiscountPage, isDiscountDataLoading, fromDate , toDate} : any) => {

    let columns: object[] = [
        {
          title: 'Discount Code ',
          key: 'discountCode',
          align: 'left' as AlignType,
          
          render: (data : any, record:any) => (
            <TableContentContainer>
            <img
              src="https://d10srchmli830n.cloudfront.net/1689677620245_d95cd2a8-ffc6-4bc0-b34d-b38b299bc21d_tag-1.svg"
              alt="discount-icon"
              style={{marginRight : '5px',height : '15px', width : '15px'}}
            />
            {data?.discountCode}
          </TableContentContainer>
          )
        },
        {
          title: 'Type of discount',
          key: 'discountType',
          align: 'left' as AlignType,
          
          render: (data : any, record:any) => (
            <TableContentContainer>
              {data?.discountType}
            </TableContentContainer>
          )
        },
        {
          title: 'Total orders placed using the coupon',
          key: 'totalOrderPlacedUsingCoupon',
          align: 'left' as AlignType,
          
          render: (data : any, record:any) => (
            <TableContentContainer>
              {data?.totalOrderPlacedUsingCoupon}
            </TableContentContainer>
          )
        },
        {
          title: 'Conversion% where coupon was available',
          key: 'conversionPercentWhenCouponAvailable',
          align: 'left' as AlignType,
          
          render: (data : any, record:any) => (
            <BadgeContainer>
              {`${data?.conversionPercentWhenCouponAvailable?.toFixed(2) ?? '-'}%`}
            </BadgeContainer>
          )
        },
        {
          title: 'Total GMV of Orders',
          key: 'totalOrderAmount',
          
          align: 'left' as AlignType,
          render: (data : any, record:any) => (
            <TableContentContainer>
              {data?.totalOrderAmount}
            </TableContentContainer>
          )
        },
        {
          title: 'Total Discount Amount',
          key: 'totalDiscountAmount',
          
          align: 'left' as AlignType,
          render: (data : any, record:any) => (
            <TableContentContainer>
              {data?.totalDiscountAmount}
            </TableContentContainer>
          )
        },
        // {
        //     title: 'Checkouts where discount was shown as default coupon',
        //     key: 'totalCheckoutWhereDiscountShownDefaultCoupon',
        //     align: 'left' as AlignType,
            
        //     render: (data : any, record:any) => (
        //       <TableContentContainer>
        //         {data?.totalCheckoutWhereDiscountShownDefaultCoupon}
        //       </TableContentContainer>
        //     )
        // },
        // {
        //     title: 'Total orders when coupon was shown as default coupon',
        //     key: 'totalOrderWhereDiscountShownDefaultCoupon',
        //     align: 'left' as AlignType,
            
        //     render: (data : any, record:any) => (
        //       <TableContentContainer>
        //         {data?.totalOrderWhereDiscountShownDefaultCoupon}
        //       </TableContentContainer>
        //     )
        // },
        // {
        //     title: 'Conversion% when coupon was shown as default',
        //     key: 'conversionPercentWhenCouponShownDefault',
        //     align: 'left' as AlignType,  
            
        //     render: (data : any, record:any) => (
        //       <BadgeContainer>
        //         {`${data?.conversionPercentWhenCouponShownDefault?.toFixed(2) ?? '-'}%`}
        //       </BadgeContainer>
        //     )
        // },
        {
          title: 'Total attempts',
          key: 'totalAttempt',
          align: 'left' as AlignType,  
          
          render: (data : any, record:any) => (
            <TableContentContainer>
              {data?.totalAttempt?.toFixed(2) ?? '-'}
            </TableContentContainer>
          )
        },
        {
          title: 'Total applied',
          key: 'totalApplied',
          align: 'left' as AlignType,  
          
          render: (data : any, record:any) => (
            <TableContentContainer>
              {data?.totalApplied?.toFixed(2) ?? '-'}
            </TableContentContainer>
          )
        },
        {
          title: 'Error rate',
          key: 'errorRateCouponApplication',
          align: 'left' as AlignType,  
          
          render: (data : any, record:any) => (
            <TableContentContainer>
              {data?.errorRateCouponApplication?.toFixed(2) ?? '-'}
            </TableContentContainer>
          )
        },
      ]

    return(
        <Container>
            <div style={{
                display: 'flex', 
                justifyContent: 'space-between', 
                padding: '0 24px', 
                alignItems:'center', 
                width: '100%'
            }}>
            <Heading>Discounts</Heading>
            <ExportButton
            /* @ts-ignore */
                icon={<CloudDownloadOutlined />}
                onClick={() =>
                    downloadDiscount(fromDate, toDate)
                }
            >
                Export
            </ExportButton>
            </div>
            { 
                (discountData || isDiscountDataLoading) ?
                <TableContainer>
                    <Table
                    columns={columns}
                    scroll={{ x: '100vh' }}
                    dataSource={discountData?.discounts}
                    bordered={true}
                    pagination={{
                      onChange: (page, pageSize) => {
                        setDiscountPage(page)
                      },
                      current : discountPage,
                      total : discountData?.totalDiscount,
                      showSizeChanger : false
                    }}
                    loading = {isDiscountDataLoading}
                    />

                </TableContainer>
                :
                <EmptyView/> 
            }
            
        </Container>
    )

}

export default Discount