import React from 'react';
import {Heading, PercentText, SubHeading, NormalText, Line, CustomCart} from './style';
import { fetchExistingCustomer } from '../script';
import { useQuery } from 'react-query';


const listData = [
    {
        percentage: '0',
        heading: 'Unique users',
        subText: 'Percentage of unique users that logged in with their address already filled',
        key:'uniqueUsers'
    },
    {
        percentage: '0',
        heading: 'Total sessions',
        subText: 'Percentage of logins with pre-filled addresses',
        key: 'totalSessions'
    },
    
    {
        percentage: '0',
        heading: 'Total orders',
        subText: 'Percentage of orders with the logged in users whose addresses were pre-filled',
        key:'totalOrders'
    },
]

const prefillList = (data: any) => {
    return listData?.map((value, index) => {
        return(
            <div key = {value.key}>
                <PercentText>{data?.[value?.key] ? `${data?.[value?.key]}%` : '-'}</PercentText>
                <SubHeading>{value?.heading}</SubHeading>
                <NormalText>{value?.subText}</NormalText>
                {index !== 2 ? <Line /> : ''}
            </div>
        )
    })
} 

const AddressPrefill = ({fromDate, toDate}:any) => {
    const {
        data,
        isLoading
    } = useQuery(['existingCustomer', {fromDate, toDate}],
        fetchExistingCustomer
    )

    return(
        <CustomCart loading={isLoading}>
            <Heading>Address prefill rate</Heading>
            <div style={{marginTop: 20}}>{prefillList(data)}</div>
        </CustomCart>
    )
}

export default AddressPrefill;

