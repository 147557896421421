import React, {useContext, useState} from "react";
import UserForm from "./UserForm";
import Role from "./Role";
import { Row, Col, Button, notification, message } from "antd";
import { CustomButton, SubHeading } from "../styled";
import { SpaceBetweenContainer } from "components/UIElements";
import { LeftOutlined } from "@ant-design/icons";
import { EMAIL_REGEX } from "utils/constants";
import { DataContext } from "context/DataProvider";
import axios from "axios";
import {createUsers, updateUser} from '../script';
import { useMutation, useQueryClient } from "react-query";


const CreateUser = ({setIsAddNewUser, rowData}:any) => {  
  const [roles, setRole] = useState<any>([]);
  const [accessRole, setAccessRole] = useState<any>({});
  const [userDetails, setUserDetails] = useState<any>({
        name: rowData?.name ? rowData?.name : '', 
        email: rowData?.email ? rowData?.email : '', 
        phone: rowData?.phone ? rowData?.phone : '',
        role: rowData?.role?.[0]?.name ? rowData?.role?.[0]?.name?.toLowerCase()?.includes('custom') ? 'CUSTOM' : rowData?.role?.[0]?.name : ''
   });

  const [userDetailsError, setUserDetailsError] = useState<any>({
    email:false, 
    phone:false
   })

  const [customRoleTypeAccess, setCustomRoleTypeAccess] = useState<any>({
    readOnly: true,
    readWrite: false,
  });

  // selected role
  const [selectedRole, setSelectedRole] = useState<string>(rowData?.role?.[0]?.name ? rowData?.role?.[0]?.name?.toLowerCase()?.includes('custom') ? 'CUSTOM' : rowData?.role?.[0]?.name : '');
  const [customAccessRole, setCustomAccessRole] = useState<any>(rowData?.role?.[0]?.permissions ? [ ...rowData?.role?.[0]?.permissions] : '');
 
  const { sellerConfig } = useContext(DataContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(createUsers, {
    onError: (error:any) => {
      message.error(error.response.data.message);
    },
    onSuccess: () => {
      message.success('user created successfully')
      queryClient.invalidateQueries('activeRole');
      setIsAddNewUser(false);
    },
  });

  const updateRole = useMutation(updateUser, {
    onError: (error:any) => {
      message.error(error.response.data.message);
    },
    onSuccess: () => {
      message.success('user updated successfully')
      queryClient.invalidateQueries('activeRole');
      setIsAddNewUser(false);
    },
  });



  const handleCreateUser = async (data:any) => {
    try{
    if(userDetails.email?.length > 0){
        EMAIL_REGEX.test(userDetails?.email.trim()) 
        ?  setUserDetailsError((prevState:any) => ({...prevState,email: false}))
        : setUserDetailsError((prevState:any) => ({...prevState,email: true}))

    }else if(userDetails.email === ""){
        setUserDetailsError((prevState:any) => ({...prevState,email: true}))
    }

    if(userDetails.phone?.length > 0){
        userDetails?.phone.trim().match(/\d/g).length===10 
        ? setUserDetailsError((prevState:any) => ({...prevState, phone: false}))
        : setUserDetailsError((prevState:any) => ({...prevState, phone: true}))
    }else if(userDetails.phone === ""){
      setUserDetailsError((prevState:any) => ({...prevState, phone: true}))
    }

    const roleSend:any = roles?.filter((value:any) => value?.label === selectedRole);
    
    // roleSend
    if(!userDetailsError?.email && !userDetailsError?.phone && userDetails?.email && userDetails?.phone){
        const reqPayload:any = {
          name: userDetails?.name,
          company_id: sellerConfig?.company_id,
          domain_name: sellerConfig?.domain_name, 
          username: userDetails?.name, 
          email: userDetails?.email,
          phone: userDetails?.phone, 
          type: 'seller',
          ...((!rowData?.id  || selectedRole !== "CUSTOM") ? {role: selectedRole === "CUSTOM" ? [] : [roleSend?.[0]?.value]} : {}),
          ...(selectedRole === "CUSTOM" && {permissions: customAccessRole}),
          active: true, 
          ...(rowData?.id && {id: rowData?.id})
        }

        if(selectedRole === "CUSTOM" && customAccessRole?.length === 0){
          notification.error({message: 'Please select at least one role'})
        }else {
          rowData?.id ? updateRole.mutate(reqPayload) : mutation.mutate(reqPayload);
        }
    }

  }catch(error){
    console.log(error)
  }
  }

  const loader:boolean = (mutation.isError && updateRole.isError) && (mutation.isLoading || updateRole?.isLoading);
  
  return (
    <div>
      <SubHeading>Add user details</SubHeading>
      <SpaceBetweenContainer>
        <Button 
            icon={<LeftOutlined />} 
            type="link"
            loading={loader}
            onClick={() => setIsAddNewUser(false)}
        >
          Back
        </Button>
        <CustomButton 
            type="primary"
            onClick={handleCreateUser}
            loading={loader}
        >
            Save
        </CustomButton>
      </SpaceBetweenContainer>
      <Row>
        <Col xl={userDetails?.role ? 23 : 20}>
            <UserForm 
                setUserDetails = {setUserDetails}
                userDetails = {userDetails}
                userDetailsError = {userDetailsError}
            />
        </Col>
        <Col xl={16}>
        <Role 
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          customRoleTypeAccess={customRoleTypeAccess}
          setCustomRoleTypeAccess={setCustomRoleTypeAccess}
          customAccessRole={customAccessRole}
          setCustomAccessRole={setCustomAccessRole}
          roles = {roles} 
          setRole = {setRole}
          accessRole = {accessRole} 
          setAccessRole = {setAccessRole}
          userDetails = {userDetails}
        /></Col>
      </Row>
    </div>
  );
};

export default CreateUser;
