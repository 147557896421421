import { downloadFile } from 'components/UIElements/function'
import axiosInstance from '../../HTTP/Http';


export const fetchAbandonedData = async ({ queryKey }: any) => {
    const [unusedArgument, 
      { pageNumber, fromDate, toDate, searchTerm, searchKey, loggedInFromAdmin }
    ] = queryKey

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/custom-logins`,
      {
        params: {
          page: pageNumber - 1,
          from: fromDate,
          to: toDate,
          type:'abandon_custom_login',
          ...(searchTerm && {key: searchKey}),
          ...(searchTerm && {value: searchTerm}),
        }
      }
    )
    return response.data.data;
}

export const handleSingleDownload = async (rowId: string, fromDate: string, toDate: string,loggedInFromAdmin : any) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=ORDER`,
      {
          params: {
              from: fromDate,
              to: toDate,
              value: rowId
          }
      }
  )
  downloadFile(response.data, 'orderdata.csv')
}

export const handleMultipleDownload = async  (fromDate: string, toDate: string, keys: string,loggedInFromAdmin : any) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=ORDER`,
      {
          params: {
              from: fromDate,
              to: toDate,
              value: keys
          }
      }
  )
  downloadFile(response.data, 'orderdata.csv')
}

export const handleAllDownload = async (
  fromDate: string, toDate: string, searchTerm:string, searchKey:string
) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=abandon_custom_login`,
      {
          params: {
              from: fromDate,
              to: toDate,
            //   source: source,
              ...(searchTerm && {key: searchKey}),
              ...(searchTerm && {value: searchTerm}),
            //   ...(deliveryFilter.length && { 'addFilter.dMethod': deliveryFilter.join(',') }),
            //   ...(paymentFilter.length && { 'addFilter.pMode': paymentFilter[0] }),
            //   ...(rtoFilter.length && { 'addFilter.rtoPredict': rtoFilter[0] })
          }
      }
  )
  downloadFile(response.data, 'orderdata.csv')
}