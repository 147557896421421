import styled from 'styled-components'

export const Container = styled.div`
    background: #FFF;
    height: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 0px 5px;
    flex-basis: 100%;

    &:first-child {
        margin-left:0;
    }

    &:last-child {
        margin-right:0;
    }
`

export const HeadingText = styled.h6`
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-align: left;
    color: rgba(0, 11, 52, 0.7);
    margin: 0;
    margin-bottom: 10px;
`

export const StatText = styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #0C3784;
`
export const ChangeBox = styled.div`
    background: ${(props) => props.color};
    width: fit-content;
    height: 23px;
    border-radius: 5.9px; 
    padding: 0px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    * {
        margin: 0;
    }
`

export const ChangeText = styled.h6`
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.17px;
    text-align: left;
    color: ${(props) => props.color};
    margin-left: 3px;
`

export const ListText = styled.h6`
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-align: left;
    color: rgba(0, 11, 52, 0.7);
;
`