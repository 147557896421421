import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "antd/lib/modal/Modal";

const HowToUseModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  
  const onCancel = () => {
    localStorage.setItem('newUser', true);
    setModalVisible(true)
  }

  return (
    <Modal
      visible={!modalVisible}
      footer={false}
      onCancel={onCancel}
      keyboard={true}
    >
    <Container>
      <Header>
        <Logo
          src="https://pickrr.s3.amazonaws.com/2024-10-11T10:59:55.713213_smart_cod_logo.svg" // Replace with actual logo URL
          alt="Shiprocket SmartCOD"
        />
      </Header>

      <HowToUseTitle>How to use:</HowToUseTitle>
      
      <StepsContainer>
        <Step 
          icon = 'https://pickrr.s3.amazonaws.com:443/2024-10-11T11:23:30.376303_step1.svg'
          number="1"
          title="Create A New Rule"
          description="Create a new rule of payment or shipping customisation"
        />
        <Step 
          icon = 'https://pickrr.s3.amazonaws.com:443/2024-10-11T11:24:34.785751_step2.svg'
          number="2"
          title="Select Criteria"
          description="Select one or more criteria on which you want your checkout to be customised"
        />
        <Step 
          number="3"
          title  = "Customise the checkout"
          icon="https://pickrr.s3.amazonaws.com:443/2024-10-11T11:24:49.075395_step3.svg"
          description="Control the visibility of your payments or shipping based on the previous criteria"
        />
      </StepsContainer>
      <div
        style={{display: 'flex', justifyContent:'center'}}
      >
        <GetStartedButton
          onClick={onCancel}
        >Get Started</GetStartedButton>
      </div>
    </Container>
    </Modal>
  );
};

const Step = ({ number, title, description, icon }) => (
  <StepWrapper>
    <StepCircle>
      <StepNumber>
        <img src = {icon} />
      </StepNumber>
    </StepCircle>
    <StepText>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>
    </StepText>
  </StepWrapper>
);

const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 8px;
  position: relative;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 10px;
`;

const HowToUseTitle = styled.h2`
  margin-top: 20px;
  font-size: 18px;
  color: #4A4A4A;
  font-weight: 600;
`;

const StepsContainer = styled.div`
  margin-top: 20px;
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StepCircle = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
`;

const StepNumber = styled.span`
  font-size: 14px;
  color: #AE69E0;
`;

const StepText = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepTitle = styled.h3`
  font-size: 16px;
  color: #AE69E0;
  margin: 0;
`;

const StepDescription = styled.p`
  font-size: 14px;
  color: #4A4A4A;
  margin-top: 5px;
`;

const GetStartedButton = styled.button`
  width: 40%;
  background-color: #6D3ED7;
  color: white;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 14.03px 28.05px 0px #4429BE3C;
  cursor: pointer;

`;

export default HowToUseModal;
