import styled from 'styled-components';

export const FormContainer = styled.div`
    padding: 10px 20px;
`

export const SubHeading = styled.h5`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000B34;
    margin-top: 10px;
    margin-bottom: 25px;
`

export const CheckboxText = styled.h5`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #0C3784;
    margin: 2px 0px 0px 12px; 
`

export const CheckboxDescriptipon = styled.h6`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 124%;
    color: #000B34;
    opacity: 0.7;
    margin: -25px 0px 20px 35px;
`

export const BadgeBox = styled.div`
    color:#0051BF;
    max-width: 580px;
    font-size:14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.03em;
    padding: 10px 20px;
    background: #F5F9FF;
    border-radius: 6px;
    vertical-align: center;
    margin-bottom: 16px;

`

export const FootereBox = styled.div`
    color:#0051BF;
    max-width: 580px;
    font-size:14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.03em;
    padding: 10px 20px;
    background: #F5F9FF;
    border-radius: 6px;
    vertical-align: center;
    margin-bottom: 16px;
    cursor:pointer

`