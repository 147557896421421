import React from "react";
import { Row, Col, Form, Select, Checkbox } from "antd";
import { SubHeading, CustomInput, LabelText, } from "../styled";
import ProductSearch from "./ProductSearch";

const { Option } = Select;

const BXGYConfig = ({
  customerBuysItems,
  setCustomerBuysItems,
  customerGetsItems,
  setCustomerGetsItems,
  configCustomerTypes,
  setConfigCustomerTypes,
  discountType,
  selectTypes,
  setSelectTypes,
  form,
  sellerConfigValues
}: any) => {

  const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);
  const regex = new RegExp(/^(((?!0)[0-9]+[.]?[0-9]*))$/);
  const percentRegex = new RegExp(
    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?)(\.[0-9]{1,2})?$)/
  );

  const getDiscountType = selectTypes?.bxgyGetsDiscountType;



  return (
    <Row align={"middle"} gutter={[20, 0]}>
      <Col span={24}>
        <LabelText>Select Quantity / Cart Value</LabelText>
        <Select
          defaultValue={selectTypes.bxgyType}
          style={{ width: "100%", marginBottom: 24 }}
          placeholder="---select type---"
          showArrow={true}
          onChange={(value: string) => {
            if (value) {
              setSelectTypes({ ...selectTypes, bxgyType: value });
              setCustomerBuysItems([]);
              setCustomerGetsItems([]);
              setConfigCustomerTypes({
                ...configCustomerTypes,
                buysType: '',
                getsType: '',
              });

              form
                .validateFields()
                .then((values: any) => {
                  form.setFieldsValue({
                    customerBuysquantity: null,
                    customerBuyscart: null,
                    customerGetscart: null,
                    customerGetsquantity: null,
                    customerBuysListType: null,
                    customerGetsListType: null,
                  });
                })
                .catch((errorInfo: any) => {
                  console.log(errorInfo);
                });
            }
          }}
        >
          <Option value="amount">Cart value</Option>
          <Option value="quantity">Quantity</Option>
        </Select>
      </Col>
      <Col span={24}>
        <SubHeading size={"14px"} style={{ marginBottom: 10 }}>
          Customer Buys
        </SubHeading>
      </Col>
      <Col span={12}>
        <Form.Item
          name={`customerBuys${selectTypes.bxgyType}`}
          label={selectTypes.bxgyType === "amount" ? "Cart value" : "Quantity"}
          rules={[
            {
              required: true,
              pattern: selectTypes.bxgyType === 'amount' ? regex : quantityRegex,
              message: "Enter valid value",
            },
          ]}
        >
          <CustomInput
            placeholder={
              selectTypes?.bxgyType === "amount"
                ? "Enter cart value"
                : "Enter Quantity"
            }
            maxLength={selectTypes.bxgyType === 'amount' ? 10 : 6}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="customerBuysListType"
          label="From"
          rules={[
            {
              // required: true,
              message: "please select from type",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="---select type---"
            allowClear
            showArrow={true}
            onChange={(value: string) => {
              if (value) {
                setConfigCustomerTypes({
                  ...configCustomerTypes,
                  buysType: value,
                });
                setCustomerBuysItems([]);
              }
            }}
          >
            <Option value="product">Product</Option>
            <Option value="collection">Collection</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <ProductSearch
          selectedItems={customerBuysItems}
          setSelectedItems={setCustomerBuysItems}
          type={configCustomerTypes.buysType}
          discountType={discountType}
          form={form}
          sellerConfigValues = {sellerConfigValues}
          bundleKey = {Math.random() * 1001}
        />
      </Col>
      <Col span={12}>
        <Form.Item
          name={"bxgyItemLevel"}
          valuePropName="checked"
        >
          <Checkbox style={{marginTop:'20px'}}value="itemLevel">Item Level</Checkbox>
        </Form.Item>
      </Col>

      <Col span={24}>
        <SubHeading size={"14px"} style={{ marginTop: 10, marginBottom: 10 }}>
          Customer Gets
        </SubHeading>
      </Col>
      <Col span={12}>
        <Form.Item
          name={`customerGetsquantity`}
          label="Quantity"
          rules={[
            {
              required: configCustomerTypes?.getsType &&  true,
              pattern: quantityRegex,
              message: "Enter valid value",
            },
          ]}
        >
          <CustomInput
            placeholder={
              selectTypes?.bxgyType === "amount"
                ? "Enter cart value"
                : "Enter Quantity"
            }
            maxLength={6}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="customerGetsListType" label="From">
          <Select
            style={{ width: "100%" }}
            placeholder="---select type---"
            allowClear
            showArrow={true}
            onChange={(value: string) => {
              if (value) {
                setConfigCustomerTypes({
                  ...configCustomerTypes,
                  getsType: value,
                });
                setCustomerGetsItems([]);
              }
            }}
          >
            <Option value="product">Product</Option>
            <Option value="collection">Collection</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <ProductSearch
          selectedItems={customerGetsItems}
          setSelectedItems={setCustomerGetsItems}
          type={configCustomerTypes.getsType}
          discountType={discountType}
          sellerConfigValues = {sellerConfigValues}
          bundleKey = {Math.random() * 1002}
        />
      </Col>
          
      <Col span={24} style={{marginTop: 16}}>
        <Form.Item 
          name="bxgyGetDiscountValue" 
          label="Give at"
          rules={[
            {
              required: true,
              message: "Please select a value",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="---select type---"
            showArrow={true}
            onChange={(value: string) => {
              if (value) {
                setSelectTypes({
                  ...selectTypes,
                  bxgyGetsDiscountType: value,
                });
              }
            }}
          >
            <Option value="bxgyPercent">Discounted value (%)</Option>
            <Option value="bxgyFlat">Flat price &#8377;</Option>
          </Select>
        </Form.Item>
      </Col>
      {
        getDiscountType === "bxgyPercent" || getDiscountType === "bxgyFlat" ? 
        <Col span={24}>
          <Form.Item
            name={getDiscountType === "bxgyPercent" ? 'bxgyPercent' : "bxgyFlat"}
            rules={[
              {
                required: true,
                pattern: getDiscountType === "bxgyPercent" ? percentRegex : regex,
                message: "Enter the valid value",
              },
            ]}
            style={{marginTop:10}}
          >
            <CustomInput 
              maxLength={getDiscountType === "bxgyPercent" ? 4 : 6} 
              placeholder= {getDiscountType === "bxgyPercent" ? 'At discounted value (%)' : 'At flat price  ₹'} 
            />
          </Form.Item>
        </Col>  
        : ''
      }
       


      <Col span={24}>
        <Form.Item name="enableLimit" style={{ marginTop: 10 }} valuePropName="checked">
          <Checkbox
            onChange={(e: any) =>
              setSelectTypes({ ...selectTypes, enableLimit: e.target.checked })
            }
          >
            <span
              style={{
                fontSize: "14px",
                color: "#38446D",
                fontWeight: "bold",
              }}
            >
              Set a maximum number of uses per order
            </span>
          </Checkbox>
        </Form.Item>
      </Col>

      {selectTypes.enableLimit && (
        <Col span={24}>
          <Form.Item
            name="maxLimit"
            label="Max Limit"
            rules={[
              {
                required: selectTypes.enableLimit,
                pattern: new RegExp(/^(([1-9]?[0-9]*))$/),
                message: "Enter the valid value",
              },
            ]}
          >
            <CustomInput
              maxLength={6}
              placeholder="Enter maximum number of uses per order"
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default BXGYConfig;
