import styled from "styled-components";
import { Modal } from 'antd'

export const CustomModal = styled(Modal)`
    .ant-modal-content{
        border-radius: 10px;
    }

    .ant-steps-item-icon {
        display: none;
    }
`

export const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const CancelButton = styled.button`
    background: none;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.07em;
    color: #0C3784;
    cursor: pointer;
`
export const BackButton = styled(CancelButton)`
    margin-right: 20px;
    cursor: pointer;
`

export const NextButton = styled.button`
    background: #0C3784;
    border-radius: 7px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.07em;
    text-align: center;
    color: #FFFFFF;
    height: 30px;
    width:110px;
    border: none;
    cursor: pointer;
`

export const Heading = styled.h3`
    margin-top: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0C3784;
    margin-bottom: 0;

`

export const SubHeading = styled.h6`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0C3784;
    margin-top:5px;
`