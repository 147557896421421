import axiosInstance from '../../HTTP/Http';
import { downloadFile } from 'components/UIElements/function';


export const fetchLoginData = async ({ queryKey }: any) => {
    const [unusedArgument, 
      { pageNumber, fromDate, toDate, searchTerm, searchKey}
    ] = queryKey

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/custom-logins`,
      {
        params: {
          page: pageNumber - 1,
          from: fromDate,
          to: toDate,
          type:'custom_login',
          ...(searchTerm && {key: searchKey}),
          ...(searchTerm && {value: searchTerm}),
        }
      }
    )
    return response.data.data;
}

export const handleSingleDownload = async (rowId: string, fromDate: string, toDate: string,loggedInFromAdmin : any) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=ORDER`,
      {
          params: {
              from: fromDate,
              to: toDate,
              value: rowId
          }
      }
  )
  downloadFile(response.data, 'orderdata.csv')
}

export const handleMultipleDownload = async  (fromDate: string, toDate: string, keys: string,loggedInFromAdmin : any) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=ORDER`,
      {
          params: {
              from: fromDate,
              to: toDate,
              value: keys
          }
      }
  )
  downloadFile(response.data, 'orderdata.csv')
}

export const handleAllDownload = async (
  fromDate: string, toDate: string, searchKey:string, searchTerm:string
) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=custom_login`,
      {
          params: {
              from: fromDate,
              to: toDate,
              ...(searchTerm && {key: searchKey}),
              ...(searchTerm && {value: searchTerm}),
          }
      }
  )
  downloadFile(response.data, 'orderdata.csv')
}