import {Modal} from 'antd';
import { PickrrButton } from 'components/UIElements';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 12px;
        overflow: hidden;
    }
`

export const Container = styled.div`
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const IconContainer = styled.div<{color: string}>`
  margin: 0 auto;
  background: ${(props:any) => (props.color ? props.color : '#19870B')};
  height: 44px;
  width: 44px;
  border-radius: 50%;
  text-align: center;
  line-height: 44px;
  font-weight: 700;
  color: #fff;
  font-size: 18px;
  position: relative;
  z-index: 22;
  &:before {
    content: '';
    background:  ${(props:any) =>
      props.color ? props.color : '#19870B'};
    opacity: .4;
    position: absolute;
    height: 56px;
    width: 56px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
    animation: circleAnimation 1s linear .2s 3 alternate;
  }
  @keyframes circleAnimation {
    0% {
      height: 44px
      width: 44px;
    }
    50%{
      height: 64px;
      width: 64px;
    }
    100% {
      height: 56px;
      width: 56px;
    }
  }
`;

export const PrimaryText = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000B34;
  max-width: 70%;
  margin-top: 24px;
`;

export const NormalText = styled.h4<{size?:string | number}>`
    font-weight: 500;
    font-size: ${(props:any) => props.size ? props.size : '16px'};
    line-height: 22px;
    text-align:center;
    color: #000B34;
    max-width: 85%;
`;


export const Price = styled.span`
  color: #0C64DB;
`

export const CustomPickrrButton = styled(PickrrButton)`
  height: 40px;
  width: 136px;
  margin-top: 16px;
  text-transform: capitalize;
  font-weight:bold !important;
`;

export const StatusAmountContainer = styled.div<{bgColor?:string}>`
  padding: 28px;
  background-color: ${(props:any) => 
    props.bgColor ? props.bgColor : '#F0F6FD'};
  min-width: 95%;
  border-radius:8px;  
  margin-bottom: 20px
`

export const KeyValue =  styled.span<{textColor?:string}>`
    color:${(props:any) => props?.textColor ? props?.textColor : '#000B34'
    };
    font-size:14px;
    line-height: 14px;
    letter-spacing: 0.4px;
`

export const ExtraText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`


