import styled from 'styled-components';
import { Button, Form } from 'antd';

export const CreateDiscountButton = styled(Button)`
  background: #ff756c;
  border: 1px solid #c5cde3;
  box-sizing: border-box;
  border-radius: 10px;
  color: #ffffff;
  width: 163px;
  height: 40px;
  &:focus {
    outline: none !important;
    background: #ff756c;
    color: #ffffff;
  }
  &:hover {
    outline: none !important;
    background: #ff756c;
    color: #ffffff;
  }
`;

export const DiscountContainer = styled.div`
  border: 1px solid #c5cde3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
`;

export const DiscountListContainer = styled.div`
  background: #f9faff;
  border: 1px solid #c5cde3;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 18px 0px;
  width: 100%;
  padding: 20px;
`;

export const Container = styled.div`
  background: #fff;
  min-height: 70vh;
  border-radius: 12px;
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
    border: 1px solid #263f97;
  }
  .ant-form-vertical .ant-form-item-label > label {
    color: #263f97;
    font-weight: bold;
  }
  .ant-upload.ant-upload-select {
    width: 100%;
    height: 100%;
  }
  .ant-switch-checked {
    background-color: #0C64DB
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0C64DB
    border-color: #0C64DB
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #0C64DB
  }
  .ant-radio-inner::after {
    background-color: #0C64DB
  }
  .ant-checkbox + span{
    color : #263f97;
  }
`;

export const CustomForm = styled(Form)`
   padding: 30px !important;
  .ant-form-item-label > label {
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    color: #263f97;
  }
  .ant-upload.ant-upload-select {
    width: 100px;
    height: 100px;
  }
`;

export const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubHeader = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #263f97;
  font-weight: bold;
`;

export const SubText = styled.div`
  font-size: 16px;
  color: #38446d;
`;

export const PickrrButton = styled(Button)`
  background-color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return '#263F97';
      case 'secondary':
        return 'transparent';
      case 'grey':
        return '#808080';
      case 'peach':
        return '#ff756c';
      case 'cancel':
        return '#EDF0F9';
      case 'outline':
        return '#EDF0F9';
      case 'white':
        return '#fff';
      case 'white-red':
        return '#fff';
      case 'error':
        return '#fff';
      case 'soft-blue':
        return 'rgb(38 63 151)';
      case 'light-focus':
        return '#EDF0F9';
      default:
        return '#263F97';
    }
  }} !important;
  border-color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return '#263F97';
      case 'secondary':
        return '#808080';
      case 'grey':
        return '#808080';
      case 'peach':
        return '#ff756c';
      case 'cancel':
        return '#EDF0F9';
      case 'outline':
        return 'transparent';
      case 'white':
        return '#fff';
      case 'white-red':
        return '#fff';
      case 'error':
        return '#FF756C';
      case 'soft-blue':
        return '#7E8FCC';
      case 'light-focus':
        return '#EDF0F9';
      default:
        return '#263F97';
    }
  }} !important;
  color: ${(props) => {
    switch (props.color) {
      case 'secondary':
        return '#808080';
      case 'cancel':
        return '#7D8CC1';
      case 'outline':
        return '#263F97';
      case 'white':
        return '#263F97';
      case 'white-red':
        return '#DE1306';
      case 'error':
        return '#FF756C';
      case 'light-focus':
        return '#263F97';
      default:
        return '#fff';
    }
  }} !important;
  font-weight: ${(props) => {
    switch (props.weight) {
      case 'bold':
        return 'bold';
      case 'normal':
        return 'normal';
      default:
        return 'normal';
    }
  }} !important;

  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.12px;
  text-transform: uppercase;

  &:disabled {
    color: #00000040 !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
`;

export const FieldDescription = styled.div`
  font-size: 12px;
  color: #8D98AA;
  margin-top: -16px;
`;