import React from 'react'

const CustomSkeleton = () => {
    return (
        <div role="status" className="max-w-sm animate-pulse text-blue-600">
            <div className="h-2.5 bg-skeletonGrey rounded w-24 m-2 p-2.5">
            </div>
        </div>
    )
}

export default CustomSkeleton