import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ModalHeading, PaymentGatewayListItem } from "./style";
import SetuGatewayForm from "./Setu";
import BilldeskGatewayForm from "./Billdesk";
import CashfreeGatewayForm from "./Cashfree";
import CredpayGatewayForm from "./Credpay";
import DecentroGatewayForm from "./Decentro";
import EaseBuzzGatewayForm from "./EaseBuzz";
import RazorpayGatewayForm from "./Razorpay";
import PayUGatewayForm from "./PayU";
import PhonePeGatewayForm from "./PhonePe";
import SnapmintGatewayForm from "./Snapmint";
import SimplGatewayForm from "./Simpl";
import PotleeGatewayForm from "./Settle";
import BharatxGatewayForm from "./BharatX";
import SimplPi3GatewayForm from "./SimplPi3";


const AddPaymentGatewayModal = ({isVisible,setIsVisible,gatewayData,setGatewayData,globalPaymentConfigData,sellerId,viewOnly,setViewOnly,setUpdateState,loading , setLoading,success , setSuccess,razorpayData}) => {

    const [selectedGateway,setSelectedGateway] = useState('')
    const [selectedGatewayConfig,setSelectedGatewayConfig] = useState({})

    // const orderedArray= ['razorpay', 'billdesk', 'decentro', 'cod', 'credpay', 'setu', 'easebuzz', 'payu', 'snapmint', 'cashfree', 'phonepe'];

    // const modifiedGatewayConfig = orderedArray.reduce((acc, item) => {
    //     const found = globalPaymentConfigData.find(element => element.payment_gateway === item);
    //         if (found) acc.push(found);
    //         return acc;
    //   }, []);

    useEffect(()=>{
        if(gatewayData?.gateway){
            setSelectedGateway(gatewayData?.gateway)
            const gatewayConfig = globalPaymentConfigData?.filter((paymentConfigData)=>paymentConfigData?.payment_gateway?.toUpperCase() ===  (gatewayData?.gateway?.toUpperCase()==='POTLEE'?'SETTLE':gatewayData?.gateway?.toUpperCase())) ?? []
            setSelectedGatewayConfig(gatewayConfig?.[0])
        }else{
            setSelectedGateway('')
            setSelectedGatewayConfig({})
        }
    },[gatewayData])

    const renderContent = () => {
        if (selectedGateway?.toUpperCase() === "RAZORPAY") {
            return <RazorpayGatewayForm gatewayData={Object.keys(gatewayData).length === 0 ? razorpayData : gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState} loading= {loading}  setLoading = {setLoading} success= {success}  setSuccess = {setSuccess}/>
        }
        else if(selectedGateway?.toUpperCase() === "SETU") {
            return <SetuGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "CASHFREE")  {
            return <CashfreeGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "DECENTRO") {
            return <DecentroGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "BILLDESK"){
            return <BilldeskGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }else if (selectedGateway?.toUpperCase() === "CREDPAY"){
            return <CredpayGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }else if (selectedGateway?.toUpperCase() === "EASEBUZZ"){
            return <EaseBuzzGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "PAYU"){
            return <PayUGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "PHONEPE"){
            return <PhonePeGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "SNAPMINT"){
            return <SnapmintGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "SIMPL"){
            return <SimplGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase()==='POTLEE'|| selectedGateway?.toUpperCase()==='SETTLE'){
            return <PotleeGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }
        else if (selectedGateway?.toUpperCase() === "SIMPLPI3"){
            return <SimplPi3GatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId ={sellerId} setIsVisible={setIsVisible} viewOnly ={viewOnly} setViewOnly={setViewOnly} setGatewayData = {setGatewayData} setSelectedGatewayConfig = {setSelectedGatewayConfig} setUpdateState = {setUpdateState}/>
        }

        // else if (selectedGateway?.toUpperCase()==='BHARATX'){
        //     return <BharatxGatewayForm gatewayData={gatewayData} gatewayConfig={selectedGatewayConfig} sellerId={sellerId} setIsVisible={setIsVisible} viewOnly={viewOnly} setViewOnly={setViewOnly} setGatewayData={setGatewayData} setSelectedGatewayConfig={setSelectedGatewayConfig} setUpdateState={setUpdateState}/>
        // }
    }

    return(
        <Modal
        visible = {isVisible}
        onCancel={()=>{
            setGatewayData({})
            setIsVisible(false)
            setSelectedGatewayConfig({})
            setViewOnly(false)
        }}
        footer = {null}
        width={650}
        destroyOnClose = {true}
        keyboard= {true}
        >
            {!selectedGateway ?
            <>
                <ModalHeading>
                Select Gateway
                </ModalHeading>
                <div style={{display : 'flex',flexDirection : 'column',width : '100%',maxHeight : '350px',overflowY : 'scroll'}}>
                {globalPaymentConfigData?.length > 0 &&
                globalPaymentConfigData?.map((paymentConfigData)=>{
                    return(
                        <PaymentGatewayListItem onClick={()=>{
                            setSelectedGatewayConfig(paymentConfigData)
                            setSelectedGateway(paymentConfigData?.payment_gateway?.toUpperCase())
                        }}>
                            {paymentConfigData?.payment_gateway?.toUpperCase()}
                        </PaymentGatewayListItem>
                    )
                })

                }
                </div>
            </>
            :
            <div style={{display : 'flex',flexDirection : 'column',width : '100%'}}>
                <ModalHeading>
                {selectedGateway?.toUpperCase()==='POTLEE'?'SETTLE':selectedGateway?.toUpperCase()}
                </ModalHeading>
                {
                    renderContent()
                }

            </div>
            }

        </Modal>
    )
}

export default AddPaymentGatewayModal