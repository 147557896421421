import React from 'react';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  pageSize:number
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  pageSize
}) => {
  const getPageNumbers = (): number[] => {
    const pageNumbers: number[] = [];
    const firstPage = Math.max(1, currentPage - 2);
    const lastPage = Math.min((totalPages/pageSize)+1, firstPage + 5);
    for (let i = firstPage; i <= lastPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div className="text-center bg-white">
        <button
        onClick={() => onPageChange(currentPage-1)}
        disabled={currentPage === 1}
        className='py-0.5 px-3 border border-pageBorder rounded'
      >
        {'<'}
      </button>
      {getPageNumbers().map((pageNumber) => (
        <button
          key={pageNumber}
          className={`px-3 py-0.5 border rounded-md border-pageBorder ml-1 mr-1 ${pageNumber===currentPage?'bg-paginationGrey text-white':''}`}
          onClick={() => onPageChange(pageNumber)}
        >
          {pageNumber}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage+1)}
        disabled={currentPage === Math.floor(totalPages/pageSize)+1}
        className='py-0.5 px-3 border border-pageBorder rounded'
      >
        {'>'}
      </button>
    </div>
  );
};

export default Pagination;
