import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { DataContext } from "context/DataProvider";
import {DownIcon} from '../../../src/images/icons';
import { DataBar } from './data';

interface Props{
    callback:any,
    defaultSelectedKey:string,
    keys:DataBar
}

const MenuItem:React.FC<Props> = ({ callback, defaultSelectedKey, keys }) => {
    const navigate = useNavigate();
    const {hideSideBar,displayAccordion,setDisplayHover} = useContext(DataContext);

    return (
        <button
            className={`${sideBarHiddenClass} ${hideSideBar ? 'w-16 px-5' : 'w-full px-2.5'} ${defaultSelectedKey === keys.name ? 'bg-white text-secondary' : ''} transition-width hover:text-black hover:bg-white`}
            onClick={() => {
                callback(keys.name)
                keys.path && navigate(keys.path)
            }}
            onMouseOver={()=>{setDisplayHover(keys.name)}}
            onMouseLeave={()=>{setDisplayHover("")}}
            disabled={keys?.disabled||false}
            >
            <div className="flex gap-2 text-sm">
                <div className="h-5 w-5">
                    {keys.icon}
                </div>
                <span className={`max-h-6 whitespace-nowrap ${hideSideBar ? "opacity-0 hidden" : "overflow-hidden truncate"}`}>
                    {keys.name}
                </span>
            </div>
            {(keys.collapse && !hideSideBar) && <>{DownIcon(displayAccordion[keys.name])}</>}
        </button>
    )
}

export default MenuItem;

const sideBarHiddenClass = 'flex justify-between text-start  items-center gap-x-3.5 py-2 text-sm rounded-lg ease-in-out duration-300 '