import axiosInstance from '../../HTTP/Http';
import { downloadFile } from 'components/UIElements/function';

export const fetchEddData = async ({ queryKey }) => {
  const [_, { pageNumber, fromDate, toDate }] = queryKey;

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/edd`,
    {
      params: {
        page: pageNumber - 1,
        from: fromDate,
        to: toDate,
        // size: 10,
      }
    }
  );
  return response.data.data;
};

export const downloadEddCsvData = async () => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=EDD`
  );

  downloadFile(response.data, 'edd.csv');
};
