import styled from "styled-components";

export const ProductSearchContainer = styled.div`
    padding: 25px 25px;

    .ant-tree-treenode {
        background: rgb(240 246 253 / 60%);
        // border-radius: 10px;
        // padding: 10px 14px;
        // margin: 10px 0;
        width:100%
    }

    .ant-tree-list-holder-inner{
        // background: rgb(240 246 253 / 60%);
        // border-radius: 10px;
        // padding: 10px 14px;
        // margin: 10px 0;
    }

    // .ant-tree-treenode.ant-tree-treenode-switcher-close{
    //     margin-top:10px;
    // }

    // .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-selected.ant-tree-treenode-leaf-last{
    //     margin-bottom: 10px;
    // }

    // .ant-tree-treenode-leaf-last{
    //     margin-bottom: 10px;
    // }


    .ant-checkbox-wrapper{
        display:flex;
        margin-bottom: 6px;
        font-size:12px;
    }
`


export const SearchResultContainer =  styled.div`
    border: 1px solid #e6e6e6; 
    padding: 10px; 
    border-top: none; 
    max-height: 150px;
    overflow-y:scroll;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
`

export const SearchResultItem = styled.div`
    padding: 8px 10px; 
    font-size:14px;
    color:#666;
    cursor:pointer
`

export const  SearchResultItemImg = styled.img`
    height: 40px; 
    width: auto; 
    border-radius:10px; 
    margin-right: 16px;
`
