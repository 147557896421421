import styled from 'styled-components';

export const Heading = styled.h5`
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #0C3784 !important;
    margin-top:20px;
`
export const Title = styled.h5`
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #0C3784 !important;
`

export const DateText = styled.h5`
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #0C3784 !important;
`

export const DateWarningText = styled(Title)`
    color: #B90E0E !important;
`
export const BreachTextContainer = styled.div`
    margin-top: 20px;
    background: rgba(12, 100, 219, 0.06)! important;
    border-radius: 5px;
    height:41px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
`
