import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  SelectRoleFilter,
  SelectRoleText,
  Card,
  Text,
  CardHeading,
  AccessRoleListCheckbox,
  AccessRoleList,
  AccessRoleRadio,
} from "../styled";
import {activeRoles} from '../script';
import { roleDescription, customTypeAcces, accessRoleData } from "../data";

const CHECKICON =
  "https://pickrr.s3.amazonaws.com:443/2024-05-27T12:57:38.811586_check.svg";

const Role = ({
  selectedRole,
  setSelectedRole,
  customRoleTypeAccess,
  setCustomRoleTypeAccess,
  customAccessRole,
  setCustomAccessRole,
  roles, 
  setRole,
  accessRole, 
  setAccessRole, 
  userDetails
}: any) => {

  const { data:activeRoleData, isLoading:isLoadingOldPricing} = useQuery(
    ['activeRole'],
    activeRoles
  );  

  // role and role permissions
  useEffect(() => {
    let role = activeRoleData?.map((role:any) => {
        return {label:role?.role?.name, value:role?.role?.id }
    })

    if(role?.length){
      role = [...role, {label:'CUSTOM', value:'CUSTOM'}]
    }

    let accressRole:any = {};
    activeRoleData?.forEach((role:any) => {
      accressRole[role?.role?.name] = role?.permissions
    })

    setRole(role);

    if(role?.length){
      if(!userDetails?.role){
        setSelectedRole(role?.[0]?.label)
      }
    }

    setAccessRole(accressRole);
  }, [activeRoleData])

  // handlers
  const selectRoleHandle = (event: any) => {
    setSelectedRole(event.target.value);
    setCustomAccessRole([])
  };

  const customAccessRoleHanlde = (event: any) => {
    if (
      customAccessRole.includes(event.target.value) &&
      customAccessRole?.length > 0
    ) {
      let newArray = customAccessRole.filter(
        (item: any) => item !== event.target.value
      );
      setCustomAccessRole(newArray);
    } else {
      setCustomAccessRole([...customAccessRole, event.target.value]);
    }
  };

  const handleOptionChange = (value: string) => {
    if (value === "readOnly") {
      setCustomRoleTypeAccess({
        ...customRoleTypeAccess,
        readOnly: true,
        readWrite: false,
      });
    } else {
      setCustomRoleTypeAccess({
        ...customRoleTypeAccess,
        readOnly: false,
        readWrite: true,
      });
    }
  };

  let accessRoleList: any;

  if (selectedRole === "CUSTOM") {
    accessRoleList = accessRoleData?.['CUSTOM']?.map((value: any) => {
      return (
        <AccessRoleListCheckbox>
          <label>
            <input
              type="checkbox"
              value={value?.toLowerCase()}
              onChange={customAccessRoleHanlde}
              checked={customAccessRole?.includes(value?.toLowerCase())}
              id={value}
            />
            {value}
          </label>
        </AccessRoleListCheckbox>
      );
    });
  } else {
    accessRoleList = accessRole?.[selectedRole]?.map((value: any) => {
      return (
        <AccessRoleList>
          <img src={CHECKICON} alt="icon" />
          {value}
        </AccessRoleList>
      );
    });
  }
  return (
    <Card style={{marginTop: 30}}>
      <CardHeading>Select Role</CardHeading>
      <Text>
        Manage permissions by selecting appropriate role. Use custom role to
        setup your own role criteria.
      </Text>
      <SelectRoleFilter>
        <SelectRoleText>Select Role</SelectRoleText>
        
        <select id="roles" value={selectedRole} onChange={selectRoleHandle}>
          {roles?.map((value: any) => {
            return (
              <option key={value?.label} value={value?.key}>
                {value?.label}
              </option>
            );
          })}
        </select>
      </SelectRoleFilter>
      {/* role description */}
      <Text>{roleDescription?.[selectedRole]}</Text>
      <div style={{ marginTop: 10 }}>{accessRoleList}</div>
      
      {/* role access list */}
      {/* {selectedRole === "custom" ? (
        <AccessRoleRadio>
          {customTypeAcces.map((value: any) => {
            return (
              <AccessRoleListCheckbox key={value.value}>
                <label>
                  <input
                    type="radio"
                    value={value.value?.toLowerCase()}
                    onChange={() => handleOptionChange(value.value)}
                    name={value.value}
                    checked={customRoleTypeAccess[value.value] ? true : false}
                    id={value.value}
                  />
                  {value.label}
                </label>
              </AccessRoleListCheckbox>
            );
          })}
        </AccessRoleRadio>
      ) : (
        ""
      )} */}

    </Card>
  );
};

export default Role;
