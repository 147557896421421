import { Button, Form } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  min-height: 70vh;
  border-radius: 12px;
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
    border-right : 0px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child{
    border-right : 1px solid #f0f0f0 !important;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{
    border-right : 0px;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child{
    border-right: 1px solid #f0f0f0 !important
  }
  .ant-input-number-group-addon:last-child{
    height : 50px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .ant-input-number{
    height : 50px;
    display:flex ;
    align-items : center;
  }
  .ant-input-number-group-wrapper{
    width : 100% !important;
  }
`;

export const TableHeading = styled.div`
    //display : flex;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color :#002659;
    //justify-content : center;
`

export const Text = styled.h6`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0C3784;
`
export const TableDataContainer = styled.div`
  //display : flex;
  flex-direction : column;
  width : 100%;
  //justify-content : center;
  align-items : center;
  .ant-switch-checked{
    background-color : #0051BF;
  }
`

export const BackButton = styled(Button)`
    margin-right: 20px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: #000B3499 !important;
    cursor: pointer;
    box-shadow : inset 0 0 0 ;
    padding : 0px;
`
export const CustomForm = styled(Form)`
  padding: 20px;
  padding-top : 0px !important;
  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #38446D;
  }
  .ant-upload.ant-upload-select {
    width: 100px;
    height: 100px;
  }
  .ant-input,.ant-select-selector{
    border-radius : 5px !important;
    height : 50px !important;
  }
`;

export const CustomDownloadButton = styled(Button)`
  height : 40px;
  border-radius : 8px;
  background: #DFEBFB !important;
  border : 0px;
  color :#0C64DB !important;
  font-weight : 600;
  font-size : 16px;
`

export const DownloadDiv = styled.div`
  display : flex;
  justify-content : space-between;
  align-items : center;
  padding : 5px; 
  background : #F2F4F7B2;
  border-radius : 5px;
  height : 50px;
`

export const RadioHeading = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #0C3784;
`

export const AddButton = styled(Button)`
  border: 1px solid #0C64DB !important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0C64DB !important;
  height: 40px;
`