import React, { useState } from "react";
import {
  SearchResultContainer,
  SearchResultItem,
  SearchResultItemImg,
} from "./styled";
import { SearchOutlined } from "@ant-design/icons";
import { notification } from "antd";

interface IVariants {
  label: string;
  price: number | string;
  productId: string;
  value: string;
}

interface ISearchedItems {
  id: string;
  imgUrl: string;
  name: string;
  price: number | string;
  type: string;
  variants: IVariants[];
}


const SearchResult = ({
  isLoading,
  data,
  setIsProductModalOpen,
  setSelectProductId,
  setEditItemId,
  type,
  setSearchTerm,
  selectedItems,
  setSelectedItems,
  discountType
}: any) => {

  const placeHolder = 'https://pickrr.s3.amazonaws.com:443/2023-03-06T14:52:40.121308_imagePlace_holder.png';


  const itemSelectHandler = (itemId: string | number, item: any) => {
    const isItemExist = selectedItems && selectedItems?.filter((item: any) => item?.id === itemId);
    if (isItemExist?.length > 0) {
      notification.error({ message: 'Item is already added to user list' })
      setSearchTerm('');
    } else {
      if (itemId && type === 'product') {
        setSelectProductId(itemId);
        setIsProductModalOpen(true);
        setEditItemId()
      } else if (type === 'collection') {

        // const items = discountType === 'bundle' 
        //   ? {...selectedItems?.products?.[0], 
        //     id: item?.id, 
        //     name: item?.name,
        //     quantity: 1
        //     } 
        //     : { id: item?.id,
        //        name: item?.name 
        //     };



        setSearchTerm('');
        setSelectProductId('');
        setSelectedItems([...selectedItems, 
          { id: item?.id, name: item?.name}])
      }
    }
  };

  return (
    <SearchResultContainer key={data?.item?.[0]?.id}>
      {isLoading && <div key={data?.item?.[0]?.id}>Loading...</div>}
      {data &&
        data.map((item: ISearchedItems) => (
          <SearchResultItem
            key={item.id}
            onClick={() => itemSelectHandler(item.id, item)}
          >
            {/* <SearchOutlined style={{marginRight: 12}}/> */}
            <SearchResultItemImg src={item?.imgUrl ? item?.imgUrl : placeHolder} />
            {item?.name}
          </SearchResultItem>
        ))}
    </SearchResultContainer>
  );
};

export default SearchResult;
