import React, { useEffect, useState } from 'react';

import {
  Container,
  CustomForm,
  SubText,
  FlexColContainer,
} from '../GeneralSettings/style';
import Header from '../../CommonComponents/Header';
import {
  Col,
  Row,
  Form,
  Input,
  Divider,
  Checkbox,
  Space,
  Radio,
  notification,
} from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   fetchSellerCheckoutConfig,
//   updateSellerCheckoutConfig,
// } from 'redux/actions/manageCheckout';
import moment from 'moment';
import { PickrrButton } from 'components/UIElements';
import { useMutation, useQuery } from 'react-query';
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
} from '../GeneralSettings/script';
// import { useHistory } from 'react-router-dom';

const ManageCommunication = () => {
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );
  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
      // navigate('/settings/shipping');
    },
  });
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const { data, fetchingSellerConfig } = useSelector(
  //   (state) => state.manageCheckout
  // );
  const [form] = Form.useForm();
  const [mediumOfCommunication, setmediumOfCommunication] = useState(null);
  const [abandonMediumList, setAbandonMediumList] = useState(null);
  const [orderConfirmList, setOrderConfirmList] = useState(null);

  const [abandonIntervalsList, setAbandonIntervalsList] = useState(null);
  const [abandonIntervals, setAbandonIntervals] = useState([]);

  const [planType, setPlanType] = useState(1);
  const [orderMedium, setOrderMedium] = useState(null);
  const [notificationInterval, setNotificationInterval] = useState([1]);

  const onChange = (e) => {
    console.log(e);
  };

  // useEffect(() => {
  //   if (data === null) {
  //     dispatch(fetchSellerCheckoutConfig());
  //   }
  // }, []);

  const handlePlanConfiguration = (planType = 0) => {
    if (planType === 0) {
      setAbandonMediumList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp' },
      ]);
      setOrderConfirmList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp', disabled: 'true' },
      ]);
    } else if (planType === 1) {
      setAbandonMediumList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp', disabled: 'true' },
      ]);
      setOrderConfirmList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp', disabled: 'true' },
      ]);
    } else if (planType === 2) {
      setAbandonMediumList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp' },
      ]);
      setOrderConfirmList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp', disabled: 'true' },
      ]);
    } else if (planType === 3) {
      setAbandonMediumList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp' },
      ]);
      setOrderConfirmList([
        { label: 'SMS', value: 'SMS' },
        { label: 'Whatsapp', value: 'Whatsapp' },
      ]);
    }
  };
  // useEffect(() => {
  //   if (data && Object.keys(data).length) setPlanType(data.plan);
  // }, [data]);

  console.log(abandonMediumList, 'liss');
  useEffect(() => {
    if (data && Object.keys(data).length) {
      handlePlanConfiguration(data.plan);
      if (!data.notifications) {
        data['notifications'] = {
          abandon_medium: [],
          order_placed_medium: [],
          abandon_intervals: [],
        };
      }
      form.setFieldsValue({
        ...data,
        ...(!!data?.pickup_config && {
          pickup_config: {
            ...data.pickup_config,
            cut_off_time: moment(data.pickup_config.cut_off_time, 'HH:mm'),
          },
        }),
      });

      setmediumOfCommunication(data.notifications.abandon_medium);
      setOrderMedium(data.notifications.order_placed_medium);
      setNotificationInterval(
        data.notifications.abandon_intervals.length > 0
          ? data.notifications.abandon_intervals[0]
          : 6
      );
      setAbandonIntervals(
        data.notifications.abandon_intervals.length > 0
          ? data.notifications.abandon_intervals
          : []
      );
    }
  }, [data]);

  useEffect(() => {
    if (abandonIntervals.length > 1) {
      // abandonIntervals.map((el) => {
      let data = abandonIntervalsList.map((item, index) => {
        if (abandonIntervals.includes(item.value)) {
          console.log('matched', item);
          return { ...item };
        }
        return { ...item, disabled: true };
      });
      setAbandonIntervalsList(data);
      // });
    } else {
      setAbandonIntervalsList([
        { label: '1 hour', value: 1 },
        { label: '6 hour', value: 6 },
        { label: '24 hour', value: 24 },
        { label: '72 hour', value: 72 },
      ]);
    }
    console.log(abandonIntervalsList, abandonIntervals);
  }, [abandonIntervals, data]);

  const submitForm = () => {
    let postData = { ...form.getFieldValue() };
    postData.notifications.abandon_medium = mediumOfCommunication;
    postData.notifications.order_placed_medium = orderMedium;
    postData.notifications.abandon_intervals =
      data.plan > 2 ? abandonIntervals : [notificationInterval];
    if (
      postData.pickup_config &&
      postData.pickup_config.cut_off_time.length !== 5
    ) {
      postData.pickup_config.cut_off_time = moment(
        postData.pickup_config.cut_off_time
      ).format('HH:mm');
    }
    mutation.mutate(postData);
    // dispatch(
    //   updateSellerCheckoutConfig(
    //     postData,
    //     history,
    //     '/settings/manage-checkout/inventory'
    //   )
    // );
  };

  const moc = ['SMS', 'Whatsapp'];

  const interval = [
    { value: 1, label: '1 hour' },
    { value: 6, label: '6 hour' },
    { value: 24, label: '24 hour' },
    { value: 72, label: '72 hour' },
  ];

  console.log(abandonIntervalsList, abandonIntervals, 'oo');

  const oc = ['SMS', 'Whatsapp'];
  return (
    <Container>
      <Header title="Communication" submitForm={submitForm} />
      {data && (
        <CustomForm layout="vertical" onFinish={submitForm}>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
              <Form.Item label="Send notification for abandoned checkout via">
                <Checkbox.Group
                  options={abandonMediumList}
                  value={mediumOfCommunication}
                  // defaultValue={mediumOfCommunication}
                  onChange={setmediumOfCommunication}
                />
              </Form.Item>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
              <SubText style={{ fontSize: '14px', marginBottom: '16px' }}>
                *Please note that notifications will not be sent between 12am to
                8am, Pending notifications will be sent at 9 am
              </SubText>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
              <Form.Item label="Send Notification after">
                <FlexColContainer>
                  {data && data.plan > 2 ? (
                    <Checkbox.Group
                      options={abandonIntervalsList}
                      value={abandonIntervals}
                      // defaultValue={mediumOfCommunication}
                      onChange={setAbandonIntervals}
                    />
                  ) : (
                    <Radio.Group
                      name="radiogroup"
                      defaultValue={notificationInterval}
                      onChange={(e) => setNotificationInterval(e.target.value)}
                      value={notificationInterval}
                    >
                      <Radio value={1}>1 hour</Radio>
                      <Radio value={6}>6 hour</Radio>
                      <Radio value={24}>24 hour</Radio>
                      <Radio value={72}>72 hour</Radio>
                    </Radio.Group>
                  )}
                </FlexColContainer>
              </Form.Item>
            </Col>
            <Divider />
            <Col xl={24} lg={24} md={24} sm={24} x={24}>
              <Form.Item label="Send notification for order confirmation via:">
                <Checkbox.Group
                  options={orderConfirmList}
                  defaultValue={orderMedium}
                  onChange={setOrderMedium}
                  value={orderMedium}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Space>
            <PickrrButton color="checkout-blue" htmlType="submit">
              Save & Proceed
            </PickrrButton>
          </Space>
        </CustomForm>
      )}
    </Container>
  );
};

export default ManageCommunication;
