export const getImageUrl = (title: string, delta: number) => {
    if (title === "Cart Abandonment Rate") {
        return (
            delta < 0 ?
                "https://d10srchmli830n.cloudfront.net/1653386841954_cbcf59ea-1a1c-48b1-a26a-60cb60254d1f_VectorIncreaseDown.svg" :
                "https://d10srchmli830n.cloudfront.net/1653386923350_af0596c3-7c18-4960-8390-25056f61ff5e_VectordecreaseUp.svg"
        )
    } else {
        return (
            delta < 0 ?
                "https://d10srchmli830n.cloudfront.net/1652870375607_bf565686-d737-436e-9e10-f5be4724de2d_VectordecreaseDown.svg" :
                "https://d10srchmli830n.cloudfront.net/1651222531941_VectorPercentageIncrease.svg"
        )
    }
}

export const getToolTipContent = (title: string) => {
    switch (title) {
        case "Address Prefill %":
            return (
                "Percentage of users whose address details were already available with Fastrr. Users who dropped off before logging in are not considered when calculating this number."
            )

        case "Prepaid% : COD%":
            return (
                "Ratio of prepaid orders to COD orders through Fastrr."
            )

        case "Cart Abandonment Rate":
            return (
                "Percentage of users who did not create an orders after abandoning cart. This percentage excludes users who created an order after resuming their cart from links included in cart abandonment SMS/WA."
            )
    }
}