import React from 'react';

import { Alert } from 'antd';

import { DetailHeadingBackground, DetailsHeading } from './style';
import { PickrrButton } from '../../../UIElements';
import { LoadingOutlined, PlusOutlined, EditOutlined} from '@ant-design/icons';
const Header = ({
  title,
  submitForm,
  shippingTab,
  id,
  sellerConfigDetails,
  addWebHook,
  setAddWebHook,
}) => {
  return (
    <>
      <DetailHeadingBackground>
        <DetailsHeading>{title}</DetailsHeading>

        {
          title==="Custom Endpoints" && !addWebHook &&
          <PickrrButton 
          onClick={()=>{
            setAddWebHook(true)
          }} 
          color="checkout-blue" 
          icon={<EditOutlined/>}
          >
            Add/Edit Custom Endpoint
          </PickrrButton>
        }
        {/* shippingTab ? <></> :
        <PickrrButton onClick={submitForm} color="checkout-blue" id={id}>
          Save {title !== 'Inventory' && title !== 'Shopify' && '& Proceed'}
        </PickrrButton>
      */}
      </DetailHeadingBackground>
      {sellerConfigDetails && !sellerConfigDetails.is_active && (
        <Alert
          description="Your fastrr checkout is inactive, please complete the form to activate
          fastrr-checkout."
          type="warning"
        />
      )}
      {sellerConfigDetails && sellerConfigDetails.is_active && (
        <Alert
          description="Live : editing this form will cause changes in your store"
          type="success"
        />
      )}
    </>
  );
};

export default Header;
