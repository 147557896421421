import React, { useEffect } from 'react'
import { FormContainer, CheckboxText, CheckboxDescriptipon } from '../styled'
import { CustomForm, Asterisk } from 'components/UIElements'
import {Row, Col, Form, Input, Checkbox, notification} from 'antd'
import { PickrrButton } from 'components/UIElements'
import { getGeneralDetails, updateSellerCheckoutConfig } from 'components/Settings/GeneralSettings/script'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';

export const GoogleAdsTrackingPage = ({tabKey}: {tabKey: string}) => {

    const [form] = Form.useForm()
    const queryClient = useQueryClient();

    const { data } = useQuery(
        'generalSettings',
        getGeneralDetails
    );

    const mutation = useMutation(updateSellerCheckoutConfig, {
        onError: () => {
          notification.error({message: 'Something went wrong.'});
        },
        onSuccess: () => {
          queryClient.invalidateQueries('generalSettings');
          notification.success({ message: 'Updated seller details.'});
        },
      });

      useEffect(() => {
        if(data) {
            form.setFieldsValue({
                //conversion_id: data?.marketing_config?.google_ads?.conversion_id,
                //conversion_label: data?.marketing_config?.google_ads?.conversion_label,
                enhance_conversion: data?.marketing_config?.google_ads?.enhance_conversion,
                google_ads_array: data?.marketing_config?.google_ads?.google_ads_array
            })
        }
    }, [data, tabKey])

    const submitForm = () => {
        const postData = {...form.getFieldsValue()}
        mutation.mutate({
            marketing_config: {
                ...data?.marketing_config, 
                google_ads: {
                    //conversion_id: postData.conversion_id,
                    //conversion_label: postData.conversion_label,
                    enhance_conversion: postData.enhance_conversion,
                    google_ads_array: postData.google_ads_array
                }
            }
        })
    }

    return (
        <FormContainer>
            <CustomForm layout="vertical" onFinish={submitForm} form={form}>
                <Row gutter={[50,0]}>
                <Form.List name="google_ads_array">
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(( field , index) => (
                                <>
                                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                                <Form.Item
                                    label = {`Conversion ID ${index + 1}`}
                                    name={[field.name,`conversion_id`]}
                                    rules={[
                                        {
                                          required: true,
                                          message: 'Please enter a valid Conversion ID.',
                                        },
                                      ]}
                                >
                                    <Input/>
                                </Form.Item>
                                </Col>
                                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                                <Form.Item
                                    label = {`Conversion Label ${index + 1}`}
                                    name={[field.name,`conversion_label`]}rules={[
                                        {
                                          required: true,
                                          message: 'Please enter a valid Conversion Label.',
                                        },
                                      ]}
                                >
                                    <Input />
                                </Form.Item>
                                </Col>
                                {fields.length > 1 &&  
                                <Col style={{display :'flex', alignItems : 'center'}} span={4}>
                                    {/* @ts-ignore */}
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Col>}
                                </>
                            ))}
                            
                            {fields.length < 5  ?
                                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                                <Form.Item>
                                    {/* @ts-ignore */}
                                    <PickrrButton color='checkout-blue' onClick={() => add()} block icon={<PlusOutlined />} style={{minWidth:'320px'}}>
                                    { fields.length > 0 ? "Add another conversion action" : "Add conversion action"}
                                    </PickrrButton>
                                </Form.Item>
                                </Col>
                                :
                                <Col span={24}>
                                    <Asterisk>*</Asterisk><span style={{color : '#0C64DB'}}>Note: You can add a maximum of 5 pairs of conversion ids and conversion labels.</span>
                                </Col>
                            }
                            </>
                        )}
                    </Form.List>
                    <Col span={24}>
                    <Form.Item name="enhance_conversion" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Enhanced conversions feature for Google Ads.</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                            Enhanced conversions is a Google Ads feature that can improve the accuracy of your conversion measurement and unlock more powerful bidding. 
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={4} style={{paddingBottom:'15px', paddingTop:'15px'}}>
                    <PickrrButton htmlType="submit" color="checkout-blue">
                        Save
                    </PickrrButton>
                    </Col>
                </Row>
            </CustomForm>
        </FormContainer>
    )
}

export default GoogleAdsTrackingPage