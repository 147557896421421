import React from "react";
import ENUMS from "utils/enums";

const SelectStoreType = ({channel, setChannel} :{ channel: any, setChannel : any}) => {

    const channelList = [
        {   
            name: "Shopify",
            key: ENUMS.SHOPIFY,
            imageUrl: "https://d10srchmli830n.cloudfront.net/1730717561731_Full-color.svg"
        },
        {   
            name: "Woo-Commerce",
            key: ENUMS.WOOCOMMERCE,
            imageUrl: "https://d10srchmli830n.cloudfront.net/1730717476351_62b45f094d5b68158481cb43-1.svg"
        },        
        {   
            name: "Magento",
            key: ENUMS.MAGENTO,
            imageUrl: "https://d10srchmli830n.cloudfront.net/1730717413321_pngwing.com-(8)-1.svg"
        },
        {   
            name: "CUSTOM",
            key: ENUMS.CUSTOM
        }
    ]
    return (
        <>
            <h1 
                style={{fontSize: '28px', textAlign:'center', fontWeight:'400'}}
            >
                Connect your platform
            </h1>
            <h2
                style={{fontSize: '20px', textAlign:'center', color: '#9C9AA5', marginTop:'8px'}}
            >
                Connect the platform where your online store or website is hosted.
            </h2>
            <div className="flex flex-wrap gap-3 overflow-hidden mt-4">
            {channelList?.map((store: any) => (

                    <div 
                        onClick={() => setChannel(store)} 
                        className="p-5 border border-solid border-2 rounded-lg cursor-pointer flex justify-center items-center"
                        style={{
                            borderColor: channel?.key === store.key ? "#7F6BF6" : "#DFDFDF",
                            height: 100,
                            width: 250
                        }}
                    >
                        {
                            store?.imageUrl 
                            ? 
                            <img 
                                src={process.env.PUBLIC_URL + store.imageUrl}
                                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                alt="Channel"
                            /> 
                            :
                            <h1 style={{fontSize: "30px"}}>
                                {store.name}
                            </h1>
                        }

                    </div>
            ))}
            </div>
        </>
    );
}

export default SelectStoreType;