import axios from "axios"
import {GetAuthToken} from 'globalQuries'

const auth = GetAuthToken();



export const fetchRatesDetail = async({queryKey}) => {
    const [unusedArgument, {domainName}] = queryKey;

    if(domainName){
    const response = await axios.get(`${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/user/aggregated-rates/?domain_name=${domainName}`,
    {
        headers : {
            "X-AUTH": auth,
        }
    })

    return response?.data?.result
}
}
export const fetchOldRatesDetail = async({queryKey}) => {
    const[unusedArgument, {prevDateValue,sellerId,paymentGateway,paymentMode}] = queryKey 
    const response = await axios.get(`${process.env.REACT_APP_FASTRR_FINANCE}/api/vi1/commission/historical/rates?date=${prevDateValue?.format('YYYY-MM-DD')}&seller_id=${sellerId}&payment_gateway=${paymentGateway}&payment_mode=${paymentMode}`,
    {
        headers : {
            "X-AUTH": auth ,
        }
    })

    return response?.data?.result
}