import { Checkbox, Col, Form, Input, Row, Switch, notification } from "antd";
import React, { useEffect, useState } from "react";
import { CancelButton, GatewayFormContainer, ModalSubHeading } from "./style";
import { CustomForm, PickrrButton } from "components/UIElements";
import { useMutation, useQueryClient } from "react-query";
import { UpdatePaymentGateway } from "../script";

const CheckboxGroup = Checkbox.Group;
const PotleeGatewayForm = ({gatewayData,gatewayConfig,sellerId,setIsVisible,viewOnly, setViewOnly,setGatewayData , setSelectedGatewayConfig, setUpdateState}) => {

  const [checkedList, setCheckedList] = useState([]);
  const [paymentModeList,setPaymentmodeList] = useState([])
  const queryClient = useQueryClient();

  useEffect(()=>{
      if(gatewayData?.pgDetail){
          form.setFieldsValue({
              potlee_payment_details : {
                  organization_id : gatewayData?.pgDetail?.organization_id ?? '',
                  api_key : gatewayData?.pgDetail?.api_key ?? '',
                  api_secret:gatewayData?.pgDetail?.api_secret,
                  api_token:gatewayData?.pgDetail?.api_token,
                  isActive : gatewayData?.active 
              }
          })
      }
      if(gatewayData?.paymentMode){
          const SelectedPaymentModes =  gatewayData?.paymentMode?.map((paymentModesDetails)=>{
              return paymentModesDetails?.mode
          })
          setCheckedList(SelectedPaymentModes?.[0]==='POTLEE'?['SETTLE']:SelectedPaymentModes)
      }
      if(!gatewayData?.pgDetail){
          form.setFieldsValue({
              potlee_payment_details : {
                  isActive : true
              }
          })
        }
  },[gatewayData])

  const mutation = useMutation(UpdatePaymentGateway, {
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      notification.error({ message: 'Something went wrong.' });
    },
    onSuccess: (message, variables, data) => {
      queryClient.invalidateQueries('userDetails');
      setUpdateState((prev)=>!prev)
      notification.success({ message: 'Updated Payment details.' });
      setGatewayData({})
      setSelectedGatewayConfig({})
      setViewOnly(false)
      setIsVisible(false)
    },
  });

  useEffect(()=>{
      setPaymentmodeList(
         gatewayConfig?.payment_mode &&  Object.keys(gatewayConfig?.payment_mode)?.map(function (key, index) {
              return key
          }
        )
      )
  },[gatewayConfig])

  const onChange = (list) => {
      setCheckedList(list);
    };

  const [form] = Form.useForm();

  const submitForm = () => {
      let uncheckedList = paymentModeList?.filter( function( el ) {
          return checkedList.indexOf( el ) < 0;
        } );
      let postData = { ...form.getFieldsValue() };
      let gatewayKeys = {
          organization_id: postData.potlee_payment_details.organization_id,
          api_key: postData.potlee_payment_details.api_key,
          api_secret:postData.potlee_payment_details.api_secret,
          api_token:postData.potlee_payment_details.api_token,
          is_active:`${postData.potlee_payment_details?.isActive}`,
      }
      const reqPayload = {
          "seller_id":sellerId,
          "payment_platform":"Potlee",
          "gateway_keys":JSON.stringify(gatewayKeys),
          "enabled_payment_methods":postData.potlee_payment_details?.isActive ? (checkedList?checkedList[0]?.toUpperCase()==='SETTLE'?['POTLEE']:[]:[]) : [],
          "disabled_payment_methods":postData.potlee_payment_details?.isActive ?(uncheckedList?uncheckedList[0]?.toUpperCase()==='SETTLE'?['POTLEE']:[]:[]) : paymentModeList?.map((mode)=>(mode?.toUpperCase()==='SETTLE'?'POTLEE':mode)),
      }
      mutation.mutate(reqPayload)    
  }

  return (
    <GatewayFormContainer>
    <CustomForm layout="vertical" form={form} onFinish={submitForm}>
    <Row gutter={[20,0]}>
    <Col span={24} style={{display : 'flex', justifyContent : 'flex-end'}}>
      <Form.Item
        name={['potlee_payment_details', 'isActive']}
        rules={[
          { 
            required: true, 
            message: "Status for payment gateway is mandatory",
        }
        ]}
      >
        <Switch
        defaultChecked={gatewayData?.active ?? true}
        style={{marginRight : '10px'}} 
        checkedChildren="Active" unCheckedChildren="Inactive"
        disabled={viewOnly}
        />
      </Form.Item>
    </Col>
    <Col xl={12} lg={12} md={24} sm={24} x={24}>
    <Form.Item
        label="Merchant Organization ID"
        name={['potlee_payment_details', 'organization_id']}
        rules={[
        { 
            required: true, 
            message: "Please enter the required value in correct format.",
            pattern: new RegExp(/^[^\s]*$/g)
        }
        ]}
    >
        <Input disabled={viewOnly}/>
    </Form.Item>
    </Col>
    <Col xl={12} lg={12} md={24} sm={24} x={24}>
    <Form.Item
        label="API key"
        name={['potlee_payment_details', 'api_key']}
        rules={[
        { 
            required: true, 
            message: "Please enter the required value in correct format.",
            pattern: new RegExp(/^[^\s]*$/g)
        }
        ]}
    >
        <Input disabled={viewOnly}/>
    </Form.Item>
    </Col>
    <Col xl={12} lg={12} md={24} sm={24} x={24}>
    <Form.Item
        label="API Secret"
        name={['potlee_payment_details', 'api_secret']}
        rules={[
        { 
            required: true, 
            message: "Please enter the required value in correct format.",
            pattern: new RegExp(/^[^\s]*$/g)
        }
        ]}
    >
        <Input disabled={viewOnly}/>
    </Form.Item>
    </Col>
    <Col xl={12} lg={12} md={24} sm={24} x={24}>
        <Form.Item
            label="API Token"
            name={['potlee_payment_details', 'api_token']}
            rules={[
            { 
                required: true, 
                message: "Please enter the required value in correct format.",
                pattern: new RegExp(/^[^\s]*$/g)
            }
            ]}
        >
            <Input disabled={viewOnly}/>
        </Form.Item>
      </Col>
    </Row>
    <ModalSubHeading>
        Payment Modes
    </ModalSubHeading>
    <Row gutter={[5,5]} style={{marginBottom : '12px'}}>
        {console.log("the payment mode list is ",paymentModeList,gatewayConfig)}
    <CheckboxGroup disabled={viewOnly} options={paymentModeList} value={checkedList} onChange={onChange} />
    </Row>
    {!viewOnly &&
    <>
    <CancelButton
    disabled={viewOnly}
    onClick={()=>{
        setGatewayData({})
        setIsVisible(false)
        setSelectedGatewayConfig({})
        setViewOnly(false)
    }}
    >
        Discard
    </CancelButton>
    <PickrrButton
    htmlType="submit"
    style={{width : '100px', border : '0.742px',textTransform : 'none'}}
    color="checkout-blue"
    disabled={viewOnly}
    >
        Save
    </PickrrButton>
    </>
    }
    </CustomForm>
    </GatewayFormContainer>
  );
};

export default PotleeGatewayForm;
