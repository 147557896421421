import React, { useEffect, useState } from "react";
import { Radio, Space, Row, Col, Form, Select, Checkbox } from "antd";
import type { RadioChangeEvent } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { SubHeading, CustomInput, AddButton, LabelText } from "../styled";
import ProductSearch from "./ProductSearch";

const FreebieConfig = ({
  discountType,
  selectTypes,
  setSelectTypes,
  form,
  sellerConfigValues,
  freebieValue,
  setFreebieValue,
  freebieList,
  setFreebieList
}: any) => {
  const [value, setValue] = useState<string | number>("flat");
  const [isError, setIsError] = useState(false);
  const Regex = new RegExp(/^(((?!0)[0-9]+[.]?[0-9]*))$/);
  const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);
  const percentRegex = new RegExp(
    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?)(\.[0-9]{1,2})?$)/
  );
    
  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ configValue: "" });
    setSelectTypes({ ...selectTypes, configType: e.target.value });
    setValue(e.target.value);
  };

  const handleValidate=(e?:any)=>{
    if(e.target.value){
      setIsError(quantityRegex.test(e.target.value));
    }else {
      setIsError(false);
    }
  }

  return (
    <Row>
        <Col span={24}>
            <ProductSearch
                selectedItems={freebieList}
                setSelectedItems={setFreebieList}
                type={freebieValue}
                discountType={discountType}
                sellerConfigValues = 
                {sellerConfigValues}
                bundleKey = {Math.random() * 1004}
                isFreebie={true}
            />           
        </Col>
      <Col span={24} style={{marginTop:'20px'}} >
        <SubHeading>Discount Configuration</SubHeading>
      </Col>
      <Col span={24}>
        <Form.Item name="configDiscountType" initialValue={"flat"}>
          <Radio.Group onChange={onChange} value={value} >
            <Space direction="horizontal" size={80}>
              <Radio value={"flat"}>Flat off /-</Radio>
              <Radio value={"percent"}>Percentage %</Radio>
              <Radio value={"freebieFlat"}>Flat /-</Radio>

            </Space>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Col span={24}>
          <>
          <Form.Item
            label={selectTypes?.configType === "percent" ? "Discount percent" : "Discount value"}
            name="configValue"
            rules={[
              {
                required: true,
                pattern:
                  selectTypes?.configType === "percent" ? percentRegex : Regex,
                message: "Please enter the valid value",
              },
            ]}
          >
            <CustomInput
              maxLength={selectTypes?.configType === "flat" ? 10 : 5}
              placeholder="Enter the value"
              style={{ width: 225 }}
            />
          </Form.Item>
            <>
            </>
          </>
        
      </Col>

    </Row>
  );
};

export default FreebieConfig;
