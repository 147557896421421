import styled from "styled-components";
import { Collapse } from "antd";

const { Panel } = Collapse;

export const CustomCollapse = styled(Collapse)`
  background: transparent;
`;

export const CustomPanel = styled(Panel)`
  border-radius: 8px;
  margin-bottom: 16px;
  overflow:hidden;
`;

export const FunctionalityContainer = styled.div`
  .ant-collapse-borderless
    > .ant-collapse-item:last-child
    .ant-collapse-header {
    border-radius: 8px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: #f2f4f7;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 8px 16px;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border: 1px solid #dfe0eb;
    border-radius: 8px;
  }

  .ant-collapse-header-text{
    width: 96%;
  }

  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    padding-top: 16px;
  }

  .ant-collapse-content > .ant-collapse-content-box{
    padding-bottom: 8px;
  }

  .ant-checkbox-wrapper{
    display:flex;
    margin-bottom: 10px;
   }
`;

export const CouponContainer = styled.div`
  //  max-height: 200px;
   width: 230px;
   border-radius:8px;
   border: 1px solid #CCD3DE;
   overflow:hidden;

   .dottedLine{
    border: .4px dashed #CCD3DE;
    margin: 4px 0;

   }
`

export const CouponBadge = styled.div`
   background:#F2FCF6;
   color:#238017;
   font-weight: 600;
   font-size: 10px;
   padding: 5px 10px;
   margin-bottom: 10px;

`
export const CouponName = styled.div`
   display:flex;
   justify-content:space-between;
   align-items:center;
   padding: 5px 10px;   
   gap:5px;

  .couponName{
    min-width: 60px;
    width: 100%
    height: 7px;
    color:#1E0A9C;
    padding: 3px 10px;
    text-align:center;
    border-radius: 4px;
    border: 1.2px dashed #1E0A9C;
    font-size:10px;
    text-transform: uppercase;
    text-align:left
  }

  .applyButton{
    font-weight: 600;
    font-size: 10px;
    color: #4E39CD;
  }
`

export const CouponDescription = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    padding: 5px 10px;   

    .description{
      max-width: 136px;
      font-size:8px;
      text-transform:capitalize;
    }
    .hideButton{
      font-weight: 400;
      font-size: 8px;
      line-height: 120%;
      cursor:pointer;
    }
`

export const TermsCondition = styled.div`
    padding: 5px 10px;   
    .termsHeading{
      font-size:8px;
      color: rgba(21, 21, 21, 0.6);
      text-decoration: underline;
      margin-bottom: 4px;
      font-weight: 400;
    }

    .terms{
      font-weight: 400;
      font-size: 8px;
      line-height: 145%;
      color: rgba(21, 21, 21, 0.6);
    }
`
