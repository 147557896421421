import styled from 'styled-components'
import { Input } from 'antd'


export const FulfilmentTag = styled.div<{type:string}>`
  border-radius: 8px;
  padding: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => {
    if (props.type === 'partial') {
      return '#FC9B3D'
    }
    else if (props.type === 'Unfulfilled'){
      return '#F0543C'
    }
    else if (props.type === 'fulfilled'){
      return '#238017'
    }
  }};
  background: ${(props) => {
    if (props.type === 'partial') {
      return '#FFF2E5'
    }
    else if (props.type === 'Unfulfilled'){
      return '#FFE5E5'
    }
    else if (props.type === 'fulfilled'){
      return 'rgba(5, 129, 25, 0.1)'
    }
  }};
`;

export const StatusTag = styled.div`
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#ffffff;
  background-color:#002659;
`

export const InfoCardContainer = styled.div`
    height: 110px;
    background: #FFF;
    border-radius: 6.5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const InfoCardTitle = styled.h1`
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #000B34! important;
    margin: 0px 0px 20px 0px;
    opacity: 0.8;
`

export const InfoCardAmount = styled.h1`
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #0C3784;
    margin: 0;
`

export const PercentageText = styled.h6`
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.2px;
    text-align: left;
    margin: 5px 0px 0px 5px;
    color: #0EB929;
`

export const DaysText = styled.h6`
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #000B34;
    opacity: 0.6;
    margin: 5px 0px 0px 0px;
`

export const TableContainer = styled.div`
    background: #FFF;
    height: auto;
    border-radius: 6.5px;
    padding: 20px;

    .ant-input-group-addon {
        background: #F0F6FD;
        border-top-left-radius: 6.5px;
        border-bottom-left-radius: 6.5px;
    }

    .ant-select-selection-item {
        color: #0C3784 !important;
        font-weight: 600;
        opacity: 1;
    }

    .ant-select-arrow {
        color: #0C3784 !important;
    }

    .ant-input {
        border-radius: 6.5px;
    }

    .ant-table-row {
        vertical-align: baseline;
    }
`

export const CustomInput = styled(Input)`
    border-radius: 10px! important;
`

export const Text = styled.h6`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0C3784;
`

export const PaymentStatus = styled.div<{textColor?:string, bgColor?: string}>`
    min-width: 90px;  
    max-width: 100%;
    height: 28px;
    border-radius:8px; 
    font-size:11px;
    line-height: 15px;
    font-weight: 600;
    margin-top:10px;
    text-align:center;
    padding:6px 0;
    background-color: ${(props:any) => 
      props.bgColor ? props.bgColor : '#F0F6FD'};
    color:${(props:any) => props?.textColor ? props?.textColor : '#000B34'
    };
`