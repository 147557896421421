import React, {useContext} from 'react';
import CustomAbandonedCart from 'pages/CustomAbandonedCart';
import AbandonedCheckout from 'pages/AbandonedCart';
import { DataContext } from 'context/DataProvider';
import AbandonedCartNew from 'pages/AbandonedCart/AbandonedCartNew';


const Abandoned = () => {

    const {platFormDetails, channelPlatForm} = useContext(DataContext);

    return (
        <>
            {
                (channelPlatForm || localStorage.getItem('isOnboarding')) &&  (
                channelPlatForm === 'custom' && platFormDetails?.custom?.login && !localStorage.getItem('isOnboarding') ? 
                <CustomAbandonedCart /> : <AbandonedCartNew/>
                )
            }
        </>
    )
}

export default Abandoned;