import { Button, Col, Form, InputNumber, notification, Row } from "antd";
import { PickrrButton } from "components/UIElements";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { EditOrderUpdate } from "./script";
import { CustomForm, CustomModal, ErrorMessageContainer, FooterContainer, ModalTitle } from "./styled";


const AdjustQuantityModal = ({isVisible,setIsVisible,adjustQuantityModalData,setAdjustQuantityModalData,editOrderData,setEditOrderData}: any)=>{
    const mutation = useMutation(EditOrderUpdate, {
        onMutate: (variables) => {
          // A mutation is about to happen!
          // Optionally return a context containing data to use when for example rolling back
          return { id: 1 };
        },
        onError: (error : any, variables, context) => {
          // An error happened!
          let sanitizedError = JSON.stringify(error?.response?.data?.error?.message)
            if(typeof sanitizedError === "string" && sanitizedError && sanitizedError.includes('message\\":')) {
              notification.error({
                  message : sanitizedError.match(/"message\\":\\"(.+)\\"}/)[1]
              });
            } else {
              notification.error({
                  message : `Something went wrong.`
              });
            }
        },
        onSuccess: (message, variables, data) => {
          // navigate('/settings/shipping');
        },
      });

      useEffect(()=>{
        if(mutation.data){
            setEditOrderData(mutation.data)
            setIsVisible(false)
            form.resetFields()
        }
      },[mutation.data])

    const handleSubmit =() => {
        let formData = form.getFieldsValue(true);
        const postData = {
            "orderId": editOrderData?.id,
            "operation": "OrderEditSetQuantity",
            "channel": editOrderData?.channel,
            "lineItemRequest": {
                "lineItemId": adjustQuantityModalData?.platformId,
                "quantity": formData?.quantity
            }
        }
        mutation.mutate(postData)
    }
    const [form] = Form.useForm();

    useEffect(()=>{
        if(adjustQuantityModalData){
            form.setFieldsValue({
                quantity: adjustQuantityModalData?.quantity
            })
        }
    },[isVisible])

    return(
        <>
        {/*//@ts-ignore */}
        <CustomModal
        visible = {isVisible}
        title={<ModalTitle>Adjust Quantity</ModalTitle>}
        onCancel={()=>{
            form.resetFields()
            setIsVisible(false)
        }}
        footer = {false}
        width = {620}
        keyboard= {true}
        >
            <CustomForm
                layout="vertical" 
                form={form} 
                onFinish = {handleSubmit}
                >
            <Col span={24} style={{display : 'flex', flexDirection : 'row',fontSize : '16px', marginBottom : '18px'}}>
                <span >
                Edit the quantity for <span style={{fontWeight : 600}}>
                 {adjustQuantityModalData?.name}
                </span>
                </span>
                

            </Col>
            <Row gutter={[20,20]}>
                <Col span={12}>
                    <Form.Item
                    name={'quantity'}
                    label = 'Quantity'
                    rules={[
                        {
                            required : true,
                            message : 'Quantity is Required'
                        }
                    ]}
                    >
                        <InputNumber max={adjustQuantityModalData?.inventory} min={0} style={{height : '50px'}} />
                    </Form.Item>
                </Col>
                <ul style={{display :'flex', alignItems : 'center', marginLeft : '20px'}}>
                    <li>
                        <span style={{fontWeight : 500,fontSize : '16px',color : '#38446D'}}>
                        Available  {adjustQuantityModalData?.inventory} in stock
                        </span>
                    </li>
                </ul>
                </Row>
                <FooterContainer>
                    <Button
                    color="cancel" 
                    style={{border : '1px solid #263F97', height : '40px', borderRadius : '8px',marginRight : '18px', width : '130px', color : '#000B34'}}
                    onClick={()=>{
                        form.resetFields()
                        setIsVisible(false)
                    }}
                    >
                        Cancel
                    </Button>
                    <PickrrButton 
                    htmlType="submit" 
                    color="checkout-blue" 
                    style={{width : '130px', height : '40px'}}
                    loading={mutation.isLoading}
                    >
                        Save
                    </PickrrButton>
                </FooterContainer>
            
            </CustomForm>

        </CustomModal>
        </>
    )
}

export default AdjustQuantityModal