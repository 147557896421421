import tw from "tailwind-styled-components"

export const TextStyle = tw.h6`
    w-max
    text-sm
    font-normal
    leading-5
    text-idColor
`
export const TagsStyle = tw.div`
    flex
    text-xs
    rounded-lg 
    px-2 py-1
    text-tagsColor
    items-center
    justify-between
    bg-tagsBg
    border-pageBorder
    border
`
export const PaymentStatusTag=tw.div`
    py-2
    text-sm
    leading-5
    flex
    justify-center
    
    
`
export const CustomButton=tw.button`
    flex
    items-center
    gap-1
    w-28
    h-8
    bg-white
    text-exportText
    shadow-md
    rounded-lg
    font-normal
    px-2
    py-1

`
export const CustomDownloadButton=tw.button`
    flex
    items-center
    justify-center
    gap-1
    h-8
    bg-white
    text-exportText
    rounded-lg
    font-normal
    px-4
    py-1
    min-w-36
    shadow-md
    border
    border-outline
    hover:bg-primary
    hover:text-white
    disabled:bg-disabledButtonPrimary
    disabled:text-disabledButtonText
    disabled:hover:bg-disabledButtonPrimary
    disabled:hover:text-disabledButtonText
    disabled:shadow-none
`
export const FlexRowTw=tw.div`
    flex
    justify-between
    items-center
`

export const TableContainerTw=tw.div`
    bg-white
    h-auto
    rounded-lg
    p-5
`