import { notification } from 'antd';
import axiosInstance from '../../../HTTP/Http';

export const FetchPaymentConfig = async ({queryKey}) => {
    const [unusedArgument, {sellerId}] = queryKey;
    try {
      const response = await axiosInstance.get(
        // `https://payment-aggregator-checkout-dev.pickrr.com/api/ve1/payment-aggregator/seller/config/v3/${sellerId}?api_version=v3`,
        `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/payment-config/${sellerId}?api_version=v3`
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

export const UpdatePaymentGateway = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/upsert-seller-payment-config`,
      payload
    )
    return response?.data
  } catch (error) {
    notification.error({
      message: error.message,
    });
  }
}