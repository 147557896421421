import styled from 'styled-components'
import { Table } from 'antd'

export const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: normal;
  color: #263f97;
  font-size: 14px;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  font-size: 16px;
  cursor:pointer;
`;

export const CustomTable = styled(Table)`

    th {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    color: #0C3784! important;
    opacity: 0.5;
    }

    // .ant-table {
    //     width: fit-content;
    // }

    th {
        &:before {
            content: none!important;
        }
    }
`

export const RuleName = styled.h6`
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 500;
    // line-height: 22px;
    // letter-spacing: 0em;
    // color: #263f97;
    text-transform:capitalize;
`

export const Criteria = styled.h6`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #263f97;
`


export const Container =styled.div`
  max-height:65vh;
  .ant-btn-primary{
    background: #6F57E9;
    border-color: #6F57E9;
    color: #ffffff;
  }

  .ant-btn.ant-btn-default{
    border-color: #6F57E9;
    color: #6F57E9;
  }

  .ant-switch{
    background: #a9a9a9 !important;
  }
  .ant-switch-checked{
    background: #17DB94 !important;
  }
  .ant-table-thead .ant-table-cell{
    color:#212122;
    font-size:14px;
    font-weight: 700;
  }

  .ant-table-tbody .ant-table-cell{
    color:#6B7280;
    font-size:12px;
    font-weight: 400;
  }
`