import styled from "styled-components";
import { Tabs, Button } from "antd";

export const CustomTabs = styled(Tabs)`
    .ant-tabs-tab-btn {
        padding: 0px 10px;
    }
    .ant-tabs-nav-list {
        border-bottom: 1px solid rgba(0, 11, 52, 0.3) !important;
    }
    padding-left:20px
`
export const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #263f97;
  cursor: pointer;
`;

export const UploadContainer = styled.div`
    .ant-upload.ant-upload-select{
        width : 100% !important;
        height : 50% !important;
    }
`


export const CustomButton = styled(Button)`
  height: 40px;
  border-radius: 6px;
`

export const SmartCodFooter = styled.div`
    position:absolute;
    bottom:0;
    background: #D5E4F9;
    left:0px;
    right:0;
    width: calc(100% - 96px);
    padding: 18px 10px;
    margin-left: auto;
    color: #0C3784;

    a{
        color: #0C64DB;
        text-decoration: underline;
    }
    span{
        color: #0C64DB;
        font-size: 12px;
        vertical-align:middle;
    }
`