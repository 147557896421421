import React from 'react';

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
} from 'components/UIElements';
import { CheckBox } from './style';

const Zone = ({ zoneList, setZoneList }) => {

  const setCheckedZone = (zone) => (zoneList.includes(zone) ? 'true' : 'false');

  const handleZoneChange = (zone) =>
    zoneList.includes(zone)
      ? setZoneList((s) => s.filter((d) => d !== zone))
      : setZoneList((s) => [...s, zone]);

  return (
    <FlexColContainer>
      <PickrrHeading size={5}>Select Zone</PickrrHeading>
      <FlexBox className="mb-16">
        <CheckBox
          checked={setCheckedZone('A')}
          onClick={() => handleZoneChange('A')}
        >
          Zone A
        </CheckBox>
        <CheckBox
          checked={setCheckedZone('B')}
          onClick={() => handleZoneChange('B')}
        >
          Zone B
        </CheckBox>
        <CheckBox
          checked={setCheckedZone('C')}
          onClick={() => handleZoneChange('C')}
        >
          Zone C
        </CheckBox>
        <CheckBox
          checked={setCheckedZone('D')}
          onClick={() => handleZoneChange('D')}
        >
          Zone D
        </CheckBox>
        <CheckBox
          checked={setCheckedZone('E')}
          onClick={() => handleZoneChange('E')}
        >
          Zone E
        </CheckBox>
      </FlexBox>
    </FlexColContainer>
  );
};

export default Zone;
