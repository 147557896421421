import styled from "styled-components";
import { Button, Modal, Form, Input } from "antd";

export const Container = styled.div`
    padding : 16px;
    .ant-modal-content{
        border-radius : 14px;
    }
`
export const BackButton = styled(Button)`
    margin-right: 20px;
    cursor: pointer;
    background: none  !important;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: #000B3499 !important;
    cursor: pointer;
    box-shadow : inset 0 0 0 ;
    padding : 0px;
`

export const CardContainer = styled.div`
    margin: 20px;
    background: #FFFFFF !important;
    border-radius: 8px;
    padding : 20px;
`
export const OrderInfo = styled.li`
    font-size: 14px;
    line-height: 120%;
    display: list-item;
    align-items: center;
    color: #38446D;
    margin-right : 20px;
`

export const DetailTitle = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 156.5%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.2px;
    color: #373737;
`
export const DetailInfo = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 156.5%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.2px;
    color : #616161
`
export const CardHeading = styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000B34;
    margin-bottom : 20px;
`
export const SummaryDetail = styled.span`
    font-size: 15px;
    line-height: 120%;
    display: block;
    align-items: center;
    color: #38446D;
    margin-bottom : 5px;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const ModalTitle = styled.span`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000B34;
`

export const CustomModal = styled(Modal)`
    border-radius: 8px;
    .ant-modal-content{
        border-radius : 14px;
    }
    .ant-modal-header{
        border-radius : 14px 14px 0 0;
        border-bottom : 0px !important;
    }
    .ant-select{
        width : 100%;
        background: #FFFFFF;
    }
    .ant-select-selector{
        border: 1px solid #CCD3DE !important;
        border-radius: 10px !important;
        height : 40px !important;
    }
    .ant-select-selection-placeholder{
        display : flex;
        align-items : center;
    }
    .ant-modal{
        top : 30px !important;
    }
`
export const CustomForm = styled(Form)`
  padding-top : 0px !important;
  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;   
    color: #38446D;
  }
  .ant-upload.ant-upload-select {
    width: 100px;
    height: 100px;
  }
  .ant-input,.ant-select-selector{
    border-radius : 5px !important;
    height : 50px !important;
  }
  .ant-input-number-affix-wrapper{
    width : 100%;
    border-radius : 5px !important;
    height : 50px !important;
  }
  .ant-input-number{
    width : 100%;
    display : flex;
    align-items : center;
    border: 1px solid #CCD3DE;
    border-radius: 10px;
  }
  .ant-input-number-handler-wrap{
    border-radius : 0 10px 10px 0
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #0051BF;
    border-color: #0051BF;
  }
  .ant-checkbox-inner{
    height : 20px;
    width : 20px;
  }
  .ant-checkbox{
    top : 0 !important;
  }
  .ant-checkbox-wrapper{
    display : flex;
    align-items : center;
    width: fit-content;
  }
  .ant-select-selector{
    display : flex;
    align-items : center;
  }
`;

export const FooterContainer = styled.div`
  display : flex;
  width : 100%;
  justify-content : center;
  align-items : center;
  margin-top : 40px;
`
export const ErrorMessageContainer = styled.div`
  display : flex;
  flex-direction : column;
  width : 100%;
  justify-content : center;
  align-items : center;
  margin-top : 40px;
  color : #38446D;
`
export const CustomInput = styled(Input)`
  border-radius: 8px;
  height: 38px;
`;
export const LabelText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: #38446d;
  margin: 6px 5px;
`;
export const RestButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ff5b51;
  cursor: pointer;
`;
export const CustomFont = styled.div<{
    size?: string;
    color?: string;
    weight?: string;
  }>`
    font-size: ${(props) => (props?.size ? props?.size : "16px")};
    color: ${(props: any) => (props?.color ? props?.color : "#38446D")};
    font-weight: ${(props: any) => (props?.weight ? props?.weight : "500")};
    letter-spacing: -0.03em;
    line-height: 14px;
`;
export const EditOrderDetailsButton = styled(Button)`
    display : flex;
    align-items : center;
    padding: 6.19231px 12.3846px;
    gap: 0.88px;
    height: 28.31px;
    left: 0px;
    top: 0px;
    background: #F0F6FD !important;
    border-radius: 7.07692px;
    margin-right : 8px;
    color: #0C64DB !important;
    font-weight: 600;
    font-size: 10.6154px;
    line-height: 13px;
    border : 0px !important;
`;
export const DeleteOrderButton = styled(Button)`
    display : flex;
    align-items : center;
    padding: 6.19231px 12.3846px;
    gap: 0.88px;
    height: 28.31px;
    left: 0px;
    top: 0px;
    background: #F2F4F7 !important;
    border-radius: 7.07692px;
    margin-right : 8px;
    color: #38446D !important;
    font-weight: 600;
    font-size: 10.6154px;
    line-height: 13px;
    border : 0px !important;
`;
export const NextButton = styled(Button)`
  background: #0c64db !important;
  border-radius: 8px;
  color: #ffffff !important;
  width: 136px;
  height: 40px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;
export const SearchCustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
  }
  .ant-modal-content {
    border-radius: 10px !important;
  }
`;
export const ProductSearchContainer = styled.div`
    padding: 25px 25px;

    .ant-tree-treenode {
        background: rgb(240 246 253 / 60%);
        // border-radius: 10px;
        // padding: 10px 14px;
        // margin: 10px 0;
        width:100%
    }

    .ant-tree-list-holder-inner{
        // background: rgb(240 246 253 / 60%);
        // border-radius: 10px;
        // padding: 10px 14px;
        // margin: 10px 0;
    }

    // .ant-tree-treenode.ant-tree-treenode-switcher-close{
    //     margin-top:10px;
    // }

    // .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-selected.ant-tree-treenode-leaf-last{
    //     margin-bottom: 10px;
    // }

    // .ant-tree-treenode-leaf-last{
    //     margin-bottom: 10px;
    // }


    .ant-checkbox-wrapper{
        display:flex;
        margin-bottom: 6px;
        font-size:12px;
    }
`
export const ProductHeading = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #38446d;
  text-transform: capitalize;
`;
export const VariantSelected = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #bcbcbc;
  margin-left: 13px;
  margin-top: 4px;
  text-transform: capitalize;
`;
export const ButtonEdit = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #0c64db;
  cursor: pointer;
`;

export const ButtonClose = styled.div`
  color: #38446d;
  font-weight: bold;
  cursor: pointer;
`;
export const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 128.5%;
  color: #000b34;
  opacity: 0.6;
`;
export const SearchResultContainer =  styled.div`
    border: 1px solid #e6e6e6; 
    padding: 10px; 
    border-top: none; 
    max-height: 150px;
    overflow-y:scroll;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
`

export const SearchResultItem = styled.div`
    padding: 8px 10px; 
    font-size:14px;
    color:#666;
    cursor:pointer
`

export const  SearchResultItemImg = styled.img`
    height: 40px; 
    width: auto; 
    border-radius:10px; 
    margin-right: 16px;
`

export const TransactionsHeadingText = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #38446D;
    margin-bottom: 20px;
`

export const TransactionsDetailsText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 149%;
    color: #38446D;
    margin-top:10px;
`

export const PaymentStatus = styled.div<{textColor?:string, bgColor?: string}>`
    width: 80px;  
    height: 28px;
    border-radius:8px; 
    font-size:12px;
    line-height: 15px;
    font-weight: 600;
    margin-top:10px;
    text-align:center;
    padding:6px 0;
    background-color: ${(props:any) => 
      props.bgColor ? props.bgColor : '#F0F6FD'};
    color:${(props:any) => props?.textColor ? props?.textColor : '#000B34'
    };
`