import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Col,
  Row,
  Button,
  Radio,
  Form,
  Select,
  Upload,
  notification,
  Image,
  Dropdown,
  Menu,
  Popconfirm,
} from 'antd';
import {
  UploadOutlined,
  LeftOutlined,
  DownOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Card,
  Heading,
  GenerateOTPButton,
  CustomRadioGroup,
  CheckoutText,
  HelpText,
  GlobalStyle,
  DetailHeadingBackground,
  PageTitle,
  BackButton,
  styles,
  Subtext,
  MutedNotiText,
} from './style';
import {
  CustomInput,
  FlexBox,
  SpaceBetweenContainer,
  PickrrButton,
  PickrrHeading,
  CustomForm,
} from 'components/UIElements';
import AddressContainer from './address';
import OrderSummary from './orderSummary';
import { useMutation, useQuery } from 'react-query';
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
} from '../GeneralSettings/script';
import { GetAuthToken } from 'globalQuries';
// import { useSelector } from 'react-redux';

const CustomizeCheckout = () => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );
  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
      // navigate('/settings/shipping');
    },
  });
  // const history = useHistory();
  const [fontList, setFontsList] = useState([]);
  const [mobileView, setMobileView] = useState('web');
  // const { user } = useSelector((state) => state);
  const [file, setFile] = useState({
    file: [],
    url: '',
  });
  const [mfile, setMfile] = useState({
    file: [],
    url: '',
  });
  const [displayFontColorPicker, setDisplayFontColorPicker] = useState(false);
  const [buttonFontColorPicker, setButtonFontColorPicker] = useState(false);
  const [headFonts, setHeadFonts] = useState({});
  const [bodyFonts, setBodyFonts] = useState({});
  const [loader, setLoader] = useState(false);
  const [highlightsColor, setHighlightsColor] = useState('#f6b73c');
  const [buttonColor, setButtonColor] = useState('#f6b73c');
  const { Option } = Select;
  useEffect(() => {
    getAllFonts();
    getUiConfig();
  }, []);

  const fontUrl = (fontFile) => {
    const {
      files: { regular = '' },
    } = fontFile;
    return regular.length && regular.replace('http', 'https');
  };

  const props = {
    onRemove: (file) => {
      setFile({
        file: [],
        url: '',
      });
    },
    beforeUpload: async (file) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        notification.error({ message: 'You can only upload JPG/PNG file!' });
      } else {
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        let url = await toBase64(file);

        setFile({ file, url });
        return false;
      }
    },
    // file,
  };

  const mProps = {
    onRemove: (file) => {
      setMfile({
        file: [],
        url: '',
      });
    },
    beforeUpload: async (file) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        notification.error({ message: 'You can only upload JPG/PNG file!' });
      } else {
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        let url = await toBase64(file);
        setMfile({ file, url });
        return false;
      }
    },
  };

  const getAllFonts = async () => {
    const response = await axios.get(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAYYt4PEYlmqG_GeWV--s3NxwbjvrnQc9g'
    );
    setFontsList(response.data.items);
  };

  const getUiConfig = async () => {
    const auth = GetAuthToken();
    const response = await axios.get(
      `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
      {
        headers: {
          Authorization: auth,
        },
      }
    );
    if (response.data.success) {
      setFile({
        file: [],
        url: response?.data?.data?.ui_config?.banner_desktop || '',
      });
      setMfile({
        file: [],
        url: response?.data?.data?.ui_config?.banner_mobile || '',
      });
      setButtonColor(response?.data?.data?.ui_config?.color_button || '');
      setHighlightsColor(response?.data?.data?.ui_config?.color_accent || '');
      setBodyFonts(response?.data?.data?.ui_config?.font_body || {});
      setHeadFonts(response?.data?.data?.ui_config?.font_heading || {});
    }
  };
  useEffect(() => {
    console.log(data, 'cous');
    if (data) {
      setFile({
        file: [],
        url: data?.ui_config?.banner_desktop || '',
      });
      setMfile({
        file: [],
        url: data?.ui_config?.banner_mobile || '',
      });
      setButtonColor(data?.ui_config?.color_button || '');
      setHighlightsColor(data?.ui_config?.color_accent || '');
      setBodyFonts(data?.ui_config?.font_body || {});
      setHeadFonts(data?.ui_config?.font_heading || {});
    }
  }, [data]);

  const submitData = async () => {
    let data = {};
    setLoader(true);
    if (!Array.isArray(mfile.file)) {
      try {
        const formData = new FormData();
        formData.append('file', mfile.file);
        formData.append('auth_token', 'ad8e9b26be353c9f9fa4f2a4ed7efdc9137222');
        let mobilebanner = await axios.post(
          `'https://pickout.pickrr.com/seller/file/upload/`,
          formData
        );
        if (mobilebanner.data.url.length) {
          data = {
            ...data,
            banner_mobile: mobilebanner.data.url.replaceAll(' ', '%20'),
          };
        }
      } catch (err) {
        notification.error({ message: err.message });
      }
    }

    if (!Array.isArray(file.file)) {
      try {
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('auth_token', 'ad8e9b26be353c9f9fa4f2a4ed7efdc9137222');
        let banner = await axios.post(
          `'https://pickout.pickrr.com/seller/file/upload/`,
          formData
        );
        if (banner.data.url.length) {
          data = {
            ...data,
            banner_desktop: banner.data.url.replaceAll(' ', '%20'),
          };
        }
      } catch (err) {
        notification.error({ message: err.message });
      }
    }
    if (Object.keys(headFonts).length) {
      data = {
        ...data,
        font_heading: {
          category: headFonts.category,
          family: headFonts.family,
          files: headFonts.files,
        },
      };
    }
    if (Object.keys(bodyFonts).length) {
      data = {
        ...data,
        font_body: {
          category: bodyFonts.category,
          family: bodyFonts.family,
          files: bodyFonts.files,
        },
      };
    }

    if (highlightsColor.length) {
      data = { ...data, color_accent: highlightsColor };
    }
    if (buttonColor.length) {
      data = { ...data, color_button: buttonColor };
    }

    // try {
    if (Array.isArray(mfile.file) && mfile.url) {
      data = { ...data, banner_mobile: mfile.url };
    }

    if (Array.isArray(file.file) && file.url) {
      data = { ...data, banner_desktop: file.url };
    }
    mutation.mutate({
      ui_config: data,
    });
    //   const response = await axios.put(
    //     `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
    //     {
    //       ui_config: data,
    //     }
    //     {
    //       headers: {
    //         Authorization: user,
    //       },
    //     }
    //   );
    //   if (response.data.success) {
    //     setLoader(false);
    //     notification.success({ message: response.data.message });
    //   } else {
    //     setLoader(false);
    //     notification.error({ message: response.data.message });
    //   }
    // } catch (err) {
    //   setLoader(false);
    //   notification.error({ message: err.response.data.message || err.message });
    // }
  };

  return (
    <Container>
      <DetailHeadingBackground>
        <BackButton onClick={() => navigate(-1)}>
          <LeftOutlined style={{ paddingRight: 8 }} />
          BACK
        </BackButton>
        <PageTitle>Customize Checkout</PageTitle>
      </DetailHeadingBackground>

      <Row style={{ backgroundColor: '#fff' }}>
        <Col
          span={8}
          style={{ padding: '20px', borderRight: '1px solid #C5CDE3' }}
        >
          <CustomForm layout="vertical">
            <Row>
              <Col span={24}>
                <PickrrHeading size={4}>Banner Image</PickrrHeading>
                <div
                  style={{
                    marginTop: '20px',
                    borderBottom: '1px solid #C5CDE3',
                  }}
                >
                  <Form.Item
                    label="Desktop image"
                    style={{ marginBottom: '6px' }}
                  >
                    {file.url.length ? (
                      <>
                        <Image src={file.url} />{' '}
                        <Button style={{ marginBottom: '12px' }}>
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item>
                                  <Upload
                                    {...props}
                                    maxCount={1}
                                    showUploadList={false}
                                  >
                                    <Button
                                      style={{
                                        marginRight: '8px',
                                        border: 'none',
                                        background: 'none',
                                        color: '#000',
                                        boxShadow: 'shadow',
                                      }}
                                    >
                                      <EditOutlined />
                                      Change
                                    </Button>
                                  </Upload>
                                  {/* <EditOutlined />
                                  &nbsp;change */}
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => setFile({ file: [], url: '' })}
                                >
                                  &nbsp; &nbsp;&nbsp;
                                  <DeleteOutlined />
                                  &nbsp; Delete
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <a
                              className="ant-dropdown-link"
                              onClick={(e) => e.preventDefault()}
                            >
                              Change <DownOutlined />
                            </a>
                          </Dropdown>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Subtext>File Type: png, jpeg</Subtext>
                        <Upload {...props} maxCount={1}>
                          <Button style={{ marginRight: '8px' }}>
                            <UploadOutlined />
                            Upload image
                          </Button>
                        </Upload>

                        <MutedNotiText style={{ marginBottom: '0px' }}>
                          <sup>*</sup>1000 x 400px recommended
                        </MutedNotiText>
                      </>
                    )}
                  </Form.Item>
                  <Form.Item label="Mobile image">
                    {mfile.url.length ? (
                      <>
                        <Image src={mfile.url} />{' '}
                        <Button>
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item>
                                  <Upload
                                    {...mProps}
                                    maxCount={1}
                                    showUploadList={false}
                                  >
                                    <Button
                                      style={{
                                        marginRight: '8px',
                                        border: 'none',
                                        background: 'none',
                                        color: '#000',
                                        boxShadow: 'shadow',
                                      }}
                                    >
                                      <EditOutlined />
                                      Change
                                    </Button>
                                  </Upload>
                                  {/* <EditOutlined />
                                  &nbsp;Change */}
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() =>
                                    setMfile({ file: [], url: '' })
                                  }
                                >
                                  &nbsp; &nbsp;&nbsp;
                                  <DeleteOutlined />
                                  &nbsp; Delete
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <a
                              className="ant-dropdown-link"
                              onClick={(e) => e.preventDefault()}
                            >
                              Change <DownOutlined />
                            </a>
                          </Dropdown>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Subtext>File Type: png, jpeg</Subtext>
                        <Upload {...mProps} maxCount={1}>
                          <Button style={{ marginRight: '8px' }}>
                            <UploadOutlined />
                            Upload image
                          </Button>
                        </Upload>
                      </>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} style={{ marginTop: '20px' }}>
                <PickrrHeading size={4}>Typography</PickrrHeading>
                <div
                  style={{
                    marginTop: '20px',
                    borderBottom: '1px solid #C5CDE3',
                  }}
                >
                  <Form.Item label="Heading">
                    <Select
                      showSearch
                      placeholder="Please select a font style"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(value) => {
                        let file = {};
                        fontList.forEach((el) => {
                          if (value === el.family) {
                            file = el;
                          }
                        });
                        setHeadFonts(file);
                      }}
                      value={headFonts.family && headFonts.family}
                    >
                      {fontList.length > 0 &&
                        fontList.map((data) => (
                          <Option value={data.family}>{data.family}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Body">
                    <Select
                      showSearch
                      placeholder="Please select a font style"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(value) => {
                        let file = {};
                        fontList.forEach((el) => {
                          if (value === el.family) {
                            file = el;
                          }
                        });
                        setBodyFonts(file);
                      }}
                      value={bodyFonts.family && bodyFonts.family}
                    >
                      {fontList.length > 0 &&
                        fontList.map((data) => (
                          <Option value={data.family}>{data.family}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} style={{ marginTop: '20px' }}>
                <PickrrHeading size={4}>Colors</PickrrHeading>
                <div style={{ marginTop: '20px' }}>
                  <Form.Item label="Highlights / Link / Checkmarks">
                    <div>
                      <div
                        style={styles.swatch}
                        onClick={() =>
                          setDisplayFontColorPicker((prevState) => !prevState)
                        }
                      >
                        <div
                          style={{
                            ...styles.color,
                            background: highlightsColor,
                          }}
                        />
                      </div>
                      {displayFontColorPicker ? (
                        <div style={styles.popover}>
                          <div
                            style={styles.cover}
                            onClick={() => setDisplayFontColorPicker(false)}
                          />
                          <SketchPicker
                            color={highlightsColor}
                            onChange={(color) => setHighlightsColor(color.hex)}
                          />
                        </div>
                      ) : null}
                    </div>
                    {/* <input
                      type="color"
                      name="primary_theme_color"
                      value={highlightsColor}
                      onChange={(e) => setHighlightsColor(e.target.value)}
                    /> */}
                  </Form.Item>
                  <Form.Item label="Button">
                    <div>
                      <div
                        style={styles.swatch}
                        onClick={() =>
                          setButtonFontColorPicker((prevState) => !prevState)
                        }
                      >
                        <div
                          style={{
                            ...styles.color,
                            background: buttonColor,
                          }}
                        />
                      </div>
                      {buttonFontColorPicker ? (
                        <div style={styles.popover}>
                          <div
                            style={styles.cover}
                            onClick={() => setButtonFontColorPicker(false)}
                          />
                          <SketchPicker
                            color={buttonColor}
                            onChange={(color) => setButtonColor(color.hex)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </CustomForm>
        </Col>
        <Col span={16}>
          <SpaceBetweenContainer style={{ padding: '20px' }}>
            <PickrrHeading size={4}>Device Preview</PickrrHeading>
            <SpaceBetweenContainer style={{ width: '165px' }}>
              <Popconfirm
                placement="top"
                title="Are you sure you want to cancel your changes?"
                onConfirm={() => getUiConfig()}
                okText="Yes"
                cancelText="No"
                // disabled={!selectedRowKeys.length}
              >
                <PickrrButton color="light-focus">Cancel</PickrrButton>
              </Popconfirm>

              <PickrrButton onClick={submitData} loading={loader}>
                Save
              </PickrrButton>
            </SpaceBetweenContainer>
          </SpaceBetweenContainer>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <CustomRadioGroup
              value={mobileView}
              onChange={(e) => setMobileView(e.target.value)}
              style={{ marginBottom: 16 }}
            >
              <Radio.Button value="web">Website</Radio.Button>
              <Radio.Button value="mobile">Mobile</Radio.Button>
            </CustomRadioGroup>
          </div>
          <GlobalStyle
            style={{
              overflowY: 'scroll',
              // maxHeight: '543px',
              padding: '12px',
              ...(mobileView === 'mobile' && { width: '400px' }),
              ...(mobileView === 'mobile' && { margin: '0 auto' }),
            }}
            headFonts={headFonts.family && headFonts.family}
            headFontUrl={Object.keys(headFonts).length && fontUrl(headFonts)}
            bodyFonts={bodyFonts.family && bodyFonts.family}
            bodyFontUrl={Object.keys(bodyFonts).length && fontUrl(bodyFonts)}
            highlightsColor={highlightsColor}
            buttonColor={buttonColor}
          >
            <Row>
              <Col span={24}>
                <Card
                  style={{
                    ...(mobileView === 'mobile'
                      ? {
                          flexDirection: 'column',
                        }
                      : { flexDirection: 'row' }),
                    justifyContent: 'space-between',
                  }}
                >
                  <FlexBox
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        height: '32px',
                        width: '32px',
                        borderRadius: '50%',
                        border: '1px solid #d8d8d8',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      className="mr-8"
                    >
                      <img
                        src="https://d10srchmli830n.cloudfront.net/1637657667680_download-(2).jpeg"
                        alt=""
                        style={{ height: '28px', borderRadius: '50%' }}
                      />
                    </div>
                    <CheckoutText className="heading">store name</CheckoutText>
                  </FlexBox>
                  <HelpText className="body-element">
                    Need help? contact our support +918824249504
                  </HelpText>
                </Card>
              </Col>
              <Col span={24}>
                {mobileView !== 'mobile'
                  ? !!file.url?.length && (
                      <Card style={{ padding: '2px', borderRadius: '2px' }}>
                        <img src={file.url} />
                      </Card>
                    )
                  : (!!file.url?.length || !!mfile.url?.length) && (
                      <Card style={{ padding: '2px', borderRadius: '2px' }}>
                        {' '}
                        <img src={mfile.url.length ? mfile.url : file.url} />
                      </Card>
                    )}
              </Col>
            </Row>
            <Row
              gutter={16}
              style={{
                ...(mobileView === 'mobile' && { flexFlow: 'column-reverse' }),
              }}
            >
              <Col
                lg={14}
                sm={24}
                style={mobileView === 'mobile' ? { maxWidth: '100%' } : null}
              >
                {' '}
                <Container>
                  <Card>
                    <FlexBox style={{ alignItems: 'center' }}>
                      <img
                        src="https://d10srchmli830n.cloudfront.net/1637151033755_Group-11209.svg"
                        alt=""
                        className="mr-8"
                        style={{ width: '18px', marginBottom: '24px' }}
                      />
                      <Heading className="heading">Mobile Number</Heading>
                    </FlexBox>
                    <SpaceBetweenContainer
                      className="mb-24"
                      style={{ alignItems: 'center' }}
                    >
                      {/* <FloatLabel label="Enter your mobile number" value={mobileNumber}> */}
                      <CustomInput size="large" disabled />
                      {/* </FloatLabel> */}
                      <Col
                        xl={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                        style={{ paddingLeft: '0px' }}
                      >
                        <GenerateOTPButton
                          size="large"
                          color="primary"
                          className="btn-color body-element"
                        >
                          Generate OTP
                        </GenerateOTPButton>
                      </Col>
                    </SpaceBetweenContainer>
                  </Card>
                  <AddressContainer />
                </Container>
              </Col>
              <Col
                lg={10}
                sm={24}
                style={mobileView === 'mobile' ? { maxWidth: '100%' } : null}
              >
                <OrderSummary />
              </Col>
            </Row>
          </GlobalStyle>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomizeCheckout;
