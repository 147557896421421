import React from 'react';
import { useMutation } from 'react-query';
import {
  FlexColContainer,
  FlexBox,
  IconContainer,
  CustomTable,
  RuleName,
  Criteria,
} from './styled';
import { Switch, Popconfirm, Tooltip, notification } from 'antd';
import {
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  handleDelete,
  handleActivity,
  handleChangeOrder,
} from '../script';

const RulesTable = ({
  setEditdata,
  setIsCreateRuleModalVisible,
  setKey,
  ruleList,
  isRuleListLoading,
  handleQueryUpdate,
  handleQueryReFetch
}) => {


  const { isLoading: deleteLoader, mutate: deleteMutation } = useMutation(
    handleDelete,
    {
      onSuccess: (data, record) => {
        handleQueryReFetch()
        notification.success({ message: 'Successfully Deleted' });
      },
    }
  );

  const { isLoading: activityLoader, mutate: activity } = useMutation(
    handleActivity,
    {
      onSuccess: (data, editData) => {
        const newRuleList = ruleList.map((rule) => {
          if (editData._id === rule._id)
            return { ...rule, is_active: !rule.is_active };
          else return rule;
        });
        handleQueryUpdate(newRuleList)
        notification.success({ message: 'Successfully updated' });
      },
    }
  );

  const { isLoading: orderLoader, mutate: handleOrder } = useMutation(
    handleChangeOrder,
    {
      onSuccess: (data, postData) => {
        if(postData.type === 'up') {
          const ruleListArray = [...postData.ruleListArray]
          const selectedRulePriority = ruleListArray[postData.index].priority
          ruleListArray[postData.index].priority = ruleListArray[postData.index - 1].priority
          ruleListArray[postData.index - 1].priority = selectedRulePriority
          const sortedRules = ruleListArray.sort((a,b) => b.priority - a.priority)
          handleQueryUpdate(sortedRules)
        } else {
          const ruleListArray = [...postData.ruleListArray]
          const selectedRulePriority = ruleListArray[postData.index].priority
          ruleListArray[postData.index].priority = ruleListArray[postData.index + 1].priority
          ruleListArray[postData.index + 1].priority = selectedRulePriority
          const sortedRules = ruleListArray.sort((a,b) => b.priority - a.priority)
          handleQueryUpdate(sortedRules)
        }
        notification.success({ message: 'Data Order Updated' });  
      },
    }
  );

  const getName = (name) => {
    if (name === 'prepaid_discount') return 'Prepaid Discount';
    if (name === 'cod_charge') return 'COD Charge';
    if (name === 'shipping_charge') return 'Shipping Charge';
    if (name === 'cod_disable') return 'Disable COD';
    if (name === 'shipping_disable') return 'Shipping Visibility';
    if (name === 'partial_cod') return 'Partial COD';
  };
  
  const getFilteredCriterias = (data) => {
    console.log(data, 'lp');
    let determiningFactors = '';
    if (data?.risk_profile_config?.risk_profile_high||data?.risk_profile_config?.risk_profile_very_high || data?.risk_profile_config?.risk_tags?.length) {
      determiningFactors += 'Risk profile / ';
    }

    Object.keys(data).map(function (key, index) {
      if (Array.isArray(data[key]) && data[key].length) {
        if (key === 'block_phone_no') {
          determiningFactors += 'Phone Number /';
        } else if (
          key === 'weight' ||
          key === 'invoice_value' ||
          (key === 'lane' && Object.keys(data[key][0]).length !== 0)
        ) {
          determiningFactors =
            determiningFactors +
            `${key[0].toUpperCase()}${key.slice(1)} / `.replace('_', ' ');
        } else {
          determiningFactors =
            determiningFactors +
            `${key[0].toUpperCase()}${key.slice(1)} / `.replaceAll('_', ' ');
        }
      }
    });
    return determiningFactors.slice(0, -2);
  };

  const handlePriority = ({ data, type, index, rowData }) => {
    let editData;
    if (type === 'up') {
      editData = {
        rules: [
          {
            ...rowData,
            rule_id: rowData._id,
            priority: rowData.priority + 1,
          },
        ],
      };
    } else {
      editData = {
        rules: [
          {
            ...rowData,
            rule_id: rowData._id,
            priority: rowData.priority - 1,
          },
        ],
      };
    }
    console.log({ editData });
    handleOrder(editData);
  };

  const columns = [
    {
      title: '',
      key: 'arrows',
      width: 40,
      render: (rowData, record, index) => (
        <FlexColContainer>
          {index === 0 ? (
            <UpOutlined
              className="mb-16"
              style={{
                opacity: '0.3',
                cursor: 'not-allowed',
              }}
            />
          ) : (
            <UpOutlined
              className="mb-16"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleOrder({
                  ruleListArray: ruleList ,
                  type: 'up',
                  index,
                  rowData,
                });
              }}
            />
          )}
          {index === ruleList?.length - 1 ? (
            <DownOutlined style={{ cursor: 'not-allowed', opacity: '0.3' }} />
          ) : (
            <DownOutlined
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleOrder({
                  ruleListArray: ruleList,
                  type: 'down',
                  index,
                  rowData,
                });
              }}
            />
          )}
        </FlexColContainer>
      ),
    },
    {
      title: 'Rule Name',
      dataIndex: 'rule_type',
      key: 'rule_type',
      width: 200,
      align: 'center',
      render: (name, record) => <RuleName>{getName(name)}</RuleName>,
    },
    {
      title: 'Added Criteria',
      key: 'added_criteria',
      align: 'center',
      render: (data, record) => (
        <Criteria>{getFilteredCriterias(data?.determining_factors)}</Criteria>
      ),
    },
    {
      title: 'Status',
      key: 'active',
      align: 'center',
      render: (data) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          checked={data.is_active}
          onClick={() => activity(data)}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (data, record) => (
        <FlexBox className="mb-16" style={{ justifyContent: 'center' }}>
          <Tooltip title="Edit">
            <IconContainer
              className="mr-12"
              onClick={() => {
                setIsCreateRuleModalVisible(true);
                setEditdata(data);
                setKey(`${Math.random()}`);
              }}
              style={{ margin: '5px' }}
            >
              <EditOutlined />
            </IconContainer>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                console.log(record, 'rec');
                deleteMutation(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <IconContainer className="mr-12" style={{ margin: '5px' }}>
                <DeleteOutlined />
              </IconContainer>
            </Popconfirm>
          </Tooltip>
        </FlexBox>
      ),
    },
  ];
  return (
    <CustomTable
      columns={columns}
      dataSource={ruleList ? ruleList : []}
      loading={isRuleListLoading || activityLoader || orderLoader || deleteLoader}
      rowKey={(record) => record.name}
      scroll={{ x: 800, y: 450 }}
      pagination={false}
    />
  );
};

export default RulesTable;
