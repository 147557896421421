import React from 'react';
import {Row, Col, Input} from 'antd';
import {InputContainer} from '../../styled';

const DestinationProvinceCode  = ({destinationProvinceCodeList, setDestinationProvinceCodeList}) => {
    const onChange = (e) =>{
        setDestinationProvinceCodeList(() => [e.target.value]);
        console.log(e.target.value)
    }
    
    
    return (
        <Row gutter={[16, 16]}>
            <Col>
            <InputContainer>
                <Input 
                    placeholder='Enter Province Code'
                    value={destinationProvinceCodeList}
                    onChange={onChange}
                />
                </InputContainer>
            </Col>
        </Row>
    )
}

export default DestinationProvinceCode;