import { FlexRow } from 'components/Settings/Discount/styled'
import { ChangeBox, Container, HeadingText, StatText, ChangeText } from './styled'
import { IStats } from 'pages/Dashboard/data'
import { Tooltip } from 'antd'
import { getImageUrl, getToolTipContent } from './helperFuntions'
import moment from 'moment'
import {RiseOutlined} from '@ant-design/icons'
import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Skeleton } from 'antd';


interface IListProps {
    fastrrPrepiadOrder: string | number
    prepaidOrder: string | number
}


const ListCustom = ({fastrrPrepiadOrder, prepaidOrder}: IListProps)=>{
    const list:any =[
        {heading:'Fastrr Prepaid Orders',order:fastrrPrepiadOrder},
        {heading:'Overall Prepaid Orders',order:prepaidOrder},
        // {heading:'Others',price:15000,profit:"1%"},
    ]
    return (
      <List
        className="demo-loadmore-list"
        // loading={initLoading}
        style={{padding: 0, marginTop: "10%"}}
        itemLayout="horizontal"
        size="small"
        grid={{ gutter: 12, column: 2 }}
        dataSource={list}
        renderItem={(item:any) => (
          <List.Item
          key={item.heading}
          style={{padding: 0}}
            // actions={[<a key="list-loadmore-edit">{item.profit}</a>]}
          >
            <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta
                title={<StatText style={{fontSize:40}}>{item.order ? `${item.order}%` :  '-'}</StatText>}
              />
              <List.Item.Meta
                title={item.heading}
                className='demo'
              />

            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <List.Item.Meta
                title={<ChangeText color='green'><RiseOutlined color='green'/> 2</ChangeText>}
              /> */}  
            </Skeleton>
          </List.Item>
        )}
      />
    );
}
interface IProps {
    heading: string
    stats: IStats
    isLoading: boolean
    fromDate: string
    toDate: string
    totalOrders: number | string
    fastrrPrepiadOrder: string | number
    prepaidOrder: string | number
}


// fastrrPrepiadOrder = {orderStatusData?.fastrrPrepaidPercent}
//                     prepaidOrder

const GridViewAnalytics = ({ 
    stats, 
    isLoading, 
    heading, 
    fromDate, 
    toDate, 
    totalOrders, 
    fastrrPrepiadOrder, 
    prepaidOrder
    }: IProps) => {

    const dateDifference = moment(toDate).diff(moment(fromDate), 'days')

    const getStatsValue = (title: string, value: number,totalOrders: string | number ) => {
        if(value > 0 || title === "Prepaid% : COD%" && totalOrders > 0) {
            switch (title) {
                case "Total Sales Through Fastrr":
                    return <StatText>₹{value.toLocaleString('en-IN')}</StatText>
                case "Total Orders Through Fastrr":
                    return <StatText>{value}</StatText>
                case "Address Prefill %":
                    return <StatText>{`${value}%`}
                        {/* <span style={{ fontSize: '20px', fontWeight: '500' }}>of 100</span> */}
                    </StatText>
                case "Prepaid% : COD%":
                    return <StatText>{totalOrders > 0 ?  `${value}% : ${100 - value}%` : '-'}</StatText>
                case "Cart Abandonment Rate":
                    return <StatText>{`${value}%`}</StatText>
            }
        } else {
            return <StatText>-</StatText>
        }
    }


    const getPrevValue = (title: string, value: number) => {
        switch (title) {
            case "Total Sales Through Fastrr":
                return value
            case "Total Orders Through Fastrr":
                return value
            case "Address Prefill %":
                return `${value}%`
            case "Prepaid% : COD%":
                return `${value}% : ${100 - value}%`
            case "Cart Abandonment Rate":
                return `${value}%`
        }
    }

        return (
            <>
                <Container>
                    {/* <FlexRow>
                        <HeadingText>{heading}</HeadingText>
                        {
                            (heading === "Address Prefill %" || heading === "Prepaid% : COD%" || heading === "Cart Abandonment Rate")
                            &&
                            <Tooltip
                                color="#F3F8FF"
                                placement="bottomRight"
                                overlayStyle={{ maxWidth: '300px' }}
                                title={
                                    <div style={{ padding: '5px' }}>
                                        <h5 style={{ color: '#0C3784' }}>
                                            {getToolTipContent(heading)}
                                        </h5>
                                    </div>
                                }
                            >
                                <img
                                    src="https://d10srchmli830n.cloudfront.net/1619693972018_info.svg"
                                    alt="info-icon"
                                    style={{marginTop:'-20px'}}
                                />
                            </Tooltip>
                        }
                    </FlexRow> */}
                    {/* {getStatsValue(heading, stats?.curValue, totalOrders)} */}
                    {/* <div style={{display:'flex', alignItems:'center', height: '100%'}}> */}
                        <ListCustom  
                            fastrrPrepiadOrder = {fastrrPrepiadOrder}
                            prepaidOrder  = {prepaidOrder}
                        />
                    {/* </div> */}
                    {
                    ((stats?.curValue > 0 || heading === "Prepaid% : COD%") && totalOrders > 0) &&  
                    
                <Tooltip
                    color="#F3F8FF"
                    placement="right"
                    overlayStyle={{ maxWidth: '300px', visibility: Math.abs(stats?.delta) >= 0 && stats?.preValue !== 0 ? 'visible' : 'hidden' }}
                    title={
                        <div style={{ padding: '5px'}}>
                            <h5 style={{ color: '#0C3784' }}>
                                was <span style={{fontWeight:'600'}}>{getPrevValue(heading, stats?.preValue)}{' '}</span>
                               {
                                   dateDifference === 0 ?
                                   <>
                                    the previous day<br/>
                                    <span style={{fontWeight:'600'}}>
                                        ({moment(fromDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')})
                                    </span>
                                   </> :
                                   <>
                                    in the preceeding window <br/>
                                    <span style={{fontWeight:'600'}}>
                                        (
                                        {moment(fromDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')}{' '}
                                        to{' '}
                                        {moment(toDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')}
                                        )
                                    </span>
                                   </>
                               }
                            </h5>
                        </div>
                    }
                >
                {
                toDate !== moment().format('YYYY-MM-DD') &&
                    <ChangeBox
                        color={
                            stats.title === "Cart Abandonment Rate" ?
                                stats?.delta < 0 ? '#E7F8EA' : '#FFEBEB' :
                                stats?.delta < 0 ? '#FFEBEB' : '#E7F8EA'
                        }
                    >
                        <FlexRow>
                            { (Math.abs(stats?.delta) >= 0 && stats?.preValue !== 0) &&
                                <img
                                    src={getImageUrl(heading, stats?.delta)}
                                    alt="change-sign"
                                />
                            }
                            <ChangeText
                                color={
                                    stats.title === "Cart Abandonment Rate" ?
                                        stats?.delta < 0 ? '#0EB929' : '#B90E0E' :
                                        stats?.delta < 0 ? '#B90E0E' : '#0EB929'
                                }
                            >
                                {(Math.abs(stats?.delta) >= 0 && stats?.preValue !== 0) ? `${Math.abs(stats?.delta)}%` : '-'}
                            </ChangeText>
                        </FlexRow>
                    </ChangeBox>
                }
                </Tooltip>
                }
                </Container>
            </>
        )
    }

    export default GridViewAnalytics