
import styled from 'styled-components';
import { Card } from 'antd';

export const Heading = styled.div`
    color: #9A9494;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.66px;
`

export const CustomCart = styled(Card)`
    border-radius: 8px;
`


export const PercentText = styled.div`
    color:  #0C3784;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.2px;
`

export const SubHeading = styled.div`
    color: rgba(0, 11, 52, 0.70);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
`

export const NormalText = styled.div`
    color: #000;
    font-size: 12px;
    font-weight: 600;
`

export const Line = styled.div`
    background: #EDEEEF;
    height: 1px;
    width: 100%;
    margin:10px 0;
`

export const ChartContainer = styled.div`
  background: #f6f8fc;
  border-radius: 10px;
  padding: 25px 20px 10px;

  .recharts-legend-wrapper {
    bottom: 0 !important;
  }
`;

export const CommonHeading = styled.h4`
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  color: #263f97;
`;

export const TotalAmount = styled.h4`
  color: #0c64db;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 4px;
`;

export const PieText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #263f97;
`;

export const TotalAmtWrapper = styled.div`
  margin-bottom: 20px;
  text-align: right;
`;

export const PieChartTotalData = styled.div`
  position: absolute;
  right: 2%;
  top: 6%;
`;

export const LegendList = styled.ul`
  list-style: none;
  margin-top: 100px;
  padding: 0;
`;

export const LegendListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;
  padding-left: 36px;
`;

export const BulletLabelText = styled.div`
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
//   line-height: 100%;
  color: #38446D;
  margin-bottom: 12px;
`;

export const EmptyBarChartContainer = styled.div`
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EmptyBarChartHeading = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: #263f97;
  margin-top: 10px;
`;

export const EmptyBarChartText = styled.p`
  font-size: 12px;
  color: #263f97;
`;

export const LegendHeading = styled.div`
    border-top: 1px solid #E1E2E9;
    border-bottom: 1px solid #E1E2E9;
    display:flex;
    justify-content:space-between;
    font-size: 14px;
    color:#0C3784;
    padding: 12px 36px;
    margin-bottom: 20px;
    font-weight: 600;
`

export const ValueText = styled.div`
    color: #9A9494;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-right: 36px;
`

export const Container = styled.div`
    background: #FFF;
    border-radius: 10px;
    height: auto;
    width: 100%;
    padding: 20px 20px;
`

export const SeeResponseButton = styled.span`
  color: #0C3784;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: #E3E6F2;
  diaplay:inline-block;
  padding: 2px 4px;
  cursor:pointer;
`

export const EmptyViewContiner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;

    * {
        margin-top: 10px;
    }
`