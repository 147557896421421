import React from "react";

const FormStep3 = ({ accountStatus, channel }: any) => {

    const handleDemoClick = () => {
        return
    }

    return <>
        {
            channel.imageUrl &&
            <img 
                src={channel.imageUrl}
                alt={channel.key} 
                style={{margin: "10px 0px", height:'50px'}}
            />
        }
        <div className="text-textGreyFont">
            <p className="font-bold text-lg text-black">Demo link</p>
            <p >Explore the demo link tailored specifically for your business needs.</p>
            <p className="font-semibold underline text-base mt-2" onClick={handleDemoClick}>Click to view</p>
            <p className="font-bold text-lg text-black mt-5">Go live approval</p>
            <p>Upon approval, we will implement the same checkout experience on your live website.</p>
        </div>
    </>
}
export default FormStep3