import styled from 'styled-components';

export const StatusTag = styled.div`
  border-radius: 8px;
  padding: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 60, 60);
  background: rgba(255, 227, 225, 1);
x`;

export const DateHeadingText = styled.h5`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #0C3784;
`

export const DateText = styled.h5`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #0C64DB;
`
