import axios from 'axios';
import { GetAuthToken } from 'globalQuries'
import { sessionConfig } from 'utils/functions';
// const authToken: any = GetAuthToken();
const authToken:any=localStorage?.getItem('userManagementToken')

export const sessionResponseValidator = async (data: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/validate/url/?domainName=${data?.domainName}`,
      {
        headers: {
          cid: data?.cid
        },
      }
    );
    if(response || response?.headers){
        const id = response.headers.cid;
        const getAuth = window.atob(decodeURIComponent(`${id}`));
        localStorage.setItem('auth_token', getAuth)
        document.cookie = `auth_key=${getAuth};max-age=604800`;
        localStorage.removeItem("loggedInFromAdmin");
        //defaultRouteRemoved
    }
  } catch (e) {

  }
}

export const sessionCreationHandler = async (data : any) => {
  try {
    const sessionResponse = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/user/create/session`,
      {
        params: {
          email: data?.email,
          domain: data?.domainName,
        }, 
        headers: {
          "Company-Id": data?.cid
        }
      }, 
    )
    
    //config handler
    sessionConfig(sessionResponse)

    if(sessionResponse) {
      sessionResponseValidator(data)
    }
  } catch (e) {

  }
}
export const onboardSeller = async (data: any) => {

  const response = await axios.post(`${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/user/onboard`,
    data,
    {
      headers: {
        'X-AUTH-TOKEN': "QDQMnKhB88wcjElb5rxCZG93P5HfIPbUzIa44b9r8Nc",
      },
    }
  );

  if(response.data) {
    sessionCreationHandler({
      email: data?.email_id,
      domainName: data?.domain_name,
      cid: data?.company_id
    })
  } 
  //return response.data; //SARWAN
};

// export const fetchDetailsOnboarding = async () => {
//   const response = await axios.get(`https://sr-auth.shiprocket.in/user/details`,
//   {
//     headers:{
//       'X-AUTH-TOKEN':authToken,
//     }
//   }
// )
// }
