import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Divider,
  DatePicker,
  TimePicker,
  Switch,
  Checkbox,
  Upload,
  Space,
  message,
  notification,
  Select,
  Spin,
  Button
} from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {
  Container,
  CustomForm,
  FlexColContainer,
  SubHeader,
  SubText,
  // PickrrButton,
  FieldDescription
} from './style';
import Header from '../../CommonComponents/Header';
import Uploader from '../../CommonComponents/ImageUploader';
import {
  FlexBox,
  FlexEndContainer,
  SpaceBetweenContainer,
  PickrrButton,
} from 'components/UIElements';
import {
  CreateDiscountButton,
  DiscountContainer,
  DiscountListContainer,
} from './style';
import moment from 'moment';
// import {
//   fetchSellerCheckoutConfig,
//   updateSellerCheckoutConfig,
// } from 'redux/actions/manageCheckout';
// import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getGeneralDetails, updateSellerCheckoutConfig } from './script';
import { GetAuthToken } from 'globalQuries';
import { DataContext } from 'context/DataProvider';
import { CheckboxText } from '../TrackingInfo/styled';
import { Label } from 'components/UIElements/CustomInputBox/styled';

const { RangePicker } = DatePicker;
const { Option } = Select;

const GeneralSettings = () => {

  const antIcon = <LoadingOutlined style={{ fontSize: 15, color:'#FFF' }} spin />;

  const navigate = useNavigate();
  const auth = GetAuthToken();
  const queryClient = useQueryClient();

  // const dispatch = useDispatch();
  // const history = useHistory();
  // const { walletDetails } = useSelector((state) => state.dashboard);
  // const { sellerConfigDetails, fetchingSellerConfig } = useSelector(
  //   (state) => state.manageCheckout
  // );
  // const user = useSelector((state) => state.user);
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );

  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      setSavingSettings(false)
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
      queryClient.invalidateQueries('generalSettings');
      setSavingSettings(false)
      // navigate('/settings/shipping');
    },
  });
  const [form] = Form.useForm();

  const [discountList, setDiscountList] = useState([]);
  const [createDiscount, setCreateDiscount] = useState(false);
  const [email, setEmail] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [discount, setDiscount] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [savingSettings, setSavingSettings] = useState(false)

  const [uploadFile, setUploadFile] = useState([]);

  const onChange = (date, dateString) => {};

  // useEffect(() => {
  //   if (sellerConfigDetails === null) {
  //     dispatch(fetchSellerCheckoutConfig());
  //   }
  // }, []);

  // useEffect(() => {
  //   if (walletDetails) {
  //     setEmail(walletDetails.email);
  //   }
  // }, [walletDetails]);
  const {onboardVersion} = useContext(DataContext)

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        ...(!!data?.pickup_config && {
          pickup_config: {
            ...data.pickup_config,
            cut_off_time: moment(data.pickup_config.cut_off_time, 'HH:mm'),
          },
        })
      });

      setDiscountList(data.discount_list);
    }
  }, [data]);

  const submitForm = () => {
    setSavingSettings(true)
    let postData = { ...form.getFieldValue() };
    postData = {
      ...postData,
      ui_config : {
        ...postData?.ui_config,
        otp_less_cod_button_animate: postData?.ui_config?.otp_less_cod_button_animate ?? null,
        otp_less_cod_button_color : postData?.ui_config?.otp_less_cod_button_color?.trim()?.length ? postData?.ui_config?.otp_less_cod_button_color?.trim() : null,
        otp_less_cod_button_text : postData?.ui_config?.otp_less_cod_button_text?.trim()?.length ? postData?.ui_config?.otp_less_cod_button_text?.trim() : null,
        otp_less_cod_button_text_color : postData?.ui_config?.otp_less_cod_button_text_color?.trim()?.length ? postData?.ui_config?.otp_less_cod_button_text_color?.trim() : null,
        otp_less_prepaid_button_animate : postData?.ui_config?.otp_less_prepaid_button_animate ?? null,
        otp_less_prepaid_button_color : postData?.ui_config?.otp_less_prepaid_button_color?.trim()?.length ? postData?.ui_config?.otp_less_prepaid_button_color?.trim() : null,
        otp_less_prepaid_button_text : postData?.ui_config?.otp_less_prepaid_button_text?.trim()?.length ? postData?.ui_config?.otp_less_prepaid_button_text?.trim() : null,
        otp_less_prepaid_button_text_color : postData?.ui_config?.otp_less_prepaid_button_text_color?.trim()?.length ? postData?.ui_config?.otp_less_prepaid_button_text_color?.trim() : null,
      }
    }
    // postData['discount_list'] = discountList;
    if (
      postData.pickup_config &&
      postData.pickup_config.cut_off_time.length !== 5
    ) {
      postData.pickup_config.cut_off_time = moment(
        postData.pickup_config.cut_off_time
      ).format('HH:mm');
    }
    if (imageUrl && imageUrl.length > 0) {
      postData['logo_url'] = imageUrl;
    }

    if (
      postData &&
      postData.support_phone &&
      postData.support_phone.phone_number
    ) {
      postData.support_phone['country_code'] = '91';
    }
    mutation.mutate(postData);
    // dispatch(
    //   updateSellerCheckoutConfig(
    //     postData,
    //     history,
    //     '/settings/manage-checkout/shipping'
    //   )
    // );
  };

  const deleteDiscount = (index) => {
    if (index === 0) {
      setDiscountList([]);
      return;
    }
    setDiscountList(discountList.splice(index, 1));
  };

  const onStartOk = (value) => {
    setStartTime(moment(value).format());
  };

  const onEndOk = (value) => {
    setEndTime(moment(value).format());
  };

  const saveDiscount = () => {
    if (startTime && endTime && discount.length > 0 && description.length > 0) {
      setDiscountList([
        {
          discount_code: discount,
          description: description,
          start_datetime: startTime,
          end_datetime: endTime,
          is_active: true,
        },
        ...discountList,
      ]);
    }
  };

  const onSelectDiscount = (e, index) => {
    let discount = discountList[index];
    discount.is_active = e.target.checked;
    setDiscountList(discountList.splice(index, 1, discount));
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (data) {
      setImageUrl(data.logo_url);
    }
  }, [data]);

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return true;
      }
      const isLt2M = file.size < 300000;
      if (!isLt2M) {
        // Upload.LIST_IGNORE;
        message.error('Image must smaller than 300kb!');
        return true;
      }
      setUploadFile([file]);
      return false;
    },
  };

  useEffect(() => {
    const uploadImage = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', uploadFile[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/upload/img/`,
        formData,
        {
          headers: {
            Authorization: auth,
          },
        }
      );
      setLoading(false);

      if (response.data.success) {
        notification.success({
          message: 'Uploaded Successfully!',
        });
        setImageUrl(response.data.data.url);
      } else {
        notification.error({
          message: response.data.err,
        });
      }
    };
    if (uploadFile.length > 0) {
      uploadImage();
    }
  }, [uploadFile]);


  // const [gstNoEnable, setGstNoEnable] = useState(false);

    const gstNoHandle = () => {
        const formData = form.getFieldsValue();
        if(formData?.allow_billing_address === false && formData?.gst_enable){          
            form.setFieldsValue({
                allow_billing_address: true,
            })
        }
    }


  return (
    <Container>
      <Header
        title="General Settings"
        submitForm={submitForm}
        sellerConfigDetails={data}
      />
      <CustomForm layout="vertical" form={form} onFinish={submitForm}>
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
          <Form.Item
            label="Store Name"
            name="display_store_name"
            rules={[
              {
                required: true,
                message: 'Please input your company / store name!',
              },
            ]}
          >
            <Input maxLength={29} />
          </Form.Item>

          <FieldDescription>Provided Store Name will be used in SMS/Whatsapp communications sent to customers for OTP validation & order confirmation (max 29 characters)</FieldDescription>
          
          {onboardVersion && onboardVersion !== 'v3' && 
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Email is required' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                whitespace: true,
                message: 'Cannot enter spaces',
              },
            ]}
          >
            <Input defaultValue={email} />
          </Form.Item>
          }   
          {/* <Form.Item
            label="Fetch Address"
            name="fetch_user_addresses"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'baseline',
            }}
            valuePropName="checked"
          >
            <Switch
              onChange={onChange}
              style={{ marginLeft: '50px', width: '50px' }}
            />
          </Form.Item> */}
        </Col>
          <Col xl={4} lg={4} md={24} sm={24} x={24}>
            <Form.Item label="Upload Logo">
              <Upload
                {...uploadProps}
                multiple={false}
                maxCount={1}
                showUploadList={false}
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}></Col>
          {/* <Divider /> */}
          {/* <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SpaceBetweenContainer>
              <FlexColContainer>
                <SubHeader>Discount List</SubHeader>
                <SubText style={{ marginTop: '12px' }}>
                  Manage all your discount and only 5 discount can be acitve
                </SubText>
              </FlexColContainer>
              <CreateDiscountButton onClick={() => setCreateDiscount(true)}>
                Create Discount
              </CreateDiscountButton>
            </SpaceBetweenContainer>
          </Col> */}

          {/* {createDiscount && (
            <DiscountContainer>
              <Row gutter={[16, 16]}>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                  <Form.Item
                    label="Discount Code"
                    name="discount"
                    onChange={(e) => setDiscount(e.target.value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                  <Form.Item
                    label="Discount Description"
                    onChange={(e) => setDescription(e.target.value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item label="Start Date & Time">
                    <DatePicker
                      onOk={onStartOk}
                      style={{ width: '100%' }}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                </Col>  
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item label="End Time & Time">
                    <DatePicker
                      onOk={onEndOk}
                      style={{ width: '100%' }}
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <FlexEndContainer>
                <PickrrButton
                  style={{
                    width: '136px',
                    height: '40px',
                    marginRight: '20px',
                    background: 'white !important',
                  }}
                  onClick={() => setCreateDiscount(false)}
                >
                  Cancel
                </PickrrButton>
                <PickrrButton
                  style={{ width: '136px', height: '40px' }}
                  onClick={saveDiscount}
                >
                  Save
                </PickrrButton>
              </FlexEndContainer>
            </DiscountContainer>
          )} */}

          {/* {discountList &&
            discountList.map((discount, index) => (
              <DiscountListContainer>
                <Row gutter={[16, 16]}>
                  <Col xl={14} lg={14} md={24} sm={24} x={24}>
                    <FlexBox style={{ width: '100%' }}>
                      <Checkbox
                        style={{ marginRight: '10px', marginTop: '2px' }}
                        checked={discount.is_active}
                        onChange={(e) => onSelectDiscount(e, index)}
                      />
                      <FlexColContainer>
                        <SubText
                          style={{ fontWeight: 600, marginBottom: '10px' }}
                        >
                          {discount.discount_code}
                        </SubText>
                        <SubText>{discount.description}</SubText>
                      </FlexColContainer>
                    </FlexBox>
                  </Col>
                  <Col xl={6} lg={6} md={24} sm={24} x={24}>
                    <FlexColContainer
                      style={{ justifyContent: 'center', height: '100%' }}
                    >
                      <SubText>
                        Starts -{' '}
                        {moment(discount.start_datetime).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </SubText>
                      <SubText>
                        Ends -{' '}
                        {moment(discount.end_datetime).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </SubText>
                    </FlexColContainer>
                  </Col>
                  <Col xl={4} lg={4} md={24} sm={24} x={24}>
                    <FlexColContainer
                      style={{
                        justifyContent: 'center',
                        height: '100%',
                        alignContent: 'end',
                      }}
                    >
                      <FlexBox style={{ justifyContent: 'end' }}>
                        <img
                          src="https://d10srchmli830n.cloudfront.net/1638522835860_edit.svg"
                          alt="edit"
                          style={{ marginRight: '10px' }}
                        />
                        <img
                          src="https://d10srchmli830n.cloudfront.net/1638522882002_delete.svg"
                          alt="delete"
                          style={{ cursor: 'pointer' }}
                          onClick={() => deleteDiscount(index)}
                        />
                      </FlexBox>
                    </FlexColContainer>
                  </Col>
                </Row>
              </DiscountListContainer>
            ))} */}

          <Divider />
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SubHeader>Links</SubHeader>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Shipping Policy" name="shipping_policy_url">
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Refund Policy" name="refund_policy_url">
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Privacy Policy" name="privacy_policy_url">
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Terms of  Service" name="tnc_url">
              <Input />
            </Form.Item>
          </Col>
          {onboardVersion && onboardVersion !== 'v3' && 
            <>
              <Divider />
              <Col xl={24} lg={24} md={24} sm={24} x={24}>
                <SubHeader>Support</SubHeader>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Form.Item
                  label="Mobile Number"
                  name={['support_phone', 'phone_number']}
                  rules={[
                    { required: true, message: 'Mobile number is required' },
                    { len: 10, message: 'Please enter a valid mobile number' },
                    { whitespace: true, message: 'Cannot enter spaces' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Form.Item
                  label="Email Address"
                  name="support_email"
                  rules={[
                    {
                      required: true,
                      message: 'Email is required',
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      whitespace: true,
                      message: 'Cannot enter spaces',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </>
          }
          
          <Divider />
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
{/*             <Form.Item label="Facebook Pixel ID" name="fb_pixel">
              <Input />
            </Form.Item> */}
            <Form.Item label="Send Order Confirmation" name="send_order_confirmation" valuePropName="checked" style={{marginBottom:'-15px'}}>
              <Switch />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Form.Item label="Enable Headless" name="enable_headless" valuePropName="checked" style={{marginBottom:'-15px'}}>
              <Switch />
            </Form.Item>
          </Col>
          {/* <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Form.Item label="Make Email Mandatory" name="show_email" valuePropName="checked" style={{marginBottom:'-15px', marginTop:'20px'}}>
              <Switch />
            </Form.Item>
          </Col> */}
          <Divider />
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Company ID" name="company_id">
              <Input disabled={data?.company_id}/>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item 
            label="Onboard Version" 
            name="onboard_version"
            rules={[
              {
                required: true,
                message: 'This field is required.',
              }
            ]}
            >
              <Select>
                <Option value="v1">Version 1</Option>
                <Option value="v3">Version 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Divider />
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SubHeader style={{fontSize : '16px'}}>Pre Login Banner</SubHeader>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Text" name={["banner_details","pre_login","text"]}>
              <Input maxLength={80} placeholder='Maximum 80 character'/>
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Background color" name={["banner_details","pre_login","background_color"]}>
              <Input placeholder='Hex code' />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Text color" name={["banner_details","pre_login","text_color"]}>
              <Input placeholder='Hex code'/>
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SubHeader style={{fontSize : '16px'}}>Post Login Banner</SubHeader>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Text" name={["banner_details","post_login","text"]}>
              <Input maxLength={80} placeholder='Maximum 80 character'/>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="Background color" name={["banner_details","post_login","background_color"]}>
              <Input placeholder='Hex code' />
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Text color" name={["banner_details","post_login","text_color"]}>
              <Input placeholder='Hex code'/>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Form.Item
                            label="GST number"
                            name="gst_enable"
                            valuePropName="checked"
                            >
                            <Switch onChange={gstNoHandle} />
                        </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Form.Item
              label="Billing Address"
              name="allow_billing_address"
              valuePropName="checked"
              >
              <Switch/>
          </Form.Item>
          </Col>
          <Divider />
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SubHeader style={{fontSize : '16px'}}>Otp-less COD button</SubHeader>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Button text"
              name={['ui_config','otp_less_cod_button_text']}
              >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Form.Item
              label="Button text color"
              name={['ui_config','otp_less_cod_button_text_color']}
              >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Button color"
              name={['ui_config','otp_less_cod_button_color']}
              >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Button animate"
              name={['ui_config','otp_less_cod_button_animate']}
              valuePropName="checked"
              >
              <Switch/>
            </Form.Item>
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SubHeader style={{fontSize : '16px'}}>Otp-less Prepaid button</SubHeader>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Button text"
              name={['ui_config','otp_less_prepaid_button_text']}
              >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <Form.Item
              label="Button text color"
              otpLessPrepaidButtonText
              name={['ui_config','otp_less_prepaid_button_text_color']}
              >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Button color"
              name={['ui_config','otp_less_prepaid_button_color']}
              >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Button animate"
              name={['ui_config','otp_less_prepaid_button_animate']}
              valuePropName="checked"
              >
              <Switch/>
            </Form.Item>
          </Col>

          <Divider />
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Enable exit form"
              name="exit_form_enabled"
              valuePropName="checked"
              >
              <Switch/>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label="Enable Single Exit Reasons"
              name="allow_single_exit_reason"
              valuePropName="checked"
              >
              <Switch/>
            </Form.Item>
          </Col>
          <Form.List name="exit_response_details">
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(( field , index) => (
                                <>
                                    <Col span={20}>
                                <Form.Item
                                    label = {`Exit response ${index+1}`}
                                    name={[field.name]}
                                >
                                    <Input/>
                                </Form.Item>
                                </Col>
                                {fields.length > 1 &&  
                                <Col style={{display :'flex', alignItems : 'center'}} span={4}>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Col>}
                                </>
                            ))}
                            
                            {fields.length < 5  ?
                                <Col span={24}>
                                <Form.Item>
                                <PickrrButton
                                    onClick={() => add()} 
                                    block 
                                    icon={<PlusOutlined />}
                                    color="checkout-blue"
                                    style={{width: 'max-content'}}
                                >
                                    Add another option
                                </PickrrButton>
                                </Form.Item>
                                </Col>
                                :
                                <Col span={24}>
                                <span style={{color : '#0C64DB'}}>*Note: You can add a maximum of 5 Exit form options.</span>
                                </Col>
                            }
                            </>
                        )}
                    </Form.List>  
        </Row>
        <Space>
          <PickrrButton htmlType="submit" color="checkout-blue" disabled={savingSettings} style={{minWidth:"150px"}} >
            { savingSettings ? <Spin indicator={antIcon} /> : "Save & Proceed" }
          </PickrrButton>
        </Space>
      </CustomForm>
    </Container>
  );
};

export default GeneralSettings;
