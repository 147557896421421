import { Form, Input,Radio,Checkbox } from "antd";
import React, {useEffect, useState} from "react";
import { CriteriaContainer, HeadingText } from "./styled";

const QuantityCriteria = ({criteriaUpdate, setCriteriaUpdate,discountType,selectTypes}:any) => {
  const [isError, setIsError] = useState(false);
  const Regex = new RegExp(/^(((?!0)[0-9]*))$/);

  const handleValidate = (e: any) => {
    setCriteriaUpdate({
      ...criteriaUpdate, 
      minimumQty:e.target.value
    })
    if(e.target.value){
      setIsError(Regex.test(e.target.value));
    }else {
      setIsError(false);
    }
  };
  

  const handleIsItemLevel = () => {
    setCriteriaUpdate({
      ...criteriaUpdate, 
      itemLevel: !criteriaUpdate.itemLevel
      }
    )
  } 


  return (
    <CriteriaContainer>
      <HeadingText>Minimum Quantity of products</HeadingText>
      <Form.Item
        name={"minQtyProduct"}
        rules={[
          {
            required: isError,
            pattern: Regex,
            message: "Enter valid value",
          },
        ]}
      >
        <Input maxLength={6} placeholder="Enter minimum quantity" onChange={handleValidate}/>
      </Form.Item>
      {(discountType==='bxgy'|| discountType==='prdAmt'||(selectTypes.tieredType && selectTypes.tieredType=='amount')|| discountType=='freebie' ) &&
      <Form.Item
        name={"minQtyItemLevel"}
        valuePropName="checked"
      >
          <Checkbox value="itemLevel" onChange={handleIsItemLevel}>Item level</Checkbox>
      </Form.Item>}
    </CriteriaContainer>
  );
};

export default QuantityCriteria;
