import axios from 'axios';
import { GetAuthToken } from 'globalQuries';

export const createNewRule = async (data) => {
  const auth = GetAuthToken();
  const response = await axios.post(
    `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/add/`,
    data,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data;
};

export const updateRule = async (data) => {
  const auth = GetAuthToken();
  const response = await axios.put(
    `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/update/`,
    data,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data;
};

export const fetchCitiesList = async () => {
  const response = await axios.get(
    `https://pickrr.com/location/get-city-list/`
  );
  return response;
};
