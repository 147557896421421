import styled from "styled-components";
import { Tabs } from "antd";

export const CustomTabs = styled(Tabs)`
    .ant-tabs-tab-btn {
        padding: 0px 10px;
    }
    .ant-tabs-nav-list {
        border-bottom: 1px solid rgba(0, 11, 52, 0.3) !important;
    }
    padding-left:20px
`
export const Text = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #263f97;
  cursor: pointer;
`;

export const UploadContainer = styled.div`
    .ant-upload.ant-upload-select{
        width : 100% !important;
        height : 50% !important;
    }
`
