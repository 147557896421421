import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { DataContext } from "context/DataProvider";
import { CustomDownloadButton, TextStyle } from './style'
import moment from 'moment'
import { useMutation } from 'react-query';
import { fetchDeductionHistory } from './script';
import { notification } from 'antd';
import CustomReactTable from 'UiComponents/Table';
import { FlexRowTw, TableContainerTw } from 'pages/Orders/OrdersTab/styles';
import { CustomReactInput } from 'UiComponents/Select';
import { ExportIcon, FilterIcon, ToastErrorCross, ToastSuccessTick } from 'images/icons';
import axios from 'axios';
import { downloadFile } from 'components/UIElements/function';
import "../../../pages/Loader.css"
import FilterNew from 'pages/AbandonedCart/FilterNew';
import { PaymentGatewayEnum } from 'context/Enums';

const TransactionTypeMapping: any = {
    'Debit': 'DEBIT',
    'Credit': 'CREDIT',
  }

const NewDeductionHistory = ({ fromDate, toDate }: any) => {

    const { sellerId } = useContext(DataContext)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [deductionData, setDeductionData] = useState<any>({})
    const [searchValue, setSearchValue] = useState([]);
    const [searchKey, setSearchKey] = useState<string>('client_order_id')
    const [inputValue, setInputValue] = useState<any>('')    
    const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)
    const [showTransactionTypeFilters, setShowTransactionTypeFilters] = useState<boolean>(false);
    const [transactionType, setTransactionType] = useState<string>('');

    const mutation = useMutation(fetchDeductionHistory, {
        onMutate: (variables) => {
            // A mutation is about to happen!
            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },
        onError: (error: any, variables, context) => {
            notification.error({
                message: error
            })
        },
        onSuccess: (message, variables, data) => {
            // to do after success
        },
    });

    const handleDownload = async(setIsDataDownloading:any) => {
        let postData : any = {
          seller_id: sellerId,
          start_date: fromDate,
          end_date: toDate
        }
        try{
          const response = await axios.post(`${process.env.REACT_APP_FASTRR_FINANCE}/api/v1/bill/export`,
          postData
          )
          if(response?.data){
            downloadFile(response.data, 'Deduction history.csv')
            toast.success('Download successful! Your file is ready', {
                icon: ToastSuccessTick
              });
            setIsDataDownloading(false)
          }
        }catch (error) {
          toast.error(error.message, {
            icon: ToastErrorCross
          })
          setIsDataDownloading(false)
        }
      }



      const getRemarks = (value : any) => {
        let remarks = ''
        if (value) {
          switch (value.toLowerCase().trim()) {
            case 'order commission':
              remarks = 'Commission deducted for order'
              break;
            case 'wallet recharge':
              remarks = 'Amount paid'
              break;
            case 'order bill recalculate':
              remarks = 'Commission recalculated for order'
              break;
            case 'order cancellation':
              remarks = 'Commission refunded for cancelled order'
              break;
            default : 
              remarks = value
          }
        }else{
          remarks = '-'
        }
        return remarks
      }

    const onSearchKeyChange = (value: string) => {
        setSearchKey(value)
    }

    const onSearch = (value: any) => {
        setSearchValue(value !== "" ? value.replaceAll(" ","").split(",") : [])
        setPageNumber(1)
    }

    const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        setInputValue(event.target.value)
        if (!event.target.value) {
            onSearch('')
        }
    }

    const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value) {
            onSearch(event.target.value)
        }
    }

    const addOnBefore = [
        { value: 'client_order_id', label: 'Client Order ID' },
        { value: 'platform_order_id', label: 'Platform Order ID' },
        { value: 'fastrr_order_id', label: 'Fastrr Order ID' }
      ];


    useEffect(() => {
        let postData: any = {
            seller_id: sellerId,
            start_date: fromDate,
            end_date: toDate,
            transaction_type: transactionType[0]? transactionType[0]: undefined,
            page_number: pageNumber - 1,
            page_limit: 20,
        }
        if (searchValue.length > 0) {
            postData = searchKey === "client_order_id" ?
                {
                    ...postData,
                    client_order_ids: searchValue,
                }
                : searchKey === "platform_order_id" ?
                    {
                        ...postData,
                        platform_order_ids: searchValue,
                    }
                    :
                    {
                        ...postData,
                        fastrr_order_ids: searchValue,
                    }
        }
        mutation.mutate(postData)
    }, [pageNumber])

    useEffect(() => {
        setPageNumber(1)
        let postData: any = {
            seller_id: sellerId,
            start_date: fromDate,
            end_date: toDate,
            transaction_type: transactionType[0]? transactionType[0]: undefined,
            page_number: 0,
            page_limit: 20,
        }
        if (searchValue.length > 0) {
            postData = searchKey === "client_order_id" ?
                {
                    ...postData,
                    client_order_ids: searchValue,
                }
                : searchKey === "platform_order_id" ?
                    {
                        ...postData,
                        platform_order_ids: searchValue,
                    }
                    :
                    {
                        ...postData,
                        fastrr_order_ids: searchValue,
                    }
        }
        mutation.mutate(postData)
    }, [sellerId, fromDate, toDate, searchValue, searchKey, transactionType])

    useEffect(() => {
        setDeductionData(mutation.data ? mutation?.data : {})
    }, [mutation?.data])


    const columns: any = [
        {
            Header: 'Client Order ID',
            accessor: 'client_order_id',
            disableSortBy: true,
            
            Cell: ({ value }: any) => {
                {/*// @ts-ignore */}
                return <TextStyle className='min-w-32 py-2 pl-2'>
                    {value ? value : '-'}
                </TextStyle>
            }
        },
        {
            Header: 'Date & Time',
            accessor: 'settled_at',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    <TextStyle className='min-w-32'>{moment(value).format('Do MMMM, h:mm a')}</TextStyle>
                </TextStyle>
            )
        },
        {
            Header: 'Payment Mode',
            accessor: 'order_payment_mode',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-32'>
                     {   
                     /*// @ts-ignore */
                        PaymentGatewayEnum[value?.toUpperCase()] ? PaymentGatewayEnum[value?.toUpperCase()]?.toUpperCase() : 
                        (value || 'COD')
                    }
                </TextStyle>
            )
        },
        {
            Header: 'Payment Gateway',
            accessor: 'payment_gateway',
            disableSortBy: true,
            Cell: ({ value }:any) => {
                return <TextStyle className='min-w-24'>
                    {   
                     /*// @ts-ignore */
                        PaymentGatewayEnum[value?.toUpperCase()] ? PaymentGatewayEnum[value?.toUpperCase()] : 
                        (value || 'COD')
                    }
                </TextStyle>
            }
        },
        {
            Header: 'Invoice Value',
            accessor: 'invoice_amount',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    {value ? `₹ ${value.toFixed(2)}` : '-'}
                </TextStyle>
            )
        },
        {
            Header: <span className='flex items-center relative'>
            Transaction Type <span onClick={() => { setShowTransactionTypeFilters(!showTransactionTypeFilters) }} className='cursor-pointer'> <FilterIcon /></span>
            {showTransactionTypeFilters && <div className='absolute' style={{ top: "35px", left: '-10px' }}>
                <FilterNew filters={Object.keys(TransactionTypeMapping)} filterKey={transactionType} setFilterKey={setTransactionType} multipleSelect={false} setIsOpen={setShowTransactionTypeFilters} setPageNumber={setPageNumber} mapping={TransactionTypeMapping} /></div>}

        </span>,
            accessor: 'transaction_type',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    {value ? value : '-'}
                </TextStyle>
            )
        },
        {
            Header: 'Commission Amount (Incl. taxes) ',
            accessor: 'amount',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                    {value ? `₹ ${value.toFixed(2)}` : '-'}
                </TextStyle>
            )
        },
        {
            Header: 'Remarks',
            accessor: 'remarks',
            disableSortBy: true,
            Cell: ({ value }: any) => (
                <TextStyle className='min-w-24'>
                     
              {`${getRemarks(value)}`}
            
                </TextStyle>
            )
        },
    ]



    return (
        <div>
            <TableContainerTw>
                <FlexRowTw className='justify-between'>
                    <FlexRowTw>
                        <CustomReactInput addOnBefore={addOnBefore} onChangeHandler={onChangeHandler} onPressEnterHandler={onPressEnterHandler} onSearchKeyChange={onSearchKeyChange} searchKey={searchKey} defaultValue={'Client Order ID'} inputValue={inputValue} setSearchTerm={setSearchValue}/>
                    </FlexRowTw>

                    <div className="flex items-center gap-3 py-4 h-8 w-max ml-8">
                                <CustomDownloadButton onClick={()=>{
                                    setIsDataDownloading(true)
                                    handleDownload(setIsDataDownloading )
                                    }} className='items-center' disabled={!deductionData?.result?.length||isDataDownloading}>
                                   {!isDataDownloading? <>
                                    <span className=''>
                                        <ExportIcon/>
                                    </span>
                                   <span>Download</span>
                                   </>:<div className='loader'/>}
                                </CustomDownloadButton>
                            </div>
                </FlexRowTw>


                
                
                <CustomReactTable
                    data={deductionData?.result || []}
                    loading={mutation?.isLoading}
                    columns={columns}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={deductionData?.totalElements}
                    checkboxes={false}
                    onSelectAll={() => { }}
                    pageSize={20}
                    selectedRowsKeys={[]}
                    defaultSortBy={[{ id: '', desc: false }]}
                    newState={''}
                    handleRowSelect={() => { }}
                    isChecked={false}
                    setHoveredRowIndex={() => { }}
                />

            </TableContainerTw>

        </div>
    )
}

export default NewDeductionHistory