import React, { useState, useEffect } from 'react';
import { Upload, Input, Button, notification, Alert } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
  SpaceBetweenContainer,
} from 'components/UIElements';
import { Text } from './style';

const { TextArea } = Input;

const DestinationPincode = ({
  destinationPincodeList,
  setDestinationPincodeList,
  destinationError,
}) => {
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorPincodesList, setErrorPincodesList] = useState([]);

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      setUploadFile([file]);
      return false;
    },
  };

  useEffect(() => {
    const uploadCSV = async () => {
      try {
      setLoading(true);
      const formData = new FormData();
      formData.append('pincodes', uploadFile[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/upload-csv/`,
        formData
      );
      setLoading(false);
      if (response.data.success) {
        if (response.data.data.err_list.length > 0) {
          notification.error({
            message: 'Invalid pincodes found.',
          });
        } else {
          notification.success({
            message: 'File uploaded successfully.',
          });
        }
        setDestinationPincodeList(response.data.data.list);
        setErrorPincodesList(response.data.data.err_list);
        setUploadFile([]);
      } else {
        notification.error({
          message: response.data.message,
        });
        setUploadFile([]);
      } 
    } catch (e) {
      notification.error({
        message: 'Something went wrong.',
      });
      setLoading(false)
    }
    };
    if (uploadFile.length === 1) {
      uploadCSV();
    }
  }, [setDestinationPincodeList, uploadFile]);

  return (
    <FlexColContainer>
      <SpaceBetweenContainer>
        <PickrrHeading size={5}>Select Pincode</PickrrHeading>
        <Button type="link" onClick={() => setDestinationPincodeList([])}>
          Reset
        </Button>
      </SpaceBetweenContainer>
      <TextArea
        placeholder="Add comma separated pincode list"
        rows={4}
        className="mb-16"
        value={destinationPincodeList.join(',')}
        onChange={(e) =>
          e.target.value
            ? setDestinationPincodeList(e.target.value.split(','))
            : setDestinationPincodeList([])
        }
      />
      {errorPincodesList.length > 0 && (
        <Alert
          message={`${
            errorPincodesList.length
          } pincode(s) found invalid. Please check the following pincode(s): ${errorPincodesList.join(
            ','
          )}`}
          type="error"
        />
      )}
      <PickrrHeading size={5}>or upload pincode list</PickrrHeading>
      <FlexBox className="mb-16">
        <Upload {...uploadProps} showUploadList={false}>
          <PickrrButton
            color="primary"
            style={{ textTransform: 'none' }}
            icon={<UploadOutlined />}
            className="mr-12"
            loading={loading}
          >
            Upload CSV
          </PickrrButton>
        </Upload>
        <a
          href="https://d10srchmli830n.cloudfront.net/1632747241375_Sample PinCode File.csv"
          download
        >
          <Text>
            <DownloadOutlined className="mr-8" /> Download Sample CSV File
          </Text>
        </a>
      </FlexBox>
      {destinationError && <h4 style={{ color: 'red' }}>{destinationError}</h4>}
    </FlexColContainer>
  );
};

export default DestinationPincode;
