export const discountSteps: string[] = [
    "Discount Type",
    "Configuration",
    "Criteria",
    "Functionality",
    "Discount validity",
  ];

  export const topHeading: any = {
    0: "Discount Type & Method",
    1: "Set Configuration",
    2: "Select Criteria & Conditions ",
    3: "Discount functionality",
    4: "Set Discount Validity",
  };

  export const discountTypeText: any = {
    prdAmt: "Amount Off Products",
    amtCart: "Amount Off Cart",
    tiered: "Tiered Discount",
    bxgy: "Buy X get Y",
    shipping: "Shipping",
    bundle: "Bundle",
  };

  export const tieredTypeText: any = {
    cart: "Cart Value",
    quantity: "Quantity",
  };

  export const discountConfigurationTypeText: any = {
    flat: "rs Flat",
    percent: "% Percentage",
  };

  export const customerType: any = {
    allCustomer: 'All Customer',
    specificCustomers: 'Specific Customers',
    tieredLimit: 'please make least one tiered',
    risk: 'Risk Customer',
    newCustomer:'New Customer',
    existingCustomer:'Existing Customer',
  }

  export const errorMessage: any = {
    userLimit: 'Usage limit per customer should be less than or equal to max usage of this discount',
    bundleProductSearch: 'Please select at least two products',
    tieredLimit: 'Please have at least one tier',
    bxgyLimit: 'Select at least one product/collection',
    criteriaProductLimit:'Please select at least one product',
    maxCartTotal: 'Max cart total could not be smaller than min cart total'
  }