import {AccessRole} from './type';

// images 
export const ROLE_IMGAGE_URL = "https://pickrr.s3.amazonaws.com:443/2024-05-26T09:59:49.340151_role.svg";
const PHONE_IMGAGE_URL = "https://pickrr.s3.amazonaws.com:443/2024-05-26T10:00:44.567704_phone.svg";
const NAME_IMGAGE_URL = "https://pickrr.s3.amazonaws.com:443/2024-05-26T10:01:04.522359_name.svg";
const EMAIL_IMGAGE_URL = "https://pickrr.s3.amazonaws.com:443/2024-05-26T10:01:22.749426_email.svg";


export const accessRoleData:any = {
    admin: ['Manage users - Access to control all the user roles', 'Dashboard', 'Orders', 'Finance', 'Discount', 'Settings'], 
    marketing: ["Abandoned Cart"], 
    finance: ['Finance', 'Refunds', 'Wallet', 'Settlement History'], 
    operations: ['Orders'], 
    CUSTOM: ["abandoned-cart", "finance", "orders", "dashboard", "settings/general-settings"]
}

export const roles:string[] = [
    'select',
    'admin', 
    'marketing', 
    'finance', 
    'operations', 
    'custom'
]

export const roleDescription:any = {
    admin: 'Admin gets the full control of Dashboard. Admin can manage users (add/update users, assign any role or data restriction), can grant full permissions to any user, including themselves, for any account or property for which they have this role.', 
    opration: 'User can view/update all pages related to orders.', 
    finance:'User can view/update all pages related to finance.', 
    marketing:'User can view/update all pages related to marketing.',
    custom: 'Admin can select access resources for this role as per their preference. Admin can mark Read only or Write access on selected resources.'
}

// radio for custom
export const customTypeAcces = [
    {label: 'Read Only', value: 'readOnly'}, 
    {label: 'Read/Write', value: 'readWrite'}, 
];


export const formConfig = [
    {
        type: 'text', 
        placeHolder: 'Enter Name', 
        label: 'Name',
        id: 'name', 
        icon: NAME_IMGAGE_URL,
        // errorMessage: 'Enter valid name'
    }, 
    {
        type: 'email', 
        placeHolder: 'Enter email', 
        label: '*Email',
        id: 'email', 
        icon: EMAIL_IMGAGE_URL, 
        errorMessage: 'Enter valid email'
    }, 
    {
        type: 'phone', 
        placeHolder: 'Enter phone', 
        label: '*Phone',
        id: 'phone', 
        icon: PHONE_IMGAGE_URL, 
        errorMessage: 'Enter valid phone'
    }, 
]

