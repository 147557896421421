import { Form, Input, Radio, Select, Upload } from "antd";
import { CustomInput, FlexBox } from "components/UIElements";
import React, { useEffect, useRef, useState } from "react";
import { UploadOutlined, DownloadOutlined,SearchOutlined } from "@ant-design/icons";
import {
  CustomSelect,
  FastrrButton,
  HeadingText,
  ProductCriteriaContainer,
  Text,
} from "./styled";
import ProductSearch from "../Configuration/ProductSearch";

const ProductCriteria = ({
  value,
  setValue,
  productList,
  setProductList,
  setSelectTypes,
  selectTypes,
  sellerConfigValues,
  tagsString,
  setTagsString
}: any) => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string | null>();
  const inputRef = useRef();

  const handleSelect = (e?: any) => {
    // setSelectTypes({...selectTypes, criteriaType:e})
    setValue(e);
    setProductList([]);
    // if (productList.length && e === "product") {
    // }
  };
  return (
    <ProductCriteriaContainer>
      <HeadingText>Criteria Applies to :</HeadingText>
      <Form.Item name="excludeProduct" initialValue={false}>
        <Radio.Group>
          <Radio value={false}>Include products</Radio>
          <Radio value={true}>Exclude products</Radio>
        </Radio.Group>
      </Form.Item>

      <HeadingText>Select Product Type</HeadingText>
      <Form.Item
        name="productType"
        // initialValue="product"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please select the Product Type",
        //   },
        // ]}
      >
        <CustomSelect
          height="big"
          placeholder="---select product---"
          onChange={(e) => handleSelect(e)}
          // allowClear
        >
          <Option value="product">Product</Option>
          <Option value="collection">Collection</Option>
          <Option value="tags">Tags</Option>
        </CustomSelect>
      </Form.Item>
      {value && value !=='tags' && (
        <ProductSearch
          selectedItems={productList}
          setSelectedItems={setProductList}
          type={value}
          sellerConfigValues = 
          {sellerConfigValues}
          bundleKey = {Math.random() * 1004}
        />
      )}
      {value && value==='tags' && (
        <div>
          <p style={{color:"#38446d",fontSize:'14px',marginLeft:'2px'}}>Enter your Tags in comma seperated format:</p>
        <CustomInput
        placeholder={`Enter the tags in csv format `}
        // suffix={<SearchOutlined />}
        ref={inputRef}
        value={tagsString}
        onChange={(e) => {
          setInputValue(e.target.value)
          setTagsString(e.target.value);
        }}
        // disabled={value!=='tags'}
      />
      </div>
      
      )}
    </ProductCriteriaContainer>
  );
};

export default ProductCriteria;
