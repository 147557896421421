import styled from 'styled-components'
import { Checkbox } from 'antd'

export const CustomCheckbox = styled(Checkbox)`
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #0C3784;
`

export const ApplyButton = styled.button`
    width: 70px;
    height: 29px;
    background: #0C64DB;
    border-radius: 5.4px;
    color: #FFF;
    border: none;
    font-size: 11px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.02em;
    cursor: pointer;
`

export const ResetButton = styled.h6`
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0C64DB;
    margin: 0;
    cursor: pointer;
`