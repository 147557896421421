import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import { FlexRow } from './styled';
import SelectRuleType from './SelectRuleTypeCard';
import AddCriteria from './AddCriteria';
import EnterValue from './EnterValue';
import { LeftOutlined, CloseOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { FlexColCenterContainer } from 'components/UIElements';
import {
  CustomModal,
  CancelButton,
  BackButton,
  NextButton,
  Heading,
  SubHeading,
} from './styled';
import { createNewRule, updateRule } from './functions';
import { fetchSellerCheckoutConfig } from './functions';
import { GetAuthToken } from 'globalQuries';
import RuleOperation from './RuleOperation';

const { Step } = Steps;

const CreateRuleModal = (props) => {
  const queryClient = useQueryClient();

  const {
    data: configData,
    isLoading,
    isSuccess,
  } = useQuery('configData', fetchSellerCheckoutConfig);

  const postRuleMutation = useMutation(createNewRule, {
    onError: (error) => {
      if (
        error.response.data.message === 'Rule for this priority already exists.'
      ) {
        notification.error({ message: error.response.data.message });
      } else {
        notification.error({ message: 'Something went wrong.' });
      }
    },
    onSuccess: () => {
      setIsRuleDone(true);
    },
  });

  const updateRuleMutation = useMutation(updateRule, {
    onError: () => {
      notification.error({ message: 'Something went wrong.' });
    },
    onSuccess: () => {
      props.handleQueryReFetch();
      notification.success({ message: 'Updated existing rule.' });
      handleCancel();
    },
  });

  const weightListArray = props?.editData?.determiningFactors?.weight?.length
    ? props?.editData?.determiningFactors?.weight
    : undefined;

    const codCountBlockArray = props?.editData?.determiningFactors?.cod_count_block?.length
    ? props?.editData?.determiningFactors?.cod_count_block
    : undefined;

    const codValueBlockArray = props?.editData?.determiningFactors?.cod_value_block?.length
    ? props?.editData?.determiningFactors?.cod_value_block
    : undefined;

  const invoiceListArray = props?.editData?.determiningFactors?.invoiceValue
    ?.length
    ? props?.editData?.determiningFactors?.invoiceValue
    : undefined;

  const laneListArray = props?.editData?.determiningFactors?.lane?.length
    ? props?.editData?.determiningFactors?.lane.map((lane, index) => {
        return {
          origin_cities: lane.source,
          destination_cities: lane.destination,
          id: index,
        };
      })
    : undefined;

  const zoneListArray = props?.editData?.determiningFactors?.zone?.length
    ? props?.editData?.determiningFactors?.zone
    : undefined;

  const pickupPincodeArray = props?.editData?.determiningFactors
    ?.pickup_pincode?.length
    ? props?.editData?.determiningFactors?.pickup_pincode
    : undefined;

  const destinationPincodeArray = props?.editData?.determiningFactors
    ?.destinationPincode?.length
    ? props?.editData?.determiningFactors?.destinationPincode
    : undefined;

  const phoneNumbersArray = props?.editData?.determiningFactors?.block_phone_no;

  const [current, setCurrent] = useState(0);
  const [ruleName, setRuleName] = useState(props?.editData?.type ?? 'PAYMENT_MODE_DISABLE');
  const [productList, setProductList] = useState(
    props?.editData?.determiningFactors?.productSku?.length
      ? props?.editData?.determiningFactors?.productSku
      : []
  );
  const [pickupPincodeList, setPickupPincodeList] = useState(
    pickupPincodeArray?.length ? pickupPincodeArray : []
  );
  const [destinationPincodeList, setDestinationPincodeList] = useState(
    destinationPincodeArray?.length ? destinationPincodeArray : []
  );
  const [weightList, setWeightList] = useState(
    weightListArray?.length ? weightListArray : [{}]
  );
  const [codCountBlockList, setCodCountBlockList] = useState(codCountBlockArray?.length ? codCountBlockArray: [{}]);
  const [codValueBlockList, setCodValueBlockList] = useState(codValueBlockArray?.length ? codValueBlockArray: [{}]);
  const [invoiceValueList, setInvoiceValueList] = useState(
    invoiceListArray?.length ? invoiceListArray : [{}]
  );
  const [zoneList, setZoneList] = useState(
    zoneListArray?.length ? zoneListArray : []
  );
  const [laneList, setLaneList] = useState(
    laneListArray?.length ? laneListArray : [{}]
  );
  const [riskProfile, setRiskProfile] = useState(
    props?.editData?.determiningFactors?.risk_profile_config?.risk_profile_high ?? false
  );
  const [phoneNumberList, setPhoneNumberList] = useState(
    phoneNumbersArray?.length ? phoneNumbersArray : []
  );

  const [applyMaxValue, setApplyMaxValue] = useState(
    props?.editData?.apply_maximum_value ?? true
  );
  const [ruleValuePercent, setRuleValuePercent] = useState(
    props?.editData?.rule_value_percent ?? 0
  );
  const [ruleValueAbsolute, setRuleValueAbsolute] = useState(
    props?.editData?.rule_value_absolute ?? 0
  );
  const [fastrrShipping, setFastrrShipping] = useState(
    props?.editData?.shipping?.fastrr ?? null
  );
  const [noRushShipping, setNoRushShipping] = useState(
    props?.editData?.shipping?.no_rush ?? null
  );
  const [standardShipping, setStandardShipping] = useState(
    props?.editData?.shipping?.standard ?? null
  );
  const [isRuleDone, setIsRuleDone] = useState(false);
  const [PAYMENT_MODE_DISABLE,setShippingDisable] =useState(props?.editData?.edd_config?props?.editData?.edd_config:{"show_rush": true,"show_express": true,"show_standard": true})
  const [payment_modes_discount,setPaymentModesDiscount] = useState({})
  

  const [utmList, setUtmList] = useState(
    props?.editData?.determiningFactors?.utm_param?.length 
    ? props?.editData?.determiningFactors?.utm_param
    : [{}]
  );

  const [productTagsList, setProductTagsList] = useState();

  const [productCollectionList, setProductCollectionList] = useState(
    props?.editData?.determiningFactors?.product_collection_id?.length
    ? props?.editData?.determiningFactors?.product_collection_id
    : []
  );
  const [selectedRiskTags, setSelectedRiskTags] = useState()
  const [riskType, setRiskType] = useState("risk_profile")
  const [partialCodShipping, setPartialCodShipping] = useState(false);
  const [patialType, setPatialType] = useState('percentage');
  const [partialValue, setPartialValue] = useState(0);
  const [hiddenPaymentMethods, setHiddenPaymentMethods] = useState('cash on delivery');
  const [hiddenShippingMethods, setHiddenShippingMethods] = useState('standard');
  const [customerTagList, setCustomerTagList] = useState();
  const [customerEmailList, setCustomerEmailList] = useState(props.editData?.determiningFactors?.customerEmail);
  const [destinationCountryCodeList, setDestinationCountryCodeList] = useState(props.editData?.determiningFactors?.customerTag);
  const [destinationProvinceCodeList, setDestinationProvinceCodeList] = useState(props.editData?.determiningFactors?.destinationProviceCode);
  const [deliveryOptionList, setDeliveryOptionList] = useState(props.editData?.determiningFactors?.deliveryOption);
  

    useEffect(() => {
      if(props.editData?.partial_cod_config){
        if(props?.editData?.partial_cod_config?.rule_value_absolute){
          const data = 
          props?.editData?.partial_cod_config?.rule_value_absolute ? 
          props?.editData?.partial_cod_config?.rule_value_absolute : 0;
          setPartialValue(data);
          setPatialType('absolute');
          
        }else {
          const data = 
          props?.editData?.partial_cod_config?.rule_value_percent ? 
          props?.editData?.partial_cod_config?.rule_value_percent : 0;
          setPartialValue(data);
          setPatialType('percentage');
        } 
      }
      if(props.editData?.partial_cod_config?.include_shipping){
        const data = 
        props.editData.partial_cod_config?.include_shipping ? 
        props?.editData?.partial_cod_config?.include_shipping : false;
        setPartialCodShipping(data);
      }

      if(props?.editData?.determiningFactors?.risk_profile_config?.risk_tags?.length){
        const data =  
        props?.editData?.determiningFactors?.risk_profile_config?.risk_tags?.length ?
        props?.editData?.determiningFactors?.risk_profile_config?.risk_tags
        : [];
        setSelectedRiskTags(data);
        setRiskType("risk_tags")
      }

      if(props?.editData?.determiningFactors?.productTag?.length){
        const data =  
        props?.editData?.determiningFactors?.productTag?.length 
        ? props?.editData?.determiningFactors.productTag 
        : []
        setProductTagsList(data)
        
      }
    }, [])
  

  const handleBack = () => {
    if (current > 0) {
      const newCurrent = current - 1;
      setCurrent(newCurrent);
    }
  };


  useEffect(() => {
      setRuleName(props?.editData?.type ?? 'PAYMENT_MODE_DISABLE');
      setCustomerTagList(props.editData?.determiningFactors?.customerTag)
  }, [props.editData?.determiningFactors])

  useEffect(()=>{
    if (props.editData?.payment_modes_discount){
      setPaymentModesDiscount(props.editData?.payment_modes_discount);
    }
  },[props.editData?.payment_modes_discount])

  const handleNext = () => {

    let pickUpString = pickupPincodeList.length
      ? pickupPincodeList.join()
      : null;

    let destinationString = destinationPincodeList
      ? destinationPincodeList.join()
      : null;
      
    let phoneNumberString = phoneNumberList.length
      ? phoneNumberList.join()
      : null;

    let productSkuString = productList.length 
    ? productList.join() 
    :  null;

    let phoneNumberError=false;
    let pickupError = false;
    let destinationError = false;
    let singleValueExpectedError = false;
    let productSkuError = false;

    if (current === 1) {
      if (productSkuString) {
        console.log('Heaby', productSkuString)
        if (
          productSkuString[productSkuString.length - 1] === ',' ||
          productSkuString[0] === ',' ||
          productSkuString.match(/\s+/g)
        ) {
          productSkuError = true;
          notification.error({
            message: 'Please enter product sku list in correct format.',
          });
        } else {
          productSkuError = false;
        }
      } else {
        productSkuError = false;
      }

      if (pickUpString) {
        if (
          pickUpString[pickUpString.length - 1] === ',' ||
          pickUpString[0] === ',' ||
          !pickUpString.match(/^([1-9][0-9]{5}\,?)+$/g)
        ) {
          pickupError = true;
          notification.error({
            message: 'Please enter pickup pincodes in correct format.',
          });
        } else {
          pickupError = false;
        }
      } else {
        pickupError = false;
      }

      if (destinationString) {
        if (
          destinationString[destinationString.length - 1] === ',' ||
          destinationString[0] === ',' ||
          !destinationString.match(/^([1-9][0-9]{5}\,?)+$/g)
        ) {
          destinationError = true;
          notification.error({
            message: 'Please enter destination pincodes in correct format.',
          });
        } else {
          destinationError = false;
        }
      } else {
        destinationError = false;
      }

      if (phoneNumberString) {
        if (
          phoneNumberString[phoneNumberString.length - 1] === "," ||
          phoneNumberString[0] === "," ||
          !phoneNumberString.match(/^([0-9]{10}\,?)+$/g)
        ) {
          phoneNumberError = true;
          notification.error({
            message: "Please enter phone numbers in correct format.",
          });
        } else {
          phoneNumberError = false;
        }
      } else {
        phoneNumberError = false;
      }

      if (
        riskProfile ||
        productList.length ||
        zoneList.length ||
        pickupPincodeList.length ||
        destinationPincodeList.length ||
        phoneNumberList.length ||
        (invoiceValueList.length &&
          invoiceValueList[0].min >= 0 &&
          invoiceValueList[0].max) ||
        (weightList.length && weightList[0].min && weightList[0].max) ||
        (codCountBlockList?.length && codCountBlockList[0].count > 0 && codCountBlockList[0].occurence_window_in_seconds)||
        (codValueBlockList?.length && codValueBlockList[0].value > 0 && codValueBlockList[0].occurence_window_in_seconds)||
        (laneList?.length &&
          laneList?.[0]?.origin_cities &&
          laneList?.[0]?.destination_cities) ||
          utmList.length ||
          productTagsList.length ||
          productCollectionList?.length
      ) {
        singleValueExpectedError = false;
      } else {
        singleValueExpectedError = true;
        notification.error({ message: 'Select atleast one criteria.' });
      }

      if (
        !pickupError &&
        !destinationError &&
        !singleValueExpectedError &&
        !phoneNumberError &&
        !productSkuError
      ) {
        // if (ruleName === "DELIVERY_MODE_DISABLE") {
        //   handleCreate();
        // } else {
          const newCurrent = current + 1;
          setCurrent(newCurrent);
        // }
      }
    } else if (current === 2) {
      handleCreate();
    }else if (current === 0) {
      const newCurrent = current + 1;
      setCurrent(newCurrent);
    }
  };

  const handleCancel = () => {
    props.handleQueryReFetch();
    props.handleCloseModal();
    setCurrent(0);
    setRuleName('');
    setProductList([]);
    setPickupPincodeList([]);
    setDestinationPincodeList([]);
    setPhoneNumberList([]);
    setWeightList([]);
    setInvoiceValueList([]);
    setZoneList([]);
    setLaneList([]);
    setRiskProfile(null);
    setApplyMaxValue(true);
    setRuleValueAbsolute(0);
    setRuleValuePercent(0);
    setFastrrShipping(null);
    setNoRushShipping(null);
    setStandardShipping(null);
    setIsRuleDone(false);
    setPaymentModesDiscount({});
    setProductCollectionList([]);
    setUtmList([]);
    setProductTagsList([]);
    setRiskType("risk_profile")
    setSelectedRiskTags()
  };

  const getName = (ruleName) => {
    if (ruleName === 'prepaid_discount') return 'Prepaid Discount';
    if (ruleName === 'cod_charge') return 'COD Charge';
    if (ruleName === 'shipping_charge') return 'Shipping Charge';
    if (ruleName === 'DELIVERY_MODE_DISABLE') return 'Disable COD';
    if (ruleName === 'PAYMENT_MODE_DISABLE') return 'Shipping Visibility';
    if (ruleName === 'partial_cod') return 'Partial COD';
  };

  const handleCreate = async () => {
    delete payment_modes_discount['Default']
    const auth = GetAuthToken();
    const cod_value_block_filtered = codValueBlockList?.filter(data=>{
      if(data?.occurence_window_in_seconds && data?.value){
        return {occurence_window_in_seconds:data.occurence_window_in_seconds,value:data.value}
      }
    })
    const cod_count_block_filtered = codCountBlockList?.filter(data=>{
      if(data?.occurence_window_in_seconds && data?.count){
        return {occurence_window_in_seconds:data.occurence_window_in_seconds,count:data.count}
      }
    })

    let postData = {
      type: ruleName,
      sellerId: configData?.data?.['_id'],
      isActive: true,
      determiningFactors: {
        productSku: productList ?? [],
        productTag: productTagsList ?? [],
        productCollectionId: productCollectionList.map((collection) => {
          return (
            {
              id: collection.id,
              name: collection.name
            }
          )
        }) ?? [],
        customerTag: customerTagList ? customerTagList : [], 
        customerEmail: customerEmailList ? customerEmailList : [], 
        customerNumberOfOrder: [], 
        destinationPincode: destinationPincodeList,
        destinationProvinceCode: destinationProvinceCodeList ? destinationProvinceCodeList  : [], 
        destinationCountryCode: destinationCountryCodeList ? destinationCountryCodeList : [],
        deliveryOption: deliveryOptionList ? deliveryOptionList : [],
        cartQuantity: [],
      },     
      
    };

    const filteredWeightList = weightList?.filter((weight) => {
      if (weight?.min !== null && weight?.max)
        return { min: weight?.min, max: weight?.max };
    });
      postData = {
        ...postData,
        determiningFactors: {
          ...postData.determiningFactors,
          weight: filteredWeightList ?? [{}],
        },
      };


    if (invoiceValueList?.length) {
      const filteredInvoiceList = invoiceValueList?.filter((inv) => {
        if (inv?.min >= 0 && inv?.max) return { min: inv?.min, max: inv?.max };
      });
      if (filteredInvoiceList.length) {
        postData = {
          ...postData,
          determiningFactors: {
            ...postData.determiningFactors,
            invoiceValue: filteredInvoiceList,
          },
        };
      }
    }

    // if(ruleName ==='PAYMENT_MODE_DISABLE'){
    //   postData = {
    //     ...postData,
    //     eddConfig:{...PAYMENT_MODE_DISABLE}
    //   }
    // }

    postData = {
      ...postData,
      "ruleOperation": {
        "hiddenPaymentMethods": [hiddenPaymentMethods],
        "hiddenShippingMethods": [hiddenShippingMethods]
      }
    }

    const hasValue = Object?.values(postData?.determiningFactors)?.some(value => {
      return Array?.isArray(value) ? value?.length > 0 : Boolean(value);
    });

    if (!props.editData) {
      if (!hasValue) {
        notification.error({message: 'At least one determining factor should have a value.'});
      } else {
        postRuleMutation.mutate(postData);
      }


      
    } else {
      const editedRule = 
        {
          ...postData,
          id: props.editData.id,
          isActive: props.editData.isActive,
        }
      updateRuleMutation.mutate(editedRule);
    }
  };

  const switchStepContent = () => {
    switch (current) {
      case 0:
        return <SelectRuleType setRuleName={setRuleName} ruleName={ruleName} />;
      case 1:
        return (
          <>
            <Heading>{ruleName?.replace(/_/g, ' ')}</Heading>
            <SubHeading>Select criteria and add condition</SubHeading>
            <AddCriteria
              ruleName={ruleName}
              setRuleName={setRuleName}
              productList={productList}
              setProductList={setProductList}
              pickupPincodeList={pickupPincodeList}
              setPickupPincodeList={setPickupPincodeList}
              destinationPincodeList={destinationPincodeList}
              setDestinationPincodeList={setDestinationPincodeList}
              weightList={weightList}
              setWeightList={setWeightList}
              invoiceValueList={invoiceValueList}
              setInvoiceValueList={setInvoiceValueList}
              zoneList={zoneList}
              setZoneList={setZoneList}
              laneList={laneList}
              setLaneList={setLaneList}
              riskProfile={riskProfile}
              setRiskProfile={setRiskProfile}
              phoneNumberList={phoneNumberList}
              setPhoneNumberList={setPhoneNumberList}
              codCountBlockList={codCountBlockList}
              setCodCountBlockList={setCodCountBlockList}
              codValueBlockList={codValueBlockList}
              setCodValueBlockList={setCodValueBlockList}
              productTagsList={productTagsList}
              setProductTagsList={setProductTagsList}
              utmList={utmList}
              setUtmList={setUtmList}
              productCollectionList={productCollectionList}
              setProductCollectionList={setProductCollectionList}
              selectedRiskTags = {selectedRiskTags} 
              setSelectedRiskTags = {setSelectedRiskTags}
              riskType = {riskType}
              setRiskType = {setRiskType}
              customerTagList ={customerTagList}
              setCustomerTagList = {setCustomerTagList}
              customerEmailList= { customerEmailList}
              setCustomerEmailList = {setCustomerEmailList}
              destinationCountryCodeList ={ destinationCountryCodeList}
              setDestinationCountryCodeList = {setDestinationCountryCodeList}
              destinationProvinceCodeList ={ destinationProvinceCodeList}
              setDestinationProvinceCodeList = {setDestinationProvinceCodeList}
              deliveryOptionList = {deliveryOptionList}
              setDeliveryOptionList = {setDeliveryOptionList}
            />
          </>
        );
      // case 2:
      //   return (
      //     <>
      //       <Heading>{getName(ruleName)}</Heading>
      //       <EnterValue
      //         ruleName={ruleName}
      //         ruleValueAbsolute={ruleValueAbsolute}
      //         setRuleValueAbsolute={setRuleValueAbsolute}
      //         ruleValuePercent={ruleValuePercent}
      //         setRuleValuePercent={setRuleValuePercent}
      //         fastrrShipping={fastrrShipping}
      //         setFastrrShipping={setFastrrShipping}
      //         noRushShipping={noRushShipping}
      //         setNoRushShipping={setNoRushShipping}
      //         standardShipping={standardShipping}
      //         setStandardShipping={setStandardShipping}
      //         applyMaxValue={applyMaxValue}
      //         setApplyMaxValue={setApplyMaxValue}
      //         PAYMENT_MODE_DISABLE = {PAYMENT_MODE_DISABLE}
      //         setShippingDisable={setShippingDisable}
      //         payment_modes_discount = {payment_modes_discount}
      //         setPaymentModesDiscount={setPaymentModesDiscount}
      //         partialCodShipping = {partialCodShipping}
      //         setPartialCodShipping = {setPartialCodShipping}
      //         patialType = {patialType} 
      //         setPatialType = {setPatialType}
      //         partialValue = {partialValue} 
      //         setPartialValue = {setPartialValue}
      //       />
      //     </>
      //   );
      case 2:
          return (
            <>
              <Heading>{ruleName?.replace(/_/g, ' ')}</Heading>
              <RuleOperation 
                ruleName = {ruleName}
                hiddenPaymentMethods = {hiddenPaymentMethods}
                setHiddenPaymentMethods= {setHiddenPaymentMethods}
                hiddenShippingMethods = {hiddenShippingMethods}
                setHiddenShippingMethods = {setHiddenShippingMethods}
              />
            </>
          );
      default:
        return null;
    }
  };

  return (
    <CustomModal
      visible={props.visible}
      footer={null}
      width={880}
      // onCancel={() => handleCancel()}
      closable={false}
      centered={true}
      keyboard= {true}
    >
      {isRuleDone ? (
        <>
          <FlexColCenterContainer>
            <img
              src="https://d10srchmli830n.cloudfront.net/1648623069359_Group-26963NewDiscount.svg"
              alt="dicount-created"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            />
            <Heading>New rule for {ruleName?.replace(/_/g, ' ')} is created.</Heading>
            <SubHeading>
              you can Delete the rule from charge rule tab
            </SubHeading>
            <FlexRow style={{ cursor: 'pointer', margin: '30px 50px' }}>
              <CancelButton onClick={handleCancel}>CLOSE</CancelButton>
            </FlexRow>
          </FlexColCenterContainer>
        </>
      ) : (
        <>
          <Steps type="navigation" current={current}>
            <Step title="Select type"></Step>
            <Step title="Select criteria"></Step>
            <Step title="Operations Name"></Step>
            {/* {ruleName !== 'DELIVERY_MODE_DISABLE' && <Step title="Enter value"></Step>} */}
            {/* {ruleName === 'cod_charge' && <Step title="Enter Rule Name"></Step>} */}
          </Steps>
          {switchStepContent()}
          <FlexRow>
            <FlexRow>
              {/* <CloseOutlined
                style={{
                  color: '#0C3784',
                  cursor: 'pointer',
                }}
              /> */}
              <CancelButton
                onClick={() => {
                  handleCancel();
                }}
              >
                CANCEL
              </CancelButton>
            </FlexRow>
            <FlexRow>
              <FlexRow>
                {current !== 0 && (
                  <>
                    <LeftOutlined
                      style={{
                        color: '#0C3784',
                        cursor: 'pointer',
                      }}
                    />
                    <BackButton onClick={handleBack}>BACK</BackButton>
                  </>
                )}
              </FlexRow>
              <NextButton onClick={handleNext}>NEXT</NextButton>
            </FlexRow>
          </FlexRow>
        </>
      )}
    </CustomModal>
  );
};

export default CreateRuleModal;



 // if(ruleName ==='partial_cod'){
    //   postData = {
    //     ...postData,
    //     partial_cod_config:{
    //       rule_value_percent:patialType === "percentage" ? partialValue : 0,
    //       rule_value_absolute:patialType === "absolute" ? partialValue : 0 ,
    //       include_shipping:partialCodShipping ? partialCodShipping : false
          
    //     }
    //   }
    // }
    // if (weightList?.length > 0 && Object.keys(weightList[0])?.length !== 0) {
      
    // }
    // if (invoiceValueList.length) {
    //   const filteredInvoiceList = invoiceValueList.filter((inv) => {
    //     if (inv.min >= 0 && inv.max) return { min: inv.min, max: inv.max };
    //   });
    //   if (filteredInvoiceList.length) {
    //     postData = {
    //       ...postData,
    //       determiningFactors: {
    //         ...postData.determiningFactors,
    //         invoice_value: filteredInvoiceList,
    //       },
    //     };
    //   }
    // }
    // if (zoneList.length) {
    //   postData = {
    //     ...postData,
    //     determiningFactors: {
    //       ...postData.determiningFactors,
    //       zone: zoneList,
    //     },
    //   };
    // }
    // if (laneList.length) {
    //   const filteredLaneList = laneList.filter((lane) => {
    //     if (lane?.origin_cities?.length && lane?.destination_cities?.length)
    //       return lane;
    //   });
    //   if (filteredLaneList.length) {
    //     postData = {
    //       ...postData,
    //       determiningFactors: {
    //         ...postData.determiningFactors,
    //         lane: filteredLaneList.map((lane) => {
    //           return {
    //             source: lane.origin_cities,
    //             destination: lane.destination_cities,
    //           };
    //         }),
    //       },
    //     };
    //   }
    // }



    // if (utmList.length) {
    //   const filteredUtmList = utmList.filter((utm) => {
    //     if (utm.source || utm.medium || utm.campaign)
    //       return { source: utm.source, medium: utm.medium, campaign: utm.campaign };
    //   });
    //   if (filteredUtmList.length) {
    //     postData = {
    //       ...postData,
    //       determiningFactors: {
    //         ...postData.determiningFactors,
    //         utm_param: filteredUtmList,
    //       },
    //     };
    //   }
    // }

    // if(productCollectionList.length) {
      // postData = {
      //   ...postData,
      //   determiningFactors: {
      //     ...postData.determiningFactors,
          
      //   },
      // };
    // }

