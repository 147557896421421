import { Form, Input } from "antd";
import React, {useState} from "react";
import { CriteriaContainer, HeadingText, InfoText } from "./styled";

const UsageCriteria = ({criteriaUpdate, setCriteriaUpdate}:any) => {
  const [isErrorMaxDiscount, setIsErrorMaxDiscount] = useState(false);
  const [isErrorCustomerLimit, setIsErrorCustomerLimit] = useState(false);
  const Regex = new RegExp(/^(((?!0)[0-9]*))$/);



  const handleDiscount = (e: any) => {
    setCriteriaUpdate({
      ...criteriaUpdate, 
      maxUsage:e.target.value
    })
    if(e.target.value){
      setIsErrorMaxDiscount(Regex.test(e.target.value));
    }else {
      setIsErrorMaxDiscount(false);
    }
  };

  const handleCustomerLimit = (e: any) => {
    setCriteriaUpdate({
      ...criteriaUpdate, 
      perCustomerUsage:e.target.value
    })
    if(e.target.value){
      setIsErrorCustomerLimit(Regex.test(e.target.value));
    }else{
      setIsErrorCustomerLimit(false);
    }
  };

  return (
    <CriteriaContainer>
      <HeadingText>Max Usage of this Discount</HeadingText>
      <Form.Item
        name="usageLimit"
        rules={[
          {
            required: isErrorMaxDiscount,
            pattern: Regex,
            message: "Enter valid value",
          },
        ]}
      >
        <Input maxLength={6} placeholder="Enter max usage" onChange={handleDiscount}/>
      </Form.Item>
      <InfoText>
        *Limit number of times this discount can be used in total
      </InfoText>
      <HeadingText>Usage limit per customer</HeadingText>
      <Form.Item
        name="customerLimit"
        rules={[
          {
            required: isErrorCustomerLimit,
            pattern: Regex,
            message: "Enter valid value",
          },
        ]}
      >
        <Input maxLength={6} placeholder="Enter customer limit" onChange={handleCustomerLimit}/>
      </Form.Item>
      <InfoText>
        *Limit number of times this discount can be used in total
      </InfoText>
    </CriteriaContainer>
  );
};

export default UsageCriteria;
