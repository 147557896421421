import { FlexRow } from 'components/Settings/Discount/styled'
import { FlexColCenterContainer, FlexColContainer } from 'components/UIElements'
import React from 'react'
import {
    Heading,
    Title,
    DateText,
    DateWarningText,
    BreachTextContainer
} from './style'

const EDDModal = ({eddModalData}) => {
    return (
        <>
        <FlexColCenterContainer style={{alignItems:'start'}}>
            <Heading>
                Pickup Dates
            </Heading>
            <FlexRow style={{width:'90%'}}>
                <Title>Configured Pickup:</Title>
                <DateText>{eddModalData.confPickupDate}</DateText>   
            </FlexRow>
            <FlexRow style={{width:'90%'}}>
                <Title>Actual Pickup:</Title>
                <DateWarningText>{eddModalData.pickupDate}</DateWarningText>   
            </FlexRow>
            <Heading>
                EDD Dates
            </Heading>
            <FlexRow style={{width:'90%'}}>
                <Title>Promised EDD:</Title>
                <DateText>{eddModalData.promiseEDD}</DateText>   
            </FlexRow>
            <FlexRow style={{width:'90%'}}>
                <Title>Actual EDD:</Title>
                <DateWarningText>{eddModalData.EDD}</DateWarningText>   
            </FlexRow>
            <BreachTextContainer>
                <FlexRow style={{justifyContent:'center'}}>
                    <Heading style={{margin:'0'}}>Breach :</Heading>
                    <Heading style={{margin:'0', color:'', marginLeft:'5px'}}>Pickup Breach</Heading>
                </FlexRow>
            </BreachTextContainer>
        </FlexColCenterContainer>
        </>
    )
}

export default EDDModal