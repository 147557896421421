import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { FlexContainer } from "components/UIElements";
import { ProductItem, ProductHeading, ButtonClose } from "../styled";

const SelectedPhoneNumbers = ({
  selectedPhoneNumbers,
  setSelectedPhoneNumbers,
}: any) => {
  const deleteItemHandler = (itemId: string | number) => {
    setSelectedPhoneNumbers(
      selectedPhoneNumbers.filter((item: any) => item.id !== itemId)
    );
  };

  return (
    <div style={{ marginBottom: 36 }}>
      {selectedPhoneNumbers?.length > 0 &&
        selectedPhoneNumbers?.map((value: any, index: number) => {
          return (
            <ProductItem>
              <div>
                <ProductHeading>
                  {`${index + 1}. +91 ${value?.number}`}
                </ProductHeading>
              </div>
              <FlexContainer style={{ gap: 20 }}>
                <ButtonClose>
                  {/* @ts-ignore */}
                  <CloseOutlined onClick={() => deleteItemHandler(value?.id)} />
                </ButtonClose>
              </FlexContainer>
            </ProductItem>
          );
        })}
    </div>
  );
};

export default SelectedPhoneNumbers;
