import { QueryCache } from 'react-query';
import axios from 'axios';

//global user details query function

export const getUserDetails = async () => {
  const auth = localStorage.getItem('auth_token');
  const response = await axios.get(
    `https://pickrr.com/api-v2/verify/user/?auth_token=${auth}`
  );
  return response.data;
};

// import global query
//   import { useGetItems } from "hooks/items.hooks.ts";

// call qury function
// const { data: items } = useGetItems();

// export const queryCache = new QueryCache({
//   onError: (error) => {
//     console.log(error);
//   },
//   onSuccess: (data) => {
//     console.log(data);
//   },
// });
export const getCookieValue = (name) =>
  document.cookie.match('(^|;)\\s*' + 'auth_key' + '\\s*=\\s*([^;]+)')?.pop() ||
  '';

export const GetAuthToken = () => {
  // if(document.cookie.match('(^|;)\\s*' + 'auth_key' + '\\s*=\\s*([^;]+)')?.pop()){
  //   return document.cookie.match('(^|;)\\s*' + 'auth_key' + '\\s*=\\s*([^;]+)')?.pop()
  // }else if(localStorage.getItem('auth_token')){
  //   return localStorage.getItem('auth_token')
  // }

  if (
    localStorage.getItem('auth_token') &&
    localStorage.getItem('auth_token')
  ) {
    return localStorage.getItem('auth_token');
  } else {
    return  getCookieValue();
  }
  
};
