import { Button, Modal } from "antd";
import styled from "styled-components";

export const CustomRechargeModal = styled(Modal)`
    .ant-modal-content {
        /* border-top: 8px solid #e83d4c; */
    }
`
export const Credits = styled.p`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.37; */
  letter-spacing: normal;
  text-align: left;
  color: #263f97;
  margin-top: 12px;
  margin-bottom: 12px;
`;
export const BalanceHeading = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.07em;
  color: rgba(38, 63, 151, 0.6);
  margin-bottom: 8px;
`;
export const QuestionMarkTextHeading = styled.p`
  color: #263f97;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;
export const QuestionTextDescription = styled.p`
  color: #38446d;
  font-size: 14px;
`;

export const FastrrHeading = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #263f97;
  margin: 16px 0px;
`;

export const Container = styled.div`
  background: #edf0f9;
  border-radius: 12px;
  padding: 24px;
  margin-bottom : 16px;

  .ant-btn {
    border: none;
    color: #263f97;
  }
  .ant-btn:focus {
    background: #263f97;
    color: #fff;
  }
`;

export const FieldTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #263f97;
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ValidateButton = styled(Button)`
  background: #d3d7e9 !important;
  border-radius: 5px !important;
  border-color: #d3d7e9 !important;
  color: #263f97 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
`;

export const Count = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-align: right;
  letter-spacing: -0.01em;
  color: #263f97;
`;

export const SubText = styled.span`
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #263f97;
  opacity: 0.6;
`;

export const PickrrHeadingloc = styled.div`
  font-weight: bold;
  color: #263f97;
  margin: 10px 0;
  cursor: pointer;
  text-decoration: underline;
  fontsize: 18px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #263f97;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
`;