import { Divider, Steps, Tabs } from "antd";
import {
  FlexBox,
  FlexColContainer,
  SmallHeading,
  SpaceAroundContainer,
} from "components/UIElements";
import styled from "styled-components";

export const CustomSmallHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #263f97;
  line-height: 19.2px;
`;
export const BackButtonText = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 12px;
  color: #263f97;
  opacity: 0.6;
  cursor: pointer;
  width: 100px;
`;
export const SubHeading = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000b34;
  line-height: 22.4px;
  opacity: 60%;
`;
export const RtoStrip = styled.div`
  background-color: #f8f9ff;
  height: 35px;
  margin-top: 30px;
  margin-left: -24px;
  width: calc(100% + 48px);
`;
export const CustomDivider = styled(Divider)`
  background-color: #dfe0eb;
  margin-left: -24px;
  width: calc(100% + 48px);
`;
export const ScrollableDiv = styled.div`
  overflow-y: auto;
`;
export const AddressDetailDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #263f97;
  line-height: 22.4px;
  max-width: 280px;
  margin-bottom: 20px;
`;
export const CustomTabs = styled(Tabs)`
  margin-left: -24px;
  width: calc(100% + 48px);
  margin-top: -29px;
  border-radius: 20px;

  .ant-tabs-nav {
    background-color: #f8f9ff;
    height: 61px;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #dfe0eb !important;
    justify-content: flex-start !important;
  }
  .ant-tabs-tab-btn {
    padding: 0px 20px;
    font-size: 20px !important;
    text-transform: capitalize !important;
    color: #000b34 !important;
  }
  .ant-tabs-ink-bar {
    background-color: #0c64db;
  }
  .ant-tabs-tab {
    font-size: 18px;
    text-transform: capitalize;
    color: #000b34;
    font-weight: 500;
  }
`;

export const TimelineCont = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #68636375;
    left: -30px;
    top: 10px;
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 120%;
    top: 23px;
    left: -24px;
    border-right: #68636375 dashed 2px;
  }
`;
export const TimelineContSmall = styled.div`
position: relative;
 &::before{
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #68636375;
  left: -29px;
  top: 10px;
  display: block;
 }
 }
`;
export const CustomerSmallHeading = styled(SmallHeading)`
  color: #000b34;
  fontsize: ${(props: any) => (props.fs ? props.fs : "20")}px;
`;
export const CustomerFlexColContainer = styled(FlexColContainer)`
  padding: 7px;
`;
export const CustomerSpaceAroundContainer = styled(SpaceAroundContainer)`
  margin-bottom: 20px;
  font-size: 14px;
  color: #263f97;
  font-weight: 600;
`;

export const CustomSteps = styled(Steps)``;
