import { notification } from 'antd';
import axios from 'axios';
import {sessionExpired, getCookie} from '../../utils/functions';
  export const activeRoles = async() => {
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/role/seller`, 
        {
          headers: {
            'X-Auth': localStorage.getItem('userManagementToken') ? localStorage.getItem('userManagementToken') : getCookie('userManagement'),
            'Authentication-Info': localStorage.getItem('sessionToken')
          }
        }
      )
      return response?.data?.data;
    }catch(error){
      notification.error({message:error?.response?.data?.error?.message})      
      if(error?.response?.status === 403){
        sessionExpired();
      }
    }
  }


  export const fetchAllUser = async({ queryKey }:any) => {
    const [_, company_id, domain_name] = queryKey;
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/user/fetch-all?domain=${domain_name}`, 
        {
          headers: {
            'X-Auth':  localStorage.getItem('userManagementToken') ? localStorage.getItem('userManagementToken') : getCookie('userManagement'),
            'Authentication-Info': localStorage.getItem('sessionToken'), 
            'companyId': company_id
          }
        }
      )

      return response?.data?.data;

    }catch(error){
      notification.error({message:error?.response?.data?.error?.message});
      if(error?.response?.status === 403){
        sessionExpired();
      }
    }
  }


  export const createUsers = async (data:any) => {
    try{
      const response = await axios.post(
        `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/user/seller`,
          data,
        {
          headers: {
            'X-Auth':  localStorage.getItem('userManagementToken') ? localStorage.getItem('userManagementToken') : getCookie('userManagement'),
            'Authentication-Info': localStorage.getItem('sessionToken'), 
          }
        }
      );
      return response.data;
    }catch(error){
      notification.error({message:error?.response?.data?.error?.message});
      if(error?.response?.status === 403){
        sessionExpired();
      }
    }
    
  };
  
  
  
  export const updateUser = async (data:any) => {
    try{
      const response = await axios.put(
        `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/user/seller`,
          data,
        {
          headers: {
            'X-Auth': localStorage.getItem('userManagementToken'),
            'Authentication-Info': localStorage.getItem('sessionToken'), 
          }
        }
      );
      return response.data;
    }catch(error){
      notification.error({message:error?.response?.data?.error?.message});
      if(error?.response?.status === 403){
        sessionExpired();
      }
    }
  };
