import React, { useEffect, useState } from "react";
import { CustomInput, RestButton } from "../styled";
import { FlexBox, PickrrButton, SpaceBetweenContainer } from "components/UIElements";
import { HeadingText, MessageText } from "./styled";
import SelectedPhoneNumbers from "./SelectedPhoneNumbers";
import { Form, notification, Radio, Alert, Upload  } from "antd";
import {DownloadOutlined, EnterOutlined, UploadOutlined} from '@ant-design/icons'
import axios from "axios";
import { Text } from "pages/ChargeRule/CreateRuleModal/AddCriteria/style";

const PhoneNumberSearch = ({
  selectedPhoneNumbers,
  setSelectedPhoneNumbers,
}: any) => {
  const [input, setInput] = useState("");
  const [isError, setIsError] = useState(false);
  const Regex = new RegExp("^[6-9]\\d{9}$", "gm");
  const numberExists = (value: any) =>
    selectedPhoneNumbers.some((user: any) => user.number === value);

  const[errorPhoneNumberList,setErrorPhoneNumberList]=useState([])
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (input) {
      if (Regex.test(input)) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    } else {
      setIsError(false);
    }
  }, [input]);

  useEffect(() => {
    const uploadCSV = async () => {
      try {
      setLoading(true);
      const formData = new FormData();
      formData.append('block_phone_no', uploadFile[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/upload-csv/`,
        formData
      );
      setLoading(false);
      if (response.data.success) {
        if (response.data.data.err_list?.length > 0) {
          notification.error({
            message: 'Invalid phone numbers',
          });
        } else {
          notification.success({
            message: 'File uploaded successfully.',
          });
        }
        setSelectedPhoneNumbers([...response?.data?.data?.list?.map((number:any)=>({number,id:Math.floor(Math.random()*1000)}))]);
        setErrorPhoneNumberList(response.data.data?.err_list);
        setUploadFile([]);
      } else {
        notification.error({
          message: response.data.message,
        });
        setUploadFile([]);
      }
    } catch (e) {
      notification.error({
        message: 'Something went wrong.',
      });
      setLoading(false)
    }
    };
    if (uploadFile?.length === 1) {
      uploadCSV();
    }
  }, [uploadFile]);

  const uploadProps = {
    onRemove: (file:any) => {
      setUploadFile([]);
    },
    beforeUpload: (file:any) => {
    if(file.type !== 'text/csv'){
      notification.error({
        message: 'You can only upload csv file!',
      });
    }
    else{
      setUploadFile([file]);
    }
    return false;
  },
  };

  return (
    <>
    <HeadingText>Criteria Applies to :</HeadingText>
      <Form.Item name="excludeCustomer" initialValue={false}>
        <Radio.Group>
          <Radio value={false}>Include Customers</Radio>
          <Radio value={true}>Exclude Customers</Radio>
        </Radio.Group>
      </Form.Item>

      <HeadingText>
        <SpaceBetweenContainer>
          Enter Customer Phone No.
          <RestButton
            onClick={() => {
              setInput("");
              setSelectedPhoneNumbers([]);
              setErrorPhoneNumberList([]);
            }}
          >
            Reset
          </RestButton>
        </SpaceBetweenContainer>
      </HeadingText>
      <MessageText>
        Enter a valid mobile number and press enter to add.
      </MessageText>

      <CustomInput
        placeholder="Enter phone no."
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onPressEnter={(e: any) => {
          if (numberExists(input)) {
            notification.error({
              message: "This phone number is already present.",
            });
          } else if (e.target.value && Regex.test(input)) {
            setSelectedPhoneNumbers([
              ...selectedPhoneNumbers,
              {
                number: `${e.target.value}`,
                id: Math.floor(Math.random() * 1000),
              },
            ]);

            setInput("");
            setIsError(false);
          } else {
            setIsError(true);
          }
        }}
        /* @ts-ignore */
        suffix = {<EnterOutlined />}
      />
      {isError && (
        <>
          <h1 style={{ color: "red", fontSize: "14px" }}>
            Please enter a valid phone number.
          </h1>
        </>
      )}

      {selectedPhoneNumbers && (
        <SelectedPhoneNumbers
          selectedPhoneNumbers={selectedPhoneNumbers}
          setSelectedPhoneNumbers={setSelectedPhoneNumbers}
        />
      )}

{errorPhoneNumberList?.length>0 && (
                <Alert
                  message={`${
                    errorPhoneNumberList?.length
                  } phone number(s) found invalid. Please check the following phone number(s): ${errorPhoneNumberList.join(
                    ','
                  )}`}
                  type="error"
                />
              )}
       <b>or upload Phone Number list</b>
      <FlexBox className="mb-16">
        <Upload {...uploadProps} showUploadList={false}>
          <PickrrButton
            color="primary"
            style={{ textTransform: 'none' }}
            icon={<UploadOutlined />}
            className="mr-12"
            loading={loading}
          >
            Upload CSV
          </PickrrButton>
        </Upload>
        <a
          href={`https://${window.location.host}/sample_phone_no.csv`}
          download
        >
          <Text>
            <DownloadOutlined className="mr-8" /> Download Sample CSV File
          </Text>
        </a>
      </FlexBox>
    </>
  );
};

export default PhoneNumberSearch;
