
import styled from 'styled-components';
import { Card } from 'antd';

export const Heading = styled.div`
    position:relative;
    height: 56px;
    min-width: 208px;
    text-align:center;
    vertical-align:middle;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-size:16px;
    font-weight:600;
    border-radius: 10px;
    border: 1.004px solid rgba(12, 100, 219, 0.10);
    background: #FFF;    
    &:after{
        content: '';
        position:absolute;
        inset: 0;
        border-radius: 8px;
        margin:6px 6px;
        background: rgba(12, 100, 219, 0.10);
        box-shadow: 0px -1.00421px 28.11785px 0px rgba(0, 0, 0, 0.10);
    }
`

export const CustomCart = styled(Card)`
    border-radius: 8px;
`


export const PercentText = styled.div`
    color:  #0C3784;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.2px;
`

export const SubHeading = styled.div`
    color: rgba(0, 11, 52, 0.70);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
`

export const NormalText = styled.div`
    color: #000;
    font-size: 12px;
    font-weight: 600;
`

export const Line = styled.div`
    background: #EDEEEF;
    height: 1px;
    width: 100%;
    margin:10px 0;
`
