import { getCookieValue } from "globalQuries";
const auth = getCookieValue();

export const is_local = () => {
  let local = process.env.REACT_APP_LOCAL_LOGIN;
  return local ? true : false;
};

export const local_cookie = () => {
  return process.env.REACT_APP_LOCAL_COOKIE;
};
export const sessionExpired = () => {
    // const redirectChannel = 
      //for new onboarding don't redirect
      if(localStorage.getItem('isOnboarding') && localStorage.getItem('onboardingType') === "NEW") {
        return
      }

      localStorage.getItem("loggedInFromAdmin") 
      ? window.location.href = 'https://fastrr-admin.fastrr.com/configuration'
      : window.location.href = 'https://app.shiprocket.in/seller/home';
    
}

export const sessionConfig = (response) => {
    const sessionBase64 = response?.headers?.['authentication-info'];
    const panelPermissions = JSON.stringify(response?.headers?.['permissions']);
    localStorage.removeItem('sessionToken');
    localStorage.setItem('sessionToken', sessionBase64);
    localStorage.removeItem('panel');
    localStorage.setItem('panel', panelPermissions);
    localStorage.removeItem('userManagementToken');
    localStorage.setItem('userManagementToken', response?.headers?.['x-auth']);
}

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export const defaultRoute = (permission) => {
  let routes = permission?.slice(1, -1)?.split(',');
    routes = routes?.map(function(item) {
        return item?.trim();
    }); 
    window.location.href = routes?.includes('dashboard') ? `/dashboard` : `/${routes?.[0]}`
}

export const routePermission = (route) => {
  if(localStorage.getItem('panel') && localStorage.getItem('panel')?.includes(route)){
    return true;
  } else {
    return false;
  }
}