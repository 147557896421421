import React from 'react';
import {Row, Col, Input} from 'antd';
import {InputContainer} from '../../styled';

const CustomerTag  = ({customerTagList, setCustomerTagList}) => {
    const onChange = (e) =>{
        setCustomerTagList(() => [e.target.value]);
        console.log(e.target.value)
    }
    
    return (
        <Row gutter={[16, 16]}>
            <Col>
                <InputContainer>
                    <Input 
                        placeholder='Enter Customer Tag'
                        value={customerTagList}
                        onChange={onChange}
                    />
                </InputContainer>
            </Col>
        </Row>
    )
}

export default CustomerTag;