import React, { useEffect } from 'react'
import { FormContainer, SubHeading, CheckboxText, CheckboxDescriptipon } from '../styled'
import { CustomForm } from 'components/UIElements'
import {Row, Col, Form, Input, Checkbox, notification} from 'antd'
import { PickrrButton } from 'components/UIElements'
import { getGeneralDetails, updateSellerCheckoutConfig } from 'components/Settings/GeneralSettings/script'
import { useQuery, useMutation, useQueryClient } from 'react-query'

export const MoEngageTrackingPage = ({tabKey}: {tabKey: string}) => {

    const [form] = Form.useForm()
    const queryClient = useQueryClient();

    const { data } = useQuery(
        'generalSettings',
        getGeneralDetails
    );

    const mutation = useMutation(updateSellerCheckoutConfig, {
        onError: () => {
          notification.error({message: 'Something went wrong.'});
        },
        onSuccess: () => {
          queryClient.invalidateQueries('generalSettings');
          notification.success({ message: 'Updated seller details.'});
        },
      });

      useEffect(() => {
        if(data) {
            form.setFieldsValue({
                base_url: data?.marketing_config?.mo_engage?.base_url,
                app_id: data?.marketing_config?.mo_engage?.app_id,
                data_api_id: data?.marketing_config?.mo_engage?.data_api_id,
                data_api_key: data?.marketing_config?.mo_engage?.data_api_key,
                phone_number_added: data?.marketing_config?.mo_engage?.events?.phone_number_added,
                logged_in: data?.marketing_config?.mo_engage?.events?.logged_in,
                address_selected: data?.marketing_config?.mo_engage.events?.address_selected,
                shipping_selected: data?.marketing_config?.mo_engage?.events?.shipping_selected,
                payment_initiated: data?.marketing_config?.mo_engage?.events?.payment_initiated,
                order_placed: data?.marketing_config?.mo_engage?.events?.order_placed
            })
        }
    }, [data, tabKey])

    const submitForm = () => {
        const postData = {...form.getFieldsValue()}
        mutation.mutate({
            marketing_config: {
                ...data?.marketing_config, 
                mo_engage: {
                    base_url: postData.base_url,
                    app_id: postData.app_id,
                    data_api_id: postData.data_api_id,
                    data_api_key: postData.data_api_key,
                    events: {
                        phone_number_added: postData.phone_number_added,
                        logged_in: postData.logged_in,
                        address_selected: postData.address_selected,
                        shipping_selected: postData.shipping_selected,
                        payment_initiated: postData.payment_initiated,
                        order_placed: postData.order_placed
                    }
                }
            }
        })
    }

    return (
        <FormContainer>
            <CustomForm layout="vertical" onFinish={submitForm} form={form}>
                <Row gutter={[50,0]}>
                   <Col span={10}>
                    <Form.Item
                        label="Base URL"
                        name="base_url"
                        rules={[
                            {
                              required: true,
                              message: 'This field is required.',
                            },
                          ]}
                        >
                        <Input />
                    </Form.Item>
                    </Col>
                    <Col span={10}>
                    <Form.Item
                        label="App ID"
                        name="app_id"
                        rules={[
                            {
                              required: true,
                              message: 'This field is required.',
                            },
                          ]}
                        >
                        <Input />
                    </Form.Item>
                    </Col>
                    <Col span={10}>
                    <Form.Item
                        label="Data API ID"
                        name="data_api_id"
                        rules={[
                            {
                              required: true,
                              message: 'This field is required.',
                            },
                          ]}
                        >
                        <Input />
                    </Form.Item>
                    </Col>
                    <Col span={10}>
                    <Form.Item
                        label="Data API Key "
                        name="data_api_key"
                        rules={[
                            {
                              required: true,
                              message: 'This field is required.',
                            },
                          ]}
                        >
                        <Input />
                    </Form.Item>
                    </Col>
                    <Col span={24}>
                        <SubHeading>Please select the Events you want to trigger :</SubHeading>
                    </Col>
                    <Col span={24}>
                    <Form.Item name="phone_number_added" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Phone Number Added</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                            Phone number shared and OTP requested. At this stage the phone number is not yet verified. 
                        </CheckboxDescriptipon>
                    </Col>
                    {/*  
                    <Col span={24}>
                    <Form.Item name="logged_in" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Logged In</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                            OTP entered by the user. The phone number is now verified.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                    <Form.Item name="address_selected" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Address Selected</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                            User selected an address from saved addresses or added a new address. 
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                    <Form.Item name="shipping_selected" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Shipping Selected</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                            User selected shipping mode. 
                        </CheckboxDescriptipon>
                    </Col>
                    )}*/}
                    <Col span={24}>
                    <Form.Item name="payment_initiated" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Payment Initiated</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                            User reached payment step. Only sent for prepaid orders.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                    <Form.Item name="order_placed" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Order Placed</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                            User completed payment or placed COD order. 
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={4} style={{paddingBottom:'15px', paddingTop:'15px'}}>
                    <PickrrButton htmlType="submit" color="checkout-blue">
                        Save
                    </PickrrButton>
                    </Col>
                </Row>
            </CustomForm>
        </FormContainer>
    )
}

export default MoEngageTrackingPage