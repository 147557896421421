import styled from "styled-components";
import { Button, Modal, Table } from "antd";

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;

  .BoldText {
    color: #0c3784;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const DiscountContainer = styled.div`
  height: auto;
  background: #ffffff;
  padding-right: 20px;
  border-radius: 10px;
  margin-top: 15px;
`;
export const CustomTable = styled(Table)`
  padding-right: 20px;

  .ant-table table {
    border-spacing: 0 15px;
  }
  tr {
    background: rgba(12, 100, 219, 0.04);
  }

  .rowClassName1 td {
    border-bottom: none;
  }
  .rowClassName1 td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .rowClassName1 td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const Heading = styled.h1`
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #0c3784;
`;

export const SubHeading = styled.h5`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: #0c3784;
`;

export const HeaderContainer = styled.div`
    height: 77px;
    display: flex:
    align-items: center;
    border-radius: 10px;
    background: #FFFFFF;
`;
export const FlexBox = styled.div`
  display: flex;

  .ant-switch-checked {
    background-color: #0c64db;
  }
`;

export const HeaderContent = styled.div`
  padding: 20px;
`;

export const HeaderTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #0c3784;
  margin: 0;
`;
export const CustomButton = styled(Button)`
  border-radius: 8px;
  background: #0c64db;
  color: white;
  font-size: 12px;
  width: 136px;
  border: none !important;
  font-weight: 500;
  transition: none !important;
  border:1px solid #0c64db !important; 

  &:hover {
    background: #0c64db;
    color: white;
  }

  &:focus {
    background: #0c64db;
    color: white;
  }
`;

export const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
  }
  .ant-modal-content {
    border-radius: 10px !important;
  }
`;
