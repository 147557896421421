import React, { useEffect, useState } from "react";
import { Radio, Space, Row, Col, Form, Select, Checkbox } from "antd";
import type { RadioChangeEvent } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { SubHeading, CustomInput, AddButton, LabelText } from "../styled";
import ProductSearch from "./ProductSearch";
import { CustomSelect, HeadingText } from "../CriteriaTypes/styled";

interface IDiscountType {
  flat: boolean;
  percent: boolean;
  amount: number | string;
}

const { Option } = Select;

const AmountOfConfig = ({
  discountType,
  bundleItem,
  setBundleItem,
  selectTypes,
  setSelectTypes,
  form,
  sellerConfigValues,
  tieredFlag,
  setTieredFlag
}: any) => {
  const [value, setValue] = useState<string | number>("flat");
  const Regex = new RegExp(/^(((?!0)[0-9]+[.]?[0-9]*))$/);
  const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);
  const percentRegex = new RegExp(
    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?)(\.[0-9]{1,2})?$)/
  );

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ configValue: "" });
    setSelectTypes({ ...selectTypes, configType: e.target.value });
    setValue(e.target.value);
  };

  const typeOfTieredHandle = (value: string) => {
    setSelectTypes({ ...selectTypes, tieredType: value});
    form.setFieldsValue({ tiers: [
      { tierValue: "", offValue: "" },
    ]
   });
  };
  return (
    <Row>
      <Col span={24}>
        <SubHeading>Discount Configuration</SubHeading>
      </Col>
      <Col span={24}>
        <Form.Item name="configDiscountType" initialValue={"flat"}>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={"flat"}>Flat /-</Radio>
              <Radio value={"percent"}>Percentage %</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Col>

      {
        discountType === "bankOffer" && 
        <Col span={11} style={{marginBottom: 16}}>
            <Form.Item 
              label = "PG discount id"
              name = "pgDiscountId"
              rules={[
                {
                  required: true,
                  message: "Please enter the valid value",
                },
              ]}
              >
                <CustomInput placeholder={'PG discount id'} />
            </Form.Item>
          </Col>
        }



      {discountType === "tiered" && (
        <>
          <Col span={24}>
            <Form.Item 
              label = "Type of Tiered"
              name = "typeOfTiered"
              rules={[
                {
                  required: true,
                  message: "Please enter the valid value",
                },
              ]}
              style={{marginBottom: 0}}
              initialValue={selectTypes.tieredType}
              >
              <Select
                style={{ width: "100%", marginBottom: 24 }}
                placeholder="---select type---"
                showArrow={true}
                onChange={typeOfTieredHandle}
              >
                <Option value="amount">Cart value</Option>
                <Option value="quantity">Quantity</Option>
              </Select>
            </Form.Item>
          </Col>
        </>
      )}
      {discountType=='tiered' && selectTypes.tieredType && selectTypes.tieredType!=='amount' && 
          <Checkbox checked={tieredFlag} onChange={(e: any) =>{
              setTieredFlag(e.target.checked)
            }} style={{marginBottom:'18px'}}>Item Level
          </Checkbox>
      }
      <Col span={24}>
        {discountType === "tiered" ? (
          <Form.List name="tiers">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Row align={"middle"} gutter={20} key={index}>
                      <Col span={11}>
                        <Form.Item
                          label={
                            selectTypes.tieredType === "amount"
                              ? "Cart Value"
                              : "Quantity"
                          }
                          name={[index, "tierValue"]}
                          rules={[
                            {
                              required: true,
                              pattern: selectTypes.tieredType === "amount" ? Regex : quantityRegex,
                              message: "Enter valid value",
                            },
                          ]}
                        >
                          <CustomInput
                            maxLength={selectTypes.tieredType === "amount" ? 10 : 6}
                            placeholder={
                              selectTypes.tieredType === "amount"
                                ? "Enter cart value"
                                : "Enter quantity"
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label={
                            selectTypes?.configType === "flat"
                              ? "Amount"
                              : "Percentage"
                          }
                          name={[index, "offValue"]}
                          rules={[
                            {
                              required: true,
                              pattern:
                                selectTypes?.configType === "percent"
                                  ? percentRegex
                                  : Regex,
                              message: "Enter valid value",
                            },
                          ]}
                        >
                          <CustomInput
                            maxLength={
                              selectTypes?.configType === "flat" ? 10 : 4
                            }
                            placeholder={
                              selectTypes?.configType === "flat"
                                ? "Enter Amount"
                                : "Enter Percentage"
                            }
                          />
                        </Form.Item> 
                      
                      </Col>
                      <Col span={2}>
                      {fields && fields?.length > 1 && index !== 0 &&
                      /* @ts-ignore */
                        <MinusCircleOutlined
                          style={{ color: "red"}}
                          onClick={() => remove(field.name)}
                        />
                        }
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <AddButton
                      type="text"
                      onClick={() => {
                        if (selectTypes.tieredType) {
                          add();
                          setSelectTypes({...selectTypes, haveTiers: true})
                        }
                      }}
                    >
                      + Add Tier
                    </AddButton>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        ) : (
          <>
          <Form.Item
            label={selectTypes?.configType === "flat" ? "Amount" : "Percentage"}
            name="configValue"
            rules={[
              {
                required: true,
                pattern:
                  selectTypes?.configType === "percent" ? percentRegex : Regex,
                message: "Please enter the valid value",
              },
            ]}
          >
            <CustomInput
              maxLength={selectTypes?.configType === "flat" ? 10 : 5}
              placeholder="Enter the value"
              style={{ width: 225 }}
            />
          </Form.Item>
          {discountType === "prdAmt" && 
            <>
            <Col span={24}>
            <Form.Item name="enableLimit" style={{ marginTop: 10 }} valuePropName="checked">
              <Checkbox
                onChange={(e: any) =>
                  setSelectTypes({ ...selectTypes, enableLimit: e.target.checked })
                }
              >
                <span
                  style={{
                    fontSize: "14px",
                    color: "#38446D",
                    fontWeight: "bold",
                  }}
                >
                  Apply discount on each eligible item
                </span><br />
                If selected the amount will be taken off each eligible item in an order.
              </Checkbox>
            </Form.Item>
            </Col>
            </>
          }
          </>
        )}
      </Col>
    </Row>
  );
};

export default AmountOfConfig;
