import { notification } from "antd";
import axios from "axios";
import { GetAuthToken } from "globalQuries";
import { BlueInfoIcon, CloudIcon, FileIcon, RedDelete } from "images/icons";
import React, { useEffect, useState } from "react";
const ImageUploaderTw = ({ uploadArray, setUploadArray,height,width,currUpload,setCurrentUpload}: any) => {
    // const [currUpload,setCurrentUpload]=useState<any>([])
    const auth = GetAuthToken();
    useEffect(() => {
        const uploadImage = async () => {
          const formData = new FormData();

          formData.append('file', currUpload[0]);
          const response = await axios.post(
            `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/upload/img/`,
            formData,
            {
              headers: {
                Authorization: auth,
              },
            }
          );
    
          if (response.data.success) {
            notification.success({
              message: 'Uploaded Successfully!',
            });
            setUploadArray(response.data.data.url);
          } else {
            notification.error({
              message: response.data.err,
            });
          }
        };

        if (currUpload?.length > 0) {
          uploadImage();
        }
        
      }, [currUpload]
    );

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    if (img.width <= width && img.height <= height) {
                        setCurrentUpload([file]);
                    } else {
                        alert(`Image must be ${height}x${width} pixels!`);
                    }
                };
                img.src = `${event.target.result}`;
            };
            reader.readAsDataURL(file);
        }
    };
    return <>

        <div className="flex items-center justify-center w-full text-purpleText">
            <label htmlFor="uploadArray" className="flex flex-col items-center justify-center w-full h-64 border border-purpleText border-dashed rounded-lg cursor-pointer ">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    {uploadArray?.length <= 0 ? <><CloudIcon />
                        <p className="text-sm"><span>Upload banner image</span></p>
                        <p style={{ fontSize: '10px' }}>(PNG,JPG,JPEG)</p></> : <>
                        <div className="bg-UploadedFileBg rounded-lg flex gap-3 px-4 py-3">
                            <FileIcon />
                            <div className="flex flex-col gap-2">
                                <div className="flex justify-between gap-36 items-center">
                                    <p className="text-black text-xs">{currUpload[0]?.name}</p>
                                    <div className="" onClick={(e:any)=>{
                                        e.preventDefault()
                                        setUploadArray([])
                                    }}><RedDelete /></div>
                                </div>
                                <hr className="w-full h-1 border bg-purpleText rounded-xl"/>
                            </div>

                        </div>
                    </>}
                    <p>OR</p>
                    <div className="bg-purpleText px-4 py-2 rounded-lg text-white">Select File</div>
                </div>
                <div className="w-fit flex justify-center items-center rounded-md px-4 py-1 text-black gap-1 bg-dimensionsColor text-xs"><BlueInfoIcon />(Max dimensions {height}px * {width}px allowed)</div>
                <input id="uploadArray" type="file" className="hidden" accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} onClick={(e:any)=>{e.target.value=null}}/>
            </label>
        </div>

    </>

}
export default ImageUploaderTw