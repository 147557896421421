import React, { useEffect } from 'react';
import {
  Container,
  CustomForm,
  PickrrButton,
} from '../../GeneralSettings/style';
import {
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Space,
  Typography,
} from 'antd';
import Header from '../../../CommonComponents/Header';
// import {
//   fetchSellerCheckoutConfig,
//   updateSellerCheckoutConfig,
// } from 'redux/actions/manageCheckout';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
} from 'components/Settings/GeneralSettings/script';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

const { Paragraph } = Typography;

const ManageChannelShopify = () => {
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );
  const queryClient = useQueryClient();
  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
      queryClient.invalidateQueries("generalSettings");
      // navigate('/settings/shipping');
    },
  });

  // const { data, fetchingSellerConfig } = useSelector(
  //   (state) => state.manageCheckout
  // );
  const [form] = Form.useForm();

  // useEffect(() => {
  //   if (data === null) {
  //     dispatch(fetchSellerCheckoutConfig());
  //   }
  // }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        ...(!!data?.pickup_config && {
          pickup_config: {
            ...data.pickup_config,
            cut_off_time: moment(data.pickup_config.cut_off_time, 'HH:mm'),
          },
        }),
      });
    }
  }, [data]);

  const submitForm = () => {
    let postData = { ...form.getFieldValue() };
    if (
      postData.pickup_config &&
      postData.pickup_config.cut_off_time.length !== 5
    ) {
      postData.pickup_config.cut_off_time = moment(
        postData.pickup_config.cut_off_time
      ).format('HH:mm');
    }

    if (postData['domain_name'] && postData['domain_name'].length > 0) {
      //remove https
      let newUrl = postData['domain_name'].replace(/(^\w+:|^)\/\//, '');
      //remove special characters
      postData['domain_name'] = newUrl.replace(/^\/|\/$/g, '').toLowerCase();
    }
    mutation.mutate(postData);
  };

  return (
    <Container>
      <Header title="Shopify" submitForm={submitForm} />
      <CustomForm layout="vertical" form={form} onFinish={submitForm}>
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item label="App Name">
              <Paragraph
                copyable={{
                  text: `Fastrr Checkout`,
                }}
              >
                Fastrr Checkout
              </Paragraph>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item label="Developer Email">
              <Paragraph
                copyable={{
                  text: `support@pickrr.com`,
                }}
              >
                support@pickrr.com
              </Paragraph>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item
              label="Channel Name"
              name={['platform_details', 'channel']}
            >
              <Input               
              disabled={
                data?.platform_details?.channel
                  ? true
                  : false
              }/>
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item
              label="Store Name"
              name={['platform_details', 'shopify', 'store_name']}
            >
              <Input
              disabled={
                data?.platform_details?.shopify?.store_name
                  ? true
                  : false
              }
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item
              label="API Key"
              name={['platform_details', 'shopify', 'admin_api_key']}
            >
              <Input
                disabled={data?.platform_details?.shopify?.admin_api_key}
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item
              label="Password"
              name={['platform_details', 'shopify', 'admin_api_password']}
            >
              <Input
                disabled={data?.platform_details?.shopify?.admin_api_password}
              />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item
              label="Shared Secret"
              name={['platform_details', 'shopify', 'admin_api_shared_secret']}
            >
              <Input
                disabled={
                  data?.platform_details?.shopify?.admin_api_shared_secret
                }
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item
              label="Storefront Access Token"
              name={['platform_details', 'shopify', 'storefront_access_token']}
            >
              <Input
                disabled={
                  data?.platform_details?.shopify?.storefront_access_token
                }
              />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} x={24}>
            <Form.Item label="Domain Name" name="domain_name">
              <Input 
                addonBefore="https://" 
                disabled={
                  data?.domain_name
                    ? true
                    : false
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Space>
          <PickrrButton htmlType="submit">Save</PickrrButton>
        </Space>
      </CustomForm>
    </Container>
  );
};

export default ManageChannelShopify;
