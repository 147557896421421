import styled from "styled-components";

export const ModalTitle = styled.span`
    display : flex;
    font-size: 18px ;
    font-weight : 600;
`

export const ModalContentContainer = styled.div`
    display : flex;
    .ant-input {
        width : 300px;
        border-radius : 4px;
    }
`
export const DateContainer = styled.div`
    display: flex;
    color : #0C64DB;
    font-weight : 600;
    font-size: 16px;
    line-height : 22.4px;
    margin-bottom : 10px;
`

export const GatewayContainer = styled.div`
    background : #0C64DB1A ;
    font-weight : 500 ;
    font-size : 16px;
    color : #0C3784;
    width: fit-content;
    padding: 0px 10px;
    border-radius : 4px;
`