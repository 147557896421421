export const downloadFile = (blobData, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const authtokenCheck = (authToken) => {
  return true;
};

export const makeFirstLetterUpperCase = (string) => {
  return string[0].toUpperCase() + string.substring(1)
}
