
const TAGS_MAPPING = [
    {mapped_name: 'Increase in RTO on buyers pincode (30days)', codes: 'Increased_RTO_in_last_30_days'},
    {mapped_name:'Increase in RTO on buyers pincode (7days)',codes:'Increased_RTO_in_last_7_days'},
    {mapped_name: 'Delivery issues on buyers address', codes: 'Deliverability_Issues'},
    {mapped_name: 'High refusal percentage of buyer',  codes: 'High_Refusal' },
    {mapped_name:'First time buyer  on your website', codes: 'New_Buyer'},
    {mapped_name:'Buyers has more prepaid affinity ', codes: 'Prepaid_Affinity'},
    {mapped_name:'Buyers unavailability issue ', codes: 'Not_quickly_available'},
    {mapped_name:'Buyers  history  indicates RTO risk', codes: 'Past_History'},

]

const RiskTagsInfo = () => {
  return (
    <div className="py-0.5 w-fit">
        <span className="text-sm font-semibold">Risk Tags</span>
        <p>Once mapped names are selected, the corresponding codes, 
            which are linked <br/> to these names, will be displayed on 
            your Shopify or WooCommerce platform.
        </p>
        <br></br>

        <table>
            <th>Mapped Names</th>
            <th>Codes</th>

            {TAGS_MAPPING.map((row)=>{
                return(
                    <tr>
                        <td className="border-y w-max text-xs">{row.mapped_name}</td>
                        <td className="border-y p-2 uppercase" style={{fontSize:'10px'}}>{row.codes}</td>
                    </tr>
                )
                
            })}
        </table>

    </div>
  )
}

export default RiskTagsInfo