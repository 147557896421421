import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';
import { CustomSelect, PickrrButton, PickrrHeading } from 'components/UIElements';

import { fetchCitiesList } from '../functions';

const { Option } = Select;

const Lane = ({ laneList, setLaneList }) => {

  const {isLoading: isCitiesDataLoading, data: citiesData} = useQuery('cityList', fetchCitiesList)

  const handleAdd = () => {
    setLaneList((prev) => [
      ...prev,
      {
        origin_cities: null,
        destination_cities: null,
        id: uuidv4(),
      },
    ]);
  };

  const handleChangeOrigin = (id, value) => {
    setLaneList((prev) =>
      prev.map((lane) => {
        if (lane.id === id) {
          return {
            ...lane,
            origin_cities: value,
          };
        }
        return lane;
      })
    );
  };

  const handleChangeDestination = (id, value) => {
    setLaneList((prev) =>
      prev.map((lane) => {
        if (lane.id === id) {
          return {
            ...lane,
            destination_cities: value,
          };
        }
        return lane;
      })
    );
  };

  const handleDelete = (id) => {
    setLaneList((prev) => prev.filter((lane) => lane.id !== id));
  };

  return (
    <>
      <PickrrHeading size={5}>Lane</PickrrHeading>
      {!!laneList.length && (
        <Row gutter={16} className="mb-16">
          <Col span={10}>
            <PickrrHeading size={5}>
              Origin city
            </PickrrHeading>
          </Col>
          <Col span={10}>
            <PickrrHeading size={5}>
              Destination city
            </PickrrHeading>
          </Col>
          <Col span={4}>{/* <Button type="link">Reset</Button> */}</Col>
        </Row>
      )}
      {laneList.map((lane, index) => (
        <>
          <Row gutter={16} className="mb-16" key={index}>
            <Col span={10}>
              <CustomSelect
                showSearch
                placeholder="Select city"
                bordered={false}
                style={{ width: '100%' }}
                size="large"
                value={lane.origin_cities}
                onChange={(value) => handleChangeOrigin(lane.id, value)}
                loading={isCitiesDataLoading}
              >
              {
                citiesData?.data?.cities?.length 
                ? 
                citiesData?.data?.cities.map((city, index) => {
                  if(city !== "BANGLORE") {
                    return (
                    <Option value={city} key={index}>
                    {city}
                    </Option>
                    )
                  }
                }) 
                :
                null
              }
              </CustomSelect>
            </Col>
            <Col span={10}>
              <CustomSelect
                showSearch
                placeholder="Select city"
                bordered={false}
                style={{ width: '100%' }}
                size="large"
                value={lane.destination_cities}
                onChange={(value) => handleChangeDestination(lane.id, value)}
                loading={isCitiesDataLoading}
              >
              {
                citiesData?.data?.cities?.length 
                ? 
                citiesData?.data?.cities.map((city, index) => {
                  if(city !== "BANGLORE") {
                    return (
                    <Option value={city} key={index}>
                    {city}
                    </Option>
                    )
                  }
                }) 
                :
                null
              }
              </CustomSelect>
            </Col>
            <Col span={4}>
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(lane.id)}
              />
            </Col>
          </Row>
        </>
      ))}
      <PickrrButton color="outline" onClick={handleAdd} block>
        {laneList.length ? "Add more" : "Add"}
      </PickrrButton>
    </>
  );
};

export default Lane;
