import React, { useContext, useEffect, useState } from 'react';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';

import {
  Container,
  CustomForm,
  SubText,
  FlexColContainer,
} from '../GeneralSettings/style';
import Header from '../../CommonComponents/Header';
import { PickrrButton } from 'components/UIElements';
import {
  Col,
  Row,
  Form,
  Input,
  Divider,
  Checkbox,
  Upload,
  message,
  Space,
  notification,
  Card,
  Button,
  Select
} from 'antd';
import Uploader from '../../CommonComponents/ImageUploader';
import moment from 'moment';
// import {
//   downloadInventoryCsv,
//   fetchSellerCheckoutConfig,
//   updateSellerCheckoutConfig,
// } from 'redux/actions/manageCheckout';
// import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { InboxOutlined } from '@ant-design/icons';
import {
  FlexContainer,
  FlexEndContainer,
  SpaceBetweenContainer,
} from 'components/UIElements';
import { useMutation, useQuery } from 'react-query';
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
} from '../GeneralSettings/script';
import { BlueText, CustomDragger } from './style';
import { GetAuthToken } from 'globalQuries';
import { DataContext } from 'context/DataProvider';

const { Option } = Select;

const ManageInventory = () => {
  const auth = GetAuthToken();
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );
  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
      // navigate('/settings/shipping');
    },
  });
  const [form] = Form.useForm();
  const [state, setState] = useState({
    loading: false,
  });
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const {channelPlatForm} = useContext(DataContext);
  // const user = useSelector((state) => state.user);

  // useEffect(() => {
  //   if (data === null) {
  //     dispatch(fetchSellerCheckoutConfig());
  //   }
  // }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        ...(!!data?.pickup_config && {
          pickup_config: {
            ...data.pickup_config,
            cut_off_time: moment(data.pickup_config.cut_off_time, 'HH:mm'),
          },
        }),
      });
    }
  }, [data]);

  const submitForm = () => {
    let postData = { ...form.getFieldValue() };
    if (
      postData.pickup_config &&
      postData.pickup_config.cut_off_time.length !== 5
    ) {
      postData.pickup_config.cut_off_time = moment(
        postData.pickup_config.cut_off_time
      ).format('HH:mm');
    }
    mutation.mutate(postData);
  };

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      console.log(file.name);
      setUploadFile([file]);
      return false;
    },
  };

  const uploadCSV = async () => {
    const auth = GetAuthToken();
    setLoading(true);
    const formData = new FormData();
    if (uploadFile.length > 0) {
      formData.append('csv_file', uploadFile[0]);
    }
    formData.append('seller_id', auth);
    formData.append(
      'default_from_pincode',
      form.getFieldValue('default_from_pincode')
    );
    formData.append(
      'inventory_syncup',
      form.getFieldValue('inventory_syncup')
    );

    // if(uploadFile.length) {
    //   dataToSend = {
    //     ...dataToSend,
    //     csv_file: uploadFile[0]
    //   }
    // }

    const response = await axios.post(
      `${process.env.REACT_APP_PICKRR_CHECKOUT}/inventory/add/`,
      formData,
      {
        headers: {
          Authorization: auth,
        },
      }
    );

    setLoading(false);
    if (response.data.success) {
      notification.success({
        message: 'Uploaded Successfully!',
      });
    } else {
      notification.error({
        message: response.data.message,
      });
    }
  };
  const { Dragger } = Upload;

  return (
    <Container>
      <Header title="Inventory" submitForm={submitForm} />
      <div style={{ color: '#0C3784', fontWeight: 800, padding: ' 16px' }}>
        Download Sample CSV Template
      </div>
      <CustomForm layout="vertical" form={form} onFinish={submitForm}>
        <Row gutter={[10, 10]}>
          {/* <Col xl={20} lg={20} md={20} sm={24} x={24}>
            <Form.Item label="Upload master inventory list">
              <Dragger
                {...uploadProps}
                showUploadList={false}
                style={{ width: '50%', height: '50px' }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                {uploadFile && uploadFile.length > 0 ? (
                  uploadFile[0].name
                ) : (
                  <>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">Csv File</p>
                  </>
                )}
              </Dragger>
            </Form.Item>
          </Col> */}
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SpaceBetweenContainer>
              <div style={{ color: '#0C3784' }}>
                Easily understand how to organise your CSV by downloading our
                template
              </div>

              <FlexEndContainer>
                <a
                  href={`${process.env.REACT_APP_PICKRR_CHECKOUT}/inventory/retrieve?seller_id=${auth}&sample=True`}
                  download
                >
                  <PickrrButton
                    color="outline"
                    style={{alignItems:'center'}}
                    weight="bold"
                  >
                   <span style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                      <img
                        src="https://d10srchmli830n.cloudfront.net/1619690467552_download_icon.svg"
                        alt="download"
                      />
                    <span>Download Sample CSV</span>
                    </span>
                  </PickrrButton>
                </a>
              </FlexEndContainer>
            </SpaceBetweenContainer>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <Card
              style={{
                borderRadius: '12px',
                border: '2px dashed #C6C8E3',
                background: '#EDF0F9',
                marginTop: '20px',
              }}
              className="mb-16"
            >
              <CustomDragger {...uploadProps} showUploadList={false}>
                <Row
                  gutter={16}
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Col
                    span={14}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FlexContainer>
                      <img
                        src="https://d10srchmli830n.cloudfront.net/1619687675111_bulk_cvs.svg"
                        alt="bulk"
                      />
                      <BlueText
                        style={{
                          wordBreak: 'break-word',
                          textAlign: 'left',
                          paddingLeft: 12,
                        }}
                      >
                        Drop your CSV here or Select it by clicking upload
                      </BlueText>
                    </FlexContainer>
                  </Col>
                  <Col span={8}>
                    <FlexContainer>
                      <PickrrButton color="checkout-blue" weight="bold">
                        {uploadFile.length ? (
                          uploadFile[0].name.substring(0, 15)
                        ) : (
                          <>
                            <img
                              src="https://d10srchmli830n.cloudfront.net/1619705858646_upload.svg"
                              alt="upload"
                              style={{ marginRight: '5px' }}
                            />{' '}
                            Upload
                          </>
                        )}
                      </PickrrButton>
                    </FlexContainer>
                  </Col>
                </Row>
              </CustomDragger>
            </Card>
          </Col>

          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <SpaceBetweenContainer
              style={{ border: 'none', color: '#0C64DB', width: '174px' }}
            >
              <a
                href={`${process.env.REACT_APP_PICKRR_CHECKOUT}/inventory/retrieve/?seller_id=${auth}`}
                download
                style={{ display: 'flex' }}
              >
                <div>
                  <img
                    src="https://d10srchmli830n.cloudfront.net/1648535907029_Download.svg"
                    alt="download"
                  />
                </div>
                <div style={{ marginLeft: '5px', marginTop: '3px' }}>
                  Download Current CSV
                </div>
              </a>
            </SpaceBetweenContainer>
            {/* <Button
              style={{ border: 'none', color: '#0C64DB' }}
              icon={
                <img
                  src="https://d10srchmli830n.cloudfront.net/1648535907029_Download.svg"
                  alt="download"
                />
              }
              weight="bold"
              className="mb-24"
              // onClick={() => dispatch(downloadInventoryCsv())}
            >
              Download Current CSV
            </Button> */}
          </Col>

          <Col xl={6} lg={6} md={24} sm={24} x={24}>
            <Form.Item label="Default Pincode" name="default_from_pincode">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24} style={{marginTop:'-15px'}}>
            {channelPlatForm !== 'woocommerce' &&
              <Form.Item 
              label="Inventory Syncup" 
              name="inventory_syncup"
            >
              <Select>
                <Option value={0}>No Sync To Shopify - Infinite Inventory</Option>
                <Option value={1}>Sync To Shopify - Infinite Inventory</Option>
                <Option value={2}>Sync To Shopify - Finite Inventory</Option>
              </Select>
            </Form.Item>  
            } 
          
          </Col>
          <Divider />
          <PickrrButton onClick={uploadCSV}>Save</PickrrButton>
        </Row>
      </CustomForm>
    </Container>
  );
};

export default ManageInventory;
