import styled from 'styled-components';
import { Layout, Divider, Menu, Button } from 'antd';

const { Content } = Layout;

export const ContentX = styled(Content)`
  min-height: calc(100vh - 69px);
  margin: 16px;
`;
export const SkeletonX = styled.div`
  min-height: calc(100vh - 69px);
  margin: 16px;
`;
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CustomDivider = styled(Divider)`
  .ant-divider-horizontal {
    width: 165px;
    margin: 16px auto;
    border-top: 1px solid rgb(238 231 231 / 38%) !important;
  }
`;

export const CustomMenu = styled(Menu)`
  .ant-menu-item, .ant-menu-submenu-title  {
    height: 50px;
    font-size: 16px;
  }

  .ant-menu-submenu-open{
    color: #000b34 !important;
    // background:#fff;
  }

  // .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-title-content{
  //   color: #000b34 !important;
  // }


  // .ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-submenu-arrow{
  //   ::before {
  //     background : #000b34  !important;
  //   }
  //   ::after {
  //     background : #000b34  !important;
  //   }
  // }


  .ant-menu-title-content{
    color: #000b34 !important;
  }

  .ant-menu-submenu-title > .ant-menu-title-content{
    color:rgba(255, 255, 255, 0.8) !important; 
  }
  
  .ant-menu-sub  > .ant-menu-item-selected{
    background: #5A5A7A !important;  
    color: rgba(255, 255, 255, 0.4);
  }


  .ant-menu-sub > .ant-menu-item-selected > .ant-menu-title-content > a {
     color:rgba(255, 255, 255, 0.8) !important;
     font-size:12px; 
  }

  .ant-menu-sub > .ant-menu-item > .ant-menu-title-content > a {
     color:rgba(255, 255, 255, 0.8) !important;
     font-size:12px; 
  }


  .ant-menu-item-selected {
    background-color: #fff !important;
    /* border-radius: 12px; */
  }
  .ant-menu-item-selected .ant-menu-title-content a {
    color: #000b34 !important;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  .ant-menu-inline.ant-menu-root
    .ant-menu-submenu-title
    > .ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #000;
  }
  .ant-menu-item-selected  a {
    color:  color: #000b34 !important;
}
`;

export const NavLinks = styled.span`
font-size: 14px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 20px;
letter-spacing: normal;
text-align: left;
color: ${(props) => (props.color === 'red' ? '#e42536' : '#303b91')};
svg {
  fill: ${(props) => (props.color === 'red' ? '#e42536' : '#303b91')};
}
letter-spacing: 0.2px;
display: flex;
align-items: center;
/* :hover {
  color: #e42536;
}
:hover svg {
  fill: #e42536;
} */
`;
export const RechargeButton = styled(Button)`
  background: #263F97 !important;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  outline: none !important;
  background: #198bfb;
  border: none !important;
  color: #ffffff;
  width: 129px;
  height: 38px;

  &:focus {
    outline: none !important;
    background: #198bfb;
    color: #263f97;
    border: none !important;
    color: #ffffff;
  }
  &:hover {
    background: #198bfb;
    color: #263f97;
    outline: none !important;
    border: none !important;
    color: #ffffff;
  }
`;

export const PricingButton = styled(Button)`
  background: #ffffff !important;
  border-radius: 5px;
  font-size: 14px;
  outline: none !important;
  color: #263f97;
  border: 1px solid #263F97;
  height: 38px;
  margin-right : 10px;
  font-weight : 600;

  &:focus {
    outline: none !important;
    background: #ffffff;
    color: #263f97;
    border: 1px solid #263F97;
  }
  &:hover {
    background: #ffffff;
    color: #263f97;
    outline: none !important;
    border: 1px solid #263F97;
  }
`