import react from 'react';
import { Input, Row, Col } from 'antd';
import { InputContainer } from 'pages/ChargeRule/styled';

// </InputContainer>
const RuleOperation = ({
    hiddenPaymentMethods, 
    setHiddenPaymentMethods, 
    hiddenShippingMethods, 
    setHiddenShippingMethods,
    ruleName
}) => {
    return (
    

        <div style={{padding: 100}}>
        <Row gutter={[16, 16]} justify = "center">
            <Col>
            {ruleName === 'PAYMENT_MODE_DISABLE' && 
                <InputContainer>
                    <div style={{color: '#0c3784', fontWeight: 600, marginBottom: 6}}>Enter the name of payment option you want to hide</div>
                    <Input  
                        placeholder='Enter the name of payment option you want to hide'
                        value = {hiddenPaymentMethods}
                        onChange={(e) => setHiddenPaymentMethods(e.target.value)}
                    />
                </InputContainer>
            }
            </Col>
            <Col>
            {ruleName === 'DELIVERY_MODE_DISABLE' && 
            <InputContainer>
                <div style={{color: '#0c3784', fontWeight: 600, marginBottom: 6}}>Enter the name of shipping option you want to hide from checkout</div>
                <Input 
                    placeholder='Enter the name of shipping option you want to hide from checkout'
                    value = {hiddenShippingMethods}
                    onChange={(e) => setHiddenShippingMethods(e.target.value)}
                />
                </InputContainer>
            }
            </Col>
        </Row>
        </div>
    )
}

export default RuleOperation;