import React, {useState, useContext} from 'react';
import {Button, Checkbox, message, Select} from 'antd';
import { FlexBox, SpaceAroundContainer, SpaceBetweenContainer } from 'components/UIElements';
import moment from 'moment';
import {Text, CustomTable, CustomDatePicker, CustomInput, PaymentStatus} from './styled';
import{TableContainer} from '../../Orders/OrdersTab/styled';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import { useQuery } from 'react-query'
import {fetchRefundData, handleAllDownload, handleMultipleDownload} from './script';
import {IRefundDetails} from '../data';
import { useNavigate } from 'react-router-dom';
import RefundCalModal from '../../Orders/OrdersTab/Refund/RefundCalModal'
import { DataContext } from 'context/DataProvider';



const { Option } = Select

const Refunds  = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
    const [fromDate, setFromDate] = useState<string | null>(moment().subtract(6, 'days').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState<string | null>(moment().format('YYYY-MM-DD'));
    const [searchKey, setSearchKey] = useState<string>('client_order_id');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0);
    const [inputValue, setInputValue] = useState('');
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [isRefundModalOpen, setIsRefundModalOpen] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>();
    const navigate = useNavigate();
    const {sellerId, IpAddress} = useContext(DataContext)
    const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)
    const disabledDates = (current: any) => {
        return current > moment()
      }
      const {
        data: refundData,
        isLoading,
        } = useQuery(
        ['ordersRefundData',{pageNumber, sellerId, fromDate, toDate, searchTerm, ...(searchTerm && {searchKey})}],
        fetchRefundData,{cacheTime:0},
    )
      const refundTextColor:any = {
        'success':'#399F50',
        'pending':'#125CCC',
        'failed':'#D65745'
      }
    
      const refundBackgroundColor:any = {
        'success':'#ecfaef',
        'pending':'#125ccc1a',
        'failed':'#fff0ee'
      }

    const onSelect = (record: any, selected: boolean) => {
        if (selected) {
            setSelectedRowKeys((keys) => [...keys, record?.ref_id])
            // setSelectedRows((rows:any) => [...rows, record])
        } else {
            setSelectedRowKeys((keys) => [...keys.filter(key => key !== record?.ref_id)])
            // setSelectedRows((rows:any) => [...rows.filter((row:any) => row?.ref_id !== record?.ref_id)])
        }
    }

    const onSelectAll = () => {
        setSelectedRowKeys((keys) => keys?.length === refundData?.refunds?.length ? [] : [...refundData?.refunds?.map((key:any) => key?.ref_id)])
        // setSelectedRows((rows) => rows.length === tableData?.length ? [] : [...tableData])
    }

    let columns: object[] = [
        {
          title: 'Client Order ID',
          key: 'refId',
          render: (rowData: IRefundDetails) => (
            <Text>{rowData?.client_order_id}</Text>
          )
        },
        {
          title: 'Payment Gateway',
          key: 'paymentPlatform',
          render: (rowData: IRefundDetails) => (
            <Text>{rowData?.payment_platform}</Text>
        )
        },        
        {
          title: <span>PG Transaction ID |<br/>PG Transaction date</span>,
          key: 'pgTransactionId',
          width: 185,
          render: (rowData: IRefundDetails) => (
            <>
                <Text>{rowData?.pg_transaction_id}</Text>
                <Text>{rowData?.pg_transaction_date}</Text> 
            </>
        )
        },
        {
          title: 'Payment amount',
          key: 'amount',
          render: (rowData: IRefundDetails) => (
            <Text>₹{rowData?.payment_amount?.toFixed(2)}</Text> 
          )
        },
        {
            title: <span>Refund Transaction ID |<br /> Refund Transaction Date</span>,
            key: 'pgRefundTxnId',
            width: 200,
            render: (rowData: IRefundDetails) => (
                <>
                    <Text>{rowData?.pg_refund_txn_id}</Text> 
                    <Text>{rowData?.updated_at}</Text> 
                </>
            )
        },
        {
            title: 'Refunded amount',
            key: 'refundedAmount',
            render: (rowData: IRefundDetails) => (
                <Text>₹{rowData?.amount?.toFixed(2)}</Text> 
            )
        },
        {
            title: 'Refund Initiated by',
            key: 'refundInitiatedBy',
            render: (rowData: IRefundDetails) => (
                <Text>{rowData?.initiated_by}</Text> 
            )
        },
        {
            title: 'Payment status',
            key: 'status',
            render: (rowData: IRefundDetails) => (
                <>
                    <PaymentStatus
                        textColor= {refundTextColor[rowData?.status?.toLowerCase()]}
                        bgColor= {refundBackgroundColor[rowData?.status?.toLowerCase()]}
                    >
                        {`${rowData?.status?.[0]?.toLocaleUpperCase()}${rowData?.status?.slice(1)?.toLocaleLowerCase()}`}
                    </PaymentStatus> 
                    <div 
                        style={{marginLeft: 12, cursor: 'pointer', fontSize:12, textDecoration: 'underline', color: '#0C64DB'}}
                        onClick= {() => {
                            setIsRefundModalOpen(true)
                            setRowData(rowData)
                        }} 
                    >
                        view more
                    </div>
                </>

            )
        },
    
        // {
        //   title: 'Action',
        //   fixed:'right',
        //   key: 'action',
        //   width: 100,
        //   render: (rowData: IRefundDetails) => (
        //       <SpaceAroundContainer>
        //           <div 
        //             // onClick={(e) => {
        //             //       e.stopPropagation()
        //             //       handleSingleDownload(rowData.orderDetails.orderId, fromDate, toDate)
        //             //   }} style= {{cursor: 'pointer'}}
        //           >
        //               <img
        //                   src="https://d10srchmli830n.cloudfront.net/1650360846221_Download.svg"
        //                   alt="download"
        //               />
        //               <Text
        //                   style={{ color: '#0C64DB', whiteSpace: 'nowrap' }}
        //               >
        //                   Download
        //               </Text>
        //           </div>
        //       </SpaceAroundContainer>
        // )
        // },
    ]
 
    const onSearchKeyChange = (value: string) => {
        setSearchKey(value)
    }
    
    const onSearch = (value: string) => {
        setSearchTerm(value)
        setPageNumber(1)
    }

    const selectBefore = (
        <Select 
            value={searchKey}
            className="select-before" 
            style={{width:'150px', textAlign:'left', marginLeft:'10px'}}
            onChange={onSearchKeyChange}
        >
            <Option value="clientOrderId">Client Order Id</Option>
            <Option value="pgTxnId">Pg Txn Id</Option>
            <Option value="platformOrderId">Platform order Id</Option>
            <Option value="pgRefundTxnId">Pg Refund Txn Id</Option>
            <Option value="status">Status</Option>
        </Select>
    )

    // const sellerId = "62175c53b2b9830831b586ec"


    return(
        <>
        <div style={{
            display:'flex',
            justifyContent: 'flex-end',
            marginBottom: 16
        }}>
            <CustomDatePicker
                allowClear={false}
                onChange={(dates: Array<moment.Moment>) => {
                if (dates[1].diff(dates[0], "days") > 60) {
                    message.error(
                    "Date range can not exceed 60 days. Please select date range less than or equal to 60 days only.",
                    1.5
                    );
                } else {
                    dates && dates[0]
                    ? setFromDate(()=>{return dates[0].format("YYYY-MM-DD")})
                    : setFromDate(null);
                    dates && dates[1]
                    ? setToDate(()=>dates[1].format("YYYY-MM-DD"))
                    : setToDate(null);
                }
                }}
                ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                ],
                "Last 7 Days": [moment().subtract(6, "d"), moment()],
                "This Month": [moment().startOf("month"), moment()],

                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                }}
                defaultValue={
                [
                    moment(fromDate, "YYYY-MM-DD"),
                    moment(toDate, "YYYY-MM-DD"),
                ]
                }
                disabledDate={disabledDates}
            />
        </div>


    <TableContainer>
        <SpaceBetweenContainer>

        <div style={{width: 380}}>
            <CustomInput 
                addonBefore={selectBefore}
                placeholder="Search..."
                onPressEnter={(e: any) => {
                    if(!searchTerm) {
                        setPrevPageNumber(pageNumber)
                    }
                    if(e.target.value) {
                        onSearch(e.target.value)
                    }
                }}
                onChange={(e: any) => {
                    setInputValue(e.target.value)
                    if(!e.target.value) {
                        onSearch('')
                        setPageNumber(prevPageNumber)
                    }
                }}
                value = {inputValue}
            />
        </div>

        <Button type="primary" 
        /* @ts-ignore */
            icon={<DownloadOutlined />}
            style={{background:'#0C64DB', borderRadius:'6.5px'}}
            onClick={() => {
                selectedRowKeys?.length ?
                handleMultipleDownload(fromDate, toDate, selectedRowKeys?.join(','),setIsDataDownloading) :
                handleAllDownload(fromDate, toDate, searchKey, searchTerm,setIsDataDownloading)
            }}
        >
            {selectedRowKeys?.length ? `Download Selected (${selectedRowKeys?.length})` : 'Download All'}
        </Button>
        </SpaceBetweenContainer>
        <CustomTable
            loading={isLoading}
            dataSource={refundData?.refunds}
            columns={columns} 
            style={{marginTop:'20px'}}
            rowSelection={{
                selectedRowKeys,
                type: 'checkbox',
                onSelect: onSelect,
                columnTitle: 
                <Checkbox 
                    checked={refundData?.refunds?.length && selectedRowKeys?.length === refundData?.refunds?.length} 
                    onChange={onSelectAll}
                    disabled={refundData?.refunds?.length ? false : true}
                />
            }}
            rowKey={(record: any) => (record?.ref_id)}
            pagination={{
                pageSize: 20,
                showSizeChanger: false,
                total: refundData?.total_refunds,
                current: pageNumber,
                onChange: (page) => {
                    setPageNumber(page);
                },
                position: ['bottomRight'],
                }}
                scroll={{x : 1000}}
        />
        </TableContainer>
        <RefundCalModal 
            isRefundModalOpen = {isRefundModalOpen} 
            setIsRefundModalOpen = {setIsRefundModalOpen}
            rowData = {rowData}
            activeKey = {Math.random() * 73.23}
        />
        </>
    )
}

export default Refunds;