import {
  Container,
  DetailHeadingBackground,
  DetailsHeading,
  BackButtonText,
  StepContainer,
  StepTextContainer,
  StepHeading,
  StepText,
  CountText,
} from "./style";

import { LeftOutlined, CopyOutlined } from "@ant-design/icons";
import {
  FlexBox,
  SpaceBetweenContainer,
} from "../../../../components/UIElements";
import { useState } from "react";
import React, { useEffect } from "react";
import { CustomForm, PickrrButton } from "../../GeneralSettings/style";
import {
  Col,
  Divider,
  Form,
  Input,
  message,
  notification,
  Row,
  Space,
  Typography,
} from "antd";

import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getGeneralDetails,
  updateSellerCheckoutConfig,
} from "components/Settings/GeneralSettings/script";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GetAuthToken } from "globalQuries";

const ManageChannelWoocommerce = () => {
  const history = useNavigate();

  const [pageStep, setPageStep] = useState(1);
  const [activateKey, setActivateKey] = useState("");
  const [apiKeyName, setApiKeyName] = useState("");
  const [apiKeySecret, setApiKeySecret] = useState("");
  const [domainName, setDomainName] = useState("");
  const handleDomainNameChange = (e) => {
    setDomainName(e.target.value);
  };
  const handleApiKeyNameChange = (e) => {
    setApiKeyName(e.target.value);
  };
  const queryClient = useQueryClient();
  const handleApiKeySecretChange = (e) => {
    setApiKeySecret(e.target.value);
  };
  const { Paragraph } = Typography;

  const downloadZip = () => {
    window.open(
      "https://pickrr.s3.amazonaws.com:443/2022-12-08T17:56:14.454463_fastrr-checkout-plugin.zip"
    );
  };

  const goBack = () => {
    if (pageStep == 1 || pageStep == 3) history(-1);
    else setPageStep(pageStep - 1);
  };
  const { data, isLoading, isSuccess } = useQuery(
    "generalSettings",
    getGeneralDetails
  );
  // if(data?.platform_details?.woocommerce?.admin_api_key)
  // setPageStep(3);
  useEffect(() => {
    if (data?.platform_details?.woocommerce?.admin_api_key) setPageStep(3);
    setDomainName(data?.domain_name);
    setActivateKey(
      data?.platform_details?.woocommerce?.admin_api_shared_secret
    );
    setApiKeyName(data?.platform_details?.woocommerce?.admin_api_key);
    setApiKeySecret(data?.platform_details?.woocommerce?.admin_api_password);
  }, [data]);
  const getSecretKey = async () => {
    const postData = { ...form.getFieldValue() };
    if (apiKeyName && apiKeySecret) {
      const auth = GetAuthToken();
      const postBody = {
        id: data?._id
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/seller/activate`,
          postBody,
          {
            headers: {
              Authorization: auth,
            },
          }
        );
        const key = response?.data?.data?.client_id;
        form.setFieldsValue({
          ...data,
          admin_api_shared_secret: key,
          ...(!!data?.platform_details && {
            platform_details: {
              channel: "woocommerce",
              ...data?.platform_details,
              woocommerce: {
                ...data?.platform_details?.woocommerce,
                admin_api_key: apiKeyName,
                admin_api_password: apiKeySecret,
                admin_api_shared_secret: key,
                storefront_access_token: "null",
                store_name: postData.display_store_name,
              },
            },
          }),
        });
        setPageStep(2);
      } catch (e) {
        message.error(e);
      }
    } else {
      message.error("Please fill in the keys");
    }
  };
  const moveToPage3 = () => {
    let postData = { ...form.getFieldValue() };
    if (
      postData?.domain_name &&
      postData?.admin_api_shared_secret
    ) {
      const api_key = postData?.admin_api_shared_secret;
      const domain = postData?.domain_name;
      setActivateKey(api_key);
      setDomainName(postData?.domain_name);

      postData = {
        ...postData,
        platform_details: {
          ...data?.platform_details,
          channel: "woocommerce",
          woocommerce: {
            ...data?.platform_details?.woocommerce,
            admin_api_key: apiKeyName,
            admin_api_password: apiKeySecret,
            admin_api_shared_secret:apiKeyName,
            storefront_access_token:apiKeySecret,
            store_name: domain
          },
        },
      }

      if (
        postData.pickup_config &&
        postData.pickup_config.cut_off_time.length !== 5
      ) {
        postData.pickup_config.cut_off_time = moment(
          postData.pickup_config.cut_off_time
        ).format("HH:mm");
      }

      if (postData?.domain_name && postData?.domain_name?.length > 0) {
        //remove https
        let newUrl = postData?.domain_name?.replace(/(^\w+:|^)\/\//, "");
        //remove special characters
        postData["domain_name"] = newUrl?.replace(/^\/|\/$/g, "")?.toLowerCase();
      }
      
      // if(postData?.platform_details){
      //   postData["platform_details"]["channel"] = "woocommerce";
      // }

      mutation.mutate(postData);
    } else {
      message.error("Please fill in all the fields");
    }
  };
  const copyAdminKey = () => {
    let postData = { ...form.getFieldValue() };
    navigator.clipboard.writeText(
      postData?.platform_details?.woocommerce?.admin_api_shared_secret
    );
    message.success("Activate Key Copied");
  };

  const [form] = Form.useForm();

  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: "Updated seller details." });
      setPageStep(3);
      queryClient.invalidateQueries("generalSettings");

      // navigate('/settings/shipping');
    },
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        ...(!!data?.pickup_config && {
          pickup_config: {
            ...data.pickup_config,
            cut_off_time: moment(data.pickup_config.cut_off_time, "HH:mm"),
          },
        }),
      });
    }
  }, [data]);

  const submitForm = () => {
    if (domainName) {
      form.setFieldsValue({
        ...data,
        domain_name: domainName,
        ...(!!data?.platform_details && {
          platform_details: {
            ...data?.platform_details,
            channel: "woocommerce",
            woocommerce: {
              ...data?.platform_details?.woocommerce,
              admin_api_key: apiKeyName,
              admin_api_password: apiKeySecret,
              admin_api_shared_secret: activateKey,
              storefront_access_token: "null",
            },
          },
        }),
      });

      let postData = { ...form.getFieldValue() };

      if (
        postData.pickup_config &&
        postData.pickup_config.cut_off_time.length !== 5
      ) {
        postData.pickup_config.cut_off_time = moment(
          postData.pickup_config.cut_off_time
        ).format("HH:mm");
      }

      if (postData["domain_name"] && postData["domain_name"].length > 0) {
        //remove https
        let newUrl = postData["domain_name"].replace(/(^\w+:|^)\/\//, "");
        //remove special characters
        postData["domain_name"] = newUrl.replace(/^\/|\/$/g, "").toLowerCase();
      }
      mutation.mutate(postData);
    } else message.error("Please fill the domain name.");
  };
  return (
    <Container>
      <DetailHeadingBackground>
        <SpaceBetweenContainer>
          <DetailsHeading>
            {pageStep != 3 ? (
              <BackButtonText onClick={goBack}>
                <LeftOutlined style={{ paddingRight: 8 }} />
                Back
              </BackButtonText>
            ) : (
              <BackButtonText>&nbsp;</BackButtonText>
            )}
            Woo Commerce
          </DetailsHeading>
          <img
            src="https://d10srchmli830n.cloudfront.net/1619674941295_app-logo-woocommerce.png"
            alt="logo"
            height={100}
            style={{
              marginRight: "2rem",
            }}
          />
        </SpaceBetweenContainer>
      </DetailHeadingBackground>
      {pageStep == 1 && (
        <CustomForm layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col md={12} sm={24}>
              <SpaceBetweenContainer>
                <Form.Item label="API Keys" style={{ width: "75%" }}>
                  <Input
                    value={apiKeyName}
                    onChange={handleApiKeyNameChange}
                    placeholder="Consumer Key"
                  />
                </Form.Item>

                <PickrrButton onClick={getSecretKey}>Save</PickrrButton>
              </SpaceBetweenContainer>
              <Form.Item style={{ width: "75%" }}>
                <Input
                  value={apiKeySecret}
                  onChange={handleApiKeySecretChange}
                  placeholder="Consumer Secret"
                />
              </Form.Item>
            </Col>
            <Col md={12} sm={24}>
              <StepContainer>
                <FlexBox>
                  <div>
                    <img
                      src="https://d10srchmli830n.cloudfront.net/1619675129703_arrow.svg"
                      alt="arrow"
                    />
                  </div>

                  <StepHeading style={{ marginLeft: "10px" }}>
                    Steps to Generate the API Keys.
                  </StepHeading>
                </FlexBox>
                <StepTextContainer>
                  <CountText>1</CountText>
                  <StepText>
                    Login to your Wordpress account and open Woo-commerce
                    Settings.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>2</CountText>
                  <StepText>
                    Open <b>Advanced</b> tab in Woo-commerce settings.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>3</CountText>
                  <StepText>
                    Open the <b>Rest API</b> tab (beside 'Page Setup') within
                    Advanced Settings.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>4</CountText>
                  <StepText>
                    Click on <b>Add Key</b> button within <b>Rest API</b> tab.{" "}
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>5</CountText>
                  <StepText>
                    On the page that opens, write <b>Fastrr</b> in the{" "}
                    <b>Description</b> field and click on Generate API Key.{" "}
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>6</CountText>
                  <StepText>
                    Copy the <b>Consumer Key</b> and <b>Consumer Secret</b> and
                    paste them in the given boxes. Click <b>Save</b> to
                    continue.{" "}
                  </StepText>
                </StepTextContainer>

                <StepTextContainer>
                  {/* <CountText style={{ background: 'transparent' }}>
                    Note:
                  </CountText> */}
                </StepTextContainer>
              </StepContainer>
            </Col>
          </Row>
        </CustomForm>
      )}
      {pageStep == 2 && (
        <CustomForm layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col md={10} sm={24}>
              <PickrrButton onClick={downloadZip}>
                Download Plugin File
              </PickrrButton>

              <Form.Item
                label="Activate key"
                // name={[
                //   "platform_details",
                //   "woocommerce",
                //   "admin_api_shared_secret",
                // ]}

                name="admin_api_shared_secret"

                style={{ marginTop: "15px" }}
              >
                <Input addonAfter={<CopyOutlined onClick={copyAdminKey} />} />
              </Form.Item>
              <Form.Item label="Domain Name" name="domain_name">
                <Input addonBefore="https://" />
              </Form.Item>
              <PickrrButton onClick={moveToPage3} style={{ top: "53%" }}>
                Done
              </PickrrButton>
            </Col>
            <Col md={14} sm={24}>
              <StepContainer>
                <FlexBox>
                  <div>
                    <img
                      src="https://d10srchmli830n.cloudfront.net/1619675129703_arrow.svg"
                      alt="arrow"
                    />
                  </div>

                  <StepHeading style={{ marginLeft: "10px" }}>
                    Steps to Install Fastrr Plugin
                  </StepHeading>
                </FlexBox>
                <StepTextContainer>
                  <CountText>1</CountText>
                  <StepText>
                    Download the plugin file by clicking on <b>Download</b>.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>2</CountText>
                  <StepText>
                    Login to you Wordpress account and click on Plugins in the
                    side menu
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>3</CountText>
                  <StepText>
                    On the page that opens, click on <b>Upload</b> in the top
                    right hand corner.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>4</CountText>
                  <StepText>
                    Click on <b>Drop files or click here to install</b> and
                    select the downloaded file in the window that opens to
                    install the plugin.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>5</CountText>
                  <StepText>
                    Wait for the plugin to install after which go to Plugins
                    &gt; &gt; Installed Plugins in the side menu.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>6</CountText>
                  <StepText>
                    Find <b>Fastrr Checkout</b> in the installed plugins. Click
                    on Fastrr Checkout <b>Settings</b>.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>7</CountText>
                  <StepText>
                    Copy the Activation Key mentioned here and paste it in the{" "}
                    <b>Activation Key</b> field key and click on <b>Verify</b>.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  <CountText>8</CountText>
                  <StepText>
                    Configure the CTA as per your requirement and click on{" "}
                    <b>Save Changes</b> to enable Fastrr checkout on your
                    website.
                  </StepText>
                </StepTextContainer>
                <StepTextContainer>
                  {/* <CountText style={{ background: 'transparent' }}>
                    Note:
                  </CountText> */}
                </StepTextContainer>
              </StepContainer>
            </Col>
          </Row>
        </CustomForm>
      )}
      {pageStep == 3 && (
        <CustomForm layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={[16, 16]}>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item label="App Name">
                <Paragraph
                  copyable={{
                    text: `Fastrr Checkout`,
                  }}
                >
                  Fastrr Checkout
                </Paragraph>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item label="Developer Email">
                <Paragraph
                  copyable={{
                    text: `support@pickrr.com`,
                  }}
                >
                  support@pickrr.com
                </Paragraph>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item
                label="Channel Name"
                name={["platform_details", "channel"]}
              >
                <Paragraph
                  copyable={{
                    text: `Woo commerce`,
                  }}
                >
                  Woo commerce
                </Paragraph>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item label="Domain Name">
                <Input
                  value={domainName}
                  onChange={handleDomainNameChange}
                  addonBefore="https://"
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item hidden label="Store Name" name="display_store_name">
                <Input
                  hidden
                  // disabled={
                  //   data?.platform_details?.shopify?.store_name
                  //     ? true
                  //     : false
                  // }
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item
                hidden
                label="API Key"
                name={["platform_details", "woocommerce", "admin_api_key"]}
              >
                <Input
                  hidden
                  disabled={data?.platform_details?.woocommerce?.admin_api_key}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item
                hidden
                label="Password"
                name={["platform_details", "woocommerce", "admin_api_password"]}
              >
                <Input
                  hidden
                  disabled={
                    data?.platform_details?.woocommerce?.admin_api_password
                  }
                />
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item
                hidden
                label="Shared Secret"
                name={[
                  "platform_details",
                  "woocommerce",
                  "admin_api_shared_secret",
                ]}
              >
                <Input
                  hidden
                  disabled={
                    data?.platform_details?.woocommerce?.admin_api_shared_secret
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} x={24}>
              <Form.Item
                hidden
                label="Storefront Access Token"
                name={[
                  "platform_details",
                  "woocommerce",
                  "storefront_access_token",
                ]}
              >
                <Input
                  hidden
                  disabled={
                    data?.platform_details?.shopify?.storefront_access_token
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Space>
            <PickrrButton htmlType="submit">Save</PickrrButton>
          </Space>
        </CustomForm>
      )}
    </Container>
  );
};
export default ManageChannelWoocommerce;
