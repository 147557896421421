import React from 'react';
import { FlexColContainer, PickrrHeading, FlexBox } from 'components/UIElements';

import { ServiceTypeContainer, CheckBox } from './style';

const ServiceType = ({ serviceType, setServiceType }) => {

  const setCheckedServiceType = (type) =>
      serviceType.includes(type) ? 'true' : 'false';

  const handleServiceTypeChange = (type) =>
    serviceType.includes(type)
      ? setServiceType((s) => s.filter((d) => d !== type))
      : setServiceType((s) => [...s, type]);

  return (
    <ServiceTypeContainer>
      <FlexColContainer>
        <PickrrHeading size={5}>
          Service type
        </PickrrHeading>
        <FlexBox className="mb-16">
          <CheckBox
            checked={setCheckedServiceType('express')}
            onClick={() => handleServiceTypeChange('express')}
          >
            Express
          </CheckBox>
          <CheckBox
            checked={setCheckedServiceType('surface')}
            onClick={() => handleServiceTypeChange('surface')}
          >
            Surface
          </CheckBox>
        </FlexBox>
      </FlexColContainer>
    </ServiceTypeContainer>
  );
};

export default ServiceType;
