import React from "react";
import AmountOfConfig from "./AmountOfConfig";
import BXGYConfig from "./BXGYConfig";
import BundleConfig from "./BundlConfig";
import UpSellingConfig from "./UpSellingConfig";
import FreebieConfig from "./FreebieConfig";

const Configuration = ({
  discountType,
  customerBuysItems,
  setCustomerBuysItems,
  customerGetsItems,
  setCustomerGetsItems,
  configCustomerTypes,
  setConfigCustomerTypes,
  selectTypes,
  setSelectTypes,
  bundleItem,
  setBundleItem,
  form,
  sellerConfigValues,
  freebieValue,
  setFreebieValue,
  freebieList,
  setFreebieList,
  tieredFlag,
  setTieredFlag,
  isDynamic,
  setIsDynamic
}: any) => {
  return (
    <div>
      {discountType === "bxgy" ? (
        <BXGYConfig
          customerBuysItems={customerBuysItems}
          setCustomerBuysItems={setCustomerBuysItems}
          customerGetsItems={customerGetsItems}
          setCustomerGetsItems={setCustomerGetsItems}
          configCustomerTypes={configCustomerTypes}
          setConfigCustomerTypes={setConfigCustomerTypes}
          discountType={discountType}
          selectTypes={selectTypes}
          setSelectTypes={setSelectTypes}
          form={form}
          sellerConfigValues={sellerConfigValues}
        />
      ) : discountType === "bundle" ? (
        <BundleConfig
          bundleItem={bundleItem}
          setBundleItem={setBundleItem}
          selectTypes={selectTypes}
          setSelectTypes={setSelectTypes}
          form={form}
          sellerConfigValues={sellerConfigValues}
          discountType={discountType}
          isDynamic={isDynamic}
          setIsDynamic={setIsDynamic}
        />
      ) : discountType === "aftersell" ? (
        <UpSellingConfig
          customerBuysItems={customerBuysItems}
          setCustomerBuysItems={setCustomerBuysItems}
          customerGetsItems={customerGetsItems}
          setCustomerGetsItems={setCustomerGetsItems}
          configCustomerTypes={configCustomerTypes}
          setConfigCustomerTypes={setConfigCustomerTypes}
          discountType={discountType}
          selectTypes={selectTypes}
          setSelectTypes={setSelectTypes}
          form={form}
          sellerConfigValues={sellerConfigValues}
        />
      ) : discountType==="freebie"?
      <FreebieConfig
          discountType={discountType}
          bundleItem={bundleItem}
          setBundleItem={setBundleItem}
          selectTypes={selectTypes}
          setSelectTypes={setSelectTypes}
          form={form}
          sellerConfigValues={sellerConfigValues}
          freebieValue={freebieValue}
          setFreebieValue={setFreebieValue}
          freebieList={freebieList}
          setFreebieList={setFreebieList}
        />
      :(
        <AmountOfConfig
          discountType={discountType}
          bundleItem={bundleItem}
          setBundleItem={setBundleItem}
          selectTypes={selectTypes}
          setSelectTypes={setSelectTypes}
          form={form}
          sellerConfigValues={sellerConfigValues}
          tieredFlag={tieredFlag}
          setTieredFlag={setTieredFlag}
        />
      )}
    </div>
  );
};

export default Configuration;
