import { notification } from 'antd';
import axios from 'axios';
import { GetAuthToken } from 'globalQuries';
const auth = GetAuthToken();
// `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/fetch/?seller_id=${auth}`,
export const fetchPickrrSelectRuleList = async (dispatch, getState) => {
  const auth = GetAuthToken();
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/shopify-function-rules/list`,
      {
        headers: {
          Authorization: auth,
        },
      }
    );
    return response.data;
  } catch (error) {
    notification.error({
      message: error.message,
    });
  }
};

export const handleDelete = async (record) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/shopify-function-rules/delete`,
    {
      data: {
        ...record,
      },
      headers: {
        Authorization: auth,
      },
    }
  );

  return response.data;
};

export const handleActivity = async (data) => {
  const auth = GetAuthToken();
  const editData = {
    // rules: [
      // {
        ...data,
        isActive: !data?.isActive,
        // priority: data.priority,
      // },
    // ],
  };
  const response = await axios.post(
    `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/shopify-function-rules/create-or-update`,
    editData,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data;
};

export const handleChangeOrder = async ({
  ruleListArray,
  type,
  index,
  rowData,
}) => {

  const auth = GetAuthToken();
  let editData;
  if (type === 'up') {
    editData = {
      rules: [
        {
          ...rowData,
          rule_id: rowData._id,
          priority: ruleListArray[index - 1].priority,
        },
        {
          ...ruleListArray[index - 1],
          rule_id: ruleListArray[index - 1]._id,
          priority: rowData.priority,
        },
      ],
    };
  } else {
    editData = {
      rules: [
        {
          ...rowData,
          rule_id: rowData._id,
          priority: ruleListArray[index + 1].priority,
        },
        {
          ...ruleListArray[index + 1],
          rule_id: ruleListArray[index + 1]._id,
          priority: rowData.priority,
        },
      ],
    };
  }
  const response = await axios.put(
    `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/update/`,
    editData,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response;
};
