import React from 'react'
import { DatePickerController, InputController, SelectController } from './FormControllers'
type Controller = ({ control, name, rules, ...props }: any) => JSX.Element;

const MAPPING: Record<string, Controller> = {
  input: InputController,
  select: SelectController,
  date_picker: DatePickerController,
}

const getFieldControllers = (type:string) => {
  return MAPPING[type] || InputController;
}

export default getFieldControllers