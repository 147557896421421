import { Col, Row} from 'antd';
import React from 'react';
import {CardHeading, TransactionsHeadingText, TransactionsDetailsText, PaymentStatus} from './styled'
import moment from "moment";
import { EmptyViewContiner, Heading, SubHeading } from 'components/UIElements/EmptyView/styled';


const UtmDetails = ({UtmDetails}:any) => {
   

    return (
        <>
            <CardHeading>UTM Details</CardHeading>
            {UtmDetails ? 
            <Row gutter={[20,20]} style={{display : 'flex', justifyContent : 'space-between'}}>
                <Col span={6} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsHeadingText>Time Visited</TransactionsHeadingText>
                    <TransactionsHeadingText> : </TransactionsHeadingText>
                </Col>
                <Col span={18} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsDetailsText style={{marginTop : '0px',lineBreak : 'anywhere'}}>
                    {UtmDetails?.time_visited ? moment(UtmDetails?.time_visited).utc().format('DD-MMMM-YYYY').toString() : '-'}
                    </TransactionsDetailsText>
                </Col>
                <Col span={6} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsHeadingText>UTM Campaign</TransactionsHeadingText>
                    <TransactionsHeadingText> : </TransactionsHeadingText>
                </Col>
                <Col span={18} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsDetailsText style={{marginTop : '0px',lineBreak : 'anywhere'}}>
                        {UtmDetails?.utm_campaign ?? '-'}
                    </TransactionsDetailsText>
                </Col>
                <Col span={6} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsHeadingText>UTM Content</TransactionsHeadingText>
                    <TransactionsHeadingText> : </TransactionsHeadingText>
                </Col>
                <Col span={18} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsDetailsText style={{marginTop : '0px',lineBreak : 'anywhere'}}>
                        {UtmDetails?.utm_content ?? '-'}
                    </TransactionsDetailsText>
                </Col>
                <Col span={6} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsHeadingText>UTM ID</TransactionsHeadingText>
                    <TransactionsHeadingText> : </TransactionsHeadingText>
                </Col>
                <Col span={18} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsDetailsText style={{marginTop : '0px',lineBreak : 'anywhere'}}>
                    {UtmDetails?.utm_id ?? '-'}
                    </TransactionsDetailsText>
                </Col>
                <Col span={6} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsHeadingText>UTM Medium</TransactionsHeadingText>
                    <TransactionsHeadingText> : </TransactionsHeadingText>
                </Col>
                <Col span={18} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsDetailsText style={{marginTop : '0px',lineBreak : 'anywhere'}}>
                    {UtmDetails?.utm_medium ?? '-'}
                    </TransactionsDetailsText>
                </Col>
                <Col span={6} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsHeadingText>UTM Source</TransactionsHeadingText>
                    <TransactionsHeadingText> : </TransactionsHeadingText>
                </Col>
                <Col span={18} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsDetailsText style={{marginTop : '0px',lineBreak : 'anywhere'}}>
                    {UtmDetails?.utm_source ?? '-'}
                    </TransactionsDetailsText>
                </Col>
                <Col span={6} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsHeadingText>UTM Term</TransactionsHeadingText>
                    <TransactionsHeadingText> : </TransactionsHeadingText>
                </Col>
                <Col span={18} style={{display : 'flex', justifyContent : 'space-between'}}>
                    <TransactionsDetailsText style={{marginTop : '0px',lineBreak : 'anywhere'}}>
                    {UtmDetails?.utm_term ?? '-'}
                    </TransactionsDetailsText>
                </Col>
            </Row>
            :
            <EmptyViewContiner>
                <img 
                    src="https://d10srchmli830n.cloudfront.net/1653465140883_ab08ffde-3852-42c3-aad0-7fc9c855ea5b_Group-27721EmptyDataImage.svg"
                    alt="empty-data"
                />
                <Heading >
                    No Data Available.
                </Heading>
                <SubHeading>
                    Looks likes there is no data available , Please try again later. 
                </SubHeading>
            </EmptyViewContiner>
            }

        </>
    )
}

export default UtmDetails;