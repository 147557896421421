import tw from "tailwind-styled-components";

export const FlexRowTw=tw.div`
    flex 
    justify-end
`

export const TableContainerTw=tw.div`
    bg-white
    h-auto
    rounded-lg
    p-5
`

export const GenerateReportButton=tw.button`
    flex
    gap-1
    h-8
    bg-white
    text-exportText
    rounded-lg
    font-normal
    px-4
    py-1
    min-w-36
    shadow-md
    border
    border-outline
    hover:bg-primary
    hover:text-white
    `

    export const ReportStatusTag=tw.div`
    py-2
    text-sm
    leading-5
    flex
    items-center
    
    
`

export const CustomDownloadButton=tw.button`
    flex
    items-center
    justify-center
    gap-1
    h-12
    bg-purpleText
    text-white
    rounded-xl
    font-normal
    px-4
    py-1
    min-w-48
    border
    border-outline
    hover:bg-primaryHover
    hover:text-white
    disabled:bg-disabledButtonPrimary
    disabled:text-disabledButtonText
    disabled:hover:bg-disabledButtonPrimary
    disabled:hover:text-disabledButtonText
    disabled:shadow-none
`