import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Container, CustomForm } from 'components/Settings/GeneralSettings/style';
import { Col, Row, Form, Input, Divider, Space, notification, Checkbox } from 'antd';
import moment from 'moment';
import { FlexBox, PickrrButton } from 'components/UIElements';
import {
  updateSellerCheckoutConfig,
} from '../../GeneralSettings/script';
import {PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';
import { CustomContainer } from './style';


const ManageOtherPayment = ({data, isLoading}) => {

  const [otherEnabled, setOtherEnabled] = useState(false)

  const queryClient = useQueryClient();

  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      notification.error({ message: 'Something went wrong.' });
    },
    onSuccess: (message, variables, data) => {
      queryClient.invalidateQueries('generalSettings');
      notification.success({ message: 'Updated seller details.' });
    },
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if ( data && data?.other_payment_details?.other_payment_name?.length) {
      form.setFieldsValue({
        ...data
      });
    }
    else{
      form.setFieldsValue({
        other_payment_details : {
          other_payment_name : ['']
        }
      });
    }
  }, [data]);

  const submitForm = () => {
    let postData = { ...form.getFieldsValue() };
    mutation.mutate({
      other_payment_details: {
        other_payment_name: postData.other_payment_details.other_payment_name === "" ? null : postData.other_payment_details.other_payment_name,
        is_active: otherEnabled
      }
    });
  };

  useEffect(() => {
    data?.other_payment_details?.is_active ?
    setOtherEnabled(data?.other_payment_details?.is_active) :
    setOtherEnabled(false)
}, [data])

  
  return (
    <Container>
      <FlexBox style={{marginLeft: '30px', marginTop:'10px'}}>
      <Checkbox onChange={(e) => setOtherEnabled(e.target.checked)} checked={otherEnabled}/>
      <div style={{ color: '#0C3784', fontWeight: 800, marginBottom:'10px', marginLeft:'20px'}}>Enable Other Payment Mode</div>
      </FlexBox>
      <CustomForm layout="vertical" form={form} onFinish={submitForm}>
        <CustomContainer>
        <Row gutter={[16, 16]}>
          <Col xl={24} lg={24} md={24} sm={24} x={24}>
            <Form.List
            name={['other_payment_details', 'other_payment_name']}
            >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Other Payment Button Text" : ''}
                    required={index === 0 && true}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      rules={[
                        { 
                          required: true,
                          message:"This field is required"
                      }
                      ]}
                      style={{width : '40%', marginRight : '20px', marginBottom : '0px'}}
                    >
                      <Input/>
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Col xl={7} lg={7} md={12} sm={24} xs={24} style={{paddingLeft : '0px'}}>
                <Form.Item>
                  <PickrrButton
                  style={{width: 'min-content'}}
                  color='checkout-blue' 
                  onClick={() => add()} 
                  block 
                  icon={<PlusOutlined />}
                  >
                    Add field
                  </PickrrButton>
                </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
          </Col>
        </Row>
        </CustomContainer>
        <Divider />
        <Space>
          <PickrrButton htmlType="submit" color="checkout-blue">
            Save & Proceed
          </PickrrButton>
        </Space>
      </CustomForm>
    </Container>
  );
};

export default ManageOtherPayment;
