import tw from "tailwind-styled-components";
export const BackButtonTw=tw.button`
    mr-5
    cursor-pointer
    bg-transparent
    border-0
    font-sm
    font-normal
    leading-4
    text-cardHeading
    p-0
`

export const CardContainerTw = tw.div`
    m-2
    bg-white
    rounded-xl
    pl-1
    py-6
    border
    border-cardBorder
`

export const FlexColContainerTw=tw.div`
    flex
    flex-col
    text-sm
    text-productTableColor
`
export const DetailTitleTw=tw.span`
    font-normal
    text-xs
    flex
    items-end
    text-productTableImg
`
export const DetailInfoTw=tw.span`
    font-normal
    text-xs
    flex
    items-end
    text-detailColor
`
export const RefundButtonTw=tw.button`
    bg-white
    rounded-xl
    text-exportText
    shadow-md
    flex
    items-center
    px-2
    py-2
    gap-2
`