import tw from "tailwind-styled-components";
export const CustomButtonTw=tw.button`
    font-bold
    border
    px-5
    py-3
    rounded-lg
    text-black
    disabled:text-buttonGrey 
    disabled:border-buttonGrey
`