import { Tabs } from "antd";
import styled from "styled-components";

export const CustomTabs = styled(Tabs)`
    .ant-tabs-tab-btn {
        padding: 0px 10px;
    }
    .ant-tabs-nav-list {
        border-bottom: 1px solid rgba(0, 11, 52, 0.3) !important;
    }
`

export const WalletContainer = styled.div`
    .ant-modal-content{
        border-radius : 15px !important;
    }
`