import React, { useContext, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from "recharts";
import {
  LegendList,
  LegendListItem,
  BulletLabelText,
  LegendHeading,
  ValueText,
  Container,
  Heading,
  SeeResponseButton,
  EmptyViewContiner,
} from "./style";
import { downloadExitData, fetchExitCheckoutData } from "./script";
import { useQuery } from "react-query";
import ModalView from "./ModalView";
import { Modal } from "antd";
import { DataContext } from "context/DataProvider";
import { SubHeading } from "components/UIElements/EmptyView/styled";
import { ExportButton } from "../styled";
import { CloudDownloadOutlined } from "@ant-design/icons";

const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      style={{
        backgroundColor,
        width: size,
        height: size,
        borderRadius: "3px",
        marginTop: "4px",
      }}
    />
  );
};

const CustomizedLegend = (props) => {
  const { payload, total_cn, fromFilled, setIsModalOpen } = props;

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <LegendList
      style={{
        marginLeft: "auto",
        marginRight: 70,
        marginBottom: 60,
        //   position: 'absolute',
        //   top: '-370px',
        //   right: '0',
        width: "50%",
      }}
    >
      <ValueText style={{ textAlign: "left", marginBottom: 16 }}>
        Form filled by &nbsp;
        <span style={{ color: "#0C3784" }}>{fromFilled}%</span> &nbsp;
        <span style={{ color: "#A1A1A1", fontSize: 12, fontWeight: 400 }}>
          users
        </span>
      </ValueText>
      <LegendHeading>
        <div>Response</div>
        <div>Total responses</div>
      </LegendHeading>
      {payload.map((entry, index) => (
        <LegendListItem style={{ paddingBottom: "2px" }} key={`item-${index}`}>
          <div style={{ display: "flex" }}>
            <Bullet backgroundColor={entry?.payload?.fill} size="18px" />
            <BulletLabelText
              style={{
                marginBottom: "8px",
                fontWeight: "normal",
                textTransform: "capitalize",
              }}
            >
              {entry?.value}{" "}
              {entry?.value === "others" ? (
                <SeeResponseButton onClick={showModal}>
                  see responses
                </SeeResponseButton>
              ) : (
                ""
              )}
              <br />{" "}
            </BulletLabelText>
          </div>
          <ValueText>
            {entry?.payload?.value}%{" "}
            {`(${entry?.payload?.responseCount} responses)`}
            {/* {entry?.payload?.value
                ? `${convertNumberToPercentage(entry?.payload?.value, total_cn)}`
                : `0%`} */}
          </ValueText>
        </LegendListItem>
      ))}
    </LegendList>
  );
};

function ExitForm({ fromDate, toDate }) {
  const { sellerId } = useContext(DataContext);

  const { data, isLoading } = useQuery(
    ["exitCheckout", { fromDate, toDate, sellerId }],
    fetchExitCheckoutData
  );

  const cnTypePieChartColor = [
    "#619BE9",
    "#F9DA56",
    "#B0D767",
    "#DA8EC0",
    "#7DC0A6",
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  let pieData = data?.checkoutExitData?.map((value) => {
    return {
      name: value?.response,
      value: value?.percentShare,
      totalResponse: data?.totalCheckoutResponses,
      responseCount: value?.responseCount
    };
  });

  const findOthers = pieData?.filter((value) => value?.name === "others");
  const sortedArray = pieData?.filter((value) => value?.name !== "others");
  const pieDataFinal = sortedArray?.concat(findOthers);

  return (
    <Container>
      <div style={{display: 'flex', justifyContent: 'space-between', padding: '0px 24px', alignItems: 'center', width: '100%'}}>
      <Heading>User feedback</Heading>
      <ExportButton
          icon={<CloudDownloadOutlined />}
          onClick={() =>
              downloadExitData(fromDate, toDate)
          }
      >
          Export
      </ExportButton>
      </div>
      {pieDataFinal?.length > 0 ? 
      <>
      <ResponsiveContainer width="100%" height={340}>
        <PieChart>
          <Pie
            data={pieDataFinal}
            dataKey="value"
            cx={230}
            cy={160}
            innerRadius={0}
            outerRadius={130}
          >
            {pieDataFinal?.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={cnTypePieChartColor[index % cnTypePieChartColor.length]}
                />
              );
            })}
          </Pie>
          <Legend
            content={
              <CustomizedLegend
                total_cn={200}
                fromFilled={data?.totalFormFilled}
                setIsModalOpen={setIsModalOpen}
              />
            }
          />
          {/* <Tooltip
                    formatter={(value) =>
                        convertNumberToPercentage(value, total_cn)
                    }
                    /> */}
        </PieChart>
      </ResponsiveContainer>

     
      {isModalOpen && 
      <ModalView
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        fromDate={fromDate}
        toDate={toDate}
        sellerId={sellerId}
      />
    }
      </>
     : 
     <EmptyViewContiner>
        <img
          src="https://d10srchmli830n.cloudfront.net/1653465140883_ab08ffde-3852-42c3-aad0-7fc9c855ea5b_Group-27721EmptyDataImage.svg"
          alt="empty-data"
        />
        <h1><b>No Data Available.</b></h1>
        <SubHeading>
          Looks likes there is no data available , Please try again later.
        </SubHeading>
      </EmptyViewContiner>
     }


    </Container>
  );
}

export default ExitForm;