import { Input } from "antd";
import styled from "styled-components";
import tw from "tailwind-styled-components";

const { Search, TextArea } = Input;
export const Title = styled.span`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  color: #263f97;
`;

export const InputGroupContainer = styled.div`
    .ant-input-affix-wrapper{
        height: 42px !important;
    }
    .ant-input-search-button{
      height: 42px !important;
      border-bottom-right-radius : 5px !important;
      border-top-right-radius : 5px !important;
  }
`
export const TableContainer = styled.div`
    background: #FFF;
    height: auto;
    border-radius: 12px;
    padding: 20px;
    width : 100% !important;
    .ant-table-row {
        vertical-align: baseline;
    }
`
export const CustomSearch = styled(Search)`
  .ant-input-group-addon {
    background-color: #edf0f9;
    border-radius: 8px 0px 0px 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #263f97;
  }
  .ant-input-affix-wrapper,
  .ant-picker-range,
  .ant-select-selector {
    border-radius: 0px 0px 0px 0px !important;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon {
    display: none;
  }
  .anticon {
    color: #263f97;
  }
`;

export const TextStyle = tw.h6`
    w-max
    text-sm
    font-normal
    leading-5
    text-idColor
`

export const CustomDownloadButton=tw.button`
    bg-white
    rounded-lg
    text-exportText
    shadow-md
    flex
    items-center
    justify-center
    px-2
    py-1.5
    gap-2
    border
    border-outline
    hover:bg-primary
    hover:text-white
   min-w-28

`
