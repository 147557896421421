import { Form, Input } from "antd";
import React, { useState } from "react";
import { CriteriaContainer, HeadingText } from "./styled";

const MinimumCartTotalCriteria = ({criteriaUpdate, setCriteriaUpdate}:any) => {
  const [isError, setIsError] = useState(false);
  const Regex = new RegExp(/^(((?!0)[0-9]+[.]?[0-9]*))$/);
  
  const handleValidate = (e: any) => {
    setCriteriaUpdate({
      ...criteriaUpdate, 
      minimumCart:e.target.value
    })
    if(e.target.value){
      setIsError(Regex.test(e.target.value));
    }else {
      setIsError(false);
    }
  };

  return (
    <CriteriaContainer>
      <HeadingText>Minimum Cart Total ₹ </HeadingText>
      <Form.Item
        name="minCartTotal"
        // initialValue={'0'}
        rules={[
          {
            required: isError,
            pattern: Regex,
            message: "Enter valid value",
          },
        ]}
      >
        <Input maxLength={10} placeholder="Enter Amount" onChange={handleValidate} />
      </Form.Item>
    </CriteriaContainer>
  );
};

export default MinimumCartTotalCriteria;
