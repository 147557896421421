import axios from "axios";
import { GetAuthToken } from "globalQuries";

export const getGeneralDetails = async () => {
  const auth = GetAuthToken();
  const response = await axios.get(
    `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data.data;
};
export const getSearchData=async({queryKey})=>{
  const[_,id,couponCode]=queryKey
  const auth=GetAuthToken();
  if (id &&couponCode?.length > 0 ){
    const response=await axios.get(`${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount/search?sellerId=${
      id}&couponCode=${couponCode}`,{
      headers: {
        'X-AUTH-TOKEN': auth,
      },
    })
    return response?.data?.data?response?.data?.data:[]
  }
}


export const fetchDiscountData = async ({ queryKey }) => {
  const [_, id, pageSize, pageNumber] = queryKey;
  const auth = GetAuthToken();

  if(id){
    const response = await axios.get(
      `${
        process.env.REACT_APP_PROMOTION_SERVICE
      }/api/ve1/promotion-service/fastrr-discount?sellerId=${id}&page=${
        pageNumber - 1
      }&size=${pageSize}`,
      {
        headers: {
          Authorization: auth,
        },
      }
    );
    return response.data.data;
  }

};
export const deleteDiscountData=async({id,couponCode})=>{
  const auth = GetAuthToken(); 
  const response=await axios.post(`${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount/delete`,
  {
    couponCode: couponCode
  },
  {
      headers: {
        'X-AUTH-TOKEN': auth,
      },
    }
  )
  return response?.data?.data

}
export const fetchSuggestedDiscountData = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const auth = GetAuthToken();

  if(id){
    const response = await axios.get(
      `${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount/visibility?sellerId=${id}`,
      {
        headers: {
          Authorization: auth,
        },
      }
    );
    return response.data.data;
  }

};

export const updateSellerCheckoutConfig = async (data, history, url) => {
  const auth = GetAuthToken();
  const response = await axios.put(
    `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
    data,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data.data;
};

export const updateSuggestedDiscountPriority = async (data) => {
  const auth = GetAuthToken();
  const response = await axios.put(
    `${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount/visibility`,
    data,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data.data;
};


export const connectedGoogleAccounts = async ({ queryKey }) => {
  const auth = GetAuthToken();
  const [_, seller_id] = queryKey;
  if(seller_id){
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/connected/ga-accounts?sellerId=${seller_id}`,
      {
        headers: {
          Authorization: auth,
          'X-AUTH': auth,
        },
      }
    );
    return response?.data?.ga_emails;
  }
  
};

export const connectedGoogleAccountsMeasurementId = async ({ queryKey }) => {
  const auth = GetAuthToken();
  const [_, seller_id,email] = queryKey;
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/poll-mIds?email=${email}&sellerId=${seller_id}`,
      {
        headers: {
          Authorization: auth,
          'X-AUTH': auth,
        },
      }
    );
    return response?.data?.measurementIds;
};

export const connectedtMeasurementIds= async ({ queryKey }) => {
  const auth = GetAuthToken();
  const [_, seller_id] = queryKey;
  if(seller_id){
    const response = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/connected/mIds?sellerId=${seller_id}`,
      {
        headers: {
          Authorization: auth,
          'X-AUTH': auth, 
        },
      }
    );
    return response?.data?.connected_mids;
  }else{
    return null
  }
};

export const connectMeasurementIdWithEmail = async ({data,measurement_id,url_for}) => {
  const auth = GetAuthToken();
  const response = await axios.post(
    gaUrl(url_for,measurement_id),
    data,
    {
      headers: {
        Authorization: auth,
        'X-AUTH': auth,
      },
    }
  );
  return response.data;
};

export const pingServerforMids = async ({queryKey}) => {
  const [_,seller_id,email] = queryKey;
  const auth = GetAuthToken();
  const response = await axios.get(`${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/measurement_ids?email=${email}&sellerId=${seller_id}`,
    {
      headers: {
        Authorization: auth,
        'X-AUTH': auth,
      },
    }
  );
  return response.data.data;
};


const gaUrl = (url_for,measurement_id) => {
  switch (url_for) {
    case 'connect':
      return  `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/CONNECT/${measurement_id}`
      break;
    case 'disconnect':
      return  `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/DISCONNECT/${measurement_id}`
      break;
    case 'primary':
      return  `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/MAKE_PRIMARY/${measurement_id}`
      break;
    case 'secondary':
      return  `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/MAKE_SECONDARY/${measurement_id}`
      break;
    case"disconnect_mail":
      return  `${process.env.REACT_APP_REPORTING}/api/ve1/google/analytics/disconnect/${measurement_id}`
      break;
    default:
      break;
  }

}

