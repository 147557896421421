import styled from "styled-components";
import tw from "tailwind-styled-components";

export const TableContainer = styled.div`
    background: #FFF;
    height: auto;
    border-radius: 12px;
    padding: 20px;
    width : 100% !important;
    .ant-table-row {
        vertical-align: baseline;
    }
`

export const TextStyle = tw.h6`
    w-max
    text-sm
    font-normal
    leading-5
    text-idColor
`

export const TableContainerTw=tw.div`
    bg-white
    h-auto
    rounded-lg
    p-5
`