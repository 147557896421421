import React from 'react'
import { ChangeBox, ChangeText } from 'components/UIElements/StatisticsBox/styled'
import { FlexRow } from 'components/Settings/Discount/styled'
import { InfoBoxContentMiddle, Line, DropOffText } from './styled'
import { IDropOff } from '../../data'
import { getColor, getDeltaImageUrl, getArrowUrl } from './helperFuntions'
import moment from 'moment'
import {Tooltip} from 'antd'


interface IProps {
    LineColor: string
    dropData: IDropOff
    fromDate: string,
    toDate: string
}

const DropOffView = ({LineColor, dropData, fromDate, toDate}: IProps) => {

    const dateDifference = moment(toDate).diff(moment(fromDate), 'days')

    return (
        <>
            <InfoBoxContentMiddle>
                <Line color={LineColor} />
                <img
                    src={
                        dropData?.title === "Conversion" ?
                        "https://d10srchmli830n.cloudfront.net/1653461464349_6a41ac47-1f68-42b0-acd0-0983ba0a0206_Group-27583ConversionIcon.svg" :
                        getArrowUrl(Number(((dropData?.curValue/dropData?.curTotal)*100).toFixed(0)), dropData?.title)
                    }
                    alt="arrow-icon"
                    style={{ marginTop: '-5px' }}
                />
                <DropOffText color={
                    dropData?.title === "Conversion" ?
                    "#0EB929" :
                    getColor(Number(((dropData?.curValue/dropData?.curTotal)*100).toFixed(0)), dropData?.title)
                    }
                >
                    {dropData?.title}
                </DropOffText>
                <DropOffText 
                    color={
                        dropData?.title === "Conversion" ?
                        "#0C3784" :
                        getColor(Number(((dropData?.curValue/dropData?.curTotal)*100).toFixed(0)), dropData?.title)
                    } 
                    style={{ fontSize: '18px' }}
                >   
                    {
                        dropData?.curTotal <=0 ?
                        "-" :
                        `${((dropData?.curValue/dropData?.curTotal)*100).toFixed(0)}%`
                    }
                        <span 
                            style=
                            {{ 
                                fontSize: '12px', 
                                fontWeight: '500', 
                                visibility: dropData?.curTotal > 0 ? "visible" : "hidden"
                             }}
                        >
                            {` of ${dropData?.curTotal}`}
                        </span>
                </DropOffText>

                {/* <Tooltip
                    color="#F3F8FF"
                    placement="right"
                    overlayStyle={{ maxWidth: '300px' }}
                    title={
                        <div style={{ padding: '5px' }}>
                            <h5 style={{ color: '#0C3784' }}>
                                was <span style={{fontWeight:'600'}}>
                                {
                                (dropData?.prevTotal <= 0 || !dropData?.prevTotal) ?
                                '- ' :
                                `${((dropData?.preValue/dropData?.prevTotal)*100).toFixed(0)}% `
                                }</span>
                               {
                                   dateDifference === 0 ?
                                   <>
                                    the previous day<br/>
                                    <span style={{fontWeight:'600'}}>
                                        ({moment(fromDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')})
                                    </span>
                                   </> :
                                   <>
                                    in the preceeding window <br/>
                                    <span style={{fontWeight:'600'}}>
                                        (
                                        {moment(fromDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')}{' '}
                                        to{' '}
                                        {moment(toDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')}
                                        )
                                    </span>
                                   </>
                               }
                            </h5>
                        </div>
                    }
                >
                { toDate !== moment().format('YYYY-MM-DD') &&
                <ChangeBox 
                    style={{ marginTop: '10px' }}
                    color={
                        dropData.title === "Drop Off" ? 
                        dropData?.delta < 0 ? '#E7F8EA' : '#FFEBEB' :
                        dropData?.delta < 0 ? '#FFEBEB' : '#E7F8EA'
                    }
                >
                    <FlexRow style={{ width: '100%' }}>
                        <img
                            src={getDeltaImageUrl(dropData?.title, dropData?.delta)}
                            alt="increase-sign"
                        />
                        <ChangeText
                            color={
                                dropData.title === "Drop Off" ? 
                                dropData?.delta < 0 ? '#0EB929' : '#B90E0E' :
                                dropData?.delta < 0 ? '#B90E0E' : '#0EB929' 
                            }
                        >
                            {Math.abs(dropData?.delta)}%
                        </ChangeText>
                    </FlexRow>
                </ChangeBox>
                }
                </Tooltip> */}
                
            </InfoBoxContentMiddle>
        </>
    )
}

export default DropOffView