import React, { useState } from 'react';
import { Button, Col, InputNumber, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
} from '../../../UIElements';
import { WeightRangeContainer, ErrorText } from './style';

const WeightRange = ({ weightList, setWeightList }) => {
  const [error, setError] = useState(false);

  const handleAdd = () => {
    setWeightList((prev) => [
      ...prev,
      {
        min: null,
        max: null,
        //id: uuidv4(),
      },
    ]);
  };

  const handleChangeMin = (id, value) => {
    setWeightList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            min: value,
          };
        }
        return w;
      })
    );
  };

  const handleChangeMax = (id, value) => {
    setWeightList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            max: value,
          };
        }
        return w;
      })
    );
  };

  const handleDelete = (id) => {
    setWeightList((prev) => prev.filter((s, index) => index !== id));
  };

  return (
    <WeightRangeContainer>
      <FlexColContainer>
        <PickrrHeading size={5}>Weight range(kg)</PickrrHeading>
        {weightList.map((s, index) => (
          <Row gutter={16} className="mb-16" key={index}>
            <Col span={10}>
              <InputNumber
                className="mr-12"
                size="large"
                placeholder="Min Weight"
                value={s.min}
                onChange={(value) => handleChangeMin(index, value)}
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Col>
            <Col span={10}>
              <InputNumber
                className="mr-12"
                size="large"
                placeholder="Max Weight"
                value={s.max}
                onChange={(value) => {
                  handleChangeMax(index, value);
                  //checkForError(s.min, value, index)
                }}
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Col>
            <Col span={4}>
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(index)}
              />
            </Col>
            <Col offset={10} span={10}>
              {s.min !== null && s.max !== null && s.min >= s.max && (
                <ErrorText>Max Value should be greater</ErrorText>
              )}
            </Col>
          </Row>
        ))}
        <PickrrButton color="outline" onClick={handleAdd}>
          {weightList.length ? 'Add More' : 'Add'}
        </PickrrButton>
      </FlexColContainer>
    </WeightRangeContainer>
  );
};

export default WeightRange;
