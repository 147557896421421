import React, { useContext, useState } from 'react';
import {
  PieChart,
  Pie,
  Legend,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from "recharts";

import {
  LegendList,
  LegendListItem,
  BulletLabelText,
  LegendHeading,
  ValueText,
  Container,
  Heading,
  SeeResponseButton,
  EmptyViewContiner,
} from "./style";
import { SubHeading } from "components/UIElements/EmptyView/styled";


const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      style={{
        backgroundColor,
        width: size,
        height: size,
        borderRadius: "3px",
        marginTop: "4px",
      }}
    />
  );
};


const calcPercentage = (value, total) => {
  return Math.round(((value / total)* 100));
}



const CustomizedLegend = (props) => {
  const { payload, total, fromFilled, title } = props;

  

  return (
    <LegendList
      style={{
        // marginLeft: "auto",
        // marginRight: 70,
        // marginBottom: 60,
        //   position: 'absolute',
        //   top: '-370px',
        //   right: '0',
        // width: "50%",
      }}
    >
      <ValueText style={{ textAlign: "left", marginBottom: 16 }}>
        {/* Form filled by &nbsp;
        <span style={{ color: "#0C3784" }}>{fromFilled}%</span> &nbsp;
        <span style={{ color: "#A1A1A1", fontSize: 12, fontWeight: 400 }}>
          users
        </span> */}
      </ValueText>
      <LegendHeading>
        <div>{title}</div>
        <div>Total responses</div>
      </LegendHeading>
      {payload.map((entry, index) => (
        <LegendListItem style={{ paddingBottom: "2px" }} key={`item-${index}`}>
          <div style={{ display: "flex" }}>
            <Bullet backgroundColor={entry?.payload?.fill} size="18px" />
            <BulletLabelText
              style={{
                marginBottom: "8px",
                fontWeight: "normal",
                textTransform: "capitalize",
              }}
            >
              {entry?.value}{" "}
              {/* {entry?.value === "others" ? (
                <SeeResponseButton onClick={showModal}>
                  see responses
                </SeeResponseButton>
              ) : (
                ""
              )} */}
              <br />{" "}
            </BulletLabelText>
          </div>
          <ValueText>

            {calcPercentage(entry?.payload?.value, total)}%&nbsp;
            {/* {entry?.payload?.value}{" "} */}
            {`(${entry?.payload?.value} responses)`}
            {/* {entry?.payload?.value
                ? `${convertNumberToPercentage(entry?.payload?.value, total_cn)}`
                : `0%`} */}
          </ValueText>
        </LegendListItem>
      ))}
    </LegendList>
  );
};

const PieChartData = ({data, title}) => {

  const cnTypePieChartColor = [
    "#619BE9",
    "#F9DA56",
    "#B0D767",
    "#DA8EC0",
    "#7DC0A6",
  ];


  let total = 0;
  data?.forEach((value) => total += value?.value)
  
  // const data = [
  //   { name: "Active Campagins", value: 90 },
  //   { name: "Inactive Campagins", value: 25 },
  //   { name: "ICPs with no campagins", value: 10 }
  // ];

  return (
    <Container>
      <Heading>{title}</Heading>
      {data?.length > 0 ? 
      <>
      <ResponsiveContainer width="100%" height={470}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            cx={250}
            cy={120}
            innerRadius={0}
            outerRadius={120}
          >
            {data?.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={cnTypePieChartColor[index % cnTypePieChartColor.length]}
                />
              );
            })}
          </Pie>
          <Legend
            content={
              <CustomizedLegend
                total={total}
                fromFilled={data?.totalFormFilled}
                title = {title}
              />
            }
          />
          {/* <Tooltip
                    formatter={(value) =>
                        convertNumberToPercentage(value, total_cn)
                    }
                    /> */}
        </PieChart>
      </ResponsiveContainer>
      </>
     : 
     <EmptyViewContiner>
        <img
          src="https://d10srchmli830n.cloudfront.net/1653465140883_ab08ffde-3852-42c3-aad0-7fc9c855ea5b_Group-27721EmptyDataImage.svg"
          alt="empty-data"
        />
        <h1><b>No Data Available.</b></h1>
        <SubHeading>
          Looks likes there is no data available , Please try again later.
        </SubHeading>
      </EmptyViewContiner>
     }


    </Container>
  );
}

export default PieChartData;