import React, { useEffect, useState } from "react";
import { Row, Col, Select, Spin, Tooltip } from "antd";
import { FlexRow } from "components/Settings/Discount/styled";
import {
  InfoCardAmount,
  InfoCardContainer,
  InfoCardTitle,
  TableContainer,
  Text,
} from "pages/Orders/OrdersTab/styled";
import {
  CustomTable,
  FlexBox,
  SpaceAroundContainer,
} from "components/UIElements";
import moment from "moment";

import { ITableRowData, ICustomerDetails } from "./data";
import { fetchCustomersData } from "./script";
import { AlignType } from "rc-table/lib/interface";
import { useQuery } from "react-query";
import useDeepCompareEffect from "use-deep-compare-effect";
const { Option } = Select;
const CustomersTab = ({
  fromDate,
  toDate,
  changeShowCustomerDetails,
  changeCustomerRowData,
  customerPageNumber,
  changeCustomerPageNumber,
}: {
  fromDate: string;
  toDate: string;
  changeShowCustomerDetails: any;
  changeCustomerRowData: any;
  customerPageNumber: number;
  changeCustomerPageNumber: any;
}) => {
  const returnCustomerTableData = (apiData: any) => {
    let table_data: any = [];
    for (let key in apiData?.customers) {
      let obj = apiData?.customers[key];
      obj.phone = key;
      table_data.push(obj);
    }
    return table_data;
  };

  const [tableData, setTableData] = useState<ICustomerDetails[]>([]);
  const [selectedRows, setSelectedRows] = useState<ITableRowData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [rtoFilter, setRTOFilter] = useState<string[]>([]);
  const [deliveryFilter, setDeliveryFilter] = useState<string[]>([]);
  const [paymentFilter, setPaymentFilter] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(customerPageNumber);
  const [searchKey, setSearchKey] = useState<string>("order");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0);
  const { data: customersData, isLoading } = useQuery(
    [
      "customerData",
      {
        pageNumber,
        fromDate,
        toDate,
        searchTerm,
        ...(searchTerm && { searchKey }),
        rtoFilter,
        paymentFilter,
        deliveryFilter,
      },
    ],
    fetchCustomersData
  );
  useDeepCompareEffect(() => {
    setSelectedRowKeys([]);
  }, [
    fromDate,
    toDate,
    searchTerm,
    searchKey,
    rtoFilter,
    paymentFilter,
    deliveryFilter,
    pageNumber,
  ]);

  useEffect(() => {
    setPageNumber(customerPageNumber);
  }, [fromDate, toDate]);

  useEffect(() => {
    setTableData(returnCustomerTableData(customersData));
  }, [customersData]);

  const columns: object[] = [
    {
      title: "Customer Name | Phone Number",
      key: "customer-name-number",
      align: "left" as AlignType,
      render: (rowData: ICustomerDetails) => (
        <>
          <div
            style={{
              maxWidth: "220px",
            }}
          >
            <Text>
              {rowData?.name || rowData?.lastName
                ? `${rowData?.name ?? ""} ${rowData?.lastName ?? ""}`
                : "-"}
            </Text>
            <Text>{rowData?.phone}</Text>
          </div>
        </>
      ),
    },
    {
      title: "Location",
      key: "location",
      align: "left" as AlignType,
      render: (rowData: ICustomerDetails) => (
        <>
          {" "}
          <Text>{rowData?.city ? `${rowData?.city}` : "-"}</Text>
        </>
      ),
    },
    {
      title: "Total Spent",
      key: "total-spent",
      align: "left" as AlignType,
      render: (rowData: ICustomerDetails) => (
        <>
          <Text>
            &#8377;
            {rowData?.spendAmount ? `${rowData?.spendAmount.toFixed(2)}` : "0"}
          </Text>
        </>
      ),
    },
    {
      title: "Total Orders",
      key: "total-orders",
      align: "left" as AlignType,
      render: (rowData: ICustomerDetails) => (
        <>
          <Text>
            {rowData?.placedOrders ? `${rowData?.placedOrders}` : "-"}
          </Text>
        </>
      ),
    },
    {
      title: "RTO",
      key: "rto-score",
      align: "center" as AlignType,
      render: (rowData: ICustomerDetails) => (
        <>
          <Text>
            {rowData?.orders
              ? ((rowData?.rtoOrders / rowData?.orders) * 100).toFixed(2)
              : "0"}
            %{" "}
          </Text>
        </>
      ),
    },
    {
      title: "Abandoned Carts",
      key: "abandoned-carts",
      align: "center" as AlignType,
      render: (rowData: ICustomerDetails) => (
        <>
          <Text>{rowData?.abandonCarts ? rowData?.abandonCarts : "0"}</Text>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center" as AlignType,
      render: (rowData: ICustomerDetails) => (
        <SpaceAroundContainer>
          <div>
            <img
              src="https://d10srchmli830n.cloudfront.net/1655575488310_9b74f49d-592a-407e-9748-b9fc013713f0_Group-27229.svg"
              alt="download"
            />
            <Text style={{ color: "#0C64DB", whiteSpace: "nowrap" }}>
              View Details
            </Text>
          </div>
        </SpaceAroundContainer>
      ),
    },
  ];

  const onSearchKeyChange = (value: string) => {
    setSearchKey(value);
  };
  const onSearch = (value: string) => {
    setSearchTerm(value);
    setPageNumber(1);
  };

  const selectBefore = (
    <Select
      value={searchKey}
      className="select-before"
      style={{ width: "150px", textAlign: "left", marginLeft: "10px" }}
      onChange={onSearchKeyChange}
    >
      <Option value="order">Customer</Option>
      <Option value="phone">Phone Number</Option>
    </Select>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={6} xl={6}>
        <InfoCardContainer>
          <FlexRow>
            <InfoCardTitle>Total Customers</InfoCardTitle>
            <Tooltip
              color="#F3F8FF"
              placement="right"
              overlayStyle={{ maxWidth: "300px" }}
              title={
                <div style={{ padding: "5px" }}>
                  <h5 style={{ color: "#0C3784" }}>
                    Total number of all the users who came to the site and at
                    least initiated an order but did not complete the payment.
                  </h5>
                </div>
              }
            >
              <img
                src="https://d10srchmli830n.cloudfront.net/1619693972018_info.svg"
                alt="info-icon"
                style={{ marginTop: "-20px" }}
              />
            </Tooltip>
          </FlexRow>
          <FlexRow>
            <FlexRow>
              <InfoCardAmount>
                {isLoading ? (
                  <Spin />
                ) : customersData?.totalCustomers ? (
                  customersData?.totalCustomers
                ) : (
                  "0"
                )}
              </InfoCardAmount>
              {/* <PercentageText>+ 1.2% from yesterday</PercentageText> */}
            </FlexRow>
            {/* <DaysText>Last 15 days</DaysText> */}
          </FlexRow>
        </InfoCardContainer>
      </Col>
      <Col xs={24} sm={24} md={12} lg={7} xl={7}>
        <InfoCardContainer>
          <FlexRow>
            <InfoCardTitle>Total Converted Customers</InfoCardTitle>
            <Tooltip
              color="#F3F8FF"
              placement="right"
              overlayStyle={{ maxWidth: "300px" }}
              title={
                <div style={{ padding: "5px" }}>
                  <h5 style={{ color: "#0C3784" }}>
                    Total number of unique customers who placed an order by
                    completing the payment.
                  </h5>
                </div>
              }
            >
              <img
                src="https://d10srchmli830n.cloudfront.net/1619693972018_info.svg"
                alt="info-icon"
                style={{ marginTop: "-20px" }}
              />
            </Tooltip>
          </FlexRow>
          <FlexRow>
            <FlexRow>
              <InfoCardAmount>
                {isLoading ? (
                  <Spin />
                ) : customersData?.convertedCustomers ? (
                  customersData?.convertedCustomers
                ) : (
                  "0"
                )}
              </InfoCardAmount>
              {/* <PercentageText>+ 1.2% from yesterday</PercentageText> */}
            </FlexRow>
            {/* <DaysText>Last 15 days</DaysText> */}
          </FlexRow>
        </InfoCardContainer>
      </Col>
      <Col xs={24} sm={24} md={12} lg={7} xl={7}>
        <InfoCardContainer>
          <FlexRow>
            <InfoCardTitle>Customer Average Order Value</InfoCardTitle>
            <Tooltip
              color="#F3F8FF"
              placement="right"
              overlayStyle={{ maxWidth: "300px" }}
              title={
                <div style={{ padding: "5px" }}>
                  <h5 style={{ color: "#0C3784" }}>
                    Average amount a customer spent on your website for the
                    given date range.
                  </h5>
                </div>
              }
            >
              <img
                src="https://d10srchmli830n.cloudfront.net/1619693972018_info.svg"
                alt="info-icon"
                style={{ marginTop: "-20px" }}
              />
            </Tooltip>
          </FlexRow>
          <FlexRow>
            <FlexRow>
              <InfoCardAmount>
                {" "}
                {isLoading ? (
                  <Spin />
                ) : ("₹" + (
                  customersData?.sellarAverageValue ?
                  Intl.NumberFormat("en-IN").format(
                    parseInt(customersData?.sellarAverageValue)
                  ) : "0"
                ))}
              </InfoCardAmount>
              {/* <PercentageText>+ 1.2% from yesterday</PercentageText> */}
            </FlexRow>
            {/* <DaysText>Last 15 days</DaysText> */}
          </FlexRow>
        </InfoCardContainer>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <TableContainer>
          <CustomTable
            loading={isLoading}
            dataSource={tableData}
            style={{ marginTop: "20px", cursor: "pointer" }}
            columns={columns}
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
              total: customersData?.totalCustomers,
              current: pageNumber,
              onChange: (page) => {
                changeCustomerPageNumber(page);
                setPageNumber(page);
              },
              position: ["bottomRight"],
            }}
            onRow={(record: ICustomerDetails) => ({
              onClick: () => {
                changeCustomerRowData(record);
                changeShowCustomerDetails();
              },
            })}
          ></CustomTable>
        </TableContainer>
      </Col>
    </Row>
  );
};

export default CustomersTab;
