import styled from 'styled-components';

export const DiscountCardContainer = styled.div`
  height: auto;
  background: rgba(12, 100, 219, 0.04);
  border-radius: 6px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DiscountCardContent = styled.div`
  padding: 15px;

  .ant-switch-checked {
    background-color: #0c64db;
  }
`;
export const FlexStartContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ListContainer = styled.div`
  padding-left: 50px;
  line-height: 17px;
  `;
  export const TagsDiv = styled.div`
  padding-left: 40px;
  line-height: 17px;
  // width: 35%;

 .tags{
  display: flex;
  align-items: center;
  justify-items: center;
  height: 18px;
  margin-right: 4px;
  border-radius: 8px;
  padding: 3px 9px;
  background-color: #E5E9EE;
  
  >p{
    font-size: 12px;
    margin-bottom: 0px;
  }
 }
`;
export const DiscountName = styled.h6`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #002659;
  margin-top: 8px;
  margin-left: 10px;
`;

export const DiscountDescription = styled.h6`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  margin-bottom: 0px;
  color: #002659;
`;

export const DateTimeText = styled.h6`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: #002659;
  margin: 0;
  width: 80%;

  span {
    font-weight: 600;
  }
`;
