import ImageUploaderTw from "UiComponents/ImageUploader"
import React, { useState } from "react"
import MobileImagePreview from "./MobilePreview"
import style from "./MobilePreview.module.css"
import { InfoIconNew } from "images/icons"
const crossIcon =require("../../../src/images/Group 1171276675.png")
const LoginPage = ({loginPageSubText,setLoginPageSubtext,loginPageCTA,setLoginPageCTA,filesUploaded,setFilesUploaded,sellerLogo,currUpload,setCurrentUpload}:any) => {
    return <>
    <div className="flex flex-row">
        <div className="flex flex-col gap-4" style={{minWidth : '40%'}}>
            <div className="rounded-lg border border-greytext flex flex-col gap-3 px-4 py-5 w-1/2" style={{width : '100%'}}>
                <div>
                    <p className="font-bold">Subheader text</p>
                    <input
                    value={loginPageSubText}
                    onChange={(e:any)=>{
                        setLoginPageSubtext(e.target.value)
                    }}
                        className="border border-greytext w-full h-9 mt-2 rounded-lg px-4 focus:border-greytext" placeholder="Enter text on subheader"></input>
                </div>
                <div>
                    <p className="font-bold">Theme color for CTA</p>
                    <input
                    value={loginPageCTA}
                    onChange={(e:any)=>{
                        setLoginPageCTA(e.target.value)
                    }}
                        className="border border-greytext w-full h-9 mt-2 rounded-lg px-4 focus:border-greytext" placeholder="#000000"></input>
                </div>
            </div>
            <div className="rounded-lg border border-greytext flex flex-col gap-3 px-4 py-5 w-1/2" style={{width : '100%'}}>
                <p className="font-bold">Image</p>
                <ImageUploaderTw uploadArray={filesUploaded} setUploadArray={setFilesUploaded} height={3211} width={2100} key={"login"} currUpload={currUpload} setCurrentUpload={setCurrentUpload}/>
            </div>
        </div>
        <div style={{flex : 1,display : 'flex', justifyContent : 'center',}}>
        <div className={`${style.previewContainer}`} >
            
            <div className={`${style.overLayMainContainer}`}>  
              <div style={{    display: "flex",justifyContent: "center"}}><img src={`${crossIcon}`}  /></div>
            
          
          <MobileImagePreview shiprocketLoginPageType={"login"} subheaderText={loginPageSubText} themeColor={loginPageCTA} filesUploaded={filesUploaded} sellerLogo={sellerLogo} />
        {!filesUploaded.length && <div className='flex items-center bg-white rounded-lg pl-2 py-1 gap-1 ml-3' style={{fontSize:'10px'}}><InfoIconNew/>This will be the default background if no image is uploaded</div>}
            
          </div>  
          </div>
        </div>
        </div>
    </>
}
export default LoginPage