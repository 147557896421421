import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Container, InputFieldLayoutStyle } from "./style";
import GeneralSettings from "components/Settings/GeneralSettings";
import Discount from "components/Settings/Discount";
import ManageShipping from "components/Settings/Shipping";
import ManagePayment from "components/Settings/Payments";
import ManageCommunication from "components/Settings/Communication";
import CustomizeCheckout from "components/Settings/CustomizeCheckout";
import ManageInventory from "components/Settings/Inventory";
import TrackingInfo from 'components/Settings/TrackingInfo';
import ManageChannelShopify from "components/Settings/ManageChannel/Shopify";
import ManageChannel from "components/Settings/ManageChannel";
import ManageChannelWoocommerce from "components/Settings/ManageChannel/Woocommerce";
import { useQuery } from "react-query";
import { getGeneralDetails } from "components/Settings/GeneralSettings/script";
import { DataContext } from 'context/DataProvider';
import WebHooks from "components/Settings/WebHooks";
import CustomWebHooks from "components/Settings/CustomWebHooks";
import ManageChannelMagento from "components/Settings/ManageChannel/Magento";

const { SubMenu } = Menu;

const rootSubmenuKeys = [
  "account-setup",
  "billing-setup",
  "manage-courier",
  "manage-brand",
  "manage-notifications",
  "manage-checkout",
  "settings",
];

const getComponent = {
  "/settings/general-settings": <GeneralSettings />,
  "/settings/discount": <Discount />,
  "/settings/shipping": <ManageShipping />,
  "/settings/payments": <ManagePayment />,
  "/settings/communication": <ManageCommunication />,
  "/settings/customize": <CustomizeCheckout />,
  "/settings/inventory": <ManageInventory />,
  "/settings/manage-channel/shopify": <ManageChannelShopify />,
  "/settings/manage-channel": <ManageChannel />,
  "/settings/manage-channel/woocommerce": <ManageChannelWoocommerce />,
  '/settings/manage-channel/magento': <ManageChannelMagento />,
  '/settings/tracking-info': <TrackingInfo />,
  '/settings/webhooks': <WebHooks />,
  '/settings/customwebhooks': <CustomWebHooks />, // Added Custom Webhooks component
};

const Settings = (props) => {
  const location = useLocation();
  const [channelKey, setChannelKey] = useState("/settings/manage-channel");
  const [manageChannelPath, setManageChannelPath] = useState(
    "/settings/manage-channel"
  );
  const { params = {} } = props.match ? props.match : {};
  const [defaultSelectedKey, setDefaultSelectedKey] = useState("");
  const [openKeys, setOpenKeys] = useState(["settings"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const { data, isLoading, isSuccess } = useQuery(
    "generalSettings",
    getGeneralDetails
  );
  useEffect(() => {
    if (data?.platform_details?.shopify?.admin_api_key)
      setManageChannelPath("/settings/manage-channel/shopify");
    else if (data?.platform_details?.woocommerce?.admin_api_key)
      setManageChannelPath("/settings/manage-channel/woocommerce");
  }, [data]);
  
  useState(() => {
    let key = location.pathname.split("/")[1];
    if (
      key === "settings" &&
      location.pathname.split("/")[2] === "manage-channel"
    ) {
      setOpenKeys([key, location.pathname.split("/")[2]]);
      return;
    }
    setOpenKeys([key]);
  }, []);
  
  useEffect(() => {
    if (location.pathname.includes("manage-channel"))
      setChannelKey(location.pathname);
    if (Object.keys(params).length === 2) {
      setDefaultSelectedKey(props.match.path);
    } else if (Object.keys(params).length === 1) {
      setDefaultSelectedKey(props.match.path);
    } else {
      setDefaultSelectedKey(location.pathname);
    }
  }, [location.pathname]);

  const selectedKey = defaultSelectedKey.includes("/manage-notifications")
    ? ["/manage-notifications"]
    : [defaultSelectedKey];
    
  const {onboardVersion, platFormDetails} = useContext(DataContext)

  return (
    <>
      <Container>
        <Row gutter={28}>
          <Col
            xl={6}
            lg={6}
            md={24}
            sm={24}
            xs={24}
            style={{ borderRadius: 12 }}
          >
            <Menu
              mode="inline"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              defaultSelectedKeys={["/settings/general-settings"]}
              selectedKeys={selectedKey}
              style={{ borderRadius: 12, paddingTop: 20, paddingBottom: 20 }}
            >
              <SubMenu key="settings" title="Settings">
                <Menu.Item
                  key="/settings/general-settings"
                  className="ant-menu-item"
                >
                  <Link to="/settings/general-settings">General Settings</Link>
                </Menu.Item>
                <Menu.Item key="/settings/discount" className="ant-menu-item">
                  <Link to="/settings/discount">Discounts</Link>
                </Menu.Item>
                {onboardVersion && onboardVersion !== 'v3' && 
                  <Menu.Item key="/settings/customize" className="ant-menu-item">
                    <Link to="/settings/customize">Customization</Link>
                  </Menu.Item>
                }
                <Menu.Item key="/settings/shipping" className="ant-menu-item">
                  <Link to="/settings/shipping">Shipping Settings</Link>
                </Menu.Item>
                <Menu.Item key="/settings/payments" className="ant-menu-item">
                  <Link to="/settings/payments">Payment</Link>
                </Menu.Item>
                {onboardVersion && onboardVersion !== 'v3' && 
                  <Menu.Item
                    key="/settings/communication"
                    className="ant-menu-item"
                  >
                    <Link to="/settings/communication">Communication</Link>
                  </Menu.Item>
                }
                <Menu.Item key="/settings/inventory" className="ant-menu-item">
                  <Link to="/settings/inventory">Inventory</Link>
                </Menu.Item>
                <Menu.Item key={channelKey} className="ant-menu-item">
                  <Link to={manageChannelPath}>Platform</Link>
                </Menu.Item>
                <Menu.Item
                  key="/settings/tracking-info"
                  className="ant-menu-item"
                >
                  <Link to="/settings/tracking-info">Tracking Info</Link>
                </Menu.Item>
                <Menu.Item
                  key="/settings/webhooks"
                  className="ant-menu-item"
                >
                  <Link to="/settings/webhooks">Webhooks</Link>
                </Menu.Item>
                {platFormDetails?.custom?.checkout && 
                  <Menu.Item
                    key="/settings/customwebhooks"
                    className="ant-menu-item"
                  >
                    <Link to="/settings/customwebhooks">CustomWebhooks</Link>
                  </Menu.Item>
                }
              </SubMenu>
            </Menu>
          </Col>
          <Col
            xl={18}
            lg={18}
            md={22}
            sm={22}
            xs={22}
            style={{
              background:
                defaultSelectedKey !==
                "/settings/manage-courier/courier-priority"
                  ? "#fff"
                  : "none",
              borderRadius: 12,
              padding: 0,
            }}
          >
            <InputFieldLayoutStyle>
              {getComponent[defaultSelectedKey]}
            </InputFieldLayoutStyle>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
