import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "antd";
import { CustomDatePicker, CustomTimePicker } from "./styled";
import {IDateTime} from '../data';

import moment from "moment";

const DiscountValidity = ({dateTime, setDateTime}:any) => {
  const dateFormat = "DD-MM-YYYY";
  const timeFormat = "hh:mm A";

  const [isValid, setIsValid] = useState<boolean>(false);

  // const [dateTime, setDateTime] = useState<IDateTime>({
  //   startDate: "",
  //   startTime: "",
  //   endDate: "",
  //   endTime: "",
  //   beginData:""
  // });


  const onChange = (time: any, timeString: any, type:any) => {
    setDateTime((prevState:IDateTime) => ({
      ...prevState,
      [type]: timeString?.split(" ")[0],
      ...(type === "startDate" && {beginData: time})
    }));
  };

  useEffect(() => {
    if(dateTime?.endDate && (dateTime?.endDate === dateTime?.startDate) && dateTime?.startTime){
      if(dateTime?.endTime){
       const isValid = dateTime.endTime <= dateTime.startTime;
       setIsValid(isValid);    
      }
    }else if (dateTime?.endDate === "" && dateTime?.endTime !== "" && dateTime?.startTime !== "") {
      const isValid = dateTime.endTime <= dateTime.startTime;
      setIsValid(isValid);    
    }else {
      setIsValid(false);    
    }
  }, [dateTime])

  return (
    <Row gutter={30}>
      <Col span={12}>
        <Form.Item
          name="startDate"
          label="Start Date"
          rules={[
            {
              required: true,
              message: "Start Date is required",
            },
          ]}
          initialValue = {moment(new Date,dateFormat)}
        >
          <CustomDatePicker
            // placeholder="Start Date"
            // defaultValue={moment().format(dateFormat)}
            // defaultValue = {moment( new Date,dateFormat)}
            disabledDate={(current) => {
              let customDate = moment().format(dateFormat);
              return current && current < moment(customDate, dateFormat);
            }}
            format={dateFormat}
            onChange={(time,timeString) => onChange(time,timeString,'startDate')}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Start Time (IST)"
          name="startTime"
          rules={[
            {
              required: true,
              message: "Start Time is required",
            },
          ]}
          initialValue = {moment(new Date,timeFormat)}
        >
          <CustomTimePicker
            // placeholder="Start Time"
            use12Hours={false}
            // defaultValue = {moment( new Date,timeFormat)}
            format={timeFormat}
            onChange={(time,timeString) => onChange(time,timeString,'startTime')}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item 
          label="End Date" 
          name="endDate" 
          rules={[
            {
              required: dateTime.endTime ? true : false,
              message: "End Date is required",
            },
          ]}
        >
          
          <CustomDatePicker
            placeholder="End Date"
            format={dateFormat}
            disabledDate={(current) => {
              return current && current < moment(dateTime?.beginData, dateFormat);
            }}
            onChange={(time,timeString) => onChange(time,timeString,'endDate')}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="End Time (IST)"
          name="endTime"
          rules={[
            {
              required: dateTime?.endDate || isValid,
              message: "End Time is required",
            },
          ]}
          style={{marginBottom: 2}}
        >
          <CustomTimePicker
            placeholder="End Time"
            use12Hours={false}
            format={timeFormat}
            onChange={(time,timeString) => onChange(time,timeString,'endTime')}
          />
        </Form.Item>
          {isValid && (
            <div style={{ color: "red", fontSize: 12 }}>
              End time should be smaller than start time
            </div>
          )}
      </Col>
    </Row>
  );
};

export default DiscountValidity;
