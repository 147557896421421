import React, { useContext, useEffect } from "react";
import { Typography } from "antd";
import DomainList from "./DomainList";
import { Container, DomainListContainer, LoaderContainer } from "./style";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const antIcon = (
  /* @ts-ignore */
  <LoadingOutlined
    style={{
      fontSize: 80,
    }}
    spin
  />
);

const SrLogin = ({ srAccountList, setSrLoggedIn, srLoginError, cid, fetchingSRAccounts }: any) => {

  const navigate = useNavigate()

  // const isOnboarding = localStorage.getItem('isOnboarding') ?? null;
  // const onboardingType = localStorage.getItem('onboardingType') ?? null;

  // useEffect(() => {
  //   if (!srLoginError && isOnboarding && onboardingType === "NEW" && !localStorage.getItem('loggedInFromAdmin')){ //and not live
  //     navigate('/home')
  //   }
  // },[isOnboarding, srLoginError, onboardingType])

  const createNewOnBoarding = () => {
    try {
        localStorage.setItem('isOnboarding', 'true')
        localStorage.setItem('onboardingType', 'NEW')
        //localStorage.setItem("srAccountList", JSON.stringify(response?.data?.data?.account_info_list))
        localStorage.setItem('panel', `"[settings/general-settings, user-management, orders, finance, dashboard, abandoned-cart]"`)
        localStorage.removeItem('loggedInFromAdmin')
        window.location.href = '/home'
    }
    catch(e){}
}

  return (
    <Container>
      { fetchingSRAccounts ?
        <>
          <Spin tip="Fetching associated accounts..." size="large"  />
        </> :
        <>
          <Title level={2} style={{ marginBottom: 30 }}>
            {!srLoginError && (srAccountList?.length > 0 ? 'Navigate to checkout' : 'Redirecting..')}
          </Title>
          {
            !srLoginError && srAccountList?.length <= 0 &&
            <div style={{textAlign:'center'}}>
              <h3>We couldn't find any associated accounts. Let's onboard you! <br/>
              <span style={{color:'blue', cursor: 'pointer'}} onClick={() => createNewOnBoarding()}>Click here</span>, if you're not automatically redirected</h3>
            </div>
          }

          <DomainListContainer style={{ justifyContent: srAccountList?.length > 1 ? 'space-between' : 'center' }}>
            {
              (srAccountList?.length >= 0 && !srLoginError)
                ? (
                  <>
                    {srAccountList?.map((value: any, index: any) => {
                      return (
                        <>
                          <DomainList
                            domainName={value?.domain_name}
                            logo={value?.logo_url}
                            email={value?.email}
                            key={index}
                            cid={cid}
                          />
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {(srLoginError) ?
                      <>
                        <div style={{ textTransform: 'capitalize', textAlign: 'center' }}>
                          <h2><b>{srLoginError}</b></h2>
                          <h4>please connect at info@fastrr.com</h4>
                        </div>
                      </> :
                      <LoaderContainer>
                        <Spin indicator={antIcon} />
                      </LoaderContainer>
                    }
                  </>
                )
            }
          </DomainListContainer>
        </>
      }
    </Container>
  );
};

export default SrLogin;
