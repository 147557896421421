import React from 'react';
import {Heading, PercentText, SubHeading, NormalText, Line, CustomCart} from './style';
// import { fetchExistingCustomer } from '../script';
import { useQuery } from 'react-query';


const listData = [
    {
        percentage: '0',
        heading: 'Address Pre-filled',
        subText: 'Percentage of unique users that logged in with their address already filled',
        key:'addressPreFilled'
    },
    {
        percentage: '0',
        heading: 'Shiprocket SSO',
        subText: 'Percentage of logins with pre-filled addresses',
        key: 'shiprocket-sso'
    },
    
    // {
    //     percentage: '0',
    //     heading: 'Total orders',
    //     subText: 'Percentage of orders with the logged in users whose addresses were pre-filled',
    //     key:'totalOrders'
    // },
]

// const prefillList = (data: any) => {
//     return listData?.map((value, index) => {
//         return(
            // <div key = {value.key}>
            //     <PercentText>{data?.[value?.key] ? `${data?.[value?.key]}%` : '-'}</PercentText>
            //     <SubHeading>{value?.heading}</SubHeading>
            //     <NormalText>{value?.subText}</NormalText>
            //     {index !== 2 ? <Line /> : ''}
            // </div>
//         )
//     })
// } 


 


const AddressPrefill = ({shiprocketSSO, addressPreFilled}:any) => {
    // const {
    //     data,
    //     isLoading
    // } = useQuery(['existingCustomer', {fromDate, toDate}],
    //     fetchExistingCustomer
    // )

    return(
        // <CustomCart loading={isLoading}>
<>
        {/* <CustomCart>
            <SubHeading>Address Pre-filled</SubHeading>
            <PercentText>{`${addressPreFilled ? `${addressPreFilled}%` : '-'}`}</PercentText>
            <NormalText>Percentage of unique users that logged in with their address already filled</NormalText>
        </CustomCart> */}
        <CustomCart style={{marginTop: 10}}>
            <SubHeading>Shiprocket SSO</SubHeading>
            <PercentText>{`${shiprocketSSO ? `${shiprocketSSO}%` : '-'}`}</PercentText>
            <NormalText>Percentage of logins with pre-filled addresses</NormalText>
        </CustomCart>
</>
    )
}

export default AddressPrefill;

