import axios from 'axios';
import { GetAuthToken } from 'globalQuries';

const auth:any = GetAuthToken();

export const syncShopifyCoupon = async (data:any) => {
  const auth:any = GetAuthToken();
  const response = await axios.post(
    `${process.env.REACT_APP_PROMOTION_SERVICE}/api/ve1/promotion-service/fastrr-discount/shopify/sync`,
    data,
    {
      headers: {
        Authorization: auth,
      },
    }
  );
  return response.data;
};
