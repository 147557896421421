import { toast } from 'react-toastify';
import { ToastErrorCross, ToastSuccessTick } from 'images/icons';
import axiosInstance from '../../HTTP/Http';
import { downloadFile } from 'components/UIElements/function';


export const fetchFinanceData = async ({ queryKey }: any) => {
  const [unusedArgument, { pageNumber, fromDate, toDate, searchKey, searchTerm }] = queryKey

  const response = await axiosInstance.get(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/finance`,
    {
      params: {
        page: pageNumber - 1,
        from: fromDate,
        to: toDate,
        // orderId: searchTerm,
        ...(searchTerm && { key: searchKey }),
        ...(searchTerm && { value: searchTerm }),
      }
    }
  )
  return response.data.data;
}


export const handleSingleDownload = async (rowId: string | number, fromDate: string, toDate: string) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=FINANCE`,
    {
      params: {
        from: fromDate,
        to: toDate,
        value: rowId
      }
    }
  )
  downloadFile(response.data, 'orderdata.csv')
}


export const handleMultipleDownload = async (fromDate: string, toDate: string, keys: string, setIsDataDownloading: any) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=order&type=FINANCE`,
      {
        params: {
          from: fromDate,
          to: toDate,
          value: keys
        }
      }
    )
    setIsDataDownloading(false)
    downloadFile(response.data, 'orderdata.csv')
    toast.success('Download successful! Your file is ready', {
      icon: ToastSuccessTick
    });
  } catch (error) {
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
    setIsDataDownloading(false)
  }

}

export const handleAllDownload = async (
  fromDate: string, toDate: string, searchKey: string, searchTerm:
    string, setIsDataDownloading: any
) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=FINANCE`,
      {
        params: {
          from: fromDate,
          to: toDate,
          ...(searchTerm && { key: searchKey }),
          ...(searchTerm && { value: searchTerm }),
        }
      }
    )
    setIsDataDownloading(false)
    downloadFile(response.data, 'orderdata.csv')
    toast.success('Download successful! Your file is ready', {
      icon: ToastSuccessTick
    });
  } catch (error) {
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
    setIsDataDownloading(false)
  }

}