import React, { useEffect } from "react"
const VideoModal = ({ isModalOpen, setViewVideoModal, videoID}: any) => {
   
    useEffect(() => {
        const handleKeyDown = (event:any) => {
          if (event.key === "Escape") {
            setViewVideoModal(false); // Call the modal close function
          }
        };
    
        if (isModalOpen) {
          window.addEventListener("keydown", handleKeyDown);
        }
    
        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }, [isModalOpen, setViewVideoModal]);
    
      if (!isModalOpen) return null; // Hide modal if not open



    return <>
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="fixed inset-0 bg-black opacity-50" onClick={() => { setViewVideoModal(false) }} />
            <div className="flex flex-col justify-center items-center relative z-50 bg-refundWhiteBg rounded-lg h-1/2 w-1/2 ">
                <iframe
                    className="w-full h-full"
                    src={`https://www.youtube.com/embed/${videoID}`}
                    title="YouTube video player"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

        </div>
    </>
}
export default VideoModal