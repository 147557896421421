import styled from 'styled-components';

export const EmptyViewContiner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;

    * {
        margin-top: 10px;
    }
`

export const Heading = styled.h6`
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000B34;
`

export const SubHeading = styled.h6`
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #B6BCC6;
`