import { notification } from 'antd';
import axios from 'axios';
import { GetAuthToken } from 'globalQuries';

export const fetchSellerCheckoutConfig = async () => {
  const auth = GetAuthToken();
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PICKRR_CHECKOUT}/seller/config/`,
      {
        headers: {
          Authorization: auth,
        },
      }
    );
    return response.data;
  } catch (error) {
    notification.error({
      message: error.message,
    });
  }
};
