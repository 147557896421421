import styled from 'styled-components'

export const Container = styled.div`
    background: #FFF;
    border-radius: 10px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
`

export const FunnelContainer = styled.div`
    height: 56vw;
    width: 300px;
    transform: rotate(270deg);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: 30px;
`

export const BoxContainer = styled.div`
    width: 56vw;
    display: flex;

    > div:nth-of-type(3) {
        border: none;
    }
`

export const Title = styled.h1`
    // font-size: 22px;
    // font-weight: 700;
    // line-height: 22px;
    // letter-spacing: -0.03em;
    // color: #000B34;
    // margin: 0;
    // width: 100%;
    // margin: 0px 0px 15px 50px;


    color: #9A9494;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.66px;
    width: 100%;
    margin-left:6vw;

`


