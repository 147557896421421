import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { FlexContainer } from "components/UIElements";
import { ProductItem, ProductHeading, ButtonClose } from "../styled";

const SelectedPinCode = ({
  selectedPinCode,
  setSelectedPinCode,
}: any) => {
  const deleteItemHandler = (itemId: string | number) => {
    setSelectedPinCode(
      selectedPinCode.filter((item: any) => item.id !== itemId)
    );
  };

  return (
    <div style={{ marginBottom: 36 }}>
      {selectedPinCode.length > 0 &&
        selectedPinCode?.map((value: any, index: number) => {
          return (
            <ProductItem>
              <div>
                <ProductHeading>
                  {`${index + 1}. ${value?.number}`}
                </ProductHeading>
              </div>
              <FlexContainer style={{ gap: 20 }}>
                <ButtonClose>
                  <CloseOutlined onClick={() => deleteItemHandler(value?.id)} />
                </ButtonClose>
              </FlexContainer>
            </ProductItem>
          );
        })}
    </div>
  );
};

export default SelectedPinCode;
