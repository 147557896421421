import moment from 'moment';
import React from 'react';
import {
  Label,
  CustomInput,
  CustomDatePicker,
  CustomTimePicker,
} from './styled';

const CustomInputBox = ({ value, setvalue, label, type,style }) => {
  const renderInputBox = () => {
    console.log(value, setvalue, 'opopo');
    switch (type) {
      case 'Time':
        return (
          <>
            <Label>{label}</Label>
            <CustomTimePicker
              format="HH:mm"
              defaultValue={moment()}
              onChange={(time, timeString) => setvalue(timeString)}
            />
          </>
        );
      case 'Date':
        return (
          <>
            <Label>{label}</Label>
            <CustomDatePicker
              defaultValue={moment()}
              onChange={(date, dateString) => setvalue(dateString)}
            />
          </>
        );
      default:
        return (
          <>
            <Label style={{...style}}>{label}</Label>
            <CustomInput
              value={value}
              onChange={(e) => setvalue(e.target.value)}
            />
          </>
        );
    }
  };

  return renderInputBox();
};

export default CustomInputBox;
