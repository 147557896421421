import { RechargeIcon, RefundIcon } from "images/icons";
import { RefundButtonTw } from "pages/EditOrderNew/styled";
import React from "react";
import { useQueryClient } from "react-query";

type UpdateBooleanState = (data: boolean) => void;
interface NavBarComponentProps {
    title: any;
    setIsViewPricingModalVisible: UpdateBooleanState;
    walletBalance: any;
    setWalletModalVisible: UpdateBooleanState;
    isCollapse: boolean;
    walletDataLoading: boolean;
    walletDetails: any;
    platFormDetails: any;
    sellerConfig: any
}

const NavBar: React.FC<NavBarComponentProps> = ({ title, setIsViewPricingModalVisible, walletBalance, setWalletModalVisible, isCollapse, walletDataLoading, walletDetails, platFormDetails, sellerConfig }) => {
    //  ${isCollapse?'ml-44':'ml-20'}
    const queryClient = useQueryClient();

    return <>
        <div className='flex w-screen justify-between bg-white pr-3 pt-6 h-16' >
            <div className={`font-bold text-xl leading-4  ease-in-out duration-300 ml-28`}>
                {title}
                {/* Dashboard */}
            </div>
            {!sellerConfig?.is_native_shopify_functions_checkout &&
                <div className='flex gap-3'>
                    <span className="flex gap-1 text-sm leading-4 font-medium"><RechargeIcon /><span className="text-black">{walletDataLoading ?
                        <div role="status" className="max-w-sm animate-pulse text-blue-600">
                            <div className="h-2.5 bg-skeletonGrey rounded w-20 p-2">
                            </div>
                        </div> : `₹${walletBalance}`}</span> </span>
                    <span className="cursor-pointer" onClick={() => { queryClient.invalidateQueries(["WalletData"]) }}>
                        <RefundIcon />
                    </span>
                    <button type='button' className="flex h-8 -mt-2 flex-wrap gap-2 focus:outline-none text-rechargeText bg-recharge font-medium rounded-lg text-sm px-2.5 pt-1.5 mb-2" onClick={() => { setWalletModalVisible(true) }}>Recharge wallet</button>
                    <div className="h-5 border-t sm:border-t-0 sm:border-s border-borderGrey"></div>
                    <button type="button" className="-mt-3 -ml-3 h-10 flex flex-wrap gap-2 pl-3.5 pr-3.5 pt-2.5 pb-2.5  text-black  
                font-medium rounded-lg text-sm px-5 py-2.5 mb-2" onClick={() => { setIsViewPricingModalVisible(true) }} ><span className="-ml-1 text-rechargeText">₹ Pricing</span></button>

                </div>
            }

        </div>
    </>
}
export default NavBar