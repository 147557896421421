import React from "react";
import { Col, Modal } from "antd";
import {CheckCircleFilled, ExclamationCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

const RechargeSuccessModal = ({rechargeSuccessModalVisible,setRechargeSuccessModalVisible,rechargeSuccessModalData,setRechargeSuccessModalData} : any)=>{

    const queryClient = useQueryClient();
    const navigate = useNavigate()
    return(
            <Modal
            visible = {rechargeSuccessModalVisible}
            onCancel={()=>{
                setRechargeSuccessModalData('')
                setRechargeSuccessModalVisible(false)
                queryClient.invalidateQueries('WalletData');
                navigate('/wallet')
            }}
            footer={null}
            width={400}
            centered
            keyboard= {true}
            >
                <Col span={24} style={{display : 'flex', flexDirection:'column', alignItems : 'center'}}>
                    {rechargeSuccessModalData === "Pending" ? 
                    /* @ts-ignore */
                    <ExclamationCircleFilled  style={{color:'#F86624', fontSize : '50px', marginBottom : '30px'}}/>
                    : rechargeSuccessModalData === "Success" ?
                    /* @ts-ignore */
                    <CheckCircleFilled style={{color:'green', fontSize : '50px', marginBottom : '30px'}}/>
                    :
                    /* @ts-ignore */ 
                    <CloseCircleFilled style={{color:'red', fontSize : '50px', marginBottom : '30px'}}/>
                    }
                    <span style={{fontSize : '22px', color : "#000B34", fontWeight : 700 }}>{`Recharge ${rechargeSuccessModalData === "Pending" ? "Pending" : rechargeSuccessModalData === "Success" ? "Successful" : "Failed"}`}</span>
                </Col>
            </Modal>
    )
}

export default RechargeSuccessModal