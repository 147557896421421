import React, { useEffect, useState, useRef } from 'react';
import {Form} from 'antd';
import {CustomInput, LabelText, RestButton } from "./styled";
import { SpaceBetweenContainer } from 'components/UIElements';
import {SearchOutlined} from '@ant-design/icons'
import SearchResult from './SearchResult';
import { productSearch } from './script';
import { getGeneralDetails } from 'components/Settings/GeneralSettings/script';
import { useQuery } from 'react-query';
import ProductSearchModal from './ProductSearchModal';
import SearchedProductList from './SearchedProductsList';

  interface ISellerDetails {
    sellerId: string;
    storeUrl: string;
    searchType: string;
    channel: string;
  }

const ProductSearch = ({selectedItems, setSelectedItems, type, discountType, form,variantList, setVariantList, editOrderData,setEditOrderData}:any) => {
    const [searchTerm, setSearchTerm] = useState<string>();
    const [inputValue, setInputValue] = useState<string | null>();
    const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
    const [editItemId, setEditItemId] = useState<number | string>();
    const [sellerDetails, setSellerDetails]= useState<ISellerDetails>()
    
    const [selectProductId, setSelectProductId] = useState<number | string>();
      

    /*getting seller details*/ 
    const { isLoading: sellerConfigLoading, data: configData } = useQuery(
        "generalSettings",
        getGeneralDetails
    );
    
    useEffect(() => {
      if(type){
        setSellerDetails({
            ...sellerDetails,
            sellerId: configData?._id,
            storeUrl: configData?.domain_name,
            searchType: type,
            channel: configData?.platform_details?.channel,
        });
      }
    }, [sellerConfigLoading, configData, type]);


    const { data: searchedProduct, isLoading: sellerActiveLoading} = useQuery(
        ["productSearch", { searchTerm, ...sellerDetails}],
        productSearch
    );
    
    const onSearch = (value: string) => {
        if (value) setSearchTerm(value);
    };

    const productSearchModalProps: any = {
      isProductModalOpen,
      setIsProductModalOpen,
      selectProductId,
      sellerDetails,
      selectedItems,
      setSelectedItems,
      setSearchTerm,
      editItemId,
      setEditItemId,
      uniqueKey: (Math.random() * 2002),
      setSelectProductId,
      variantList,
      setVariantList,
      setInputValue
    };


    const inputRef = useRef();

    return (
        <>
        <div>
        <LabelText> Add Product </LabelText>
        <CustomInput
          placeholder={`Search or Select Product`}
          /* @ts-ignore */
          suffix={<SearchOutlined />}
          ref = {inputRef}
          value={inputValue}
          onPressEnter={(e: any) => {
            if (e.target.value) {
              onSearch(e.target.value);
            }
          }}
          onChange={(e : any) => {
            setInputValue(e.target.value);
            if (!e.target.value) {
              onSearch("");
              setSearchTerm("");
            }
          }}
          disabled={!type}
        />
        {(searchedProduct?.products?.length || searchedProduct?.collections?.length) && searchTerm?.length && (
            <SearchResult
              isLoading={sellerActiveLoading}
              data={searchedProduct.products || searchedProduct?.collections}
              setIsProductModalOpen={setIsProductModalOpen}
              setSelectProductId={setSelectProductId}
              setEditItemId = {setEditItemId}
              type = {type}
              setSearchTerm = {setSearchTerm}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
        )}
        </div> 
          
          {
            selectedItems?.length > 0 && 
        (
        <SearchedProductList 
          selectedItems = {selectedItems}
          setSelectedItems = {setSelectedItems}
          setEditItemId = {setEditItemId}
          setSelectProductId={setSelectProductId}
          setIsProductModalOpen = {setIsProductModalOpen}
          type = {'product'}
          variantList = {variantList}
          setVariantList ={setVariantList}
          editOrderData ={editOrderData}
          setEditOrderData = {setEditOrderData}
        />
        )
      }


      <ProductSearchModal
        {...productSearchModalProps}
        />
      </>
    )
}

export default ProductSearch;
