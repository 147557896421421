import React from "react";
import {
  Card,
  CardHeading,
  InputWrapper,
  ErrorMessage,
  RoleBadge,
} from "../styled";
import { SpaceBetweenContainer } from "components/UIElements";
import { formConfig, ROLE_IMGAGE_URL } from "../data";

const UserForm = ({ userDetails, setUserDetails, userDetailsError }: any) => {
  const onChange = (e: any, id: any) => {
    const value = e.target.value;
      setUserDetails((prev: any) => {
        return {
          ...prev,
          [id]: value,
        };
      });
  };

  return (
    <Card style={{marginTop: 10}}>
      <CardHeading>Basic Details</CardHeading>
      <SpaceBetweenContainer
        style={{ marginTop: 20, alignItems: "flex-start" }}
      >
        {formConfig.map((value: any) => {
          console.log(value.icon);
          return (
            <InputWrapper id={value.id}>
              <div style={{display:'flex', alignItems:'center'}}>
                <label>
                  <img src={`${value.icon}`} alt={value.id} />
                  {value.label}
                </label>
                <input
                  id={value.id}
                  type="text"
                  placeholder={value.placeHolder}
                  maxLength={value.id === "phone" ? 10 : 50}
                  onChange={(e) => onChange(e, value.id)}
                  value = {userDetails[value.id]}
                />
              </div>
              {value.errorMessage && userDetailsError[value.id] && (
                <ErrorMessage>{value.errorMessage}</ErrorMessage>
              )}
            </InputWrapper>
          );
        })}
        {userDetails?.role ? (
          <InputWrapper>
            <SpaceBetweenContainer style={{ gap: 10 }}>
              <label>
                <img src={ROLE_IMGAGE_URL} alt={"role-image"} />
                Current Role
              </label>
              <RoleBadge>{userDetails?.role}</RoleBadge>
            </SpaceBetweenContainer>
          </InputWrapper>
        ) : (
          ""
        )}
      </SpaceBetweenContainer>
    </Card>
  );
};

export default UserForm;
