import React from 'react'
import Select, { Props as SelectProps, StylesConfig } from 'react-select'

export type OptionType = {
    value: string;
    label: string;
};

interface CustomSelectProps extends SelectProps<OptionType> {
    customStyles?: StylesConfig<OptionType>;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({options, customStyles,...props}:any) => {

    const defaultStyles: StylesConfig<OptionType> = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#6F57E926' : 'white',
            color: state.isFocused ? '#5B3DEB' : 'black',
            cursor:'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused? '1px solid #6E5FDD' :'1px solid #E0E4EC',
            boxShadow: state.isFocused ? '0 0 0 1px #6E5FDD' : 'none',
            outline: 'none' ,
            '&:hover': {
                border: '1px solid #6E5FDD',
            },
            borderRadius:'8px',
            cursor: state.isDisabled ? 'not-allowed' : 'pointer',
            minWidth: '320px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#282828',
        }),
        indicatorSeparator: (provided)=>({
            display: 'none'
        })
    };
  return (
    <div>
        <Select options={options} 
        styles={{ ...defaultStyles, ...customStyles }} 
        {...props}
        />
    </div>
  )
}

