// @ts-nocheck
import React, { useState, useContext, ChangeEvent, useEffect } from "react";
import { message } from "antd";
import moment from "moment";
import { CustomDatePicker } from "./styled";
import { useQuery } from "react-query";
import {
  fetchSettlementsData,
  handleAllDownload,
  handleMultipleDownload,
} from "./script";
import { DataContext } from "context/DataProvider";
import { AOVCard, AmountOfCard, SortByCard, TotalCard } from "UiComponents/Cards";
import { CustomReactInput } from "UiComponents/Select";
import { CustomButton, CustomDownloadButton, FlexRowTw, TableContainerTw, TextStyle } from "pages/Orders/OrdersTab/styles";
import CustomReactTable from "UiComponents/Table";
import { ExportIcon, FilterIcon, UpDownIcon } from "images/icons";
import Filter from "UiComponents/CustomFilter/Filter";
import { Ndata } from "UiComponents/SideBar/data";
import "../../Loader.css"
import { PaymentGatewayEnum } from "context/Enums";

const SettlementHistoryNew = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [fromDate, setFromDate] = useState<string | null>(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const [filter, setFilter] = useState<any>([])
  const [searchKey, setSearchKey] = useState<string>("pgSettlementId");
  const [displayFilter, setDisplayFilter] = useState<boolean>(false)
  const [displaySort, setDisplaySort] = useState<boolean>(false)
  const [sortValue, setSortValue] = useState<any>("")

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [prevPageNumber, setPrevPageNumber] = useState<number | null>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { sellerId, IpAddress } = useContext<any>(DataContext);
  const [viewMoreState, setViewMoreState] = useState<any>()
  const [defaultSortBy, setDefaultSortBy] = useState<any>([{ id: '', desc: false }]);
  const [isDataDownloading, setIsDataDownloading] = useState<boolean>(false)
  const disabledDates = (current: any) => {
    return current > moment();
  };

  const onSelectAll = (pageData: any) => {
    setSelectedRowKeys((keys: any) => keys.length === settlementData?.settlements?.length ? [] : [...settlementData?.settlements?.map((key: any) => {
      return key?.id
    })])
    setSelectedRows((rows: any) => rows?.length === pageData?.length ? [] : [...pageData])
  };


  const onSearchKeyChange = (value: string) => {
    setSearchKey(value);
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
    setPageNumber(1);
  };

  const addOnBefore = [
    { value: 'pgSettlementId', label: 'PG Transaction ID' }
  ];
  const onChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(event.target.value)
    if (!event.target.value) {
      onSearch('')
      setPageNumber(prevPageNumber)
    }
  }
  const onPressEnterHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!searchTerm) {
      setPrevPageNumber(pageNumber)
    }
    if (event.target.value) {
      onSearch(event.target.value)
    }
  }
  const handleRowSelect = (row: any) => {
    const rowIndex = selectedRows.findIndex((r: any) => {
      return r?.original?.id === row?.original?.id
    });
    if (rowIndex === -1) {
      setSelectedRows([...selectedRows, row]);
      setSelectedRowKeys([...selectedRowKeys, row?.original?.id])
    }
    else {
      const updatedSelectedRows = [...selectedRows];
      updatedSelectedRows.splice(rowIndex, 1);
      setSelectedRows(updatedSelectedRows);
      setSelectedRowKeys([...selectedRowKeys.filter(ele => ele != row?.original?.id)]);
    }
  };

  const isChecked = (key: any, row: any) => {
    return key === row?.id
  }

  const { data: settlementData, isLoading } = useQuery(
    [
      "SettlementsData",
      {
        pageNumber,
        sellerId,
        fromDate,
        toDate,
        searchTerm,
        ...(searchTerm && { searchKey }),
      },
    ],
    fetchSettlementsData, { cacheTime: 0 },
  );

  const TagsValue = ['Order Id', 'Date', 'Items', "Payment Total", 'Payment Transaction Id']

  const Filterdata: Ndata = {
    Source: [
      {
        key: 1,
        name: 'Fastrr',
        count: 20
      },
      {
        key: 2,
        name: 'Online - website',
        count: 1
      }
    ],
    "Order Status": [
      {
        key: 3,
        name: 'Open',
        count: 20
      },
      {
        key: 4,
        name: 'Archived',
        count: 20
      },
      {
        key: 5,
        name: 'Cancelled',
        count: 20
      }
    ],
    "Payment Gateway": [
      {
        key: 6,
        name: 'Setu',
        count: 20
      },
      {
        key: 7,
        name: 'Fastrr',
        count: 20
      },
      {
        key: 8,
        name: 'RazorPay',
        count: 20
      }
    ]

  }

  const columns: any = [
    {
      Header: "Settlement ID (Fastrr/PG)",
      accessor: 'pg_settlement_id',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => {
        return <TextStyle className="min-w-40">{value || '-'}</TextStyle>
      },
    },
    {
      Header: "Settlement Date & Time",
      accessor: 'payout_date',
      disableSortBy: true,
      Cell: ({ cell: { value }, row: { original } }: any) => (
        <TextStyle className="min-w-36">{moment(original?.updated_at)?.format('Do MMMM , h:mm a')}</TextStyle>
      ),
    },
    {
      Header: "Payout amount",
      accessor: 'pg_payout_amount',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle className="min-w-24">{value? `${value.toLocaleString("en-IN", {style:"currency", currency:"INR"})}`: '-'}</TextStyle>
      ),
    },
    {
      Header: "No of Transactions",
      accessor: 'settlement_transactions_count',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle className="min-w-32">{value? value: '-'}</TextStyle>
      ),
    },
    {
      Header: "Wallet Recharge amount",
      accessor: 'total_settlement_recharge_amount',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle className="min-w-40">{value? `${value.toLocaleString("en-IN", {style:"currency", currency:"INR"})}`: '-'}</TextStyle>
      ),
    },
    {
      Header: "Wallet Recharge ID",
      accessor: 'settlement_bill_id',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle className="min-w-32">{value || '-'}</TextStyle>
      ),
    },
    {
      Header: "Payment Gateway",
      accessor: 'payment_platform',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle className="min-w-28">
          {PaymentGatewayEnum[value?.toUpperCase()]? PaymentGatewayEnum[value?.toUpperCase()] : value || '-'}
          </TextStyle>
      ),
    },
    {
      Header: "Settlement UTR/Ref no",
      accessor: 'bank_transaction_id',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle>{value|| '-'}</TextStyle>
      ),
    },
    {
      Header: "Bank Details (Last 4 digit)",
      accessor: 'bank_account_number',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle>
          {value?.replace(/\d(?=\d{4})/g, "*")}
        </TextStyle>
      ),
    },
    {
      Header: "Bank Name (Settlement A/C)",
      accessor: 'bank_name',
      disableSortBy: true,
      Cell: ({ cell: { value } }: any) => (
        <TextStyle className="min-w-44">{value|| '-'}</TextStyle>
      ),
    },
  ];

  return (
    <>
      <div className=' h-auto rounded-md min-h-screen' style={{backgroundColor:"#F1F5F9"}}>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div className='flex gap-8 mb-4 flex-wrap'>
              {/* <AmountOfCard heading={'Settlement Amount'} amount={0} /> */}
              <TotalCard heading={'Total Settlement'} amount={settlementData?.total_settlements||0} />
            </div>
            <div>
              <CustomDatePicker
                allowClear={false}
                onChange={(dates: Array<moment.Moment>) => {
                  if (dates[1].diff(dates[0], "days") > 60) {
                    message.error(
                      "Date range can not exceed 60 days. Please select date range less than or equal to 60 days only.",
                      1.5
                    );
                  } else {
                    dates && dates[0]
                      ? setFromDate(dates[0].format("YYYY-MM-DD"))
                      : setFromDate(null);
                    dates && dates[1]
                      ? setToDate(dates[1].format("YYYY-MM-DD"))
                      : setToDate(null);
                  }
                }}
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(6, "d"), moment()],
                  "This Month": [moment().startOf("month"), moment()],

                  "Last 30 Days": [moment().subtract(29, "days"), moment()],
                }}
                defaultValue={[
                  moment(fromDate, "YYYY-MM-DD"),
                  moment(toDate, "YYYY-MM-DD"),
                ]}
                disabledDate={disabledDates}
              />
            </div>
          </div>

        </div>
        <TableContainerTw>

          <FlexRowTw className="flex justify-between">
            <FlexRowTw>
              <CustomReactInput addOnBefore={addOnBefore} onChangeHandler={onChangeHandler} onPressEnterHandler={onPressEnterHandler} onSearchKeyChange={onSearchKeyChange} searchKey={searchKey} defaultValue={'PG Transaction ID'} inputValue={inputValue} setSearchTerm={setSearchTerm}/>
            </FlexRowTw>
            <div className="flex items-center gap-3 font-bold py-4 h-8 w-max ml-8">
              <CustomDownloadButton onClick={() => {
                setIsDataDownloading(true)
                selectedRows?.length
                  ? handleMultipleDownload(
                    fromDate,
                    toDate,
                    selectedRowKeys?.join(","),
                    setIsDataDownloading
                  )
                  : handleAllDownload(fromDate, toDate, searchKey, searchTerm,setIsDataDownloading)
              }} className='items-center' disabled={isDataDownloading || !settlementData?.settlements.length}>
                {
                  !isDataDownloading?
                <>
                <span>
                  <ExportIcon />
                </span>
                <span>{selectedRowKeys.length ? `Download Selected (${selectedRowKeys.length})` : 'Download All'}</span>
                </>:<div className="loader"/>}
              </CustomDownloadButton>
            </div>
            {/* <div className='flex gap-2'> */}
            {/*// @ts-ignore */}
            {/* <CustomButton className='pl-2 w-20' onClick={() => { setDisplayFilter(!displayFilter) }}><div className='flex'><FilterIcon /><span>Filter</span></div></CustomButton> */}
            {/* {displayFilter && <div className='absolute w-96 top-96 right-4 rounded-lg'><Filter filterData={Filterdata} setFilter={setFilter} setDisplayFilter={setDisplayFilter} /></div>} */}
            {/* <CustomButton className="pl-2 w-20" onClick={() => { setDisplaySort(!displaySort) }}><div className='flex pl-2'><div className='mr-2 mt-1'><UpDownIcon /></div><span>Sort</span></div></CustomButton> */}
            {/* {displaySort && <div className='absolute top-96 right-6'>
                <SortByCard TagsValue={TagsValue} setDisplaySort={setDisplaySort} setSortValue={setSortValue} sortValue={sortValue} setDefaultSortBy={setDefaultSortBy} defaultSortBy={defaultSortBy} displayFilter={setDisplayFilter} />
              </div>} */}
            {/* </div> */}
          </FlexRowTw>

          {/* {selectedRowKeys?.length > 0 &&
            <div className="flex items-center gap-3 font-bold text-idColor mt-4 h-10 w-max ml-8">
              <span>
                {selectedRowKeys.length} selected
              </span>
              <CustomButton onClick={() => {
                selectedRows?.length
                  ? handleMultipleDownload(
                    fromDate,
                    toDate,
                    selectedRowKeys?.join(",")
                  )
                  : handleAllDownload(fromDate, toDate, searchKey, searchTerm)
              }} className='pl-4'>
                <ExportIcon />
                Export
              </CustomButton>
            </div>}
 */}


          <CustomReactTable
            loading={isLoading}
            data={settlementData?.settlements || []}
            pageNumber={pageNumber}
            columns={columns}
            setPageNumber={setPageNumber}
            totalPages={settlementData?.total}
            checkboxes={true}
            onSelectAll={onSelectAll}
            pageSize={20}
            selectedRowsKeys={selectedRowKeys}
            defaultSortBy={defaultSortBy}
            newState={viewMoreState}
            handleRowSelect={handleRowSelect}
            isChecked={isChecked}

          />
        </TableContainerTw >

      </div>
    </>
  );
};

export default SettlementHistoryNew;
