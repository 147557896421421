import React, { useState } from 'react';
import moment from 'moment';
import { Col, Modal, Row, Tabs, Tooltip } from 'antd';
import {
  CustomTable,
  FlexBox,
  FlexColContainer,
  FlexColCenterContainer,
  Heading,
  PickrrButton,
} from 'components/UIElements';
import { CustomDatePicker } from '../../components/UIElements';
import { Container } from 'pages/Settings/style';
import { StatusTag, DateHeadingText, DateText } from './style';
import { fetchEddData, downloadEddCsvData } from './script';
import { useQuery } from 'react-query';
import { CustomModal } from 'components/Settings/Discount/styled';
import EDDModal from './Modal';
const Eddtracker = () => {
  const { TabPane } = Tabs;
  const [pageNumber, setPageNumber] = useState(1);
  const [fromDate, setFromDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  const { data, isLoading, isSuccess } = useQuery(
    ['edddata', { pageNumber, fromDate, toDate }],
    fetchEddData
  );

  const [eddModalData, setEddModalData] = useState(null);
  const [isEddModalVisible, setIsEddModalVisible] = useState(false);

  const handleModalClose = () => {
    setIsEddModalVisible(false);
  };

  const handleViewBreachDetails = (data) => {
    setEddModalData(data);
    setIsEddModalVisible(true);
  };

  const defaultData = {
    status: true,
    data: {
      total: 1,
      orders: [
        {
          orderId: '#4055',
          orderDate: '2022-03-13 17:19:52',
          custPhone: 919787547666,
          custName: 'jack',
          pickupDate: '2022-03-15 14:42:00',
          confPickupDate: null,
          EDD: '2022-03-20 18:30:00',
          deliveredDate: '2022-03-20 17:38:00',
          breachDays: -1,
          refundAmt: 100,
          shippingMethod: 'Pickrr-No Rush',
          shippingCharges: 0,
          promiseEDD: '2022-03-21 23:59:00',
          firstAttemptDate: '2022-03-20 10:03:00',
          amount: 399,
          rtoPrediction: 'low',
          edd: '2022-03-20 18:30:00',
        },
      ],
    },
  };

  const columns = [
    {
      title: 'Order Id / Date',
      dataIndex: 'orderId',
      key: 'orderId',
      width: 300,
      align: 'center',
      render: (data, record) => (
        <FlexColCenterContainer>
          <span style={{ textTransform: 'capitalize' }}>{record.orderId}</span>
          <span style={{ textTransform: 'capitalize' }}>
            {moment(record.orderDate).format('DD/MM/YYYY')}
          </span>
        </FlexColCenterContainer>
      ),
    },
    {
      title: 'Customer Name / Phone Number',
      dataIndex: 'orderId',
      key: 'custName',
      width: 300,
      align: 'center',
      render: (data, record) => (
        <FlexColCenterContainer>
          <span style={{ textTransform: 'capitalize' }}>{record.custName}</span>
          <span style={{ textTransform: 'capitalize' }}>
            {record.custPhone}
          </span>
        </FlexColCenterContainer>
      ),
    },
    {
      title: 'Shipping Method / Charges',
      dataIndex: 'orderId',
      key: 'custName',
      width: 300,
      align: 'center',
      render: (data, record) => (
        <FlexColCenterContainer>
          <span style={{ textTransform: 'capitalize' }}>
            {record.shippingMethod}
          </span>
          <span style={{ textTransform: 'capitalize' }}>
            {record.shippingCharges}
          </span>
        </FlexColCenterContainer>
      ),
    },
    {
      title: 'Actual Pickup',
      dataIndex: 'pickupDate',
      key: 'custName',
      width: 300,
      align: 'center',
      render: (data, record) => (
        <>
          <FlexBox>
            {' '}
            <span style={{ textTransform: 'capitalize' }}>
              {record.pickupDate}&nbsp;
            </span>
            {record.pickupDate && (
              <Tooltip
                title={
                  <FlexColContainer style={{ padding: '10px' }}>
                    {record.confPickupDate && (
                      <>
                        <DateHeadingText>Configured pickup</DateHeadingText>
                        <DateText>
                          {moment(record.confPickupDate).format('DD-MM-YYYY')}
                        </DateText>
                      </>
                    )}

                    {record.pickupDate && (
                      <>
                        <DateHeadingText style={{ marginTop: '10px' }}>
                          Actual pickup
                        </DateHeadingText>
                        <DateText>
                          {moment(record.pickupDate).format('DD-MM-YYYY')}
                        </DateText>
                      </>
                    )}
                  </FlexColContainer>
                }
                placement="bottomRight"
                color="#FFFFFF"
              >
                <img
                  src="https://d10srchmli830n.cloudfront.net/1619693972018_info.svg"
                  alt="info-icon"
                />
              </Tooltip>
            )}
          </FlexBox>
        </>
      ),
    },
    {
      title: 'Actual EDD',
      dataIndex: 'EDD',
      key: 'edd',
      width: 300,
      align: 'center',
      render: (data, record) => (
        <>
          <FlexBox>
            {' '}
            <span style={{ textTransform: 'capitalize' }}>{data}&nbsp;</span>
            {record.EDD && (
              <Tooltip
                title={
                  <FlexColContainer style={{ padding: '10px' }}>
                    <DateHeadingText>Promised EDD</DateHeadingText>
                    <DateText>
                      {moment(record.promiseEDD).format('DD-MM-YYYY')}
                    </DateText>
                    <DateHeadingText style={{ marginTop: '10px' }}>
                      Actual EDD
                    </DateHeadingText>
                    <DateText>
                      {moment(record.EDD).format('DD-MM-YYYY')}
                    </DateText>
                  </FlexColContainer>
                }
                placement="bottomRight"
                color="#FFFFFF"
              >
                <img
                  src="https://d10srchmli830n.cloudfront.net/1619693972018_info.svg"
                  alt="info-icon"
                />
              </Tooltip>
            )}
          </FlexBox>
        </>
      ),
    },
    {
      title: 'Breach',
      dataIndex: 'breachDays',
      key: 'breachDays',
      width: 300,
      align: 'center',
      render: (data, record) => (
        <>
          {' '}
          <span style={{ textTransform: 'capitalize' }}>
            {data === -1 ? (
              <FlexColCenterContainer>
                <StatusTag>Breach</StatusTag>
                <h5
                  onClick={() => handleViewBreachDetails(record)}
                  style={{
                    color: '#263f97',
                    marginTop: '5px',
                    cursor: 'pointer',
                  }}
                >
                  View
                </h5>
              </FlexColCenterContainer>
            ) : (
              'N/A'
            )}
          </span>
          {/* <span style={{ textTransform: 'capitalize' }}>
              {record.shippingCharges}
            </span> */}
        </>
      ),
    },
    // {
    //   title: 'Refund Amount',
    //   dataIndex: 'refundAmt',
    //   key: 'refundAmt',
    //   width: 300,
    //   align: 'center',
    //   render: (data, record) => (
    //     <>
    //       {' '}
    //       <span style={{ textTransform: 'capitalize' }}>{data}</span>
    //       {/* <span style={{ textTransform: 'capitalize' }}>
    //           {record.shippingCharges}
    //         </span> */}
    //     </>
    //   ),
    // },
  ];
  return (
    <>
      <Tabs
        tabBarExtraContent={{
          left: (
            <FlexBox style={{ alignItems: 'center' }}>
              {' '}
              {/* <div>
                <img src="https://pickrr.s3.amazonaws.com:443/2022-03-30T00:55:52.168723_Group 27328.svg" />
              </div> */}
              <Heading style={{ marginLeft: '8px' }}>
                Total EDD - {(data && data.length) || 0}
              </Heading>
            </FlexBox>
          ),
          right: (
            <CustomDatePicker
              //   disabledDate={disabledDate}
              allowClear={false}
              onChange={(dates) => {
                dates && dates[0]
                  ? setFromDate(dates[0].format('YYYY-MM-DD'))
                  : setFromDate(null);
                dates && dates[1]
                  ? setToDate(dates[1].format('YYYY-MM-DD'))
                  : setToDate(null);
              }}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, 'days'),
                  moment().subtract(1, 'days'),
                ],
                'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                'This Month': [moment().startOf('month'), moment()],

                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              }}
              defaultValue={[
                moment(fromDate, 'YYYY-MM-DD'),
                moment(toDate, 'YYYY-MM-DD'),
              ]}
            />
          ),
        }}
      >
        <TabPane>
          <Container style={{ background: '#fff' }}>
            <Row gutter={28}>
              <div
                style={{
                  width: ' 100%',
                  textAlign: 'end',
                  padding: '20px',
                  paddingBottom: '0px',
                  paddingTop: '11px',
                }}
              >
                <PickrrButton
                  color="checkout-blue"
                  onClick={downloadEddCsvData}
                  icon={
                    <img
                      style={{ marginBottom: '5px', marginRight: '5px' }}
                      src="https://pickrr.s3.amazonaws.com:443/2022-03-31T13:16:39.416997_Download.svg"
                    />
                  }
                >
                  Download
                </PickrrButton>
              </div>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ borderRadius: 12, padding: '20px' }}
              >
                <CustomTable
                  columns={columns}
                  loading={isLoading}
                  dataSource={data?.orders}
                  pagination={{
                    pageSize: 20,
                    showSizeChanger: false,
                    total: data?.total,
                    current: pageNumber,
                    onChange: (page) => {
                      setPageNumber(page);
                    },
                    position: ['bottomRight'],
                  }}
                />
              </Col>
            </Row>
          </Container>
        </TabPane>
      </Tabs>
      <CustomModal
        visible={isEddModalVisible}
        footer={null}
        width={400}
        closable={true}
        entered={true}
        onCancel={handleModalClose}
        keyboard= {true}
      >
        <EDDModal eddModalData={eddModalData} />
      </CustomModal>
    </>
  );
};

export default Eddtracker;
