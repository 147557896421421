import ImageUploaderTw from "UiComponents/ImageUploader";
import React, { useEffect, useState } from "react";
import MobileImagePreview from "./MobilePreview";
import style from "./MobilePreview.module.css"
import { InfoIcon, InfoIconNew } from "images/icons";
const crossIcon =require("../../../src/images/Group 1171276675.png")

const LandingPageLogin = ({showOnLanding,setShowOnLanding, subheaderText, setSubheaderText, themeColor, setThemeColor, discountText, setDiscountText, discountCode, setDiscountCode, filesUploaded, setFilesUploaded,sellerLogo,currUpload,setCurrentUpload }: any) => {
    return (<>
    <div className="flex flex-row">
    <div className="flex flex-col gap-4" style={{minWidth : '40%'}}>
            <label className="inline-flex items-center cursor-pointer">
                <p className="mr-12 font-bold">Show on landing page</p>
                <input type="checkbox" value="" className="sr-only peer" checked={showOnLanding} onChange={()=>{setShowOnLanding(!showOnLanding)}} />
                <div className={`relative w-11 h-6 bg-greytext peer-focus:outline-none rounded-full peer dark:bg-greytext peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-greytext after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-greytext ${'peer-checked:bg-paidGreen'} disabled:bg-greytext`}
                />

            </label>
            <span className="flex gap-2 items-center bg-infoBg rounded-md px-3 py-1 text-xs"><InfoIconNew/>By enabling 'Show on landing page,' the following information will be shown as a popup on your landing page</span>
            <div className="rounded-lg border border-greytext flex flex-col gap-3 px-4 py-5">
                <div>
                    <p className="font-bold">Subheader text</p>
                    <input
                        value={subheaderText}
                        onChange={(e: any) => {
                            setSubheaderText(e.target.value)
                        }}
                        className="border border-greytext w-full h-9 mt-2 rounded-lg px-4 focus:border-greytext" placeholder="Enter text on subheader"></input>
                </div>
                <div>
                    <p className="font-bold">Theme color for CTA</p>
                    <input
                        value={themeColor}
                        onChange={(e: any) => {
                            setThemeColor(e.target.value)
                        }}
                        className="border border-greytext w-full h-9 rounded-lg px-4 mt-2 focus:border-greytext" placeholder="#000000"></input>
                </div>
            </div>
            <div className="rounded-lg border border-greytext flex flex-col gap-3 px-4 py-5">
                <div>
                    <p className="font-bold">Discount popup text</p>
                    <input value={discountText}
                        onChange={(e: any) => {
                            setDiscountText(e.target.value)
                        }}
                        className="border border-greytext w-full h-9 mt-2 rounded-lg px-4 focus:border-greytext" placeholder="Enter text"></input>
                </div>
                <div>
                    <p className="font-bold">Discount Code</p>
                    <input value={discountCode}
                        onChange={(e: any) => {
                            setDiscountCode(e.target.value)
                        }}
                        className="border border-greytext w-full h-9 rounded-lg px-4 mt-2 focus:border-greytext" placeholder="Enter discount code"></input>
                </div>
            </div>

            <div className="rounded-lg border border-greytext flex flex-col gap-3 px-4 py-5 w-1/2" style={{width : '100%'}}>
                <p className="font-bold">Image</p>
                <ImageUploaderTw uploadArray={filesUploaded} setUploadArray={setFilesUploaded} height={3211} width={2100}  key={"landing"} currUpload={currUpload} setCurrentUpload={setCurrentUpload}/>
            </div>

        </div>
        <div></div>
        <div style={{flex : 1,display : 'flex', justifyContent : 'center',}}>
        <div className={`${style.previewContainer}`} >
            
            
          <div className={`${style.overLayMainContainer}`}>  
            <div style={{    display: "flex",justifyContent: "center"}}><img src={`${crossIcon}`}  /></div>
          
        
        <MobileImagePreview shiprocketLoginPageType={"Landing"} subheaderText={subheaderText} themeColor={themeColor} filesUploaded={filesUploaded} sellerLogo={sellerLogo} />
        {!filesUploaded.length && <div className='flex items-center bg-white rounded-lg pl-2 py-1 gap-1 ml-3' style={{fontSize:'10px'}}><InfoIconNew/>This will be the default background if no image is uploaded</div>}
      
        </div>  
        </div>
        </div>
    </div>
    </>)
}
export default LandingPageLogin