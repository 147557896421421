import React, { useState, useEffect } from 'react';
import { Upload, Button, Input, notification } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
  SpaceBetweenContainer,
} from 'components/UIElements';
import { Text } from './style';

const { TextArea } = Input;

const ProductTags = ({ productTagsList, setProductTagsList }) => {
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      setUploadFile([file]);
      return false;
    },
  };

  useEffect(() => {
    const uploadCSV = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('product_tag', uploadFile[0]);
        const response = await axios.post(
          `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/upload-csv/`,
          formData
        );
        setLoading(false);
        if (response.data.success) {
          notification.success({
            message: 'Uploaded Successfully!',
          });
          setProductTagsList(response.data.data.list);
          setUploadFile([]);
        } else {
          notification.error({
            message: response.data.message,
          });
          setUploadFile([]);
        }
      } catch (e) {
        notification.error({
          message: 'Something went wrong.',
        });
        setLoading(false)
      }
    };
    if (uploadFile.length === 1) {
      uploadCSV();
    }
  }, [setProductTagsList, uploadFile]);

  return (
    <FlexColContainer>
      <SpaceBetweenContainer>
        <PickrrHeading size={5}>Select Tags</PickrrHeading>
        <Button type="link" onClick={() => setProductTagsList([])}>
          Reset
        </Button>
      </SpaceBetweenContainer>
      <TextArea
        placeholder="Add comma separated product list"
        rows={4}
        className="mb-16"
        value={productTagsList?.join(',')}
        onChange={(e) =>
          e.target.value
            ? setProductTagsList(e.target.value.split(','))
            : setProductTagsList([])
        }
      />
      <PickrrHeading size={5}>or upload product tags list</PickrrHeading>
      <FlexBox className="mb-16">
        <Upload {...uploadProps} showUploadList={false}>
          <PickrrButton
            color="primary"
            style={{ textTransform: 'none' }}
            icon={<UploadOutlined />}
            className="mr-12"
            loading={loading}
          >
            Upload CSV
          </PickrrButton>
        </Upload>
        <a
          href="https://d10srchmli830n.cloudfront.net/1632747494981_Sample Product File.csv"
          download
        >
          <Text>
            <DownloadOutlined className="mr-8" /> Download sample CSV file
          </Text>
        </a>
      </FlexBox>
    </FlexColContainer>
  );
};

export default ProductTags;
