import React, { useContext, useEffect, useState } from 'react';
import Header from 'components/CommonComponents/Header';

import RulesTable from './RulesTable';
import CreateRuleModal from '../CreateRuleModal';
import {
  Col,
  Row,
  Switch,
  Form,
  Divider,
  Input,
  InputNumber,
  Space,
  Typography,
  Popconfirm,
  Radio,
  notification,
  TimePicker,
  Table,
  Button,
  Upload,
  Alert
} from 'antd';
import { Container, CustomForm, SubHeader } from '../styled';
import { FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
  SpaceBetweenContainer,
} from 'components/UIElements';
import { CustomTabs, Text, UploadContainer, CustomButton, SmartCodFooter } from './style';
// import { CustomButton } from '../styled';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getGeneralDetails, updateSellerCheckoutConfig } from 'components/Settings/GeneralSettings/script';

import { fetchPickrrSelectRuleList } from './script';
import moment from 'moment';
import { DataContext } from 'context/DataProvider';
import { UploadOutlined, DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import axios from 'axios';
import HowToUseModal from '../HowToUseModal';

const { TabPane } = CustomTabs;
const { TextArea } = Input;
const ManageShipping = () => {
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const { data, isLoading, isSuccess } = useQuery(
    'generalSettings',
    getGeneralDetails
  );

  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (message, variables, data) => {
      notification.success({ message: 'Updated seller details.' });
    },
  });
  const [cutOffTime, setCutOffTime] = useState(null);
  const [isCreateRuleModalVisible, setIsCreateRuleModalVisible] =
    useState(false);
  const [activeKey, setActiveKey] = useState(1);

  const [editingKey, setEditingKey] = useState('');
  const [editData, setEditdata] = useState(undefined);
  const [key, setKey] = useState(0);
  const [tableData, setTableData] = useState([])
  const [updated, setUpdated] = useState(false);
  const [pincodeList , setPincodeList] = useState([])
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorPincodesList, setErrorPincodesList] = useState([]);
  const [paymentModeDiscount, setPaymentModeDiscount] = useState([]);
  const [deliveryModeDiscount, setDeliveryModeDiscount] = useState([]);

  const queryClient = useQueryClient();

  const Regex = new RegExp(/^(([0-9]+[.]?[0-9]*))$/);
  const percentRegex = new RegExp(/(^100(\.0{1,2})?$)|(^([0-9]([0-9])?)(\.[0-9]{1,2})?$)/);
  const timeRegex = new RegExp(/^(((?!0)[0-9]*))$/);


  useEffect(() => {
    const uploadCSV = async () => {
      try {
      setLoading(true);
      const formData = new FormData();
      formData.append('pincodes', uploadFile[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/upload-csv/`,
        formData
      );
      setLoading(false);
      if (response.data.success) {
        if (response.data.data.err_list.length > 0) {
          notification.error({
            message: 'Invalid pincodes found.',
          });
        } else {
          notification.success({
            message: 'File uploaded successfully.',
          });
          setPincodeList(response.data.data.list);
        }
        setErrorPincodesList(response.data.data.err_list);
        setUploadFile([]);
      } else {
        notification.error({
          message: response.data.message,
        });
        setUploadFile([]);
      } 
    } catch (e) {
      notification.error({
        message: 'Something went wrong.',
      });
      setLoading(false)
    }
    };
    if (uploadFile.length === 1) {
      uploadCSV();
    }
  }, [setPincodeList, uploadFile]);

  const { isLoading: isRuleListLoading, data: ruleListData } = useQuery(
    'courierRuleList',
    fetchPickrrSelectRuleList
  );


  useEffect(() => {
    if(ruleListData?.data?.length){

      const paymentModeDiscount = ruleListData?.data?.filter((rule) => rule?.type === "PAYMENT_MODE_DISABLE");
      const deliveryModeDiscount = ruleListData?.data?.filter((rule) => rule?.type === "DELIVERY_MODE_DISABLE");
      setDeliveryModeDiscount(deliveryModeDiscount)
      setPaymentModeDiscount(paymentModeDiscount)
    }
  }, [ruleListData])

  const handleQueryReFetch = () => {
    queryClient.invalidateQueries('courierRuleList');
  };

  const handleQueryUpdate = (data) => {
    queryClient.setQueryData('courierRuleList', { data: data });
  };

  const {onboardVersion} = useContext(DataContext);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        block_pincodes : data?.block_pincodes !== undefined ? data?.block_pincodes : true,
        ...(!!data?.pickup_config && {
          pickup_config: {
            ...data.pickup_config,
            cut_off_time: moment(
              data.pickup_config.cut_off_time,
              'HH:mm'
            ),
          },
        }),
      });

      setPincodeList(data?.blocking_pincode_list ? data?.blocking_pincode_list : [])
      let tableData = [];
      for (let item in data.pickup_ofd_config) {
        tableData.push({
          zone: item,
          ...data.pickup_ofd_config[item],
          key: item,
        });
      }
      setTableData(tableData);
    }
  }, [data]);



  const handleOpenModal = () => {
    setKey(`${Math.random()}`);
    setIsCreateRuleModalVisible(true);
  };

  const handleCloseModal = () => {
    setEditdata(null);
    setKey(`${Math.random()}`);
    setIsCreateRuleModalVisible(false);
  };

  const handleTabChange = (activeKey) => {
    setActiveKey(activeKey);
    console.log(activeKey);
  };


  return (
    <div>
      <Container>
        {/* <Header
          title="Shiprocket Smart COD"
          submitForm={submitForm}
          id={form}
          shippingTab={activeKey === '1' ? true : false}
        /> */}
          <CustomTabs
            defaultActiveKey="1"
            tabBarExtraContent={
              // activeKey === '1' && (
                <CustomButton
                  type='primary'
                  style={{marginRight: 16}}
                  onClick={handleOpenModal}
                >
                  Create Rule
                </CustomButton>
              // )
            }
            onChange={handleTabChange}
          >
            <TabPane tab="Delivery Mode Discount" key="1">
              <RulesTable
                setEditdata={setEditdata}
                setIsCreateRuleModalVisible={setIsCreateRuleModalVisible}
                setKey={setKey}
                updated={updated}
                setUpdated={setUpdated}
                ruleList={deliveryModeDiscount?.length ? deliveryModeDiscount : []}
                isRuleListLoading={isRuleListLoading}
                handleQueryUpdate={handleQueryUpdate}
                handleQueryReFetch={handleQueryReFetch}
              />
            </TabPane>
            <TabPane tab="Payment Mode Discount" key="2">
              <RulesTable
                setEditdata={setEditdata}
                setIsCreateRuleModalVisible={setIsCreateRuleModalVisible}
                setKey={setKey}
                updated={updated}
                setUpdated={setUpdated}
                ruleList={paymentModeDiscount?.length ? paymentModeDiscount : []}
                isRuleListLoading={isRuleListLoading}
                handleQueryUpdate={handleQueryUpdate}
                handleQueryReFetch={handleQueryReFetch}
              />
            </TabPane>
          </CustomTabs>
          <CreateRuleModal
            visible={isCreateRuleModalVisible}
            handleCloseModal={handleCloseModal}
            editData={editData}
            key={key}
            setKey={setKey}
            updated={updated}
            setUpdated={setUpdated}
            ruleList={ruleListData?.data}
            handleQueryReFetch={handleQueryReFetch}
          />
          {!localStorage.getItem('newUser') && 
            <HowToUseModal />
          }
      </Container>
        <SmartCodFooter>
          For any queries related to Shiprocket Checkout, contact us at &nbsp;
          <a href="">Checkoutsupport@shiprocket.com</a> 
          &nbsp; <CopyOutlined onClick={() => navigator.clipboard.writeText('Checkoutsupport@shiprocket.com')}/>
        </SmartCodFooter>
    </div>
  );
};

export default ManageShipping;
