import { Row, Col, Divider, Steps, Tooltip } from "antd";
import {
  FlexBox,
  FlexColContainer,
  PickrrCard,
  SmallHeading,
  SpaceAroundContainer,
  SpaceBetweenContainer,
} from "components/UIElements";
import React from "react";
import {
  AddressDetailDiv,
  BackButtonText,
  CustomDivider,
  CustomerFlexColContainer,
  CustomerSmallHeading,
  CustomerSpaceAroundContainer,
  CustomSmallHeading,
  CustomTabs,
  RtoStrip,
  ScrollableDiv,
  SubHeading,
  TimelineCont,
  TimelineContSmall,
} from "./style";
import moment from "moment";
import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";

const { TabPane } = CustomTabs;
const CustomerDetailsView = ({
  customerRowData,
  changeShowCustomerDetails,
}: {
  customerRowData: any;
  changeShowCustomerDetails: any;
}) => {
  return (
    <>
      <BackButtonText
        onClick={() => {
          changeShowCustomerDetails();
        }}
      >
        {/* @ts-ignore */}
        <LeftOutlined />
        BACK
      </BackButtonText>
      <Row gutter={[10, 10]}>
        <Col xl={10} lg={10}>
          <PickrrCard style={{ height: "200px", marginBottom: "10px" }}>
            <CustomerSmallHeading>Action Points</CustomerSmallHeading>
            <SpaceBetweenContainer>
              <FlexColContainer>
                <CustomSmallHeading>
                  {customerRowData?.lastOrderDate
                    ? moment(customerRowData?.lastOrderDate).format(
                        "DD-MMM-YYYY"
                      )
                    : "-"}
                </CustomSmallHeading>
                <SubHeading>Last Order Date</SubHeading>
              </FlexColContainer>
              <FlexColContainer>
                <CustomSmallHeading>
                  &#8377;
                  {customerRowData?.spendAmount
                    ? (
                        customerRowData?.spendAmount /
                        customerRowData?.placedOrders
                      ).toFixed(2)
                    : "-"}
                </CustomSmallHeading>
                <SubHeading>Avg Order Value</SubHeading>
              </FlexColContainer>
              <FlexColContainer>
                <CustomSmallHeading>
                  &#8377;
                  {customerRowData?.spendAmount
                    ? customerRowData?.spendAmount.toFixed(2)
                    : "-"}
                </CustomSmallHeading>
                <SubHeading>Total Amt Spent</SubHeading>
              </FlexColContainer>
            </SpaceBetweenContainer>
            <RtoStrip>
              <SpaceBetweenContainer
                style={{
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  paddingTop: "7px",
                  color: "#0C64DB",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                <div>
                  RTO Score{" "}
                  {customerRowData?.orders
                    ? (
                        (customerRowData?.rtoOrders / customerRowData?.orders) *
                        100
                      ).toFixed(2) + "%"
                    : "-"}{" "}
                  <span
                    style={{
                      color: "#263f97",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                  >
                    ({customerRowData?.rtoOrders} out of{" "}
                    {customerRowData?.orders})
                  </span>
                </div>
                {/*
 // @ts-ignore */}
                <Tooltip
                  color="#F3F8FF"
                  placement="right"
                  overlayStyle={{ maxWidth: "300px" }}
                  title={
                    <div style={{ padding: "5px" }}>
                      <h5 style={{ color: "#0C3784" }}>
                        Orders successfully delivered to this customer / orders
                        placed by this customer.
                      </h5>
                      <h6 style={{ color: "#0C64DB" }}>
                        *This data is only for orders fulfilled by Pickrr.
                      </h6>
                    </div>
                  }
                >
                  {/* @ts-ignore */}
                  <InfoCircleOutlined />
                </Tooltip>
              </SpaceBetweenContainer>
            </RtoStrip>
          </PickrrCard>
          <PickrrCard style={{ height: "410px" }}>
            <CustomerSmallHeading>Customer</CustomerSmallHeading>
            <SpaceBetweenContainer style={{ alignItems: "flex-end" }}>
              <CustomerFlexColContainer>
                <CustomSmallHeading>
                  {customerRowData.name || customerRowData?.lastName
                    ? `${customerRowData?.name ?? ""} ${
                        customerRowData?.lastName ?? ""
                      }`
                    : "-"}
                </CustomSmallHeading>
                <SubHeading>Name</SubHeading>
              </CustomerFlexColContainer>
              <CustomerFlexColContainer style={{ wordBreak: "break-all" }}>
                <CustomSmallHeading>
                  {customerRowData?.email ? customerRowData?.email : "-"}
                </CustomSmallHeading>
                <SubHeading>Email</SubHeading>
              </CustomerFlexColContainer>
              <CustomerFlexColContainer>
                <CustomSmallHeading>
                  {customerRowData?.phone ? customerRowData?.phone : "-"}
                </CustomSmallHeading>
                <SubHeading>Mobile No.</SubHeading>
              </CustomerFlexColContainer>
            </SpaceBetweenContainer>
            <CustomDivider />
            <CustomerSmallHeading style={{ fontSize: "16px" }}>
              Address
            </CustomerSmallHeading>
            <ScrollableDiv style={{ height: "170px" }}>
              <AddressDetailDiv>
                {customerRowData?.address[0]?.shipAddress}
              </AddressDetailDiv>
            </ScrollableDiv>
          </PickrrCard>
        </Col>
        <Col xl={14} lg={14}>
          <PickrrCard style={{ height: "620px" }}>
            <CustomTabs
              centered={true}
              tabBarGutter={100}
              defaultActiveKey={"1"}
            >
              <TabPane tab="Order History" key="1">
                <SpaceAroundContainer>
                  <SubHeading
                    style={{
                      fontWeight: "700",
                      width: "20%",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Timeline
                  </SubHeading>
                  <SubHeading
                    style={{
                      fontWeight: "700",
                      width: "40%",
                      textAlign: "center",
                    }}
                  >
                    Order ID
                  </SubHeading>
                </SpaceAroundContainer>

                <ScrollableDiv style={{ maxHeight: "500px" }}>
                  <Divider />
                  {customerRowData?.ordersTimeLine?.map(
                    (data: any, index: any) => {
                      return (
                        <>
                          {data?.orderPlacedTime && (
                            <CustomerSpaceAroundContainer>
                              {data?.orderPlacedTime && (
                                <>
                                  <FlexBox>
                                    <TimelineCont>
                                      {" "}
                                      <div>
                                        <div
                                          style={{
                                            width: "50%",
                                            textAlign: "left",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Order Placed
                                        </div>
                                        <div>
                                          {moment(data?.orderPlacedTime).format(
                                            "DD-MM-YYYY, LT"
                                          )}
                                        </div>
                                      </div>
                                    </TimelineCont>
                                  </FlexBox>
                                  <div
                                    style={{
                                      width: "40%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {data?.orderId}
                                  </div>{" "}
                                </>
                              )}
                            </CustomerSpaceAroundContainer>
                          )}

                          <CustomerSpaceAroundContainer>
                            <FlexBox>
                              <TimelineContSmall>
                                <div>
                                  <div
                                    style={{
                                      width: "40%",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Cart Initiated
                                  </div>
                                  <div>
                                    {moment(data?.checkoutTime).format(
                                      "DD-MM-YYYY, LT"
                                    )}
                                  </div>
                                </div>
                              </TimelineContSmall>
                            </FlexBox>
                            <div style={{ width: "40%", textAlign: "center" }}>
                              -
                            </div>
                          </CustomerSpaceAroundContainer>

                          <Divider />
                        </>
                      );
                    }
                  )}
                </ScrollableDiv>
              </TabPane>
              <TabPane tab="Products Purchased" key="2">
                <SpaceAroundContainer>
                  <SubHeading
                    style={{
                      fontWeight: "700",
                      textAlign: "left",
                      marginLeft: "-60px",
                    }}
                  >
                    Product Name
                  </SubHeading>
                  <SubHeading style={{ fontWeight: "700" }}>
                    Product Id
                  </SubHeading>
                  <SubHeading style={{ fontWeight: "700" }}>
                    Quantity
                  </SubHeading>
                </SpaceAroundContainer>
                <ScrollableDiv style={{ maxHeight: "500px" }}>
                  <Divider />

                  {customerRowData?.products &&
                    Object.keys(customerRowData?.products)?.map(
                      (data: any, index: any) => {
                        return (
                          <>
                            <CustomerSpaceAroundContainer>
                              <div style={{ width: "20%", textAlign: "left" }}>
                                {customerRowData?.products[data]?.name.replace(
                                  /<(.|\n)*?>/g,
                                  ""
                                )}
                              </div>
                              <div style={{ width: "30%", textAlign: "right" }}>
                                #{data}
                              </div>
                              <div
                                style={{ width: "30%", textAlign: "center" }}
                              >
                                {customerRowData?.products[data]?.quantity}
                              </div>
                            </CustomerSpaceAroundContainer>
                            <Divider />
                          </>
                        );
                      }
                    )}
                </ScrollableDiv>
              </TabPane>
            </CustomTabs>
          </PickrrCard>
        </Col>
      </Row>
    </>
  );
};

export default CustomerDetailsView;
