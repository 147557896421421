import React, { useEffect, useState } from "react";
import { StatusContainer, BulletIcon, SummaryHeading } from "../styled";
import { FlexBox } from "components/UIElements";
import { discountTypeText, customerType } from "../mapping";

interface ITieredConfig {
  tierValue?: string;
  offValue?: string | number;
}

const DiscountSummary = (props: any) => {
  const {
    data,
    discountConfig,
    setDiscountConfig,
    editData,
    couponId,
    current,
  } = props;

  let config;
  let configVisibility: string;
  let discountMethod: string;
  const edit = editData?.couponConfig;
  let configValue:string;
  if (data?.discountType=='freebie'){
    configValue=data?.discountConfig?.discountPercentage||data?.discountConfig?.discountFlat||data?.discountConfig?.freebieFlat||edit?.discountConfig?.discountPercentage||edit?.discountConfig?.discountFlat||edit?.discountConfig?.freebieFlat
  }
  else if (data?.discountType=='aftersell'){
    configValue=data?.discountConfig?.aftersellPercentage||data?.discountConfig?.aftersellFlat||edit?.discountConfig?.aftersellPercentage||edit?.discountConfig?.aftersellFlat
  }
  else{
    configValue=data?.discountConfig?.value?data?.discountConfig?.value:edit?.discountConfig?.value
  }

  // const configValue = data?.discountConfig?.value
  //   ? data?.discountConfig?.value
  //   : data?.discountConfig?.aftersellPercentage
  //   ? data?.discountConfig?.aftersellPercentage
  //   : edit?.discountConfig?.aftersellPercentage
  //   ? edit?.discountConfig?.aftersellPercentage
  //   : data?.discountConfig?.aftersellFlat
  //   ? data?.discountConfig?.aftersellFlat
  //   : edit?.discountConfig?.aftersellFlat
  //   ? edit?.discountConfig?.aftersellFlat
  //   : data?.discountConfig?.freebiePercentage
  //   ? data?.discountConfig?.freebiePercentage
  //   : edit?.discountConfig?.freebiePercentage
  //   ? edit?.discountConfig?.freebiePercentage
  //   : data?.discountConfig?.freebieFlat
  //   ? data?.discountConfig?.freebieFlat
  //   : edit?.discountConfig?.freebieFlat
  //   ? edit?.discountConfig?.freebieFlat
  //   : edit?.discountConfig?.value ;


  const couponCode = data?.discountMethod?.couponCode
    ? data?.discountMethod?.couponCode
    : editData?.couponCode;

  const discountType = edit?.discountType
    ? edit?.discountType
    : data?.discountType;

  const minimumCart = data?.discountCriteria?.minCartTotal
    ? data?.discountCriteria?.minCartTotal
    : edit?.discountCriteria?.minCartTotal;

  const customerLimit = data?.discountCriteria?.discountUsage?.customerLimit
    ? data?.discountCriteria?.discountUsage?.customerLimit
    : edit?.discountCriteria?.discountUsage?.customerLimit;

  let customerTypes: string;

  if (
    (data?.discountCriteria?.customerFilter?.customerType ||
      edit?.discountCriteria?.customerFilter?.customerType) !== "allCustomer"
  ) {
    customerTypes = data?.discountCriteria?.customerFilter?.customerType
      ? data?.discountCriteria?.customerFilter?.customerType
      : edit?.discountCriteria?.customerFilter?.customerType;
  } else {
    customerTypes = "";
  }

  if (data?.discountMethod?.automaticDiscount) {
    discountMethod = data?.discountMethod?.automaticDiscount
      ? "Automatic Discount"
      : "Coupon Discount";
  } else {
    discountMethod = edit?.discountMethod?.automaticDiscount
      ? "Automatic Discount"
      : "Coupon Discount";
  }

  function tieredConfig() {
    configVisibility =
      data?.discountConfig?.tiers?.slice(-1) ||
      edit?.discountConfig?.tiers?.slice(-1);

    const tieredType =
      data?.discountConfig?.discountCond === "quantity" ||
      edit?.discountConfig?.discountCond === "quantity";

    const tieredData =
      (data?.discountConfig?.tiers?.length && data?.discountConfig?.tiers) ||
      (edit?.discountConfig?.tiers?.length && edit?.discountConfig?.tiers);

    const configType = data?.discountConfig?.type
      ? data?.discountConfig?.type
      : edit?.discountConfig?.type;

    if (tieredData) {
      const items = tieredData?.map(
        (value: ITieredConfig, index: number | string) => {
          let firstLabel: string;
          const lastValue = configType === "flat" ? "and get" : "at";

          if (tieredType) {
            firstLabel = `Buy ${value?.tierValue} item's ${lastValue}`;
          } else {
            firstLabel = `With cart value ₹${value?.tierValue} ${
              configType === "flat" ? "get" : "get"
            }`;
          }
          return (
            <SummaryText
              label={firstLabel}
              value={
                configType === "flat" ? `₹${value?.offValue}*` : value?.offValue
              }
              endLabel={`${configType === "percent" ? "%" : ""}  off`}
              key={index}
            />
          );
        }
      );

      return items;
    }
  }

  const getvalueType = () => {
    if ((data?.discountConfig?.type || edit?.discountConfig?.type) === "flat") {
      return "flat";
    }
    if (
      data?.discountConfig?.aftersellFlat ||data?.discountConfig?.discountFlat
      ||edit?.discountConfig?.aftersellFlat||data?.discountConfig?.discountFlat
    ) {
      return "flat";
    }
    if (data?.discountConfig?.freebieFlat||edit?.discountConfig?.freebieFlat){
      return "freebieFlat"
    }
    return "percent";
  };
  // const configType = data?.discountConfig?.type
    //   ? data?.discountConfig?.type
    //   : edit?.discountConfig?.type;
  const getConfigType=(config:any)=>{
    if (config){
    const { type,discountPercentage,discountFlat,freebieFlat} = config;
    if (type){
      return data?.discountConfig?.type
    }
    else if (discountFlat && discountFlat>0){
      return 'flat'
    }
    else if(discountPercentage && discountPercentage>0){
      return 'Percent'
    }
    else if (freebieFlat && freebieFlat>0){
      return 'freebieFlat'
    }
    else if(edit?.discountConfig?.discountPercentage && edit?.discountConfig?.discountPercentage>0){
      return 'Percent'
    }
    else if(edit?.discountConfig?.discountFlat && edit?.discountConfig?.discountFlat>0){
      return 'flat'
    }
    else if(edit?.discountConfig?.freebieFlat && edit?.discountConfig?.freebieFlat>0){
      return 'freebieFlat'
    }}
    return 
  }

  function bundleConfig() {
    configVisibility = configValue;
    const firstName =
      (data?.discountConfig?.type || edit?.discountConfig?.type) === "flat"
        ? "On selected products get ₹"
        : "On selected products get";

    const latName =
      (data?.discountConfig?.type || edit?.discountConfig?.type) === "flat"
        ? " off"
        : "%  off";

    const returnValue = (
      <SummaryText label={firstName} value={configValue} endLabel={latName} />
    );
    return returnValue;
  }

  function defaultConfig() {
    configVisibility = configValue;

    const typeValue =
      (getvalueType() === "flat" || getvalueType() === "freebieFlat" ) ? `₹${configValue}` : `${configValue} %`;

    const returnValue = <SummaryText value={typeValue} endLabel={"Off"} />;

    return returnValue;
  }

  switch (discountType) {
    case "bxgy":
      configVisibility =
        data?.discountConfig?.customerGets?.searchList?.length ||
        edit?.discountConfig?.customerGets?.searchList?.length;
      config = (
        <SummaryText
          label={`Buy x item's get ${configVisibility} item/s free`}
        />
      );
      break;
    case "tiered":
      config = tieredConfig();
      break;
    case "bundle":
      config = bundleConfig();
      break;
    case "shipping":
      configVisibility = `Free`;
      config = <SummaryText label={"Free"} />;
      break;
    default:
      config = defaultConfig();
  }

  function SummaryText({ label, value, endLabel }: any) {
    return (
      <StatusContainer>
        <BulletIcon />
        <div style={{ flex: 1 }}>
          {label} {value} {endLabel}
        </div>
      </StatusContainer>
    );
  }

  useEffect(() => {
    let tieredType: string;
    // const configType = data?.discountConfig?.type
    //   ? data?.discountConfig?.type
    //   : edit?.discountConfig?.type;

    const configType=getConfigType(data?.discountConfig)
    if (data?.discountConfig?.discountCond) {
      tieredType =
        data?.discountConfig?.discountCond === "quantity"
          ? "quantity"
          : "cartValue";
    } else {
      tieredType =
        edit?.discountConfig?.discountCond === "quantity"
          ? "quantity"
          : "cartValue";
    }

    setDiscountConfig({
      ...discountConfig,
      code: couponCode,
      discountConfiguration: {
        discountType: `Discount Type ${discountTypeText[discountType]}`,
        applicableType: `Application Type ${discountMethod}`,
        config: configVisibility,
        configType: configType,
        ...(discountType === "tiered" && {
          tieredType: tieredType,
        }),
      },
      discountCondition: {
        minimumCart: minimumCart,
        limitTo: customerLimit,
        customerType: customerType[customerTypes],
      },
    });
  }, [data, editData]);

  return (
    <>
      {(current > 0 || couponId) && (
        <>
          <SummaryHeading style={{ marginTop: 8 }}>Coupon code:</SummaryHeading>
          <StatusContainer>
            <b>{couponCode}</b>
          </StatusContainer>
        </>
      )}

      {(current > 1 || couponId) && (
        <>
          <SummaryHeading style={{ marginTop: 8 }}>
            Configuration:
          </SummaryHeading>
          <div style={{ marginLeft: 8 }}>
            <SummaryText
              label={"Discount Type"}
              value={discountTypeText[discountType]}
            />
            <SummaryText label={"Application Type"} value={discountMethod} />
            {config}
          </div>
        </>
      )}

      {(current > 2 || couponId) && (
        <>
          {(customerTypes || minimumCart || customerLimit) && (
            <SummaryHeading style={{ marginTop: 12 }}>
              Conditions:
            </SummaryHeading>
          )}
          <div style={{ marginLeft: 8 }}>
            {minimumCart > 0 && (
              <SummaryText
                label={"Minimum cart value"}
                value={`₹${minimumCart}`}
              />
            )}
            {customerLimit && (
              <SummaryText
                label={"Limit to"}
                value={customerLimit}
                endLabel={"use per customer"}
              />
            )}
            {customerTypes && (
              <SummaryText
                label={"Limit to"}
                value={customerType[customerTypes]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DiscountSummary;
