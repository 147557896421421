import { Spin, Tooltip } from "antd"
import { FlexRow } from "components/Settings/Discount/styled"
import { StatText } from "components/UIElements/StatisticsBox/styled"
import { AOVicon, AmountIcon, CrossIcon, HatkeImage, ResetIcon, ShoppingCartIcon, SortByImages, WalletCardIcon } from "images/icons"
import { HeadingText } from "pages/Dashboard/PieView/styled"
import { Container } from "pages/EditOrder/styled"
import { CustomButton } from "pages/Orders/OrdersTab/styles"
import React from "react"
const FounderImage = require('../../images/person.png')
export const AmountOfCard = ({ heading, amount }: any) => {
    return <>
        <div className="flex gap-6 bg-white  px-6 items-center shadow-md rounded-lg min-w-60 h-24">
            <div><AmountIcon /></div>
            <div className={`flex-col gap-3`}>
                <div className="text-clear text-sm">{heading}</div>
                <div className="text-xl font-bold mt">{amount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</div>
            </div>
        </div>
    </>
}

export const TotalCard = ({ heading, amount }: any) => {
    return <>
        <div className="flex gap-6 bg-white  px-6 items-center py-8 shadow-md rounded-lg min-w-60 h-24">
            <div><ShoppingCartIcon /></div>
            <div className={`flex-col gap-3`}>
                <div className="text-clear text-sm">{heading}</div>
                <div className="text-xl font-bold mt">{amount}</div>
            </div>
        </div>

    </>
}
export const AOVCard = ({ heading, amount }: any) => {
    return <>
        <div className="flex gap-6 bg-white  px-6 items-center py-8 shadow-md rounded-lg min-w-60 h-24">
            <div><AOVicon /></div>
            <div className={`flex-col gap-3`}>
                <div className="text-clear text-sm">{heading}</div>
                <div className="text-xl font-bold mt">{amount.toLocaleString("en-IN", { style: "currency", currency: "INR" })}</div>
            </div>
        </div>

    </>
}
export const WalletCard = ({ heading, amount }: any) => {
    return <>
        <div className="flex gap-6 bg-white  px-5 items-center  shadow-md rounded-lg min-w-60 h-24">
            <div><WalletCardIcon /></div>
            <div className={`flex-col gap-3`}>
                <div className="text-clear text-sm w-fit">{heading}</div>
                <div className="text-xl font-bold mt">{amount}</div>
            </div>
        </div>

    </>
}
export const SortByCard = ({ TagsValue, setDisplaySort, setSortValue, sortValue, setDefaultSortBy, defaultSortBy }: any) => {
    return <div className="bg-white items-center px-5 py-3 rounded-xl shadow-md w-max">
        <div className="flex justify-between items-center gap-16">
            <span className="text-lg text-sortByColor font-bold">Sort by</span>
            <div className="flex gap-2 items-center">
                {/*// @ts-ignore */}
                <CustomButton onClick={() => {
                    setSortValue("")
                    setDefaultSortBy({})
                }}>
                    <ResetIcon />
                    Reset all
                </CustomButton>
                <span onClick={() => { setDisplaySort(false) }}><CrossIcon /></span>
            </div>
        </div>
        <div className="mt-4">
            {TagsValue?.length > 0 && TagsValue.map((tag: string) => {
                return <div className="flex items-center mb-4 justify-between">
                    <input type="radio" value={tag} className="w-5 h-5 border-black accent-purpleText"
                        onChange={(e) => {
                            setSortValue(e.target.value)
                            setDefaultSortBy({ ...defaultSortBy, id: e.target.value, desc: true })
                        }}
                        checked={sortValue === tag ? true : false} />
                    <span className="flex justify-between w-full">
                        <span className="ms-2 text-md font-medium text-sortByColor">{tag}</span>
                        {sortValue === tag && <span onClick={() => { setDefaultSortBy({ ...defaultSortBy, desc: !defaultSortBy?.['desc'] }) }} > <SortByImages /> </span>}
                    </span>
                </div>
            })}
        </div>
    </div>

}
export const ToolTipCard = ({ data, isLoading, heading }: any) => {
    return <>
        <div className="bg-white items-center px-5 py-4 rounded-xl shadow-md min-w-72">
            <div className="flex justify-between items-center">
                <span className="text-toolTipCardText text-sm w-36 ">{heading}</span>
            </div>
            {!isLoading ?
                <span className="text-xl font-bold text-sortByColor">
                    {(heading === "Retargeting Success Rate" || heading === "Resumed Checkout Conversion") ?
                        data && data !== undefined ? `${data?.toFixed(2)}%` : '-'
                        :
                        data && data !== undefined ? data : '-'
                    }
                </span>
                :
                <Spin />
            }
        </div>
    </>
}
export const ConverstionSrCard = ({ heading, content,Icon }: any) => {
    return <>
        <div className=" w-fit px-6 py-6 rounded-lg">
            
            <div className="flex flex-col gap-4">
                <div className="w-12 h-12">{Icon}</div>
                <span className="font-semibold text-ConversionBlue text-xl">{heading}</span>
                <span className="text-ConversionGrey max-w-64 flex flex-wrap text-lg">{content}</span>
            </div>
        </div>
    </>
}

export const ReviewCard = ({ Name, Designation, content ,image}: any) => {
    return <>
    <div className="flex flex-col gap-2 max-w-80 border border-ReviewBorder rounded-lg px-8 py-8 shadow-lg">
    <div className="w-10 h-10">{image}</div>
    <div className="text-lg font-semibold text-headingBlue">{Name}</div>
    <div className="-mt-3 text-ReviewDesignation">{Designation}</div>
    <div className="w-11/12 flex flex-wrap text-ReviewGrey font-normal">
                {content}
    </div>
    </div>

    </>
}