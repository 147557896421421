import { FlexColContainer } from "components/UIElements";
import React from "react";
import {
  SuccessButton,
  SuccessCustomModal,
  SuccessHeading,
  SuccessSubHeading,
} from "./styled";

const SuccessModal = ({ isSuccessModalOpen, setIsSuccessModalOpen , main_heading, sub_heading=null}) => {
  return (
    <SuccessCustomModal
      title=""
      visible={isSuccessModalOpen}
      onCancel={() => setIsSuccessModalOpen(false)}
      centered
      footer={null}
    >
      <FlexColContainer
        style={{ alignItems: "center", justifyContent: "center", padding: 10 }}
      >
        <img
          src="https://d10srchmli830n.cloudfront.net/1678690699381_efe82449-ee01-4506-9ce5-98eeac8cc652_status.svg"
          alt=""
          className="mb-24"
        />
        <SuccessHeading>{main_heading}</SuccessHeading>
       {sub_heading&& <SuccessSubHeading>
          {sub_heading}
        </SuccessSubHeading>}
        <SuccessButton onClick={() => setIsSuccessModalOpen(false)}>
          Done
        </SuccessButton>
      </FlexColContainer>
    </SuccessCustomModal>
  );
};

export default SuccessModal;
