import React from 'react'
import { EmptyViewContiner, Heading, SubHeading } from './styled'

const EmptyView = ({isCompact} : {isCompact?: boolean}) => {
    return (
        <>
            <EmptyViewContiner>
                <img 
                    src="https://d10srchmli830n.cloudfront.net/1653465140883_ab08ffde-3852-42c3-aad0-7fc9c855ea5b_Group-27721EmptyDataImage.svg"
                    alt="empty-data"
                />
                <Heading style={{fontSize: isCompact && "18px"}}>
                    No Data Available.
                </Heading>
                <SubHeading style={{fontSize: isCompact && "14px", lineHeight: isCompact && "20px"}}>
                    Looks likes there is no data available for this date range, you can try with other date range 
                </SubHeading>
            </EmptyViewContiner>
        </>
    )
}

export default EmptyView