import styled from 'styled-components';
import {DatePicker, TimePicker} from 'antd';

export const CustomDatePicker = styled(DatePicker)`
    width: 100%;
    border-radius: 8px;
    height:38px;
`

export const CustomTimePicker = styled(TimePicker)`
    width: 100%;
    border-radius: 8px;
    height:38px;
`
