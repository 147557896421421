import { notification } from "antd";
import React, { useState } from "react";
import {
  SearchResultContainer,
  SearchResultItem,
  SearchResultItemImg,
} from "./styled";

interface IVariants {
  label: string;
  price: number | string;
  productId: string;
  value: string;
}

interface ISearchedItems {
  id: string;
  imgUrl: string;
  name: string;
  price: number | string;
  type: string;
  variants: IVariants[];
}


const SearchResult = ({
  isLoading,
  data,
  setIsProductModalOpen,
  setSelectProductId,
  setEditItemId,
  type,
  setSearchTerm,
  selectedItems,
  setSelectedItems,
}: any) => {

  const placeHolder = 'https://pickrr.s3.amazonaws.com:443/2023-03-06T14:52:40.121308_imagePlace_holder.png';


  const itemSelectHandler = (itemId: string | number, item: any) => {
    const isItemExist = selectedItems && selectedItems?.filter((item: any) => item?.id === itemId);
    if (isItemExist?.length > 0) {
      notification.error({ message: 'Item is already added to user list' })
    } else if (itemId) {
        setSelectProductId(itemId);
        setIsProductModalOpen(true);
        setEditItemId()
  }
}

  return (
    <SearchResultContainer key={data?.item?.[0]?.id}>
      {isLoading && <div key={data?.item?.[0]?.id}>Loading...</div>}
      {data &&
        data.map((item: ISearchedItems) => (
          <SearchResultItem
            key={item.id}
            onClick={() => itemSelectHandler(item.id, item)}
          >
            <SearchResultItemImg src={item?.imgUrl ? item?.imgUrl : placeHolder} />
            {item?.name}
          </SearchResultItem>
        ))}
    </SearchResultContainer>
  );
};

export default SearchResult;
