import CustomInput from 'UiComponents/CustomInput';
import { CustomSelect } from 'UiComponents/Select/dropdownNew';
import { CustomDatePicker } from 'components/UIElements';
import React from 'react'
import { Controller } from 'react-hook-form';

export const SelectController = ({ control, name, rules, ...props }: any) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => <CustomSelect {...field} {...props} />} />
);

export const InputController = ({ control, name, rules, ...props }: any) => (
    <Controller
        control={control}
        name={name}
        rules={rules} 
        render={({ field }) => <CustomInput {...field} {...props}/>}/>
)

export const DatePickerController = ({ control, name, rules, disabledDates, ...props }: any) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => <CustomDatePicker {...field} {...props} disabledDate={disabledDates || (() => false)} />}
    />
)