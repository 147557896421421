import styled from 'styled-components'
import {Table, DatePicker, Input} from 'antd'

const { RangePicker } = DatePicker;

export const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0C3784;
`

export const CustomTable = styled(Table)`
  .ant-table {
    border: 1px solid rgba(38, 63, 151, 0.1);
    border-radius: 12px;
  }
  .ant-table-thead > tr > th {
    background: #fff;
    color: rgba(13, 57, 133, 0.5);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    /* text-transform: uppercase; */
    word-break: none;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-word;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0c3784;
    text-align: left;
  }
  .ant-table-tbody > tr {
    vertical-align: center;
    border: 1px solid rgba(38, 63, 151, 0.1);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #f1faff;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }
  .ant-table-tbody > tr > td > td:first-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
  .ant-table-thead > tr > th:last-child {
    text-align: center;
  }
`;

export const CustomDatePicker = styled(RangePicker)`
  &.ant-picker {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
  }
`;

export const CustomInput = styled(Input)`
    border-radius: 10px! important;
`
export const PaymentStatus = styled.div<{textColor?:string, bgColor?: string}>`
    width: 80px;  
    height: 28px;
    border-radius:8px; 
    font-size:12px;
    line-height: 15px;
    font-weight: 600;
    margin-top:10px;
    text-align:center;
    padding:6px 0;
    background-color: ${(props:any) => 
      props.bgColor ? props.bgColor : '#F0F6FD'};
    color:${(props:any) => props?.textColor ? props?.textColor : '#000B34'
    };
`