import React, { useEffect, useState } from 'react'
import { FormContainer, SubHeading, CheckboxText, CheckboxDescriptipon, BadgeBox } from '../styled'
import { Asterisk, CustomForm } from 'components/UIElements'
import {Row, Col, Form, Input, Checkbox, notification, Select} from 'antd'
import { PickrrButton } from 'components/UIElements'
import { getGeneralDetails, updateSellerCheckoutConfig } from 'components/Settings/GeneralSettings/script'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import {InfoCircleOutlined, PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';


const { Option } = Select;

export const FacebookTrackingPage = ({tabKey} : {tabKey: string}) => {

    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [contentIdValue, setContentIdValue] = useState<string>("product_id");

    const handleContentIdValue = (value:string) => {
        setContentIdValue(value);
    }





    const { data } = useQuery(
        'generalSettings',
        getGeneralDetails
    );

    const mutation = useMutation(updateSellerCheckoutConfig, {
        onError: () => {
          notification.error({message: 'Something went wrong.'});
        },
        onSuccess: () => {
          queryClient.invalidateQueries('generalSettings');
          notification.success({ message: 'Updated seller details.'});
        },
      });

    useEffect(() => {
        if(data) {
            form.setFieldsValue({
                pixel_array: data?.marketing_config?.facebook?.pixel_array,
                token_id: data?.marketing_config?.facebook?.token_id,
                checkout_initiated: data?.marketing_config?.facebook?.events?.checkout_initiated,
                payment_initiated: data?.marketing_config?.facebook?.events?.payment_initiated,
                order_placed: data?.marketing_config?.facebook?.events?.order_placed,
                enable_content_id: data?.marketing_config?.facebook?.enable_content_id === undefined ? true : data?.marketing_config?.facebook?.enable_content_id,
                content_id: data?.marketing_config?.facebook?.content_id
            }
            )
        }
    }, [data, tabKey])

    const submitForm = () => {
        const postData = {...form.getFieldsValue()}

        // console.log(postData.content_id ? postData.content_id : contentIdValue);

         mutation.mutate({
            marketing_config: {
                ...data?.marketing_config, 
                facebook: {
                    pixel_array: postData.pixel_array,
                    token_id: postData.token_id,
                    events: {
                        checkout_initiated: postData.checkout_initiated,
                        payment_initiated: postData.payment_initiated,
                        order_placed: postData.order_placed,
                    },
                    enable_content_id: postData.enable_content_id,
                    content_id: postData.content_id ? postData.content_id : contentIdValue
                }
            }
        })
    }

    return (
        <FormContainer>
            <CustomForm layout="vertical" onFinish={submitForm} form={form}>
                <Row gutter={[50,0]}>
                    <Form.List name="pixel_array">
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(( field , index) => (
                                <>
                                    <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                                <Form.Item
                                    label = {`Pixel ID ${index===0 ? '' : index}`}
                                    name={[field.name,`pixel_id`]}
                                    rules={[
                                        {
                                          required: true,
                                          message: 'Please input Pixel ID',
                                        },
                                      ]}
                                >
                                    <Input/>
                                </Form.Item>
                                </Col>
                                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                                <Form.Item
                                    label = {`Access Token ${index===0 ? '' : index}`}
                                    name={[field.name,`token_id`]}rules={[
                                        {
                                          required: true,
                                          message: 'Please input Access Token',
                                        },
                                      ]}
                                >
                                    <Input />
                                </Form.Item>
                                </Col>
                                {fields.length > 1 &&  
                                <Col style={{display :'flex', alignItems : 'center'}} span={4}>
                                    {/* @ts-ignore */}
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Col>}
                                </>
                            ))}
                            
                            {fields.length < 5  ?
                                <Col xl={7} lg={7} md={12} sm={24} xs={24}>
                                <Form.Item>
                                    {/* @ts-ignore */}
                                    <PickrrButton color='checkout-blue' onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add another pixel id
                                    </PickrrButton>
                                </Form.Item>
                                </Col>
                                :
                                <Col span={24}>
                                    <Asterisk>*</Asterisk><span style={{color : '#0C64DB'}}>Note: You can add a maximum of 5 pairs of pixel ids and access tokens.</span>
                                </Col>
                            }
                            </>
                        )}
                    </Form.List>
                    <Col span={24}>
                        <SubHeading>Please select the Events you want to trigger :</SubHeading>
                    </Col>
                    <Col span={24}>
                    <Form.Item name="checkout_initiated" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Checkout Initiated</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                        This event is triggered when the checkout pop-up opens. It is the standard Meta Pixel InitiateCheckout event.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                    <Form.Item name="payment_initiated" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Add Payment Info</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                        This event is triggered when the user reaches payment stage. It is the standard Meta Pixel AddPaymentInfo event.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                    <Form.Item name="order_placed" valuePropName="checked">
                        <Checkbox>
                            <CheckboxText>Purchase</CheckboxText>
                        </Checkbox>
                    </Form.Item>
                        <CheckboxDescriptipon>
                        This event is triggered when the order is placed. This is the standard Meta Pixel Purchase event.
                        </CheckboxDescriptipon>
                    </Col>
                    <Col span={24}>
                        <SubHeading>Please select the content id you want to send :</SubHeading>
                    </Col>
                    <Col span={24}>
                    <SubHeading style={{fontSize: 14, color:'#38446D', fontWeight: 'normal', marginBottom: 10, marginTop: 0}}>Content ID</SubHeading>
                        <Form.Item  name= "content_id" >
                          <Select style={{width:'200px'}} 
                            // placeholder="Product Id"
                            onChange={handleContentIdValue}
                            defaultValue={'product_id'} 
                          >
                            <Option value="product_id">Product ID</Option>
                            <Option value="variant_id">Variant ID</Option>
                            <Option value="sku">SKU</Option>
                        </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={24}>
                        {
                        contentIdValue === "variant_id" &&
                        <BadgeBox>
                            <InfoCircleOutlined /> &nbsp;Variant id will only be sent for products with variants and otherwise product id will be sent.
                        </BadgeBox>
                        }
                    </Col> */}
                    <Col span={24}>
                    {/* name="enable_content_id" valuePropName="checked" */}
                    
                        <Form.Item name="enable_content_id" valuePropName="checked">
                            <Checkbox defaultChecked={true}>
                                <CheckboxText>Send contents in facebook events </CheckboxText>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{paddingBottom:'15px', paddingTop:'15px'}}>
                    <PickrrButton htmlType="submit" color="checkout-blue">
                        Save
                    </PickrrButton>
                    </Col>
                </Row>
            </CustomForm>
        </FormContainer>
    )
}

export default FacebookTrackingPage