import React, { useState } from 'react';
import {notification} from 'antd';
import { ModalHeading, CustomButton, CustomModal } from './style';
import { CustomInput } from '../CreateDiscountModal/styled';
import { useMutation, useQueryClient } from 'react-query';
import {syncShopifyCoupon} from './script';


const ShopifySyncDiscountModal = ({isOpenImportDiscountModal, setIsOpenImportDiscountModal, sellerConfigValues, shopifyKey}:any) => {
    const [couponCode, setCouponCode] = useState<string>();
    const [disabledButton, setDisableButton] = useState(true)
  

    const queryClient = useQueryClient();

    const mutate = useMutation(syncShopifyCoupon, {
        onMutate: (variables) => {
          return { id: 1 };
        },
        onError: (error: any) => {
          notification.error({ message: error?.response?.data?.message});
        },
        onSuccess: () => {
          setIsOpenImportDiscountModal(false);
          queryClient.invalidateQueries(["fetchSuggestedDiscountData"]);
          queryClient.invalidateQueries(["fetchDiscountData"]);
          notification.success({ message: "CouponCode imported successfully"});
        },
      });


    const onChangeCouponCodeHandle = (e:any) => {
        if(e?.target?.value){
            setCouponCode(e?.target?.value)
            setDisableButton(false)
        }else {
            setDisableButton(true)
        }
    }

    const handleImportCoupon = () => {
        mutate.mutate({
            discount_code:couponCode.trim(),
            seller_id: sellerConfigValues?.seller_id
        });
    }
    
    return (
    <CustomModal 
        title={null}
        visible={isOpenImportDiscountModal}
        footer={null}
        onCancel={() => setIsOpenImportDiscountModal(false)}
        width = {360}
        key = {shopifyKey}
        keyboard= {true}
        >
        <ModalHeading>Import discount from Shopify</ModalHeading>
        <CustomInput 
            style={{marginTop: 16}}
            placeholder='Enter discount Code'
            onChange={onChangeCouponCodeHandle}

        />
        <CustomButton 
            buttontype='outline'
            onClick={() => setIsOpenImportDiscountModal(false)}
            >
                Discard
        </CustomButton>
        <CustomButton 
            disabled = {disabledButton}
            onClick = {handleImportCoupon}
            loading = {mutate?.isLoading}
            >
                Save
        </CustomButton>
      </CustomModal>
    )
}

export default ShopifySyncDiscountModal;

