import React from 'react';

const   UPIDetailsList = ({paymentModeDataResponseList}:any) => {
    const listItems = paymentModeDataResponseList?.map((value:any) => {
        return (
            <div style={{display:'flex'}} className='custom__rows'>
                <div style={{width: '34%', marginBottom: 10, marginLeft : 50}}>{value?.paymentMethod}</div>
                <div style={{width: '15%', marginBottom: 10}}>{value?.checkouts}</div>
                <div style={{width: '15%', marginBottom: 10}}>{value?.orders}</div>
                <div style={{width: '15%', marginBottom: 10}}>₹{value?.gmvOrder}</div>
                <div style={{width: '17%', marginBottom: 10}}>{value?.percentConversion}%</div>                
            </div>
        )
    })

    return (<>{listItems}</>)
}

export default UPIDetailsList;


{/* <div style={{display:'flex'}}>
    <div style={{width: '34%', marginBottom: 10, marginLeft : 50}}>{value?.paymentMethod}</div>
    <div style={{width: '15%', marginBottom: 10}}>{value?.checkouts}</div>
    <div style={{width: '15%', marginBottom: 10}}>{value?.orders}</div>
    <div style={{width: '15%', marginBottom: 10}}>{value?.gmvOrder}</div>
    <div style={{width: '17%', marginBottom: 10}}>{value?.percentConversion}%</div>                
</div> */}
