// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { CustomButton } from 'pages/Orders/OrdersTab/styles'
import { ResetIcon } from 'images/icons'


const FilterNew = ({filters,filterKey,setFilterKey,multipleSelect,setIsOpen,setPageNumber,mapping}: any) => {
    const [checkedList, setCheckedList] = useState<string[]>([...filterKey])

    const handleFilterChange=(value:string)=>{
        if(multipleSelect) {
            !checkedList.includes(mapping[value]) ?
            setCheckedList([...checkedList, mapping[value]]) :
            setCheckedList(checkedList.filter(filter => filter !== mapping[value]))
        } else {
            setCheckedList([mapping[value]])
        }
        setPageNumber(1)
    }
    const isChecked=(value:string)=>{
        
        const val=checkedList.find((ele:string)=>{return ele===mapping[value]})
        // console.log('THIS IS VALUE',mapping[value],value)
        return val?true:false
    }
    function useOutsideAlerter(ref: any) {
        useEffect(() => {
          function handleClickOutside(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setIsOpen(false)
              setCheckedList(filterKey)
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref, filterKey]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


    return <div className='bg-white h-fit w-max flex flex-col gap-2 shadow-lg rounded-xl px-4 accent-purpleText py-4 z-40' ref={wrapperRef}>
        {filters.map((filter:string)=>{
            return <div className='flex gap-3 items-center'><input type={multipleSelect?'checkbox':'radio'} className='h-4 w-4' checked={isChecked(filter)} onChange={()=>{handleFilterChange(filter)}} /><span>{filter}</span></div>
        }
        )}

        <div>
            <hr className="w-11/12 h-px my-1 bg-hrGrey border-0 dark:bg-hrGrey" />
            <div className=" flex justify-end gap-2 mt-3">
                <CustomButton className="px-1 w-20 h-7"><span className='flex gap-1' onClick={()=>{
                    setFilterKey([])
                    setCheckedList([])
                    setIsOpen(false)
                    setPageNumber(1)
                }
                    }><ResetIcon />Reset</span></CustomButton>
                <CustomButton className=" w-20 h-7 px-6 bg-purpleText text-white" onClick={()=>{
                    setFilterKey(checkedList)
                    setIsOpen(false)
                    setPageNumber(1)
                    }}><span>Apply</span></CustomButton>
            </div>
        </div>


    </div>
}

export default FilterNew