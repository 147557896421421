import { notification } from 'antd';
import axios from 'axios';
import {GetAuthToken} from 'globalQuries'
const auth: any = GetAuthToken();

export const productSearch = async({queryKey} : any) => {
  const auth: any = GetAuthToken();
    const[unusedArgument, {searchTerm, sellerId, storeUrl, searchType, channel, productId}] = queryKey 
  try{
    if((searchTerm || productId) && storeUrl){
        const response = await axios.get(`${process.env.REACT_APP_CATALOGUE_SERVICE}/api/ve1/catalog-service/search`,
        {params : {
            sellerId: sellerId,
            storeUrl:storeUrl,
            searchType: searchType,
            channel: channel,
            ...(productId && {id: productId}),
            ...(searchTerm && {query: searchTerm}),
        },
        headers: {
            'X-Auth': auth,
        },
        }
        ) 
        return response.data;
    }
  }catch(error){
    notification.error({message:error?.response?.data?.message})
  }
}
