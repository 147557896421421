export const getDeltaImageUrl = (title: string, delta: number) => {
    if(title === "Drop Off") {
        return (
            delta < 0 ?
            "https://d10srchmli830n.cloudfront.net/1653386841954_cbcf59ea-1a1c-48b1-a26a-60cb60254d1f_VectorIncreaseDown.svg" :
            "https://d10srchmli830n.cloudfront.net/1653386923350_af0596c3-7c18-4960-8390-25056f61ff5e_VectordecreaseUp.svg"
        )
    } else {
        return (
            delta < 0 ?
            "https://d10srchmli830n.cloudfront.net/1652870375607_bf565686-d737-436e-9e10-f5be4724de2d_VectordecreaseDown.svg" :
            "https://d10srchmli830n.cloudfront.net/1651222531941_VectorPercentageIncrease.svg"
        )
    }
}

export const getArrowUrl = (percentage: number, title: string) => {
        if(isNaN(percentage)) {
            return "https://d10srchmli830n.cloudfront.net/1653636999745_7885b616-d32e-4a04-9243-05b58973b932_Group-27578GreyDownIcon.svg"
        } else {
            if (percentage <= 100 && percentage >=80) {
                return "https://d10srchmli830n.cloudfront.net/1651649562501_Group-27578DownDarkRed.svg"
            }
            else if (percentage <= 79 && percentage >=60) {
                return "https://d10srchmli830n.cloudfront.net/1653398218928_e476ade7-285f-4650-84db-428731194d76_Group-27746ArrowIcon2.svg"
            } 
            else if (percentage <= 59 && percentage >=40) {
                return "https://d10srchmli830n.cloudfront.net/1653398487913_fbb93859-b157-4309-80a7-c590eae241b3_Group-27747ArrowIcon3.svg"
            } 
            else if (percentage <= 39 && percentage >=20) {
                return "https://d10srchmli830n.cloudfront.net/1653398558351_d313057c-b42a-4efb-9159-a2245a8f40cf_Group-27748ArrowIcon4.svg"
            }
            else if ((percentage <= 19 && percentage >=0) || isNaN(percentage)) {
                return "https://d10srchmli830n.cloudfront.net/1653398599794_51bf32a9-4cb2-4a3e-9c90-e7fc5b178c66_Group-27749ArrowIcon5.svg"
            }
    }
    }

export const getColor = (percentage: number, title: string) => {
        if(isNaN(percentage)) {
            return "#858585"
        } else {
            if (percentage <= 100 && percentage >=80) {
                return "#EA0404"
            }
            else if (percentage <= 79 && percentage >=60) {
                return "#F04F4F"
            } 
            else if (percentage <= 59 && percentage >=40) {
                return "#F26868"
            } 
            else if (percentage <= 39 && percentage >=20) {
                return "#F58282"
            }
            else if ((percentage <= 19 && percentage >=0) || isNaN(percentage)) {
                return "#F79B9B"
            }
    }
}