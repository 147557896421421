import React from 'react';

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
} from 'components/UIElements';
import { CheckBox } from './style';

const OrderType = ({ orderType, setOrderType }) => {

  const setCheckedOrderType = (type) =>
    orderType.includes(type) ? 'true' : 'false';

  const handleOrderTypeChange = (type) =>
    orderType.includes(type)
      ? setOrderType((s) => s.filter((d) => d !== type))
      : setOrderType((s) => [...s, type]);

  return (
    <FlexColContainer>
      <PickrrHeading size={5}>Order type</PickrrHeading>
      <FlexBox className="mb-16">
        <CheckBox
          checked={setCheckedOrderType('cod')}
          onClick={() => handleOrderTypeChange('cod')}
        >
          COD
        </CheckBox>
        <CheckBox
          checked={setCheckedOrderType('prepaid')}
          onClick={() => handleOrderTypeChange('prepaid')}
        >
          Prepaid
        </CheckBox>
        <CheckBox
          checked={setCheckedOrderType('reverse')}
          onClick={() => handleOrderTypeChange('reverse')}
        >
          Reverse
        </CheckBox>
      </FlexBox>
    </FlexColContainer>
  );
};

export default OrderType;
