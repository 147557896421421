import React, { useState } from 'react';
import { Button, Col, Input, InputNumber, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import {   
  FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
} from 'components/UIElements';


import { WeightRangeContainer, ErrorText } from './style';

const UTMList = ({ utmList, setUtmList }) => {
  const [error, setError] = useState(false);

  const handleAdd = () => {
    setUtmList((prev) => [
      ...prev,
      {
        source: null,
        medium: null,
        campaign: null
        //id: uuidv4(),
      },
    ]);
  };

  const handleChangeSource = (id, event) => {
    setUtmList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            source: event.target.value,
          };
        }
        return w;
      })
    );
  };

  const handleChangeMedium = (id, event) => {
    setUtmList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            medium: event.target.value,
          };
        }
        return w;
      })
    );
  };

  const handleChangeCampaign = (id, event) => {
    setUtmList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            campaign: event.target.value,
          };
        }
        return w;
      })
    );
  };

  const handleDelete = (id) => {
    setUtmList((prev) => prev.filter((s, index) => index !== id));
  };

  return (
    <WeightRangeContainer>
      <FlexColContainer>
        <PickrrHeading size={5}>UTM Parameters</PickrrHeading>
        {utmList.map((s, index) => (
          <Row gutter={16} className="mb-16" key={index}>
            <Col span={7}>
              <Input
                className="mr-12"
                size="large"
                placeholder="Source"
                value={s.source}
                onChange={(value) => handleChangeSource(index, value)}
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Col>
            <Col span={7}>
              <Input
                className="mr-12"
                size="large"
                placeholder="Medium"
                value={s.medium}
                onChange={(value) => {
                  handleChangeMedium(index, value);
                  //checkForError(s.min, value, index)
                }}
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Col>
            <Col span={7}>
              <Input
                className="mr-12"
                size="large"
                placeholder="Campaign"
                value={s.campaign}
                onChange={(value) => {
                  handleChangeCampaign(index, value);
                  //checkForError(s.min, value, index)
                }}
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Col>
            <Col span={3}>
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(index)}
              />
            </Col>
            <Col offset={10} span={10}>
              {s.min !== null && s.max !== null && s.min >= s.max && (
                <ErrorText>Max Value should be greater</ErrorText>
              )}
            </Col>
          </Row>
        ))}
        <PickrrButton color="outline" onClick={handleAdd}>
          {utmList.length ? 'Add More' : 'Add'}
        </PickrrButton>
      </FlexColContainer>
    </WeightRangeContainer>
  );
};

export default UTMList;
