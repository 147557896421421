import React, { useState } from 'react'
import CustomInputBox from 'components/UIElements/CustomInputBox'
import { Checkbox ,Col, Select, Input} from 'antd';
import MultipalDiscounts from './MultipalDiscounts'
import {
    FlexColContainer,
    Container,
    ContainerDisableShipping,
} from './styled'

const EnterValue = ({
    ruleName,
    ruleValuePercent,
    ruleValueAbsolute,
    setRuleValuePercent,
    setRuleValueAbsolute,
    fastrrShipping,
    setFastrrShipping,
    noRushShipping,
    setNoRushShipping,
    standardShipping,
    setStandardShipping,
    applyMaxValue,
    setApplyMaxValue,
    shipping_disable,
    setShippingDisable,
    payment_modes_discount,
    setPaymentModesDiscount,
    partialCodShipping,
    setPartialCodShipping,
    patialType,
    setPatialType,
    partialValue,
    setPartialValue
}) => {
    const [isError, setIsError] = useState(false);
    const percentageRegex = /^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)%$/;    

    const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);
    
    
    

    
    const shippingDisableChange =(key)=>{
        setShippingDisable((previousState)=>{
            return {...previousState,[key]:!previousState[key]}
        })
    }
    const onlyShippingDisale =(key)=>{
        setPartialCodShipping((previousState)=>{
            return !previousState
        })
    }
    const {Option} = Select;
    const handleValidate = (e) => {
        setPartialValue(e.target.value)
        if(patialType === "percentage" && e.target.value > 100){
            setIsError(true)
        }else {
            setIsError(false)
        }

        // if()
        // if(percentageRegex.test(e?.target?.value?.toString())){
        // //   setIsError(percentRegex.test(e.target.value));
        //   console.log('valid regex')
        // }else {
        //   setIsError(false);
        // }
      };

    const renderRuleFields = () => {
        switch(ruleName) {
            case "prepaid_discount": return (
            <MultipalDiscounts  
                payment_modes_discount = {payment_modes_discount}
                setPaymentModesDiscount={setPaymentModesDiscount}
                ruleValueAbsolute={ruleValueAbsolute}
                setRuleValueAbsolute={setRuleValueAbsolute}
                ruleValuePercent={ruleValuePercent}
                setRuleValuePercent={setRuleValuePercent}
                applyMaxValue={applyMaxValue}
                setApplyMaxValue={setApplyMaxValue}
            />
            )
            case "cod_charge": return (
                <Container>
                    <CustomInputBox label="COD charges ₹"  setvalue={setRuleValueAbsolute} value={ruleValueAbsolute}/>
                    <hr style={{border:'1px solid rgba(0, 11, 52, 0.1)'}}/>
                    <CustomInputBox label="COD percentage %"  setvalue={setRuleValuePercent} value={ruleValuePercent} />
                    <Checkbox
                        checked={applyMaxValue}
                        onChange={() => setApplyMaxValue(!applyMaxValue)}
                        style={{marginTop:'20px', color: '#0C3784'}}
                    >
                        Apply max value?
                    </Checkbox>
                </Container>
            )
            case "shipping_charge": return (
                <Container>
                    <CustomInputBox label="Rush ₹" name="fastrrShipping" setvalue={setFastrrShipping} value={fastrrShipping} />
                    <hr style={{border:'1px solid rgba(0, 11, 52, 0.1)'}} />
                    <CustomInputBox label="Express ₹"  name="noRushShipping" setvalue={setNoRushShipping} value={noRushShipping} />
                    <hr style={{border:'1px solid rgba(0, 11, 52, 0.1)'}}/>
                    <CustomInputBox label="Standard ₹"  name="standardShipping" setvalue={setStandardShipping} value={standardShipping}/>
                </Container>
            )
            case "shipping_disable": return (
                <ContainerDisableShipping>
                    <Col span={24}>
                    <Checkbox
                        checked={shipping_disable.show_rush}
                        onChange={() => shippingDisableChange('show_rush')}
                        style={{ color: '#0C3784'}}
                    >
                        Show Rush
                    </Checkbox>
                    </Col>
                    <Col span={24}>
                    <Checkbox
                        checked={shipping_disable.show_express}
                        onChange={() => shippingDisableChange('show_express')}
                        style={{marginTop:'20px', color: '#0C3784'}}
                    >
                        Show Express
                    </Checkbox>
                    </Col>
                    <Col span={24}>
                    <Checkbox
                        checked={shipping_disable.show_standard}
                        onChange={() => shippingDisableChange('show_standard')}
                        style={{marginTop:'20px', color: '#0C3784'}}
                    >
                        Show Standard
                    </Checkbox>
                    </Col>
                </ContainerDisableShipping>
            )
            case "partial_cod": return (
                <ContainerDisableShipping>
                    <Col>
                    <div style={{display:'flex'}}>
                        <Select
                            value={patialType}
                            onChange={(value)=>{
                                setPatialType(value)
                            }}
                        >
                            <Option value="percentage">Percentage</Option>
                            <Option value="absolute">Absolute</Option>
                        </Select>
                        <Input 
                            name={patialType === "percentage" ? "rule_value_percent" : 'rule_value_absolute'} 
                            maxLength={patialType === "percentage" ? 4 : 10 }
                            value={partialValue}
                            onChange={(e) => handleValidate(e)}
                            style={{width: 200}}
                        />
                        </div>
                        {isError && <div style={{fontSize:14, color: 'red', marginTop:6}}>Enter valid number</div>}
                    </Col>
                    <Col span={24}>
                    <Checkbox
                        checked={partialCodShipping}
                        onChange={() => onlyShippingDisale()}
                        style={{marginTop:'20px', color: '#0C3784'}}
                    >
                        Include Shipping Charges
                    </Checkbox>
                    </Col>
                </ContainerDisableShipping>
            )
        }
    }

    return (
        <FlexColContainer>
            {renderRuleFields()}
        </FlexColContainer>
    )
}

export default EnterValue