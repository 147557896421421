import React, { useEffect, useState } from "react";
import { Button, Checkbox, Tooltip, message, Spin} from "antd";
import { CustomModal } from "../../styled";
import {
  CustomFont,
  NextButton,
  ButtonContainer,
} from "../styled";
import { ProductSearchContainer } from "./styled";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useQuery } from "react-query";
import { productSearch } from "./script";

const CheckboxGroup = Checkbox.Group;

const ProductSearchModal = ({
  isProductModalOpen,
  setIsProductModalOpen,
  selectProductId,
  sellerDetails,
  selectedItems,
  setSelectedItems,
  setSearchTerm,
  editItemId,
  setEditItemId,
  randomKey,
  discountType,
  form,
  setSelectProductId
}: any) => {
  const [checkedList, setCheckedList] = useState<any>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState<any>(false);
  const [productId, setProductId] = useState<string | number>();
  const [variantsDetails, setVariantsDetails] = useState<any>();
  const [checkListValues, setCheckListValues] = useState<any>([]);
  const [title, setTitle] = useState<string>();
  // productSearch

  useEffect(() => {
    if (selectProductId) {
      setProductId(selectProductId);
    }else if(editItemId){
      setProductId(editItemId);
    }
  }, [selectProductId, editItemId]);

  const searchTerm = "";
  const { data: searchedProduct, isLoading: sellerActiveLoading } = useQuery(
    ["productDetails", { searchTerm, ...sellerDetails, productId }],
    productSearch
  );


  useEffect(() => {
    if (searchedProduct?.products) {
      const data = searchedProduct?.products[0]?.variants?.map((value: any) => {
        return {
          label: value?.label,
          value: value?.value,
        };
      });
      setVariantsDetails(data);            
      setTitle(searchedProduct?.products[0]?.name)
    }
  }, [searchedProduct, sellerActiveLoading]);

  useEffect(() => {
    if (editItemId) {
      const currentSelectedProduct =
        selectedItems?.length > 0 &&
        selectedItems?.find((value: any) => value.id === editItemId);
        const data = searchedProduct?.products[0]?.variants?.map((value: any) => value?.value);

        if(currentSelectedProduct?.checked){
          setCheckedList(data)
        }else{
          if(currentSelectedProduct?.selectedItems){
            setCheckedList(currentSelectedProduct?.selectedItems);  
          }else{
            setCheckedList(currentSelectedProduct?.variants);
          }

        }

      if (
        currentSelectedProduct?.variants?.length ===
        currentSelectedProduct?.selectedItems?.length
      ) {
        setIndeterminate(false);
        setCheckAll(true);
      } else {
        setIndeterminate(true);
        setCheckAll(false);
      }
    }
  }, [editItemId, searchedProduct, sellerActiveLoading]);  


  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setCheckListValues(list);
    setIndeterminate(!!list.length && list.length < variantsDetails.length);
    setCheckAll(list.length === variantsDetails.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    let checkListValues;

    if (variantsDetails && e.target.checked) {
      checkListValues = variantsDetails.map((value: any) => {
        return value.value;
      });
      setCheckListValues(checkListValues);
    } else {
      setCheckListValues([]);
    }

    setCheckedList(e.target.checked ? [...checkListValues] : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };


  const init = () => {
    message.info("Variant/s added");
    setIsProductModalOpen(false);
    setSearchTerm('');  
    setCheckAll(false);
    setEditItemId('');
    setSelectProductId('');
  }

  const modalCloseHandler = () => {
    setIsProductModalOpen(false); 
    setCheckAll(false);
    setEditItemId('');
    setSelectProductId('');
  }

  const addItemHandler = () => {
    if (checkListValues.length && !editItemId) {
      const items = (discountType === 'bundle'||discountType==='freebie') ? {...searchedProduct?.products?.[0], quantity: 1} : searchedProduct?.products?.[0];
      setSelectedItems([...selectedItems, items]);
      items["selectedItems"] = checkListValues;
      init();
      
    }else if(editItemId){
      const index =  selectedItems.map((item:any) => item.id).indexOf(editItemId);
      let newArr = [...selectedItems]
      const variants = searchedProduct?.products[0]?.variants?.map((value: any) => value?.value);      
      newArr[index]['variants'] = variants;
      newArr[index]['selectedItems'] = checkListValues;
      setSelectedItems(newArr)
      init(); 
    }
  };  

  return (
    <>
    {/* @ts-ignore */}
    <CustomModal
      title={null}
      centered
      visible={isProductModalOpen}
      footer={null}
      width={480}
      onCancel={modalCloseHandler}
      closable={false}
      key= {randomKey}
      keyboard= {true}
    >
      <ProductSearchContainer key= {randomKey}>
        <CustomFont size={"14px"} weight={"600"}>
          Product Details
        </CustomFont>
       
          <div style={{ margin: "10px 0" }}>
            <div
              style={{
                background: "#F0F6FD",
                borderRadius: 10,
                padding: "10px 14px",
                marginBottom: 10,
              }}
            >
              {sellerActiveLoading ? 
                <Spin tip="Loading" size="small"/> 
              :
                <>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  >
                    {title}
                  </Checkbox>
                  <CheckboxGroup
                    options={variantsDetails}
                    value={checkedList}
                    onChange={onChange}
                    style={{ marginLeft: 24 }}

                  />
                </>
              }
            </div>

            <ButtonContainer style={{ marginTop: 16 }}>
              <Button type="text" onClick={modalCloseHandler}>
                CANCEL
              </Button>
              <Tooltip
                placement="topRight"
                title={checkListValues.length <= 0 && "Please Select Variant"}
              >
                <NextButton
                  onClick={addItemHandler}
                  disabled={checkListValues.length <= 0}
                >
                  Add
                </NextButton>
              </Tooltip>
            </ButtonContainer>
          </div>
        
      </ProductSearchContainer>

    </CustomModal>
    </>
  );
};

export default React.memo(ProductSearchModal);
