import styled from 'styled-components'

export const InfoBoxContentMiddle = styled.div`
    width: calc(80vw/3);
    height: 200px;
    border-right: 1px dashed #DFE0EB;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`

export const Line = styled.div`
    height: 11px;
    width: 99%;
    background: ${(props) => props.color}
`

export const DropOffText = styled.h5`
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin-top: 10px;
    opacity: 0.9;
    color: ${(props) => props.color}
`