import React, { useState } from "react";
import {
  AllUserContainer,
  Text,
  CustomButton,
  UserHeader,
  Container,
} from "./styled";
import UserTable from "./UserTable";
import CreateUser from "./CreateUser";

const UserMangement = () => {
  const [isAddNewUser, setIsAddNewUser] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>();

  return (
    <Container>
      {/* user table */}
       {!isAddNewUser && (
        <AllUserContainer>
          <UserHeader>
            <Text style={{ fontSize: 16 }}>All Users</Text>
            <CustomButton type="primary" onClick={() => {
              setIsAddNewUser(true)
              setRowData("")
            }}>
              Add New User
            </CustomButton>
          </UserHeader>
          <UserTable 
            setRowData = {setRowData}
            setIsAddNewUser = {setIsAddNewUser}
          />
        </AllUserContainer>
      )} 
      {/* end user table */}


      {/* add or update new user */}
      {isAddNewUser && 
        <CreateUser 
          rowData = {rowData}
          setIsAddNewUser = {setIsAddNewUser} 
        />}
      {/* end add new user */}

    
    </Container>
  );
};

export default UserMangement;