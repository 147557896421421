import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import {
  bankList,
  cardType,
  bankNetwork,
  quantityRegex,
  paymentMethod,
  emiList, 
  walletsList
} from "./util";
import { CustomInput } from "../styled";

const { Option } = Select;

interface IOption {
  key: string;
  value: string;
  img?:string;
}

interface ICardDetails {
  card: string | null;
  bank: string | null;
  network: string | null;
  iin: string | number | null;
}

const imageStyle = {
  marginRight: 12, 
  height: 22, 
  borderRadius: 3,
}

function selectOptions(options: IOption[]) {
  const option_list = options?.map((value) => {
    return (
      <Option key={value?.key} value={value?.key}>
        {value?.img ? <img src= {value?.img} alt ={value.value} style={imageStyle}/> : ''}
        {value?.value}
      </Option>
    );
  });

  return option_list;
}



const BankOffer = ({ cardDetails, setCardDetails }: any) => {
  const [paymentMethodType, setPaymentMethodType] = useState<string | null>();
  
  const handlePaymentType = (value: string) => {
    setPaymentMethodType(value);
  };

  // setCardDetails({...cardDetails, [key]: value});
  const handleCardDetails = (value: string, key: string) => {
    console.log(key, value)
    const currentBank = bankList.filter((bank:any) => bank?.key === value);
    const currentWallet = walletsList?.filter((wallet:any) => wallet?.key === value)
    const currentEmi = emiList?.filter((emi:any) => emi?.key === value)
    
    let image:any
    if(key === "bankList"){
      image = currentBank[0]?.img
    }else if(key === "walletList"){
      image = currentWallet[0]?.img
    }else if(key === "emiList"){
      image = currentEmi[0]?.img
    }

    setCardDetails((prev: ICardDetails) => ({
      ...prev,
      [key]: value,
      bankImg: image
    }));

  };

  useEffect(() => {
    document.getElementById('bank-offer-payment-method')
    .scrollBy(0, 100);
  }, [cardDetails?.network])



  return (
    <div id="bank-offer-payment-method" style={{maxHeight: '380px', overflow:"scroll"}}>
      <Form.Item
        name="paymentMethod"
        label="Payment Method"
        rules={[
          {
            required: true,
            message: "please select form type",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="---select type---"
          allowClear
          showArrow={true}
          onChange={(value) => {
            handleCardDetails(value, "paymentMethod");
          }}
        >
          {selectOptions(paymentMethod)}
        </Select>
      </Form.Item>

      {cardDetails.paymentMethod === "card" ? (
        <Form.Item
          name="cardType"
          label="Cards Types"
          rules={[
            {
              required: paymentMethodType === "card",
              message: "please select form type",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="---select card---"
            showArrow={true}
            onChange={(value) => {
              handleCardDetails(value, "cardType");
            }}
          >
            {selectOptions(cardType)}
          </Select>
        </Form.Item>
      ) : (
        ""
      )}

      { 
      ((cardDetails.cardType && cardDetails.paymentMethod  === "card") || cardDetails.paymentMethod === "netBanking")
      ? (
        <Form.Item
          name="banksList"
          label="Bank List"
          // rules={[
          //   {
          //     // required:  cardDetails.card ? true : false,
          //     message: "please select form type",
          //   },
          // ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="---select bank---"
            allowClear
            showArrow={true}
            onChange={(value) => {
              handleCardDetails(value, "bankList");
            }}
          >
            {selectOptions(bankList)}
          </Select>
        </Form.Item>
      ) : (
        ""
      )}

    {(cardDetails.paymentMethod  === "wallet")
      ? (
        <Form.Item
          name="banksList"
          label="Wallet List"
          // rules={[
          //   {
          //     required:  cardDetails.card ? true : false,
          //     message: "please select form type",
          //   },
          // ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="---select wallet---"
            allowClear
            showArrow={true}
            onChange={(value) => {
              handleCardDetails(value, "walletList");
            }}
          >
            {selectOptions(walletsList)}
          </Select>
        </Form.Item>
      ) : (
        ""
      )}

  {(cardDetails.paymentMethod  === "emi")
      ? (
        <Form.Item
          name="banksList"
          label="Emi List"
          // rules={[
          //   {
          //     required:  cardDetails.card ? true : false,
          //     message: "please select form type",
          //   },
          // ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="---select emi---"
            allowClear
            showArrow={true}
            onChange={(value) => {
              handleCardDetails(value, "emiList");
            }}
          >
            {selectOptions(emiList)}
          </Select>
        </Form.Item>
      ) : (
        ""
      )}




    {cardDetails.paymentMethod === "card" && cardDetails.cardType ?
      <Form.Item
        name="network"
        label="Network"
        // rules={[
        //   {
        //     required: cardDetails.bank ? true : false,
        //     message: "please select form type",
        //   },
        // ]}
      >
        <Select
          mode="multiple"
          maxTagCount={3}
          style={{ width: "100%" }}
          placeholder="---select Network---"
          allowClear
          showArrow={true}
          
          onChange={(value) => {
            handleCardDetails(value, "network");
          }}
        >
          {selectOptions(bankNetwork)}
        </Select>
      </Form.Item>
      : ""}


    {cardDetails.paymentMethod === "card" && cardDetails.cardType? 
      <Form.Item
        label={"IIN"}
        name={"issuerIdentificationNumber"}
        rules={[
          // {
          //   required: cardDetails.network ? true : false,
          //   pattern: quantityRegex,
          //   message: "Enter valid value",
          // },
          {
            min: 6,
            message: "value should equal 6",
          },
        ]}
        
      >
        <CustomInput maxLength={6} placeholder={"Enter IIN"} onChange={(value:any) => {
            handleCardDetails(value.target.value, "network");
          }}/>
      </Form.Item>
 : ''}

    </div>
  );
};

export default BankOffer;
