import React, { useState, useEffect } from 'react';
import { Upload, Input, Button, notification, Alert } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
  SpaceBetweenContainer,
} from 'components/UIElements';
import { Text } from './style';

const { TextArea } = Input;

const PhoneNumber = ({
    phoneNumberList,
    setPhoneNumberList
}) => {
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorPhoneNumberList, setErrorPhoneNumberList] = useState([]);

  const uploadProps = {
    onRemove: (file) => {
      setUploadFile([]);
    },
    beforeUpload: (file) => {
      setUploadFile([file]);
      return false;
    },
  };

  useEffect(() => {
    const uploadCSV = async () => {
      try {
      setLoading(true);
      const formData = new FormData();
      formData.append('block_phone_no', uploadFile[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/upload-csv/`,
        formData
      );
      setLoading(false);
      if (response.data.success) {
        if (response.data.data.err_list.length > 0) {
          notification.error({
            message: 'Invalid phone numbers',
          });
        } else {
          notification.success({
            message: 'File uploaded successfully.',
          });
        }
        setPhoneNumberList(response.data.data.list);
        setErrorPhoneNumberList(response.data.data.err_list);
        setUploadFile([]);
      } else {
        notification.error({
          message: response.data.message,
        });
        setUploadFile([]);
      }
    } catch (e) {
      notification.error({
        message: 'Something went wrong.',
      });
      setLoading(false)
    }
    };
    if (uploadFile.length === 1) {
      uploadCSV();
    }
  }, [uploadFile]);

  return (
    <FlexColContainer>
      <SpaceBetweenContainer>
        <PickrrHeading size={5}>Select Buyer Phone number</PickrrHeading>
        <Button type="link" onClick={() => setPhoneNumberList([])}>
          Reset
        </Button>
      </SpaceBetweenContainer>
      <TextArea
        placeholder="Add comma separated phone number list"
        rows={4}
        className="mb-16"
        value={phoneNumberList.join(',')}
        onChange={(e) =>
          e.target.value
            ? setPhoneNumberList(e.target.value.split(','))
            : setPhoneNumberList([])
        }
      />
      {errorPhoneNumberList.length > 0 && (
        <Alert
          message={`${
            errorPhoneNumberList.length
          } phone number(s) found invalid. Please check the following phone number(s): ${errorPhoneNumberList.join(
            ','
          )}`}
          type="error"
        />
      )}
      <PickrrHeading size={5}>or upload Phone Number list</PickrrHeading>
      <FlexBox className="mb-16">
        <Upload {...uploadProps} showUploadList={false}>
          <PickrrButton
            color="primary"
            style={{ textTransform: 'none' }}
            icon={<UploadOutlined />}
            className="mr-12"
            loading={loading}
          >
            Upload CSV
          </PickrrButton>
        </Upload>
        <a
          href={`https://${window.location.host}/sample_phone_no.csv`}
          download
        >
          <Text>
            <DownloadOutlined className="mr-8" /> Download Sample CSV File
          </Text>
        </a>
      </FlexBox>
    </FlexColContainer>
  );
};

export default PhoneNumber;
