import React, { useEffect } from "react";
import { Button, Col, Form, Input, Modal, notification, Row, Select } from "antd";
import {  ModalTitle,CustomModal, CustomForm, FooterContainer } from "./styled";
import { PickrrButton } from "components/UIElements";
import { useMutation,useQueryClient } from "react-query";
import { EditOrderUpdate } from "./script";

const { Option } = Select;
const EditCustomerDetailsModal = ({isVisible,setIsVisible,editCustomerModalData,setEditCustomerModalData,orderData}: any)=>{
    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const options=[
        {
            value :"Andhra Pradesh",
            label : "Andhra Pradesh"
        },
        {
            value :"Andaman and Nicobar Islands",
            label : "Andaman and Nicobar Islands"
        },
        {
            value :"Arunachal Pradesh",
            label : "Arunachal Pradesh"
        },
        {
            value :"Assam",
            label : "Assam"
        },
        {
            value :"Bihar",
            label : "Bihar"
        },
        {
            value :"Chandigarh",
            label : "Chandigarh"
        },
        {
            value :"Chhattisgarh",
            label : "Chhattisgarh"
        },
        {
            value :"Dadar and Nagar Haveli",
            label : "Dadar and Nagar Haveli"
        },
        {
            value :"Daman and Diu",
            label : "Daman and Diu"
        },
        {
            value :"Delhi",
            label : "Delhi"
        },
        {
            value :"Goa",
            label : "Goa"
        },
        {
            value :"Gujarat",
            label : "Gujarat"
        },
        {
            value :"Haryana",
            label : "Haryana"
        },
        {
            value :"Himachal Pradesh",
            label : "Himachal Pradesh"
        },
        {
            value :"Jammu and Kashmir",
            label : "Jammu and Kashmir"
        },
        {
            value :"Jharkhand",
            label : "Jharkhand"
        },
        {
            value :"Karnataka",
            label : "Karnataka"
        },
        {
            value :"Kerala",
            label : "Kerala"
        },
        {
            value :"Ladakh",
            label : "Ladakh"
        },
        {
            value :"Lakshadweep",
            label : "Lakshadweep"
        },
        {
            value :"Madhya Pradesh",
            label : "Madhya Pradesh"
        },
        {
            value :"Maharashtra",
            label : "Maharashtra"
        },
        {
            value :"Manipur",
            label : "Manipur"
        },
        {
            value :"Meghalaya",
            label : "Meghalaya"
        },
        {
            value :"Mizoram",
            label : "Mizoram"
        },
        {
            value :"Nagaland",
            label : "Nagaland"
        },
        {
            value :"Odisha",
            label : "Odisha"
        },
        {
            value :"Punjab",
            label : "Punjab"
        },
        {
            value :"Puducherry",
            label : "Puducherry"
        },
        {
            value :"Rajasthan",
            label : "Rajasthan"
        },
        {
            value :"Sikkim",
            label : "Sikkim"
        },
        {
            value :"Tamil Nadu",
            label : "Tamil Nadu"
        },
        {
            value :"Telangana",
            label : "Telangana"
        },
        {
            value :"Tripura",
            label : "Tripura"
        },
        {
            value :"Uttar Pradesh",
            label : "Uttar Pradesh"
        },
        {
            value :"Uttarakhand",
            label : "Uttarakhand"
        },
        {
            value :"West Bengal",
            label : "West Bengal"
        },
      ]


    const mutation = useMutation(EditOrderUpdate, {
        onMutate: (variables) => {
          // A mutation is about to happen!
          // Optionally return a context containing data to use when for example rolling back
          return { id: 1 };
        },
        onError: (error : any, variables, context) => {
          // An error happened!
          let sanitizedError = JSON.stringify(error?.response?.data?.error?.message)
            if(typeof sanitizedError === "string" && sanitizedError && sanitizedError.includes('message\\":')) {
              notification.error({
                  message : sanitizedError.match(/"message\\":\\"(.+)\\"}/)[1]
              });
            } else {
              notification.error({
                  message : `Something went wrong.`
              });
            }
        },
        onSuccess: (message, variables, data) => {
            queryClient.invalidateQueries('orderData');
            notification.success({message : 'Details Updated Successfully'})
            setIsVisible(false)
        },
      });

    useEffect(()=>{
        form.setFieldsValue({
            firstName: editCustomerModalData?.firstName,
            lastName : editCustomerModalData?.lastName,
            phone : editCustomerModalData?.phone,
            email :editCustomerModalData?.email,
            line1 : editCustomerModalData?.line1,
            line2 :editCustomerModalData?.line2,
            city :editCustomerModalData?.city,
            state :editCustomerModalData?.state,
            pincode : editCustomerModalData?.pincode,
        })
    },[editCustomerModalData])

   const handleSubmit = () => {
    let formData = form.getFieldsValue(true);
    const postData = {
        "orderId": orderData?.id,
        "operation": "OrderUpdate",
        "channel": orderData?.channel,
        phone: formData?.phone,
        email: formData?.email,
        customerUpdateRequest:{
        shippingAddress: {
        firstName: formData?.firstName,
        lastName : formData?.lastName,
        phone : formData?.phone,
        email :formData?.email,
        line1 : formData?.line1,
        line2 :formData?.line2,
        city :formData?.city,
        state :formData?.state,
        pincode : formData?.pincode,
        }
    }
    }

    mutation.mutate(postData);
   }

    return(
        <>
        {/*//@ts-ignore */}        
            <CustomModal
            title={<ModalTitle>Edit Customer Details</ModalTitle>}
            visible = {isVisible}
            destroyOnClose = {true}
            onCancel = {()=>{
                form.resetFields()
                setEditCustomerModalData({})
                setIsVisible(false)
            }}
            footer={false}
            width={'700px'}
            keyboard= {true}
            >
                <CustomForm
                layout="vertical" 
                form={form} 
                onFinish = {handleSubmit}
                >
                <Row gutter={[20,20]}>
                <Col span={12}>
                    <Form.Item
                    name={'firstName'}
                    label = 'First Name'
                    >
                        <Input placeholder="Enter First Name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name={'lastName'}
                    label = 'Last Name'
                    >
                        <Input placeholder="Enter Last Name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name={'phone'}
                    label = 'Phone No.'
                    >
                        <Input placeholder="Enter Phone No." />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name={'email'}
                    label = 'Email Id'
                    >
                        <Input placeholder="Enter Email Id" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                    name={'line1'}
                    label = 'Flat, House no., Floor, Building'
                    >
                        <Input placeholder="Enter Flat, House no., Floor, Building" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                    name={'line2'}
                    label = 'Area, Street, Sector, Village'
                    >
                        <Input placeholder="Enter Area, Street, Sector, Village" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                    name={'city'}
                    label = 'City'
                    >
                        <Input placeholder="Enter City" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                    name={'state'}
                    label = 'State'
                    >
                        <Select placeholder='Select State' options={options} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                    name={'pincode'}
                    label = 'Pincode'
                    >
                        <Input placeholder="Enter Pincode" />
                    </Form.Item>
                </Col>
                </Row>
                <FooterContainer>
                    <Button 
                    onClick={()=>{
                        form.resetFields()
                        setEditCustomerModalData({})
                        setIsVisible(false)
                    }}
                    color="cancel" 
                    style={{border : '1px solid #263F97', height : '40px', borderRadius : '8px',marginRight : '18px', width : '130px', color : '#000B34'}}
                    >
                        Cancel
                    </Button>
                    <PickrrButton 
                    htmlType="submit" 
                    color="checkout-blue" 
                    style={{width : '130px', height : '40px'}}
                    loading = {mutation?.isLoading}
                    >
                        Save
                    </PickrrButton>
                </FooterContainer>
                </CustomForm>
            </CustomModal>
        </>
    )
}

export default EditCustomerDetailsModal