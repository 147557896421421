import React, { useContext } from "react";
import { AlignType } from "rc-table/lib/interface";
import { Table, Switch, notification, message } from "antd";
import { RoleBadge, TableActionButton } from "./styled";
import {fetchAllUser, updateUser} from './script';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DataContext } from "context/DataProvider";

const UserTable = ({setRowData, setIsAddNewUser}: any) => {
  const {sellerConfig} = useContext(DataContext)
  const queryClient = useQueryClient();

  const { data:userData, isLoading} = useQuery(
    ['activeRole', sellerConfig?.company_id, sellerConfig?.domain_name],
    fetchAllUser
  );  

  // active and inactive user mutation
   const mutation = useMutation(updateUser, {
    onError: (error:any) => {
        message.error(error.response.data.message);
    },
    onSuccess: () => {
      message.success('user active status changed successfully')
      queryClient.invalidateQueries('activeRole');
    },
  });


  // handlers
  const handleActiveUser = (data:any) => {
    const postData = {
      id: data?.id,
      active: !data?.active,
      company_id:sellerConfig?.company_id, 
      domain_name:sellerConfig?.domain_name, 
      email: data?.email,
      ...(data?.name  && {name: data?.name}),
      ...(data?.name  && {username: data?.name}),
      ...(data?.phone && {phone: data?.phone}),
      ...(!data?.role?.[0]?.name.includes('custom') ? {role: [data?.role?.[0]?.id]} : []),
      ...(data?.role?.[0]?.name.includes('custom') && {permissions: data?.role?.permissions}),
      type: 'seller',
    }

    mutation.mutate(postData);
  }

  const handleEditUser = (data:any) => {
    setRowData(data);
    setIsAddNewUser(true);
  }

  let column: object[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      align: "left" as AlignType,
    },
    {
      title: "Email ID",
      key: "email",
      dataIndex: "email",
      align: "left" as AlignType,
    },
    {
      title: "Phone number",
      key: "phone",
      dataIndex: "phone",
      align: "left" as AlignType,
    },
    {
      title: "Role",
      key: "type",
      dataIndex: "type",
      align: "left" as AlignType,
      render: (rowData: any, data:any) => 
        (data?.role && data?.role[0]?.name 
          ? <RoleBadge>
            {data?.role[0]?.name?.toLowerCase()?.includes('custom') 
            ? 'CUSTOM' 
            : data?.role[0]?.name
            }
          </RoleBadge> 
          : ''
        )
      ,
    },
    {
      title: "Status",
      key: "active",
      dataIndex: "active",
      align: "left" as AlignType,
      render: (rowData: any, data: any) => (
        <Switch
          checked={rowData}
          key={rowData}
          loading = {mutation.isLoading}
          onClick={() => handleActiveUser(data)}
        />
      ),
    },
    {
      title: "Action",
      fixed: "right",
      key: "action",
      width: 210,
      align: "center" as AlignType,
      render: (_: any, rowData:any) => (
        <>
          <TableActionButton
            onClick={() => handleEditUser(rowData)}
            style={{ cursor: "pointer" }}
          >
            Edit
          </TableActionButton>{" "}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={column}
      loading= {isLoading}
      dataSource={userData?.users}
      pagination={false}
    />
  );
};

export default UserTable;