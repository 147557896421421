import { InfoIcon, RightArrowSVG, TickMark } from "images/icons"
import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { onboardSeller } from "./script"
import "../Loader.css"
import VideoModal from "UiComponents/Modals/VideoModal"
import ENUMS from "utils/enums"
import { Select } from "antd"
import Title from "antd/lib/skeleton/Title"

const FormStep1 = ({ shopifyUrl, setShopifyUrl, setAccessCode, accessCode, accountStatus,approveRequest,setApproveRequest,shareRequest ,setShareRequest, channel, orderVolume, setOrderVolume}: any) => {

    const { Option } = Select

    const [isError, setIsError] = useState<boolean>(false)
    const [response, setResponse] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const cid = localStorage.getItem('cid')
    const user_email = localStorage.getItem('user_email')
    const user_name = localStorage.getItem('user_name')
    const [generateCodeModal, setGenerateCodeModal] = useState<boolean>(false)
    const [collabReqModal, setCollabReqModal] = useState<boolean>(false)
    const [validShopifyUrl, setValidShopifyUrl] = useState<boolean>(true)
    const [validAccessCode, setValidAccessCode] = useState<boolean>(true)
    const mutation = useMutation(onboardSeller, {
        onMutate: (variables) => {
            return { id: 1 };
        },
        onError: (response: any) => {
            setResponse(response)
            setIsError(true)
            setIsLoading(false)
        },
        onSuccess: (response) => {
            //shopify
            localStorage.setItem('onboardingType', 'DONE')
            setIsLoading(false)
            setResponse(response)
            setIsError(false)
            setShareRequest(true)
        },
    });
    const handleClick = () => {
        const postData = { collab_code: accessCode, domain_name: `${shopifyUrl}`, company_id: cid, email_id: user_email, utm_param: {utm_medium:localStorage?.getItem('utm_medium')}, user_name: user_name }
        mutation.mutate({
            ...postData,
        });
    }
    const validateURL = (url: string) => {
        const urlPattern = /^(?=.{1,253}$)((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,63}$/
        return urlPattern.test(url);
    }

    const validateAccessCode = (url: string) => {
        const accessCodePattern = /^\d+$/;
        return accessCodePattern.test(url)
    }

    return <>
        <div>
            {
                channel.imageUrl &&
                <img 
                    src={channel.imageUrl}
                    alt={channel.key} 
                    style={{margin: "10px 0px", height:'50px'}}
                />
            }
            <div className="text-black font-semibold text-base flex items-center gap-6">Store URL<span data-tip data-tooltip-id='tooltip-1'><InfoIcon /></span>
                <ReactTooltip id='tooltip-1' border={'1px solid #EAEAEA'} place='bottom' opacity={1} clickable={true} style={{ background: "#06064D", borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>
                    <div className="text-xs">Example  - https://www.mystore.com</div>
                </ReactTooltip>
            </div>

            <input className="border border-greytext bg-white h-10 w-1/2 pl-5 pr-10 mt-3 rounded-lg text-sm focus:outline-none"
                type="search" name="search" placeholder="MyShopifyStore"
                value={shopifyUrl}
                disabled={accountStatus}
                onChange={(event: any) => {
                    setShopifyUrl(event.target.value)
                    setValidShopifyUrl(validateURL(event.target.value))
                }}
            />

            
            {shopifyUrl?.length > 0 && !validShopifyUrl && <div className="text-pendingRed mt-3"> Please enter a valid Website Url</div>}

            {/* SHOPIFY-START */}
            {channel.key === ENUMS.SHOPIFY &&
            <>
            <div className="text-black font-semibold text-base mt-4 flex items-center gap-2">Shopify Collaborator Access Code <span data-tip data-tooltip-id='tooltip-2'><InfoIcon /></span>
                <ReactTooltip id='tooltip-2' border={'1px solid #EAEAEA'} place='bottom' opacity={1} clickable={true} style={{ background: "#06064D", borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)", width: '200px', justifyContent: 'center', alignContent: 'center' }}>
                    <div className="text-xs">This code will help to send collaborator request to your Shopify store</div>
                </ReactTooltip>
            </div>

            <div className="text-purpleText text-xs underline decoration-borderGrey cursor-pointer" onClick={() => {
                setGenerateCodeModal(!generateCodeModal)
            }}>How to generate code?</div>

            {(shareRequest || (accountStatus && !accountStatus?.includes("created"))) ? <div style={{ fontSize: '10px' }} className="text-approveGreen bg-approveBg w-fit rounded-md py-1 px-2 flex items-center mt-4 gap-1"><TickMark />Thank you! The code you entered has been successfully shared for collaborator access.</div>
                : <><div className="flex">
                    <input className="border border-greytext border-r-0 rounded-r-none bg-white h-10 w-1/2 px-5 pr-16 mt-3 rounded-lg text-sm focus:outline-none"
                        type="search" name="search" placeholder="Eg.2709"
                        value={accessCode}
                        disabled={accountStatus}
                        maxLength={4}
                        onChange={(event: any) => {
                            setAccessCode(event.target.value)
                            setValidAccessCode(validateAccessCode(event.target.value))
                        }}
                    />
                    <button type="button" className="mt-3 -ml-1 flex gap-2 focus:outline-none border text-purpleText border-backPurple bg-backPurple focus:ring-0 font-medium rounded-lg text-sm px-16 py-2 dark:bg-backPurple dark:hover:bg-backPurple disabled:text-black disabled:bg-greytext disabled:hover:bg-greytext disabled:cursor-not-allowed"
                        disabled={accessCode?.length !== 4 || shopifyUrl?.length == 0 || isLoading||!validAccessCode||!validShopifyUrl}
                        onClick={() => {
                            setIsLoading(true)
                            handleClick()
                        }} >{!isLoading ? 'Share' : <div className="loader" />}</button>
                </div>
                    {!validAccessCode && accessCode?.length > 0 && <div className="text-pendingRed">Please Enter Valid Access Code</div>}
                </>
            }

            <div className="text-black font-semibold text-base mt-4 flex items-center gap-2">Approve Collaborator Request <span data-tip data-tooltip-id='tooltip-3'><InfoIcon /></span>
                <ReactTooltip id='tooltip-3' border={'1px solid #EAEAEA'} place='bottom' opacity={1} clickable={true} style={{ background: "#06064D", borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)", width: '250px', justifyContent: 'center', alignContent: 'center' }}>
                    <div className="text-xs">This code will grant us Collaborator access to your Shopify store. It will not make any changes to your live store.</div>
                </ReactTooltip>
            </div>

            <div className="text-purpleText text-xs underline decoration-borderGrey cursor-pointer" onClick={() => {
                setCollabReqModal(!collabReqModal)
            }}>How to locate collaborator request?</div>

            {(approveRequest || (accountStatus && (accountStatus?.includes('collab access shared')||accountStatus?.includes('collab permissions missing')))) ? 
            <div style={{ fontSize: '10px' }} className="text-approveGreen bg-approveBg w-fit rounded-md py-1 px-2 mt-4 flex gap-1 items-center"> <TickMark /> Thank you! The collaborator access permission approved.</div>
                :<button type="button" className="mt-3 flex gap-2 focus:outline-none border border-paginationGrey text-white bg-purpleText focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-purpleText dark:hover:bg-purpleText disabled:bg-recharge disabled:hover:bg-recharge disabled:text-buttonGreyText"
                onClick={() => {
                    window?.open(`https://${shopifyUrl}/admin/settings/account`)
                    setApproveRequest(true)
                }}
                disabled={(!accountStatus?.includes('collab approval pending'))} >Approve Request <RightArrowSVG shareRequest={shareRequest} /></button> 
            }

            <span style={{ fontSize: '8px' }} className="text-notifiedColor">Receiving collaborator request might take a few hours, you will be notified via email </span>
            </>
            }
            {/* SHOPIFY-END */}

            {
                channel.key !== ENUMS.SHOPIFY &&
                <div style={{marginTop:'10px'}}>
                <div className="text-black font-semibold text-base flex items-center gap-6">Order volume (Monthly)</div>
                <select
                id="orderVolume"
                value={orderVolume}
                style={{
                    width: "50%",
                    textAlign: "left",
                    marginRight: '20px',
                    marginTop:'10px',
                    padding:'10px',
                    border: "1px solid rgb(221 221 221)",
                    borderRadius: '10px'
                }}
                onChange={(e) => {setOrderVolume(e.target.value)}}
                >
                    <option value="0-10">0-10</option>
                    <option value="10-100">10-100</option>
                    <option value="10-100">10-100</option>
                    <option value="1000+">1000+</option>
                </select>
                </div>
            }
            
        </div>

        {generateCodeModal && <VideoModal isModalOpen  = {generateCodeModal} setViewVideoModal={setGenerateCodeModal} videoID={`jI574TQfjYs`} />}
        {collabReqModal && <VideoModal  isModalOpen  = {generateCodeModal} setViewVideoModal={setCollabReqModal} videoID={`pW_tTYb9k8E`} />}


    </>
}
export default FormStep1