import axiosInstance from 'HTTP/Http';
import { downloadFile } from 'components/UIElements/function';

export const fetchExitCheckoutData = async ({ queryKey }) => {
    const [unusedArgument, { fromDate, toDate, sellerId}] = queryKey

    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/exit-checkout-data`,
      {
        params: {
          from: fromDate,
          to: toDate,
          sellerId: sellerId
        }
      }
    )
    return response.data.data;
}


export const fetchOthersResponse = async ({ queryKey }) => {
  const [unusedArgument, { fromDate, toDate, pageNumber, sellerId }] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/exit-response-data`,
    {
      params: {
        from: fromDate,
        to: toDate,
        page: pageNumber - 1,
        sellerId: sellerId,
        size: 20,
      }
    }
  )
  return response.data.data;
}


export const downloadResponse = async (fromDate, toDate) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/`,
      {
          params: {
              from: fromDate,
              to: toDate,
              type: 'EXIT_CHECKOUT_OTHER_RESPONSE'
          }
      }
  )
  downloadFile(response.data, 'FastrrCheckoutConversion.csv')
}

export const downloadExitData = async (fromDate, toDate) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/`,
      {
          params: {
              from: fromDate,
              to: toDate,
              type: 'EXIT_CHECKOUT_REPORT'
          }
      }
  )
  downloadFile(response.data, 'Exit Responses.csv')
}
