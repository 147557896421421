import { Upload } from 'antd';
import styled from 'styled-components';
const { Dragger } = Upload;

export const BlueText = styled.p`
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 140%;
  margin-top: 12px !important;
  color: #263f97;
`;

export const CustomDragger = styled(Dragger)`
  .ant-upload.ant-upload-drag {
    border: none;
  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    background: #edf0f9;
  }
`;
