import { notification } from 'antd';
import axiosInstance from '../../../HTTP/Http';

export const fetchWebhookList =async () => {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/custom-channel-endpoint/list`);
        return response.data.data;
    } catch (error) {
        notification.error({
        message: error.message,
        });
    }
};
