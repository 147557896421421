import React from 'react';

import moment from "moment";

const getControls = ({watchReportType}:any) => {
    
    const optionsMapping = {
        finance: [
            { value: 'refunds', label: 'Refunds' },
            { value: 'wallet_deduction', label: 'Wallet Deduction' },
            { value: 'settlement', label: 'Settlement' },
            { value: 'settlement_transaction', label: 'Settlement Transaction' },
        ],
        orders: [
            { value: 'orders', label: 'Order MIS' },
            { value: 'order_line', label: 'Order Line Item MIS' },
        ],
        abandoned_cart: [
            { value: 'abandoned_cart', label: 'Abandoned Cart' },
            { value: 'traffic_acquisition', label: 'Traffic Acquisition' },
            
        ]
    }

    const allowedTabs = localStorage.getItem('panel').slice(2, -2).split(', ').map(item => item.trim());;

    const getModifiedOptions = () => {
        let modifiedOptions = [];
        for (const [key, value] of Object.entries(optionsMapping)) {
            if (allowedTabs.includes(key.replace(/_/g, "-"))) {
                modifiedOptions.push(...value);
            }
        }
        return modifiedOptions;
    }

    const options = getModifiedOptions();

    return [
        {
            name: 'report_type',
            type: 'select',
            label: 'Report Type',
            placeholder: 'Select Report Type',
            options: options,
            rules: { required: 'Report Type is required' },
        },
        {
            name: 'emails',
            type: 'input',
            input_type: 'email',
            label: 'Emails',
            placeholder:'Comma separated emails',
            rules: {required: 'Email is required', pattern: {
                value: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}(\s*,\s*[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})*$/,
                message: 'Invalid email'
              } },
            tooltip: `Report will be sent to the registered email addresses you've provided.`,
        },
        {
            name: 'date_range',
            type: 'date_picker',
            label: 'Date Range',
            disabledDates: (current: any) => {
                return current > moment()
              },
            rules:{
                required: 'Date range is required',
                validate:(dates: Array<moment.Moment>) => {
                    const dayDifference = dates[1].diff(dates[0], 'days');                    
                    if (watchReportType?.value === 'settlement_transaction' && dayDifference > 15) {                        
                        return 'Date range cannot exceed 15 days for settlement transaction';
                      }
                      return dayDifference <= 60 || 'Date range cannot exceed 60 days'; 
                },
            },
            style:{minHeight: '40px', width: '320px'}
        },

    ]
}

export default getControls;