import React, { useState } from "react";
import { Button, Col, InputNumber, Row, Typography, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
} from "components/UIElements";


import { WeightRangeContainer, ErrorText } from "./style";

const { Option } = Select;

const CodBlock = ({ codList, setList, label, key_list, placeholder }) => {
  const [error, setError] = useState(false);

  const handleAdd = () => {
    setList((prev) => [
      ...prev,
      {
        [key_list]: null,
        occurence_window_in_seconds: null,
        occurence_window: null,
        unit: "sec",
        occurence_window_max_input: 604800,
      },
    ]);
  };

  const render_occurance_value_sec = (value, unit = "sec") => {
    switch (unit) {
      case "min":
        return value * 60;
      case "sec":
        return value;
      case "hours":
        return value * 3600;
      default:
        break;
    }
  };

  const convert_sec_into_given_unit = (value, unit = "sec") => {
    switch (unit) {
      case "min":
        return value / 60;
      case "sec":
        return value;
      case "hours":
        return value / 3600;
      default:
        break;
    }
  };

  const handleChangeMin = (id, value) => {
    setList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            [key_list]: value,
          };
        }
        return w;
      })
    );
  };
  const handleChangeUnit = (value, i) => {
    setList((prev) =>
      prev.map((w, index) => {
        if (index === i) {
          return {
            ...w,
            occurence_window_in_seconds: render_occurance_value_sec(
              w.occurence_window,
              value
            ),
            unit: value,
            occurence_window_max_input: convert_sec_into_given_unit(604800, value),
          };
        }
        return w;
      })
    );
  };

  const handleChangeMax = (id, value) => {
    setList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            occurence_window_in_seconds: render_occurance_value_sec(value, w.unit),
            occurence_window: value,
            occurence_window_max_input: convert_sec_into_given_unit(604800, w.unit),
          };
        }
        return w;
      })
    );
  };

  const handleDelete = (id) => {
    setList((prev) => prev.filter((s, index) => index !== id));
  };

  return (
    <WeightRangeContainer>
      <FlexColContainer>
        <PickrrHeading size={5}>{label}</PickrrHeading>
        {codList?.map((s, index) => (
          <Row gutter={16} className="mb-16" key={index}>
            <Col span={10}>
              <InputNumber
                className="mr-12"
                size="large"
                placeholder={placeholder}
                min={1}
                value={s[key_list]}
                onChange={(value) => handleChangeMin(index, value)}
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </Col>
            <Col span={10}>
              <InputNumber
                className="mr-12"
                size="large"
                placeholder="Occurence Window"
                min={1}
                max={
                  s.occurence_window_max_input
                    ? s.occurence_window_max_input
                    : 604800
                }
                value={
                  s.occurence_window
                    ? s.occurence_window
                    : s.occurence_window_in_seconds
                }
                onChange={(value) => {
                  handleChangeMax(index, value);
                }}
                addonAfter={
                  <Select
                    defaultValue={s.unit ? s.unit : "sec"}
                    onChange={(e) => {
                      handleChangeUnit(e, index);
                    }}
                    value={s.unit ? s.unit : "sec"}
                    style={{ width: 70 }}
                  >
                    <Option value="min">Min</Option>
                    <Option value="sec">Sec</Option>
                    <Option value="hours">Hours</Option>
                    {/* <Option value="CNY">¥</Option> */}
                  </Select>
                }
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </Col>
            <Col span={4}>
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(index)}
              />
            </Col>
            <Col offset={10} span={10}>
              {s["occurence_window_in_seconds"] !== null &&
                s.occurence_window_in_seconds < 0 && (
                  <ErrorText>Value should be positive</ErrorText>
                )}
            </Col>
          </Row>
        ))}
        <PickrrButton color="outline" onClick={handleAdd}>
          {codList.length ? "Add More" : "Add"}
        </PickrrButton>
      </FlexColContainer>
    </WeightRangeContainer>
  );
};

export default CodBlock;
