import React, { useState } from 'react';
import { Button, InputNumber, Row, Col } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import {
  FlexColContainer,
  PickrrHeading,
  FlexBox,
  PickrrButton,
} from 'components/UIElements';


import { WeightRangeContainer, ErrorText } from './style';

const InvoiceValue = ({ invoiceValueList, setInvoiceValueList }) => {
  const [error, setError] = useState(false);

  const handleAdd = () => {
    setInvoiceValueList((prev) => [
      ...prev,
      {
        min: null,
        max: null,
        //id: uuidv4(),
      },
    ]);
  };

  const handleChangeMin = (id, value) => {
    setInvoiceValueList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            min: value,
          };
        }
        return w;
      })
    );
  };

  const handleChangeMax = (id, value) => {
    setInvoiceValueList((prev) =>
      prev.map((w, index) => {
        if (index === id) {
          return {
            ...w,
            max: value,
          };
        }
        return w;
      })
    );
  };

  const handleDelete = (id) => {
    setInvoiceValueList((prev) => prev.filter((s, index) => index !== id));
  };

  return (
    <WeightRangeContainer>
      <FlexColContainer>
        <PickrrHeading size={5}>
          Invoice Value (₹)
        </PickrrHeading>
        {invoiceValueList.map((inv, index) => (
          <Row gutter={16} className="mb-16" key={index}>
            <Col span={10}>
              <InputNumber
                className="mr-12"
                size="large"
                placeholder="Min Value"
                value={inv.min}
                onChange={(value) => handleChangeMin(index, value)}
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Col>
            <Col span={10}>
              <InputNumber
                className="mr-12"
                size="large"
                placeholder="Max Value"
                value={inv.max}
                onChange={(value) => {
                  handleChangeMax(index, value);
                  setError(value < inv.min ? true : false);
                }}
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Col>
            <Col span={4}>
              <Button
                size="large"
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(index)}
              />
            </Col>
            <Col offset={10} span={10}>
              {inv.min !== null && inv.max!==null && inv.min >= inv.max && (
                <ErrorText>
                  Max Value should be greater
                </ErrorText>
              )}
            </Col>
          </Row>
        ))}
        <PickrrButton color="outline" onClick={handleAdd}>
          {invoiceValueList.length
            ? "Add more"
            : "Add"
          }
        </PickrrButton>
      </FlexColContainer>
    </WeightRangeContainer>
  );
};

export default InvoiceValue;
