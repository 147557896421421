import React, { useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import moment from 'moment';
import {
  CustomTable,
  FlexBox,
  Heading,
  PickrrButton,
} from 'components/UIElements';
import { CustomDatePicker } from '../../components/UIElements/index';
import { Container } from 'pages/Settings/style';
import { getRtoData, downloadRtoCsvData } from './script';
import { useQuery } from 'react-query';

const RtoTable = () => {
  const { TabPane } = Tabs;
  const [pageNumber, setPageNumber] = useState(1);
  const [fromDate, setFromDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const {
    data: tableData,
    isLoading,
    isSuccess,
  } = useQuery(['rtoTableData', { pageNumber, fromDate, toDate }], getRtoData);

  const columns = [
    {
      title: 'Order Id ',
      dataIndex: 'orderId',
      key: 'orderId',
      width: 300,
      align: 'center',
      render: (data) => (
        <span style={{ textTransform: 'capitalize' }}>{data}</span>
      ),
    },
    {
      title: 'Customer Name / Phone Number',
      dataIndex: 'custPhone',
      key: 'custPhone',
      width: 300,
      align: 'center',
      render: (data) => (
        <span style={{ textTransform: 'capitalize' }}>{data}</span>
      ),
    },
    {
      title: 'Order Value (₹)',
      dataIndex: 'amount',
      key: 'amount',
      width: 300,
      align: 'center',
      render: (data) => (
        <span style={{ textTransform: 'capitalize' }}>{data}</span>
      ),
    },
    {
      title: 'Predicted RTO ',
      dataIndex: 'rtoPrediction',
      key: 'rtoPrediction',
      width: 300,
      align: 'center',
      render: (data) => (
        <span style={{ textTransform: 'capitalize' }}>{data}</span>
      ),
    },
    {
      title: 'Reason of RTO',
      dataIndex: 'rtoReason',
      key: 'rtoReason',
      width: 300,
      align: 'center',
      render: (data) => (
        <span style={{ textTransform: 'capitalize' }}>{data}</span>
      ),
    },
    {
      title: 'Actual Status',
      dataIndex: 'status',
      key: 'status',
      width: 300,
      align: 'center',
      render: (data) => (
        <span style={{ textTransform: 'capitalize' }}>{data}</span>
      ),
    },
    // {
    //   title: 'Refund Amount',
    //   dataIndex: 'refundAmt',
    //   key: 'refundAmt',
    //   width: 300,
    //   align: 'center',
    //   render: (data) => (
    //     <span style={{ textTransform: 'capitalize' }}>{data}</span>
    //   ),
    // },
  ];
  return (
    <>
      <Tabs
        tabBarExtraContent={{
          left: (
            <FlexBox style={{ alignItems: 'center' }}>
              {' '}
              <div>
                <img src="https://pickrr.s3.amazonaws.com:443/2022-03-30T00:55:52.168723_Group 27328.svg" />
              </div>
              <Heading style={{ marginLeft: '8px' }}>
                Total RTO - {(tableData && tableData.length) || 0}
              </Heading>
            </FlexBox>
          ),
          right: (
            <CustomDatePicker
              //   disabledDate={disabledDate}
              allowClear={false}
              onChange={(dates) => {
                dates && dates[0]
                  ? setFromDate(dates[0].format('YYYY-MM-DD'))
                  : setFromDate(null);
                dates && dates[1]
                  ? setToDate(dates[1].format('YYYY-MM-DD'))
                  : setToDate(null);
              }}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, 'days'),
                  moment().subtract(1, 'days'),
                ],
                'Last 7 Days': [moment().subtract(6, 'd'), moment()],
                'This Month': [moment().startOf('month'), moment()],

                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              }}
              defaultValue={[
                moment(fromDate, 'YYYY-MM-DD'),
                moment(toDate, 'YYYY-MM-DD'),
              ]}
            />
          ),
        }}
      >
        <TabPane>
          <Container style={{ background: '#fff' }}>
            <Row gutter={28}>
              <div
                style={{ width: ' 100%', textAlign: 'end', padding: '20px' }}
              >
                <PickrrButton
                  color="checkout-blue"
                  onClick={downloadRtoCsvData}
                  icon={
                    <img
                      style={{ marginBottom: '5px', marginRight: '5px' }}
                      src="https://pickrr.s3.amazonaws.com:443/2022-03-31T13:16:39.416997_Download.svg"
                    />
                  }
                >
                  Download
                </PickrrButton>
              </div>

              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ borderRadius: 12 }}
              >
                <CustomTable
                  columns={columns}
                  dataSource={tableData}
                  loading={isLoading}
                  pagination={{
                    pageSize: 20,
                    showSizeChanger: false,
                    total: tableData?.total,
                    current: pageNumber,
                    onChange: (page) => {
                      setPageNumber(page);
                    },
                    position: ['bottomRight'],
                  }}
                />
              </Col>
            </Row>
          </Container>
        </TabPane>
      </Tabs>
    </>
  );
};

export default RtoTable;
