import styled from 'styled-components';
export const Container = styled.div`
  .ant-menu-root.ant-menu-inline,
  .ant-menu-root.ant-menu-vertical {
    background: #fff;
  }
  .ant-menu-item a,
  .ant-menu-item a:hover {
    color: #263f97;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover a {
    color: #263f97 !important;
    background-color: #d4f1f4 !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #d4f1f4 !important;
    color: #263f97;
    margin: auto;
    max-width: 94%;
    border-radius:8px;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover,
  .ant-menu-item-selected svg {
    color: #263f97;
    fill: #263f97;
  }
  .ant-menu-dark .ant-menu-item > span > a {
    color: #000 !important;
  }
  .menu-icon {
    color: #263f97;
  }
  .ant-menu-root.ant-menu-inline {
    background: #fff;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #fff;
  }
  .ant-menu-item a {
    color: #263f97;
  }
  .ant-menu-submenu-title {
    color: #263f97;
    font-size: 16px;
    font-weight: bold;
  }
  .ant-menu-submenu-vertical
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::after {
    background: #ff756c !important;
  }
  .ant-menu-submenu-title {
    color: #263f97;
  }
  .ant-menu-submenu-title:hover {
    color: #263f97;
  }
  a {
    color: #263f97;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
  .menu-left-alignment {
    padding-left: 48px;
  }
  @media only screen and (max-width: 968px) {
    .responsive_settings {
      margin-top: 24px;
      margin-left: 16px;
    }
  }
`;

export const InputFieldLayoutStyle = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgba(48, 59, 145, 0.2);
    height: 36px! important;
    border-radius: 5px;
  }
  .ant-select-selection-item {
    color: #263f97;
    font-size: 14px;
  }
  .ant-input {
    border: 1px solid rgba(48, 59, 145, 0.2);
    font-size: 14px;
    padding: 8px 16px;
    color: #263f97;
    border-radius: 5px;
  }
  .ant-input-number {
    border: 1px solid rgba(48, 59, 145, 0.2);
    font-size: 12px;
    padding: 2px;
    border-radius: 5px;
    color: #263f97;
  }
`;
