export const bankList = [
    {
      key: "HDFC",
      value: "HDFC Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:53:40.736149_HDFC-bank.jpg",
    },
    // {
    //   key: "HSBC",
    //   value: "HSBC Bank",
    //   img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:58:31.896042_HSBC-bank.jpg",
    // },
    {
      key: "ICIC",
      value: "ICICI Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:53:51.847637_ICICI-bank.jpg",
    },
    {
      key: "INDB",
      value: "INDUSIND Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:54:13.655756_Induslnd-bank.jpg",
    },
    {
      key: "KKBK",
      value: "Kotak Mahindra Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:54:29.035094_Kotak-mahindra-bank.jpg",
    },
    {
      key: "RATN",
      value: "RBL Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:54:39.212293_Ratnakar-bank.jpg",
    },
    {
      key: "SCBL",
      value: "Standard Chartered Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:54:51.007404_Standard-chartered-bank.jpg",
    },
    {
      key: "UTIB",
      value: "Axis Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:53:14.258322_Axis-bank.jpg",
    },
    {
      key: "YESB",
      value: "Yes Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:55:16.797739_Yes-bank.jpg",
    },
    {
      key: "SBIN",
      value: "State Bank of India",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:55:02.978651_State-bank-of-India.jpg",
    },
    // {
    //   key: "BARB",
    //   value: "Bank of Baroda Bank",
    //   img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:53:27.966524_Bank-of-baroda.jpg",
    // },
    {
      key: "IBKL",
      value: "IDBI Bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:54:03.294612_IDBI-bank.jpg",
    },
    {
      key: "AUBL",
      value: "AU small finance bank",
      img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:52:58.712563_AU-small-finance-bank.jpg",
    },
  ];
export const cardType = [
    {key: "both", value: "Both (Debit and Credit Card)"}, 
    {key: "debit", value: "Debit Card"}, 
    {key: "credit", value: "Credit Card"}
]

export const paymentMethod = [
    {key: "card", value: "Card"}, 
    {key: "netBanking", value: "Netbanking"}, 
    {key: "wallet", value: "Wallet"}, 
    {key: "emi", value: "EMI"}, 
]


export const bankNetwork = [
    {key: "Visa", value: "Visa"}, 
    {key: "MasterCard", value: "Mastercard"}, 
    {key: "American Express", value: "American Express"},
    {key: "Diners Club", value: "Diners Club"},
    {key: "RuPay", value: "RuPay"},
    {key: "Maestro", value: "Maestro"},
]
    
export const walletsList = [
  {
    key: "freecharge",
    value: "Freecharge",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:21:51.960879_freecharge.jpg",
  },
  {
    key: "phonepe",
    value: "Phonepe",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:24:04.902782_phonepe.jpg",
  },
  {
    key: "mobikwik",
    value: "Mobikwik",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:22:30.963567_mobikwik.jpg",
  },
  {
    key: "payzapp",
    value: "PayZapp",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:23:37.894666_payzapp.jpg",
  },
  {
    key: "olamoney",
    value: "Ola Money",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:22:47.902396_olamoney.jpg",
  },
  {
    key: "airtelmoney",
    value: "Airtel Money",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:21:11.699009_airtel-money.jpg",
  },
  {
    key: "amazonpay",
    value: "Amazon Pay",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:21:37.995768_amazon-pay.jpg",
  },
  {
    key: "jiomoney",
    value: "Jiomoney",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:22:12.021246_jiomoney.jpg",
  },
  {
    key: "paypal",
    value: "PayPal",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:23:18.489859_paypal.jpg",
  },
  {
    key: "paytm",
    value: "Paytm",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-24T10:50:56.564360_Paytm.jpg",
  },
];

export const emiList = [
  {
    key: "barb",
    value: "Bank of Baroda",
    img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:53:27.966524_Bank-of-baroda.jpg",
  },
  {
    key: "cshe",
    value: "CASHe",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:47:33.798720_Cashe.jpg",
  },
  {
    key: "icic",
    value: "ICICI Bank",
    img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:53:51.847637_ICICI-bank.jpg",
  },
  {
    key: "idfb",
    value: "IDFC Bank",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:28:24.910980_IDFC-bank.jpg",
  },
  {
    key: "hdfc",
    value: "HDFC Bank",
    img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:53:40.736149_HDFC-bank.jpg",
  },
  {
    key: "kkbk",
    value: "Kotak Bank",
    img: "https://pickrr.s3.amazonaws.com:443/2023-10-22T16:54:29.035094_Kotak-mahindra-bank.jpg",
  },
  {
    key: "krbe",
    value: "KreditBee",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:31:24.972607_Kreditbee.jpg",
  },
  {
    key: "tvsc",
    value: "TVS Credit",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:29:11.012524_TVS-Credit.jpg",
  },
  {
    key: "walnut369",
    value: "Axio",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:32:51.115122_Axio.jpg",
  },
  {
    key: "earlysalary",
    value: "Fibe",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:33:24.440464_Fibe.jpg",
  },
  {
    key: "zestmoney",
    value: "ZestMoney",
    img: "https://pickrr.s3.amazonaws.com:443/2023-11-22T10:28:53.759777_Zestmoney.jpg",
  },
];

    
export const quantityRegex = new RegExp(/^(((?!0)[0-9]*))$/);
