import styled from "styled-components";
import {Button} from 'antd';


export const Container =styled.div`
  .ant-btn-primary{
    background: #6F57E9;
    border-color: #6F57E9;
    color: #ffffff;
  }

  .ant-btn.ant-btn-default{
    border-color: #6F57E9;
    color: #6F57E9;
  }

  .ant-switch-checked{
    background: #17DB94 !important;
  }
  .ant-table-thead .ant-table-cell{
    color:#212122;
    font-size:14px;
    font-weight: 700;
  }

  .ant-table-tbody .ant-table-cell{
    color:#6B7280;
    font-size:12px;
    font-weight: 400;
  }
`

export const Heading = styled.h1`
    font-size: 26px;
    font-weight: 700;
    line-height: 31.67px;
    letter-spacing: -0.03em;
    color: #000B34;
`
export const MutatedText = styled.h2`
    font-size: 20px;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #B6BCC6;
`

export const FlexCenter = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height: calc(100vh - 140px);
    flex-direction:column;
`

export const AddNewUserButton = styled(Button)`
  background: #263F97 !important;
  border-radius: 5px;
  font-size: 14px;
  outline: none !important;
  color: #fff;
  height: 40px;
  margin-top: 10px;
  border-radius: 8px;

  &:focus {
    outline: none !important;
    background: #ffffff;
    color: #263f97;
    border: 1px solid #263F97;
  }
  &:hover {
    background: #ffffff !important;
    color: #263f97;
    outline: none !important;
    border: 1px solid #263F97;
  }
`

export const SubHeading = styled.h4`
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #333843;
`

export const CustomButton = styled(Button)`
  height: 40px;
  border-radius: 6px;
`
export const Card = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #E0E2E7;
  box-shadow: 0px 1.5px 2px 0px #1018281A;
  background: #ffffff;
`

export const CardHeading = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.005em;
  text-align: left;
`

export const InputWrapper = styled.div`
  display:flex;
  label{
    display:flex;
    align-items:center;
    img {
      vertical-align:middle;
      padding-right: 8px;
    } 
  }

  input{
    border:none; 
    background: none;
    margin-left: 12px;
    width: 180px;
    &:focus{
      outline:none
    }
  }
`

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #4D5464;
`

export const SelectRoleFilter = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:250px;
  height: 40px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #EAEAEA;
  padding: 10px;
  margin: 12px 0;

  select{
    width: 100%;
    height: 30px;
    border:none;
    outline:none;
    color: #8B909A;

  }
`

export const SelectRoleText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #111111;
  border-right: 1px solid #111111;
  padding-right: 10px;
  min-width: 84px;
  margin-right: 10px;
`

export const AccessRoleList = styled.div`
  margin-bottom: 10px;
  size: 12px;
  display:flex;
  
  img{
   vertical-align:middle;
   padding-right: 10px; 
  }

  color:#252C32;
`

export const AccessRoleListCheckbox = styled.div`
  color:#252C32;
  margin-bottom: 10px;
  size: 12px;
  input{
    margin-right: 10px;
  }
`

export const AccessRoleRadio = styled.div`
  display:flex;
  align-items:center;
  gap: 20px;
  margin-top: 20px;
`

export const DeleteModalImage = styled.div`
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translateX(-52%);
`

export const DeleteModalHeading = styled.h4`
  font-size: 24px;
  font-weight: 700;
  line-height: 29.23px;
  text-align: left;
  color: #2F2F2F;
  text-align:center;
  margin-top: 60px;
`

export const DeleteModalText = styled.p`
  font-family: Proxima Nova;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #2F2F2F;
  text-align:center;
`
export const DeleteModalButton = styled(Button)`
  height: 48px;
  width: 100px;
  border-radius: 6px;
`

export const DeleteButtonContainer = styled.div`
  display:flex;
  justify-content:center;
  gap: 20px;

  
`


export const AllUserContainer = styled.div`
   border: 1px solid #E0E2E7;
   background: #ffffff;
   border-radius: 8px;
   overflow:hidden;   
   .ant-switch{
    background: #d3d3d3;
   }
`

export const UserHeader = styled.div`
  display:flex;
  justify-content:space-between;
  padding: 20px;
  align-items:center;
`

export const RoleBadge = styled.div`
  border: 1px solid #D1D5DB;
  background: #F3F4F6;
  min-width: 60px;
  height: 22px;
  border-radius: 4px;
  font-size:11px;
  line-height: 20px;
  color:#1F2937;
  text-align:center;
  font-weight: 500;
`
export const TableActionButton = styled.span`
  cursor: pointer;
  color: #705CDF;
`

export const ErrorMessage= styled.div`
  font-size:12px;
  color: #c80000;
  margin-top: 8px;
`

