import { DetailsHeading, BackButtonText, Container, HeadingContainer } from "./style";
import { CustomForm, PickrrButton } from 'components/Settings/GeneralSettings/style'
import React, { useEffect, useState } from 'react'
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Col, Form, Input, message, notification } from "antd";
import axiosInstance from "HTTP/Http";
import { GetAuthToken } from "globalQuries";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getGeneralDetails, updateSellerCheckoutConfig } from "components/Settings/GeneralSettings/script";
const MagentoLogo = require("../../../../images/magento-logo.png");



const ManageChannelMagento = () => {
  const [loading, setLoading] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const { data, isLoading } = useQuery(
    'generalSettings',
    getGeneralDetails
  );

  const queryClient = useQueryClient()
  const history = useNavigate();

  const mutation = useMutation(updateSellerCheckoutConfig, {
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context.id}`);
      setApiSuccess(false);
    },
    onSuccess: (message, variables, data) => {
      setApiSuccess(true);
      notification.success({ message: 'Updated seller details.' });
      queryClient.invalidateQueries("generalSettings");
    },
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (data?.platform_details?.magento) {
      form.setFieldsValue({
        ...data?.platform_details?.magento,
      })

    }
  }, [apiSuccess])


  const goBack = () => {
    history(-1);
  };

  const submitForm = () => {
    const fieldValues = { ...form.getFieldsValue() };
    setLoading(true);
    const auth = GetAuthToken();

    const postData = {
      ...data,
      platform_details: {
        ...data?.platform_details,
        channel: "magento",
        magento: { ...fieldValues },
      }

    }

    mutation.mutate(postData);
    setLoading(false);
  }



  return (
    <Container>
      <HeadingContainer>
        <div>
          <DetailsHeading>
            <BackButtonText onClick={goBack}>
              <LeftOutlined style={{ paddingRight: 8 }} />
              Back
            </BackButtonText>
            Magento
          </DetailsHeading>
        </div>

        <img src={MagentoLogo} alt="logo" height={100} style={{
          marginRight: "2rem",
        }} />
      </HeadingContainer>

      <Col md={12} sm={24}>
        <CustomForm form={form} onFinish={submitForm}>
          <div>
            <Form.Item name="admin_api_key" rules={[{ required: true }]} label="Access Token">
              <Input placeholder="Access Token" />
            </Form.Item>

            <Form.Item name="admin_api_password" rules={[{ required: true }]} label="Access Token Secret">
              <Input placeholder="Access Token Secret" />
            </Form.Item>

            <Form.Item name="api_base_url" rules={[{ required: true }]} label="API Base URL">
              <Input placeholder="API Base URL" />
            </Form.Item>
          </div>

          <PickrrButton htmlType="submit" loading={loading}>Save</PickrrButton>

        </CustomForm>

      </Col>

    </Container>

  )
}

export default ManageChannelMagento