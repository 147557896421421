import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { FlexBox, FlexContainer, FlexEndContainer, PickrrButton } from "components/UIElements";
import {
  ProductItem,
  ProductHeading,
  VariantSelected,
  ButtonEdit,
  ButtonClose,
  Text,
} from "./styled";
import { convertToObject } from "typescript";
import { ValueText } from "pages/Dashboard/PieView/styled";

import { Col, InputNumber, notification } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { EditOrderUpdate } from "./script";

const SearchedProductList = ({
  selectedItems,
  setIsProductModalOpen,
  setEditItemId,
  setSelectProductId,
  setSelectedItems,
  type,
  discountType,
  variantList,
  setVariantList,
  editOrderData,
  setEditOrderData
}: any) => {

  const [addingProductApiCount, setAddingProductApiCount] = useState(0)
  const [quantityError, setQuantityError] = useState(false)
  const queryClient = useQueryClient();
  const mutation = useMutation(EditOrderUpdate, {
    onMutate: (variables) => {
      // A mutation is about to happen!
      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },
    onError: (error : any, variables, context) => {
      // An error happened!
      setAddingProductApiCount((prevState) =>(prevState+1))
      let sanitizedError = JSON.stringify(error?.response?.data?.error?.message)
            if(typeof sanitizedError === "string" && sanitizedError && sanitizedError.includes('message\\":')) {
              notification.error({
                  message : sanitizedError.match(/"message\\":\\"(.+)\\"}/)[1]
              });
            } else {
              notification.error({
                  message : `Something went wrong.`
              });
            }
    },
    onSuccess: (message, variables, data) => {
      setAddingProductApiCount((prevState) =>(prevState+1))
      queryClient.invalidateQueries('orderData');
      // navigate('/settings/shipping');
    },
});

  const editItemHandler = (itemId: number | string) => {
    if (itemId) {
      setSelectProductId()
      setEditItemId(itemId);
      setIsProductModalOpen(true);
    }
  };

  const handleAddProducts = async()=>{
   if(variantList?.length > 0) {
    variantList.map((variantId:any)=>{
      const postData={
        "orderId": editOrderData?.id,
        "operation": "OrderEditAddVariant",
        "channel": editOrderData?.channel,
        "lineItemRequest": {
            "variantId": variantId,
            "quantity": 1
        }
    }
    mutation.mutate(postData)  
    })
    }
  }

  useEffect(()=>{
    let count = 0
    let errorPresent = false
    if(selectedItems?.length > 0){
      selectedItems?.map((productDetail : any)=>{
        if(productDetail?.variants?.length > 0){
          productDetail?.variants?.map((variantDetails : any)=>{
            if(productDetail?.selectedItems.includes(variantDetails?.value)){
              count++
              if(variantDetails?.qty <= 0){
                setQuantityError(true)
                errorPresent = true
              }
            }
          })
        }
      })
      if(count === variantList.length && errorPresent === false){
        setQuantityError(false)
      }
    }
  },[selectedItems])
  useEffect(()=>{
    if(mutation.data){
      setEditOrderData(mutation.data)
    }
  },[mutation.data])
  useEffect(()=>{
    if(addingProductApiCount === variantList?.length){
      setTimeout(() => {
        setVariantList([])
      setSelectedItems([])
      setAddingProductApiCount(0)
      }, 100);      
    }
  },[addingProductApiCount])

  const deleteItemHandler = (itemId: string | number) => {
    const deleteVariantArr = selectedItems?.filter((item: any) => item.id === itemId)
    setSelectedItems(selectedItems?.filter((item: any) => item.id !== itemId));
    const tempArr = variantList?.filter((variantId:any )=>!deleteVariantArr[0]?.selectedItems?.includes(variantId))
    setVariantList(tempArr)
  };


  const qtyChangeHandler = (qty: number, itemId: string | number) => {
    const updatedItems = [...selectedItems];
    updatedItems.forEach((item:any) => {
        if(item.id === itemId){
        item.qty = qty
      }
    })
    setSelectedItems([...updatedItems]);
  }

  return (
    <div style={{ marginBottom: 36 }}>
      {selectedItems?.length > 0 &&
        selectedItems?.map((value: any, index: number) => {
          return (
            <ProductItem key ={index}>
              <div style={{maxWidth: '64%'}}>
                <ProductHeading>
                <FlexBox style={{gap: 4}}>
                  <span>{`${index + 1}.`}</span>
                  <span>{`${value?.name} ( ID: ${value?.id} )`}</span>
                </FlexBox>
                   
                </ProductHeading>
                {type === "product" && (
                  <>
                  <VariantSelected>
                    {value?.name} ({
                      value?.selectedItems?.length ? value?.selectedItems?.length : value?.checked ? value?.variantCount : value?.variants?.length} out{" "}
                    {value?.variantCount ? value?.variantCount : value?.variants?.length} variants selected)
                  </VariantSelected>
                      {value?.variants?.map((variantDetails: any)=>{
                        return(
                          value?.selectedItems.includes(variantDetails.value) ?
                          <VariantSelected>
                          {`Available stock of ${variantDetails.label} variant : ${variantDetails.qty} `}
                          </VariantSelected>
                          : 
                          null
                        )
                      })}
                  </>
                )}
              </div>
              <FlexContainer style={{ gap: 16 }}>
                {discountType === "bundle" && 
                <>
                  <Text>Qty</Text>
                    <InputNumber
                    onChange={(qty:number) => qtyChangeHandler(qty, value?.id)}
                      defaultValue={value?.qty || value?.quantity}
                      min={1}
                        style={{
                          maxWidth: 76,
                          border: "1px solid #DFE0EB",
                          borderRadius: 6,
                        }}
                    />
                  </>
                }

                {type === "product" && (
                  <ButtonEdit onClick={() => editItemHandler(value?.id)}>
                    Edit
                  </ButtonEdit>
                )}

                <ButtonClose>
                  {/* @ts-ignore */}
                  <CloseOutlined onClick={() => deleteItemHandler(value?.id)} />
                </ButtonClose>
              </FlexContainer>
            </ProductItem>
          );
        })}
        {quantityError &&
        <div style={{display : 'flex', width : '100%', color : 'red', fontSize : '12px'}}>
          Available stock for one of the selected variant is less than 0
        </div>
        }
        <FlexEndContainer>
              <PickrrButton 
              onClick={()=>{
                handleAddProducts()
              }}
              color="checkout-blue" 
              style={{fontSize : '10px',lineHeight : '12px'}}
              loading = {mutation?.isLoading}
              disabled = {quantityError}
              >
                Add Products
              </PickrrButton>
            </FlexEndContainer>
    </div>
  );
};

export default SearchedProductList;
