import Header from "./Header";
import React, { useState, useContext, useEffect } from "react";
import { AddButton, BackButton, Container, CustomForm, RadioHeading, TableDataContainer, TableHeading } from "./style";
import { useQuery, useQueryClient } from 'react-query';
import { Table, Col, Row, Form, Input, Space, Select, notification, Switch, Radio, InputNumber } from "antd";
import { PickrrButton } from "components/UIElements";
import { LeftOutlined } from '@ant-design/icons';
import { fetchWebhookList } from "./script";
import axiosInstance from '../../../HTTP/Http';


const { Option } = Select;
const CustomWebHooks = () => {

    const queryClient = useQueryClient();

    const { data: webhookListData, isLoading } = useQuery(
        ['WebhookList'],
        fetchWebhookList
    );

    const [addWebHook, setAddWebHook] = useState(false);
    const [webhookType, setWebhookType] = useState("");
    const [editWebhookData, setEditWebhookData] = useState({});
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [requestMethod,setRequestMethod]=useState("POST");
    const [switchLoading, setSwitchLoading] = useState(null);
    const selectionOptions= ['PRODUCT_FETCH',
        'COLLECTION_FETCH',
        'COLLECTION_PRODUCT_FETCH',
        'ORDER_WEBHOOK',
        'ABANDON_CART_WEBHOOK'];
    const [form] = Form.useForm();

    useEffect(() => {
        if (Object.keys(editWebhookData)?.length === 0) {
            form.setFieldsValue({
                type: ""
            });
            setWebhookType("");
        } else {
            form.setFieldsValue({
                apiEndPoint: editWebhookData.apiEndPoint,
                header: editWebhookData?.header?.length ? editWebhookData.header : [{}],
                stage: editWebhookData.stage,
                serviceProvider: editWebhookData.serviceProvider,
                type: editWebhookData.type,
                requestMethod: editWebhookData.requestMethod,
                extra: editWebhookData?.extra?.length ? editWebhookData.extra : [{}],
                time: editWebhookData?.time
            });
            setWebhookType(editWebhookData.type);
        }
    }, [editWebhookData]);

    const requestMethods=[{value:"GET",label:"GET"},{value:"POST",label:"POST"}]

    const submitForm = async (values) => {
        setIsSubmitLoading(true);

        let headersObject = {}
        let customConfigObject ={}
        if(values?.header?.length){
            values?.header?.forEach(header => {
                if(header?.key?.trim()?.length){
                headersObject[header?.key?.trim()] = header?.value;
                }
            })
        }

        if(values?.extra?.length){
            values?.extra?.forEach(extra => {
                if(extra?.key?.trim()?.length){
                    customConfigObject[extra?.key?.trim()] = extra?.value;
                }
            })
        }

        const postData = {
            type: webhookType,
            url: values.apiEndPoint,
            request_method: values.requestMethod,
            request_headers: headersObject,
            custom_config: customConfigObject
        };

        try {
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/custom-channel-endpoint/create-or-update`,
                postData
            );

            if (response.data.errors) {
                notification.error({
                    message: response.data.errors.message
                });
                setIsSubmitLoading(false);
            } else {
                await queryClient.invalidateQueries('WebhookList');
                notification.success({
                    message: 'URL Endpoint Updated Successfully'
                });
                setIsSubmitLoading(false);
                form.resetFields();
                setEditWebhookData({});
                setAddWebHook(false);
            }
        } catch (error) {
            notification.error({
                message: error.response.data.message
            });
            setIsSubmitLoading(false);
        }
    };

    const columns = [
        {
            title: <TableHeading> TYPE </TableHeading>,
            width: "40%",
            dataIndex: 'type',
            align: "left",
            render: (data) => {
                const formattedType = data?.replace(/_/g, ' ');
                return <TableDataContainer>{formattedType}</TableDataContainer>;
            },
        },
        {
            title: <TableHeading> URL </TableHeading>,
            width: "60%",
            dataIndex: 'url',
            align: "left",
            render: (data) => {
                return <TableDataContainer>{data}</TableDataContainer>;
            },
        }
    ];

    return (
        <Container>
            <Header
                title="Custom Endpoints"
                addWebHook={addWebHook}
                setAddWebHook={setAddWebHook}
            />
            {!addWebHook ?
                <Col xl={24} lg={24} md={24} sm={24} x={24}>
                    <Table
                        bordered
                        dataSource={webhookListData}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination={false}
                        loading={isLoading}
                    />
                </Col>
                :
                <CustomForm layout="vertical" form={form} onFinish={submitForm}>
                    <Row gutter={[20, 20]} style={{ marginLeft: '10px' }}>
                        <Col span={24}>
                            <>
                                <LeftOutlined
                                    style={{
                                        color: '#000B3499 !important',
                                        cursor: 'pointer',
                                    }}
                                />
                                <BackButton
                                    onClick={() => {
                                        setAddWebHook(false)
                                        setEditWebhookData({})
                                        form.resetFields()
                                    }}
                                >BACK</BackButton>
                            </>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={<RadioHeading>Select Custom Endpoint Type</RadioHeading>}
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Webhook Type is required",
                                    }
                                ]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setWebhookType(value);
                                        // Find the corresponding webhook data
                                        const selectedWebhook = webhookListData.find(webhook => webhook.type === value);
                                        // Set the API Endpoint if the webhook is found
                                        if (selectedWebhook) {
                                            form.setFieldsValue({ apiEndPoint: selectedWebhook.url ,requestMethod:selectedWebhook.request_method });
                                        }
                                    }}
                                    value={webhookType} 
                                >
                                    {selectionOptions?.map((webhook) => (
                                        <Option key={webhook} value={webhook}>
                                            {webhook.replace(/_/g,' ')}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                style={{ width: '100%' }}
                                label="API Endpoint"
                                name="apiEndPoint"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter a valid API endpoint.",
                                        validator(rule, value) {
                                            if (!value?.trim().length) {
                                                return Promise.reject(new Error("Please enter a valid API endpoint."));
                                            }
                                            return Promise.resolve();
                                        },
                                    }   
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={"Select Method Type"}
                                name="requestMethod"
                                rules={[
                                    {
                                        required: true,
                                        message: "Request Method Type is required",
                                    }
                                ]}
                                value={requestMethod} 
                            >
                                <Select
                                    onChange={(value) => {
                                        setRequestMethod(value);       
                                    }}
                                    value={requestMethod}
                                >
                                    {requestMethods?.map((item) => (
                                        <Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xl={12} lg={12} md={24} sm={24} x={24}>
                            <Form.List
                                name="header"
                                initialValue={[{}]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
                                                <Form.Item
                                                    style={{ marginRight: '10px', width: '45%' }}
                                                    label={index === 0 && "Headers"}
                                                    name={[field.name, `key`]}
                                                    rules={[
                                                        (formInstance) => ({
                                                            message: 'Enter Valid Header Key',
                                                            validator(rule, value) {
                                                                const key = formInstance.getFieldValue(['header', field.name, 'value']);
                                                                if (key && !value?.trim().length && index === 0) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (!value?.trim().length && fields.length > 1) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (value && !value?.trim().length) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input placeholder="Enter key" />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginRight: '10px', width: '45%' }}
                                                    label={index === 0 && ""}
                                                    name={[field.name, `value`]}
                                                    rules={[
                                                        (formInstance) => ({
                                                            message: 'Enter valid Header value',
                                                            validator(rule, value) {
                                                                const key = formInstance.getFieldValue(['header', field.name, 'key']);
                                                                if (key && !value?.trim().length && index === 0) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (!value?.trim().length && fields.length > 1) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (value && !value?.trim().length) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input placeholder="Enter value" />
                                                </Form.Item>
                                                {fields.length > 1 &&
                                                    <img
                                                        onClick={() => remove(field.name)}
                                                        style={{ marginBottom: '30px' }}
                                                        src="https://d10srchmli830n.cloudfront.net/1678874340329_65c480e9-a900-4dbd-b9e6-a94b7fd405a7_Group-1116599519.svg"
                                                    />}
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <AddButton onClick={() => add()}>
                                                Add More
                                            </AddButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Col xl={12} lg={12} md={24} sm={24} x={24}>
                            <Form.List
                                name="extra"
                                initialValue={[{}]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
                                                <Form.Item
                                                    style={{ marginRight: '10px', width: '45%' }}
                                                    label={index === 0 && "Additional Info"}
                                                    name={[field.name, `key`]}
                                                    rules={[
                                                        (formInstance) => ({
                                                            message: 'Enter valid Additional key',
                                                            validator(rule, value) {
                                                                const key = formInstance.getFieldValue(['extra', field.name, 'value']);
                                                                if (key && !value?.trim().length && index === 0) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (!value?.trim().length && fields.length > 1) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (value && !value?.trim().length) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input placeholder="Enter key" />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ marginRight: '10px', width: '45%' }}
                                                    name={[field.name, `value`]}
                                                    rules={[
                                                        (formInstance) => ({
                                                            message: 'Enter valid Additional value',
                                                            validator(rule, value) {
                                                                const key = formInstance.getFieldValue(['extra', field.name, 'key']);
                                                                if (key && !value?.trim().length && index === 0) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (!value?.trim().length && fields.length > 1) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                if (value && !value?.trim().length) {
                                                                    return Promise.reject(new Error());
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input placeholder="Enter value" />
                                                </Form.Item>
                                                {fields.length > 1 &&
                                                    <img
                                                        onClick={() => remove(field.name)}
                                                        style={{ marginBottom: '30px' }}
                                                        src="https://d10srchmli830n.cloudfront.net/1678874340329_65c480e9-a900-4dbd-b9e6-a94b7fd405a7_Group-1116599519.svg"
                                                    />}
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <AddButton onClick={() => add()}>
                                                Add More
                                            </AddButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Col span={24}>
                            <Space>
                                <PickrrButton
                                    style={{ marginLeft: '10px' }}
                                    htmlType="submit"
                                    color="checkout-blue"
                                    loading={isSubmitLoading}
                                >
                                    Save & Proceed
                                </PickrrButton>
                            </Space>
                        </Col>
                    </Row>
                </CustomForm>
            }
        </Container>
    );
};

export default CustomWebHooks;
