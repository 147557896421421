import React, { useEffect, useState, useContext } from "react";
import {Space, Button, Typography, message } from "antd";
import {CustomCard} from './style'
import axios from "axios";
import moment from "moment";
import { is_local, local_cookie, setCookie, defaultRoute, sessionConfig } from "utils/functions";

const { Title } = Typography;

const DomainList = ({domainName, logo, cid, email}:any) => {
  const [accountName, setAccountName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  const shiprocketLogin = async (cid:any, domainName:any) => {
    setIsLoading(true)
    try{
      const sessionResponse = await axios.get(
        `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/user/create/session`,
        {
          params: {
            email: email,
            domain: domainName,
          }, 
          headers: {
            "Company-Id": cid
          }
        }, 
      )

    sessionConfig(sessionResponse);

    const response = await axios.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/aggregator-service/validate/url/?domainName=${domainName}`,
      {
        headers: {
          cid:cid
        },
      }
    );
    if(response || response?.headers){
      try{
        console.log("ENTERED TRY BLOCK")

        const id = response.headers.cid;
        const getAuth = window.atob(decodeURIComponent(`${id}`));
        setIsLoading(false)
        localStorage.setItem('auth_token', getAuth)
        document.cookie = `auth_key=${getAuth};max-age=604800`;
        localStorage.removeItem("loggedInFromAdmin");
        defaultRoute(sessionResponse?.headers?.['permissions']);

      }catch(error){
        console.log(`error`,error)
      }
    }
    }catch(error){
      if(error?.response?.data?.message?.includes("User not found")){
        message.error("User not found redirecting to your native place");
        window.location.href = 'https://app.shiprocket.in/seller/home';
      }else{
        message.info("something went wrong");
      }
      setIsLoading(false)
    }
  };
  

  useEffect(() => {
    const ShiprocketTokentExpireDate = localStorage.getItem("ShiprocketTokentExpire")
    if(moment().toString() >= ShiprocketTokentExpireDate){
      localStorage.removeItem("ShiprocketTokentExpire")
      localStorage.removeItem("ShiprocketToken")
    }
    const urlSearchParams = new URLSearchParams(window.location.search);    
    const ShiprocketToken = urlSearchParams.get('token');
    if(ShiprocketToken){
      localStorage.setItem('ShiprocketToken',ShiprocketToken)
      localStorage.setItem("ShiprocketTokentExpire",moment().add('7','days').toString())
    }
    if(cid && accountName) {
      shiprocketLogin(cid, accountName);
    }
    // if (is_local()) {
    //   document.cookie = `auth_key=${local_cookie()};max-age=604800`;
    // }
  }, [accountName]); 




  return (
    <CustomCard>
      <Space direction="vertical" size={"small"}>
        <img
          src={logo ? logo : "https://pickrr.s3.amazonaws.com:443/2023-08-15T19:46:20.006658_logo-icon.svg"}
          alt="logo"
          style={{ margin: "0 auto", height: 55, width: 'auto' }}
        />
        <Title level={5}>{domainName}</Title>
        <Button
          onClick = {() => {setAccountName(domainName)}}
          loading = {isLoading}
        >Open store</Button>
      </Space>
    </CustomCard>
  );
};

export default DomainList;
