import React, { useContext, useEffect } from "react";
import {
  Route,
  Routes,
  Redirect,
  useNavigate,
  useLocation,
} from "react-router-dom";
// import { useSelector } from 'react-redux';
import Navigation from "./Navigation";
import MasterLayout from "components/MasterLayout";
import Dashboard from "pages/Dashboard";
import { useQuery } from "react-query";
import { getCookieValue, getUserDetails } from "globalQuries";
import { DataContext } from "context/DataProvider";
import { is_local } from "utils/functions";
import SideBar from "UiComponents/SideBar/SideBar";
export const PrivateRoute = ({
  element: Component,
  path,
  onboardVersion,
  ...rest
}) => {
  // const { data, isLoading, isSuccess } = useQuery(
  //   'userDetails',
  //   getUserDetails
  // );
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchingWalletDetails, walletDetails, walletBalance, walletError } =
    useContext(DataContext);
  const auth = getCookieValue();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get('token');
  const uid = urlSearchParams.get("uid");

  // useEffect(() => {
  //   if (!fetchingWalletDetails) {
  //     if (walletBalance < walletDetails?.current_tolerance) {
  //       navigate("/wallet");
  //     }
  //   }
  // }, [location.pathname, fetchingWalletDetails, walletBalance, walletDetails]);

  // if(uid){
  //   localStorage.setItem('auth_token', uid);
  // }

  return (
    <Routes>
      {" "}
      <Route
        {...rest}
        path={path}
        element={
          auth || token || uid || is_local() || localStorage?.getItem('isOnboarding')
          ? (
            <SideBar {...rest}>
              <Component onboardVersion={onboardVersion} />
            </SideBar>
          ) : (
              // <Redi
              //   to={{
              //     pathname: 'https://dashboard.pickrr.com/login',
              //     replace: true,
              //   }}
              // />
              <Navigation />
          )
        }
      />
    </Routes>
  );
};
