import styled from 'styled-components';
import media from '../../../../components/UIElements/media';


export const DetailsHeading = styled.div`
  font-size: 22px;
  color: #263f97;
  padding: 28px 20px 40px 20px;
  font-weight: bold;
  line-height: 24px;
  ${media.mobile`
    font-size: 22px;
  `};
`;

export const BackButtonText = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 12px;
  color: #263f97;
  opacity: 0.6;
  cursor: pointer;
`;

export const Container = styled.div`
  border-radius: 12px;
`;

export const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-image: url('https://d10srchmli830n.cloudfront.net/1619690201338_dots.svg');
    width: 100%;
    height: 200px;
    background-size: contain;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-repeat: no-repeat;
    background-position: right;   
    background-color: #f9fafe;
`;

