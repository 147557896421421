import { Button, Col, Form, InputNumber, notification, Radio} from "antd";
import { PickrrButton } from "components/UIElements";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { EditOrderUpdate } from "./script";
import { CustomForm, CustomModal, ErrorMessageContainer, FooterContainer, ModalTitle } from "./styled";


const DiscountModal = ({isVisible,setIsVisible,DiscountModalData,editOrderData,setEditOrderData}: any)=>{
    const [form] = Form.useForm();
    const [discountType, setDiscountType] = useState(undefined)
    useEffect(()=>{
        if(isVisible){
            form.setFieldsValue({
            type : "flat"
            })
            setDiscountType("flat")
        }
    },[isVisible])
    const mutation = useMutation(EditOrderUpdate, {
        onMutate: (variables) => {
          // A mutation is about to happen!
          // Optionally return a context containing data to use when for example rolling back
          return { id: 1 };
        },
        onError: (error : any, variables, context) => {
          // An error happened!
          let sanitizedError = JSON.stringify(error?.response?.data?.error?.message)
            if(typeof sanitizedError === "string" && sanitizedError && sanitizedError.includes('message\\":')) {
              notification.error({
                  message : sanitizedError.match(/"message\\":\\"(.+)\\"}/)[1]
              });
            } else {
              notification.error({
                  message : `Something went wrong.`
              });
            }
        },
        onSuccess: (message, variables, data) => {            
            // console.log(data)
          // navigate('/settings/shipping');
        },
      });

    useEffect(()=>{
        if(mutation?.data){
            setEditOrderData(mutation?.data)
            setIsVisible(false)
            form.resetFields()
        }
    },[mutation?.data])

    const handleSubmit = ()=> {
        let formData = form.getFieldsValue(true);
        const postData = formData?.type === "flat" ?
        {
            "orderId": editOrderData?.id,
            "operation": "OrderEditAddLineItemDiscount",
            "channel": editOrderData?.channel,
            "lineItemRequest": {
                "lineItemId": DiscountModalData?.platformId,
                "discountFixedValue": formData?.amount ,
                "currencyCode" : "INR",
            }
        }
        : {
            "orderId": editOrderData?.id,
            "operation": "OrderEditAddLineItemDiscount",
            "channel": editOrderData?.channel,
            "lineItemRequest": {
                "lineItemId": DiscountModalData?.platformId,
                "discountPercentValue" :  formData?.amount,
            }
        }
        mutation.mutate(postData)
        
    }

    return(
        <>
        {/*//@ts-ignore */}
        <CustomModal
        visible = {isVisible}
        title={<ModalTitle>Apply Discount </ModalTitle>}
        onCancel={()=>{
            form.resetFields()
            setIsVisible(false)
        }}
        footer = {false}
        width = {560}
        keyboard= {true}
        >
            <CustomForm
                layout="vertical" 
                form={form} 
                style={{padding : '0 100px 0 100px'}}
                onFinish = {handleSubmit}
                >
                    <Col span={24}>
                        <Form.Item
                        name={'type'}
                        label = 'Discount Type'
                        >
                            <Radio.Group
                            onChange={(e)=> {
                                form.setFieldsValue({
                                    'type' : e.target.value
                                })
                                setDiscountType(e.target.value)
                            }}
                            >
                                <Radio value="flat">Flat /-</Radio>
                                <Radio value="percentage">Percentage %</Radio>
                            </Radio.Group>

                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                        name={'amount'}
                        label = 'Amount  ₹  '
                        rules={[
                            {
                                required : true,
                                message : 'Amount is Required'
                            }
                        ]}
                        >
                            <InputNumber min={0} max={discountType === "percentage" ? 100 : DiscountModalData?.price } style={{height : '50px'}} />
                        </Form.Item>
                    </Col>
                    <FooterContainer>
                    <Button 
                    color="cancel" 
                    style={{border : '1px solid #263F97', height : '40px', borderRadius : '8px',marginRight : '18px', width : '130px', color : '#000B34'}}
                    onClick={()=>{
                        form.resetFields()
                        setIsVisible(false)
                    }}
                    >
                        Cancel
                    </Button>
                    <PickrrButton 
                    htmlType="submit" 
                    color="checkout-blue" 
                    style={{width : '130px', height : '40px'}}
                    >
                        Save
                    </PickrrButton>
                </FooterContainer>

            </CustomForm>
        </CustomModal>
        </>
    )
}

export default DiscountModal