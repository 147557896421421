import axios from "axios"

export const fetchWalletDetails = async ({queryKey}) => {
    const [unusedArgument,sellerId,ShiprocketToken] = queryKey;
      const response = ShiprocketToken ? await axios.get(
        `${process.env.REACT_APP_FASTRR_FINANCE}/api/v1/wallet?sellerId=${sellerId}&fetch_sr_wallet=true`,
        {
          headers : {
            "Sr-Authorization-Token": ShiprocketToken
          }
        }
      )
      : await axios.get(
        `${process.env.REACT_APP_FASTRR_FINANCE}/api/v1/wallet?sellerId=${sellerId}`
      )
      return response
  }