import React from "react";
import { Select } from "antd";

const { Option } = Select;
const ChannelSource = ({ channelSource, setChannelSource }: any) => {
  const changeChannelSourceHandler = (value: string) => {
    setChannelSource(value);
  };

  return (
    <Select
      id="duration"
      value={channelSource}
      style={{
        width: "100px",
        marginRight: "10px",
      }}
      onChange={changeChannelSourceHandler}
    >
      <Option value="all">All</Option>
      <Option value="web">Web</Option>
      <Option value="app">App</Option>
    </Select>
  );
};

export default ChannelSource;
