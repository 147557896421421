import React from 'react'
import { RiskProfileHeading, RadioButtonGroup, RiskParameterSubheading } from './style'
import { Checkbox, Radio} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import RiskTagsInfo from './RiskTagsInfo';


const RiskTags = ({selectedRiskTags, setSelectedRiskTags}) => {
    const onChange = (checkedValues) => {
        setSelectedRiskTags(checkedValues);
      };
    const options = [
        {
        label: 'Delivery Issues on Buyers Address',
        value: 'DELIVERABILITY_ISSUES',
        },
        {
            label: 'High Refusal Percentage of Buyers',
            value:'HIGH_REFUSAL',
        },
        {
            label:'Increase in RTO on Buyers Pincode (30 days)',
            value:  'INCREASED_RTO_IN_LAST_30_DAYS',
        },
        {
            label: 'Increase in RTO on Buyers Pincode (7 days)',
            value:  'INCREASED_RTO_IN_LAST_7_DAYS',
        },
        {
            label: 'First time Buyer on your Website',
            value: 'NEW_BUYER',
        },
        {
            label: 'Buyers Unavailability Issue',
            value: 'NOT_QUICKLY_AVAILABLE',
        },
        {
            label: 'Buyers History indicates RTO Risk',
            value:'PAST_HISTORY',
        },
        {
            label:'Buyer has more Prepaid Affinity',
            value: 'PREPAID_AFFINITY',
        },
    ];

      return (
        <RadioButtonGroup>
            <Checkbox.Group 
                options={options} 
                value = {selectedRiskTags}
                // defaultValue={selectedRiskTags} 
                onChange={onChange}
             />
        </RadioButtonGroup>
    )
}


const RiskProfile = ({
    riskProfile,
    setRiskProfile, 
    selectedRiskTags,
    setRiskType,
    setSelectedRiskTags, 
    riskType,
    
}) => {
    const onChange = (e) => {
        setRiskType(e.target.value);
        // setRiskProfile(!riskProfile)
      };
    return (
        <>
        <RiskProfileHeading>Risk Profile</RiskProfileHeading>
        <Radio.Group onChange={onChange} value={riskType}>
            <Radio value={'risk_profile'}>Risk Prediction</Radio>
            <Radio value={'risk_tags'}>Risk Tags </Radio>
        </Radio.Group>

        <RiskParameterSubheading>
        <span className='flex items-center gap-7 text-base'>
        {riskType === "risk_profile" ? ( "Select the risk profile below") : (
                <>
                    Select the risk tags below
                    <InfoCircleFilled data-tooltip-id="tags" data-tooltip-place="bottom-start" style={{ cursor: "pointer" }} />
                </>
        )}
        </span>
        </RiskParameterSubheading>
        
        <ReactTooltip noArrow id="tags" position={{x:650,y:350}} opacity={1} style={{ background: "#F6F6F6", alignContent: 'left', borderRadius: '4px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)", color:"black",zIndex:'10' }}>
            <RiskTagsInfo />
        </ReactTooltip>
        {
        riskType === "risk_profile" ? 
        <div>
        <Checkbox
            checked={riskProfile}
            onChange={() => setRiskProfile(!riskProfile)}
            style={{ color: '#0C3784'}}
        >
            High
        </Checkbox>
        </div> : 
        <RiskTags 
            selectedRiskTags = {selectedRiskTags}
            setSelectedRiskTags = {setSelectedRiskTags}
        />}
        </>
    )
}

export default RiskProfile