import { CheckoutRing, SRCOnboardLogo, SRlogo, ShopifyLogo } from "images/icons"
import React, { useState } from "react"
import SRCForm from "./Src_Form"
import LandingPage from "./LandingPage"
import NotFound from "UiComponents/NotFound/NotFound"
const SRCOnBoarding = () => {
    const points:string[]=['Personalize your checkout','Accurately pre-filled addresses','Ultimate discount engine','Advanced analytics']
    const[showLanding,setShowLanding]=useState<boolean>(true)
    return <>
    {localStorage.getItem('isOnboarding') ?
    <>
    {showLanding ? <LandingPage setShowLanding={setShowLanding}/> :<div className="h-full w-full bg-white px-8 py-8">
        <div className="flex justify-evenly py-12 bg-onBoarding" style={{height:'40vw'}}>
            <div className="flex flex-col gap-7 ml-12">
                <SRCOnboardLogo />
                <span className="font-bold text-5xl tracking-wide">Get started for <br/>effortless <br/>checkout conversions</span>
                <div>
                    {points.map((point:string)=>{
                        return <div className="flex gap-3 font-semibold mt-3"><CheckoutRing/><span className="text-pointsColor">{point}</span></div>
                    })}
                </div>
                {/* <ShopifyLogo/> */}
            </div>
            <SRCForm setShowLanding={setShowLanding}/>
        </div>
    </div>
    }
    </> :
    <>
        <NotFound />
    </>
    }
    </>

}
export default SRCOnBoarding