import { MailOutlined, MobileFilled, PhoneOutlined } from "@ant-design/icons";
import React from "react";

const OnboardingSuccessScreen = ({channel, userPhone, userEmail, setShowLanding} : {channel: any, userPhone: any, userEmail: any, setShowLanding: any}) => {
    return (
        <>
            <div>
            {
                channel.imageUrl &&
                <img 
                    src={channel.imageUrl}
                    alt={channel.key} 
                    style={{margin: "10px 0px", height:'50px'}}
                />
            }
            <div className="flex justify-center items-center flex-col text-center">
                <img src="https://d10srchmli830n.cloudfront.net/1730880422718_Vector.svg" alt="successfull-tick" />
            </div>
            <h1 style={{fontSize: "28px", fontWeight:'600', color: "#D48CCC", textAlign:"center", marginTop:'10px'}}>
                Thank you for sharing!
            </h1>
            <h2 style={{fontSize: "20px", fontWeight:'600', color: "#676565", textAlign:"center", marginTop:'10px'}}>
                Our sales team will reach out to you soon on below contact details to guide you through the next steps.
            </h2>
            {
                userPhone && userPhone !== "undefined" &&
                <div className="flex justify-center mt-5">
                    <PhoneOutlined style={{fontSize: "24px"}}/>
                    <h3 style={{color:'#676565', fontSize: "18px", fontWeight:'500', marginLeft:'10px'}}>+91-{userPhone}</h3>
                </div>
            }
            {
                userEmail && userEmail !== "undefined" &&
                <div className="flex justify-center mt-5">
                    <MailOutlined style={{fontSize: "24px"}}/>
                    <h3 style={{color:'#676565', fontSize: "18px", fontWeight:'500', marginLeft:'10px'}}>{userEmail}</h3>
                </div>
            }
            <h2 style={{fontSize: "18px", fontWeight:'400', color: "#676565", textAlign:"center", marginTop:'10px'}}>
                If you prefer us to reach you on different contact information, please fill out the form - 
                <span  
                    style={{color:'#6F57E9', cursor: 'pointer'}} 
                    onClick={
                        () => window.open('https://docs.google.com/forms/d/e/1FAIpQLScuFUZnQA24-GCwcljCuRtEHaO5hVvk9uHAD0Ef8MicL6FG5w/viewform?usp=sf_link', '_blank')
                }>
                        {" "}Contact Details
                </span>
            </h2>
            <div className="flex justify-center">
            <button 
                type="button" 
                className="mt-3 flex gap-2 focus:outline-none border border-purpleText text-white bg-purpleText focus:ring-0 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-purpleText dark:hover:bg-purpleText" 
                style={{fontWeight:'600'}}
                onClick={() => {setShowLanding(true)}}>
                    Return to Dashboard
                </button>
            </div>
            </div>
        </>
    )
}

export default OnboardingSuccessScreen;