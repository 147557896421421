import axiosInstance from '../../HTTP/Http';

export const fetchDashboardData = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate, timeDuration }] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/custom-login-dashboard`,
    {
      params: {
        from: fromDate,
        to: toDate,
        duration:timeDuration
      }
    }
  )
  return response.data.data;
}

export const fetchExistingCustomer = async ({ queryKey }: any) => {
  const [unusedArgument, { fromDate, toDate, }] = queryKey

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/existing-customer-stats`,
    {
      params: {
        startDate: fromDate,
        endDate: toDate,
      }
    }
  )
  return response.data.data;
}