export function summary(discountConfig: any, discountType: any) {
  const descriptionBase = discountConfig?.discountConfiguration;
  const conditionBase = discountConfig?.discountCondition;
  const configValue = descriptionBase.config;
  let description: string;

  const offType =
    descriptionBase.configType === "percent"
    ? `${configValue}%`
      : `₹${configValue}`;

  if (discountType === "prdAmt" || discountType === "amtCart" || discountType === "bankOffer") {
    description = `Apply this code and get ${offType} off`;
  } else if (discountType === "bxgy") {
    description = `Apply this code and get ${configValue} item/s free`;
  } else if (discountType === "shipping") {
    description = `Apply this code and get shipping free`;
  } else if (discountType === "bundle" || discountType === 'aftersell'||discountType==="freebie") {
    description = `Apply this code and get ${offType} off`;
  } else if (discountType === "tiered") {
    const offType =
      descriptionBase.configType === "flat"
        ? `₹${configValue?.[configValue.length - 1]?.offValue}*`
        : `${configValue?.[configValue.length - 1]?.offValue}%*`;

    if (descriptionBase?.tieredType === "quantity") {
      // description = `Apply this code and get ${configValue?.[0]?.tierValue} item's at ${offType} off`;
      description = `Apply this code and get maximum discount upto ${offType}`;
    } else {
      description = `Apply this code and get upto ${offType} off on cart value greater than ₹${configValue?.[0]?.tierValue}`;
    }
  }

  // let condition = `${
  //   conditionBase?.minimumCart
  //     ? `Minimum cart value ${conditionBase?.minimumCart}`
  //     : ""
  // }, ${
  //   conditionBase?.limitTo
  //     ? `Limit to ${conditionBase?.limitTo} use per customer`
  //     : ""
  // }, ${
  //   conditionBase?.customerType ? `Limit to ${conditionBase?.customerType}` : ""
  // }`;

  
  const minimumCart = conditionBase?.minimumCart ? 'Minimum cart value ' + '₹'+conditionBase?.minimumCart : '';
  const applyLimit = conditionBase?.limitTo ? 'Limit to ' +  conditionBase?.limitTo + ' use per customer' : '';
  const limitToCustomer = conditionBase?.customerType ? 'Limit to ' +  conditionBase?.customerType : ''

  let condition = 
     minimumCart + `${minimumCart && ',' + '\n'}`+  
     applyLimit + `${applyLimit && ',' + '\n'}`+
     limitToCustomer;

  return [description, condition];
}


export function activeTabKey(data:any, activeKey?:any){
  return [
      data?.discountFunctionality?.visibility.active ? 1 :  activeKey?.includes(1) ? 1 : null,
      data?.discountFunctionality?.combination?.active ? 2 : activeKey?.includes(2) ? 2 : null,
      data?.discountFunctionality?.discountLink?.active ? 3 : activeKey?.includes(3) ? 3 : null,
      (data?.discountFunctionality?.capping?.active || 
      data?.discountFunctionality?.caping?.active) ? 4 : activeKey?.includes(4) ? 4 : null,
      data?.discountFunctionality?.bulkGenerate?.active ? 5 : activeKey?.includes(5) ? 5 : null,
      data?.discountFunctionality?.paymentModes?.length ? 6 : activeKey?.includes(6) ? 6 : null,
  ]
}

export function activeSwitchTabKey(data:any, activeSwitch?:any){
  return {
      visibility: data?.discountFunctionality?.visibility.active || activeSwitch?.visibility,
      combination: data?.discountFunctionality?.combination?.active || activeSwitch?.combination,
      discountlink: data?.discountLink?.active || activeSwitch?.discountlink,
      caping: (data?.discountFunctionality?.caping?.active || data?.discountFunctionality?.capping?.active) || activeSwitch?.caping,
      bulkgenerate: data?.discountFunctionality?.bulkGenerate?.active || activeSwitch?.bulkgenerate,
      paymentModes: data?.discountFunctionality?.paymentModes?.length > 0 || activeSwitch?.paymentModes
    };
}

export function displayCouponDetails(data:any){
  return{
      title: data?.discountFunctionality?.visibility?.title,
      description: data?.discountFunctionality?.visibility?.description,
      termsAndCondition: data?.discountFunctionality?.visibility?.tnc,
  }
}
