import { Button, Col, Form, Input, Row } from "antd";
import React, {useEffect, useState} from "react";
import { CriteriaContainer, HeadingText } from "./styled";
import { PickrrButton } from "components/UIElements";
import { DeleteOutlined } from '@ant-design/icons';
import { PlusOutlined, MinusCircleOutlined} from '@ant-design/icons';

const UtmCriteria = ({criteriaUpdate, setCriteriaUpdate,form}) => {

  const [utmList, setUtmList] = useState(criteriaUpdate?.utmFilter ?? [{}])


  const HandleUtmUpdate = () => {
    setCriteriaUpdate({
      ...criteriaUpdate,
      utmFilter : form.getFieldValue('utmFilter')
    })
  }
  
  return (
    <CriteriaContainer>
        <Form.List name="utmFilter">
          {(fields, { add, remove }) => (
              <>
              <Row gutter={[5,10]}>
              {fields.map(( field , index) => (
                <>
                  <Col span={7}>
                    <Form.Item
                        name={[field.name,`source`]}
                        rules={[
                          {
                            required : (form.getFieldValue('utmFilter')?.[index]?.medium || form.getFieldValue('utmFilter')?.[index]?.campaign) ? true : false,
                            message : 'Source is required'
                          }
                        ]}
                    >
                        <Input                 
                        className="mr-12"
                        placeholder="Source"
                        onChange={()=>HandleUtmUpdate()}
                        />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                        name={[field.name,`medium`]}
                        rules={[
                          {
                            required : form.getFieldValue('utmFilter')?.[index]?.campaign ? true : false,
                            message : 'Medium is required'
                          }
                        ]}
                    >
                        <Input
                        className="mr-12"
                        placeholder="Medium"
                        onChange={()=>HandleUtmUpdate()}
                        />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                        name={[field.name,`campaign`]}
                    >
                        <Input
                        className="mr-12"
                        placeholder="Campaign"
                        onChange={()=>HandleUtmUpdate()}
                        />
                    </Form.Item>
                  </Col>
                  {fields.length > 1 &&  
                  <Col span={3}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => {
                        remove(field.name)
                        HandleUtmUpdate()
                      }}
                    />
                  </Col>}
                  </>
              ))}
              </Row>
              
              <Col span={12}>
              <Form.Item>
                  <PickrrButton color='outline' onClick={() => {
                    add()
                    HandleUtmUpdate()
                    }}>
                    Add UTM Parameters
                  </PickrrButton>
              </Form.Item>
              </Col>
              </>
          )}
        </Form.List>
    </CriteriaContainer>
  );
};

export default UtmCriteria;
