import { FlexColContainer } from 'components/UIElements'
import React from 'react'
import { FlexRow } from '../styled'
import { Card, Label, Heading } from './styled'

const SelectRuleType = (props) => {
    return (
        <FlexColContainer style={{padding:'70px 70px'}}>
        <Heading>Create rule for</Heading>
        <FlexRow>
            <FlexColContainer style={{alignItems:'center'}}>
                <Card 
                    onClick={() => {
                        props.setRuleName("prepaid_discount")
                    }}
                    style={{
                        border: props.ruleName === "prepaid_discount" ? "1px Solid #0C3784" : "none"
                    }}
                >
                    <img
                        src="https://d10srchmli830n.cloudfront.net/1648623009081_Group-27284PrepaidDiscount.svg"
                        alt="prepaid-discount"
                        width="50"
                    />    
                </Card>
                <Label>Prepaid Discount</Label>
            </FlexColContainer>
            <FlexColContainer style={{alignItems:'center'}}>
                <Card 
                    onClick={() => {
                        props.setRuleName("cod_charge")
                    }}
                    style={{
                        border: props.ruleName === "cod_charge" ? "1px Solid #0C3784" : "none"
                    }}
                >
                    <img
                        src="https://d10srchmli830n.cloudfront.net/1648624183592_XMLID_807_CODCharge.svg"
                        alt="prepaid-discount"
                        width="50"
                    />
                 </Card>      
                <Label>COD Charge</Label>
            </FlexColContainer>
            <FlexColContainer style={{alignItems:'center'}}>
                <Card 
                    onClick={() => {
                        props.setRuleName("shipping_charge")
                    }}
                    style={{
                        border: props.ruleName === "shipping_charge" ? "1px Solid #0C3784" : "none"
                    }}
                >
                    <img
                        src="https://d10srchmli830n.cloudfront.net/1648624309251_Group-27303ShippingCharge.svg"
                        alt="prepaid-discount"
                        width="50"
                    />
                 </Card>   
                <Label>Shipping Charge</Label>
            </FlexColContainer>
            <FlexColContainer style={{alignItems:'center'}}>
                <Card 
                    onClick={() => {
                        props.setRuleName("cod_disable")
                    }}
                    style={{
                        border: props.ruleName === "cod_disable" ? "1px Solid #0C3784" : "none"
                    }}
                >
                    <img
                        src="https://d10srchmli830n.cloudfront.net/1648624333863_Group-27301DisableCOD.svg"
                        alt="prepaid-discount"
                        width="50"
                    />
                 </Card>   
                <Label>Disable COD</Label>
            </FlexColContainer>
            <FlexColContainer style={{alignItems:'center'}}>
                <Card 
                    onClick={() => {
                        props.setRuleName("shipping_disable")
                    }}
                    style={{
                        border: props.ruleName === "shipping_disable" ? "1px Solid #0C3784" : "none"
                    }}
                >
                    <img
                        src="https://d10srchmli830n.cloudfront.net/1648624333863_Group-27301DisableCOD.svg"
                        alt="prepaid-discount"
                        width="50"
                    />
                 </Card>   
                <Label>Shipping Visibility</Label>
            </FlexColContainer>
            <FlexColContainer style={{alignItems:'center'}}>
                <Card 
                    onClick={() => {
                        props.setRuleName("partial_cod")
                    }}
                    style={{
                        border: props.ruleName === "partial_cod" ? "1px Solid #0C3784" : "none"
                    }}
                >
                    <img
                        src="https://d10srchmli830n.cloudfront.net/1648624333863_Group-27301DisableCOD.svg"
                        alt="prepaid-discount"
                        width="50"
                    />
                 </Card>   
                <Label>Partial Cod</Label>
            </FlexColContainer>
        </FlexRow>
        </FlexColContainer>
    )
}

export default SelectRuleType