import React from "react";
import { Select } from "antd";

const { Option } = Select;
const TimeDuration = ({ timeDuration, setTimeDuration, dateDiff }: any) => {
  const changeDurationHandle = (value: string) => {
    setTimeDuration(value);
  };

  return (
    <Select
      id="duration"
      value={timeDuration}
      style={{
        width: "100px",
        marginRight: "10px",
      }}
      onChange={changeDurationHandle}
    >
      <Option value="daily">Daily</Option>
      {dateDiff >= 1 && <Option value="weekly">Weekly</Option>}
      {dateDiff >= 7 && <Option value="monthly">Monthly</Option>}
    </Select>
  );
};

export default TimeDuration;
