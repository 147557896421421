import styled from 'styled-components';
import media from '../../../../components/UIElements/media';

export const Container = styled.div`
  border-radius: 12px;
  .ant-form-item-label > label {
    color: #263f97;
    font-size: 16px;
    font-weight: bold;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background: #263f97;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #263f97;
  }
`;
export const DetailsHeading = styled.div`
  font-size: 22px;
  color: #263f97;
  padding: 28px 20px 40px 20px;
  font-weight: bold;
  line-height: 24px;
  ${media.mobile`
    font-size: 22px;
  `};
`;

export const DetailHeadingBackground = styled.div`
  background-image: url('https://d10srchmli830n.cloudfront.net/1619690201338_dots.svg');
  width: 100%;
  height: auto;
  background-size: contain;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #f9fafe;
`;
export const DetailContainer = styled.div`
  padding-top: 32px;
  padding: 20px;
  margin-bottom: 24px;
`;
export const BackButtonText = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 12px;
  color: #263f97;
  opacity: 0.6;
  cursor: pointer;
`;

export const InfoText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
  color: #7e8fcc;
  line-height: 24px;
`;

export const StepContainer = styled.div`
  border-radius: 12px;
  padding: 20px;
  background-color: #ebedf7;
  color: #263f97;
  margin:10px;
`;
export const StepHeading = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #000B34;
  padding-left: 20px;
  padding-bottom: 32px;
`;
export const StepTextContainer = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
`;
export const CountText = styled.div`
  color: #000B34;
  padding: 6px 12px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: bold;
`;
export const StepText = styled.div`
  font-size: 14px;
  color: #0C3784;
  padding-top: 8px;
  padding-bottom: 8px;
`;
export const AppInformation = styled.span`
  color: #fe756c;
`;
