import styled from "styled-components";
import { Button, Modal } from "antd";

export const CustomModal = styled(Modal)`
    .ant-modal-body {
        border-radius: 20px;
        overflow: hidden;
    }
    .ant-modal-content {
        border-radius: 10px !important;
    }    
    // left: 24%;
    // top: 16%;

    // @media (min-width: 991px) and (max-width: 1024px) {
    //     left: 13%;
    //     top: 20%;
    // }


    // @media (max-width: 1441px) and (min-width: 1660px) {
    //     left: 24%;
    //     top: 15%;
    // }

`;

export const ModalHeading = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #0C64DB;
    margin-top: -2%;
`;

export const CustomButton = styled(Button)<{ buttontype?: string }>`
  background: ${(props: any) =>
    props?.buttontype === "outline" ? "transparent" : "#0c64db"};
  border-radius: 8px;
  color: ${(props: any) =>
    props?.buttontype === "outline" ? "#0c64db" : "#ffffff"};
  width: 100px;
  height: 36px;
  border: 1px solid  #0c64db;
  margin-top:16px;
  margin-right: 8px;
  &:disabled {
    color: #FFFFFF !important;
    background: #0C64DB !important;
    border-color: #0C64DB !important;
    text-shadow: none !important;
    box-shadow: none !important;
    opacity: .5; 
  }
`;
