import { toast } from 'react-toastify';
import axiosInstance from '../../../HTTP/Http';
import { downloadFile } from 'components/UIElements/function';
import { ToastErrorCross, ToastSuccessTick } from 'images/icons';

export const fetchRefundData = async ({ queryKey }: any) => {
    const [unusedArgument, {pageNumber, sellerId, fromDate, toDate, searchTerm, searchKey,}] = queryKey;
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/refunds`,
      {
        params: {
          page: pageNumber - 1,
          seller_id: sellerId,
          from: fromDate, 
          to: toDate,
          ...(searchTerm && {key: searchKey}),
          ...(searchTerm && {value: searchKey === "status" ?  searchTerm.toUpperCase() : searchTerm}),
        }
      }
    )
    return response.data.data;
}

export const handleMultipleDownload = async  (fromDate: string, toDate: string, keys: string,setIsDataDownloading:any) => {
  try{
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=refunds&type=REFUND`,
        {
            params: {
                from: fromDate,
                to: toDate,
                value: keys
            }
        }
    )
  setIsDataDownloading(false)
    downloadFile(response.data, 'orderdata.csv')
    toast.success('Download successful! Your file is ready', {
      icon: ToastSuccessTick
    });
  }catch(error){
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
    setIsDataDownloading(false)
  }
  
}

export const handleAllDownload = async (fromDate: string, toDate: string, searchKey: string, searchTerm:string,setIsDataDownloading:any) => {
  try{
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=REFUND`,
        {
            params: {
                from: fromDate,
                to: toDate,
                source: 'fastrr',
                ...(searchTerm && {key: searchKey}),
                ...(searchTerm && {value: searchTerm}),
            }
        }
    )
  setIsDataDownloading(false)
    downloadFile(response.data, 'refunds.csv')
    toast.success('Download successful! Your file is ready', {
      icon: ToastSuccessTick
    });
  }catch(error){
    toast.error('Download failed. Please try again', {
      icon: ToastErrorCross
    })
    setIsDataDownloading(false)
  }
  
}