import axiosInstance from '../../HTTP/Http';
import { downloadFile } from 'components/UIElements/function';

export const getRtoData = async ({ queryKey }) => {
  const [_, { pageNumber, fromDate, toDate }] = queryKey;
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report/rto`,
    {
      params: {
        page: pageNumber - 1,
        // size: 10,
        from: fromDate,
        to: toDate,
      }
    }
  );

  return response.data.data.orders;
};

export const downloadRtoCsvData = async () => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?type=RTO`
  );

  downloadFile(response.data, 'rto.csv');
};
