import styled from "styled-components";

export const Container = styled.div`
    background: #FFF;
    border-radius: 10px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
`

export const Title = styled.h1`
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.03em;
    color: #000B34;
    margin: 0;
    width: 80vw;
    margin: 0px 0px 15px 0px;
`
export const TableContainer = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;

    .ant-table-wrapper {
        width : 100%;
    }
    .ant-table-thead > tr > th{
    color: var(--text, #0C3784);
    font-size: 14.059px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    vertical-align : baseline;
    background : #ffffff;
    border-top : 1px solid #f0f0f0;
    }
`

export const TableContentContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--text-color-1, #38446D);
    font-size: 16.059px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const BadgeContainer = styled.div`
    display: flex;
    padding: 2.008px 8.034px 2.008px 6.025px;
    justify-content: center;
    align-items: center;
    gap: 6.025px;
    border-radius: 16.067px;
    background: #ECFDF3;
    color: #037847;
    text-align: center;
    font-size: 16.059px;
    font-weight: 500;
    width : fit-content;
`
