import React, { useState } from 'react';
import { Upload, notification, Modal } from 'antd';

import { Subtext } from './style';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const Uploader = ({ setUploadedFile, uploadedFile }) => {
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
  });

  const uploadProps = {
    onRemove: (file) => {
      setUploadedFile([]);
    },
    beforeUpload: (file) => {
      if (Math.floor(file.size / 1024) > 300) {
        notification.error({
          message: 'Max file size 300 KB only',
        });
        return Upload.LIST_IGNORE;
      } else if (
        file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg'
      ) {
        setUploadedFile([file]);
        return false;
      } else {
        notification.error({
          message: 'You can only upload png/jpeg/jpg/pdf file!',
        });

        return Upload.LIST_IGNORE;
      }
    },
    uploadedFile,
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        {...uploadProps}
        maxCount={1}
        onPreview={handlePreview}
      >
        {uploadedFile.length >= 1 ? null : 'Upload Logo'}
      </Upload>
      <Subtext>Max file size 300kb</Subtext>
      <Modal
        visible={state.previewVisible}
        title={state.previewTitle}
        footer={null}
        onCancel={() => setState((s) => ({ ...s, previewVisible: false }))}
        keyboard= {true}
      >
        <img alt="example" style={{ width: '100%' }} src={state.previewImage} />
      </Modal>
    </>
  );
};

export default Uploader;
