import React, { useEffect, useState } from "react";
import FunnelView from "pages/Dashboard/FunnelView";
import { useQuery } from "react-query";
import { fetchDashboardData } from "./script";
import moment from "moment";
import { CustomDatePicker } from "components/UIElements";
import { message, Row, Col, Skeleton } from "antd";
import PieChart from "./PieChartData";
import UserJourney from "./UserJourney";
import AddressPrefill from "./AddressPrefill";

const CustomDashboard = () => {
  const [fromDate, setFromDate] = useState<string | null>(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const [timeDuration, setTimeDuration] = useState("daily");
  const [dateDiff, setDateDiff] = useState<number | string>(6);
  const [duration, setDuration] = useState<string>("daily");
  const [loginSplit, setLoginSplit] = useState<any>();
  const [addressSplit, setAddressSplit] = useState<any>();
  const [shiprocketSSO, setShiprocketSSO] = useState<any>();
  const [addressPreFilled, setAddressPreFilled] = useState<any>();

  const { data: dashboardData, isLoading } = useQuery(
    ["dashboardData", { fromDate, toDate, timeDuration }],
    fetchDashboardData
  );

  const disabledDates = (current: any) => {
    return current > moment();
  };

  const calcPercentage = (value:number, total:number) => {
    return Math.round(((value / total)* 100));
  }

  useEffect(() => {
    const login_split = dashboardData?.loginTypeStats?.map((value:any) => {
      return {
              name:value?.title , value:value?.curValue
            }
        }
      )

      setLoginSplit(login_split)

      const address_split = dashboardData?.addressSourceStats?.map((value:any) => {
        return {
            name:value?.title , value:value?.curValue
        }
        }
      )

      setAddressSplit(address_split)

      let addressSplitTotal = 0;
      dashboardData?.addressSourceStats?.forEach((value:any) => {
        addressSplitTotal += value?.curValue;
      })

      let loginSplitTotal = 0;
        dashboardData?.loginTypeStats?.forEach((value:any) => {
        loginSplitTotal += value?.curValue;
      })

      let oldUser;
      dashboardData?.addressSourceStats.forEach((value:any) => {
        if(value?.title === 'New Address'){
          oldUser = value?.curValue
        }
      })

      let ssoUser;
      dashboardData?.loginTypeStats.forEach((value:any) => {
        if(value?.title === 'Shiprocket SSO'){
          ssoUser = value?.curValue
        }
      })

    const addressPrefill = calcPercentage(oldUser, addressSplitTotal);
    const shiprocketSSo =  calcPercentage(ssoUser, loginSplitTotal);
    setShiprocketSSO(shiprocketSSo);
    setAddressPreFilled(addressPrefill);

  }, [dashboardData])

  return (
    <div style={{height:'100%'}}>
    <div
        style={{
          textAlign: "right",
          padding: "0px 0px 15px 0px",
        }}
      >
        <CustomDatePicker
          allowClear={false}
          onChange={(dates: Array<moment.Moment>) => {
            if (dates[1].diff(dates[0], "days") > 60) {
              message.error(
                "Date range can not exceed 30 days. Please select date range less than or equal to 60   days only.",
                1.5
              );
            } else {
              dates && dates[0]
                ? setFromDate(dates[0].format("YYYY-MM-DD"))
                : setFromDate(null);
              dates && dates[1]
                ? setToDate(dates[1].format("YYYY-MM-DD"))
                : setToDate(null);
            }
            setDateDiff(dates[1].diff(dates[0], "days"));
            if (dates[1].diff(dates[0], "days") === 0) {
              setDuration("hourly");
            } else {
              setDuration("daily");
            }
          }}
          ranges={{
            "Today": [moment(), moment()],
            "Yesterday": [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "d"), moment()],
            "This Month": [
              moment().startOf("month"),
              moment().subtract(1, "days"),
            ],

            "Last 30 Days": [
              moment().subtract(30, "days"),
              moment().subtract(1, "days"),
            ],
          }}
          defaultValue={[
            moment(fromDate, "YYYY-MM-DD"),
            moment(toDate, "YYYY-MM-DD"),
          ]}
          disabledDate={disabledDates}
        />
      </div>


          
          {!isLoading ? 
          <>
          
        <Row gutter={[16, 16]}>
          <Col xl={18} lg={18} md={18} sm={24} xs={24}>
            <FunnelView
                conversionData={dashboardData?.stageStats}
                dropOffData={dashboardData?.stageDropoffStats}
                fromDate={fromDate}
                toDate={toDate}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={24} xs={24}>

            <AddressPrefill 
              fromDate={fromDate}
              toDate={toDate}
              />
            <UserJourney 
              shiprocketSSO = {shiprocketSSO}
              addressPreFilled = {addressPreFilled}
            />
          </Col>
        </Row>
        <div style={{marginTop: 20}}></div>
        <Row gutter={[16, 16]}>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <PieChart
               data = {loginSplit}
               title = {'Login Split'}
              />
            </Col>      
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <PieChart 
                data = {addressSplit}
                title = {'Address Split'}
              />
            </Col>      
        </Row>
        </>
        : <Skeleton active paragraph={{ rows: 16 }} />
        }
    </div>


  );
};

export default CustomDashboard;
