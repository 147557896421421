import styled from 'styled-components';
import { Button } from 'antd';

export const DetailHeadingBackground = styled.div`
  width: 100%;
  height: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #f9fafe;
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
`;

export const DetailsHeading = styled.div`
  font-size: 30px;
  color: #263f97;
  line-height: 24px;
  font-weight: bold;
`;
