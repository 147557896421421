import styled from "styled-components";

interface ErrorDivProps {
    bgColor?: string;
    borderColor?: string;
  }
export const ErrorDiv = styled.div<ErrorDivProps>`
  display : flex;
  width : fit-content;
  align-items : center ;
  margin : 12px 0px 0px 114px;
  padding-left: 10px;
  background: ${(props) => props.bgColor || '#FFFBE6'};
  border: 1px solid ${(props) => props.borderColor || '#FFE58F'};
  border-radius : 8px;
  font-size : 14px;
  min-height : 42px;
  padding: 0px 10px;`
