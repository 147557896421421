import React, { useState, ChangeEvent, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { fetchReportsData } from './script'
import { FlexRowTw, GenerateReportButton, TableContainerTw, ReportStatusTag } from './styles';
import { TextStyle } from 'pages/Orders/OrdersTab/styles';
import CustomReactTable from 'UiComponents/Table';
import moment from 'moment';
import { DownIconTable, DownloadIcon, FilterIcon, ToastSuccessTick } from 'images/icons';
import GenerateReportModal from 'UiComponents/Modals/GenerateReportModal/GenerateReportModal';
import FilterNew from 'pages/AbandonedCart/FilterNew';
import { toast } from 'react-toastify';

const startCase = (input: string) =>
  input
    .split(/[_ ]+/)                       
    .map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()) 
    .join(' '); 

    const ReportStatusColor: any = {
      'SUCCESS': '#1F806E',
      'IN_PROGRESS': '#F79C41',
      'FAILED': '#EA4E2D',
      'PARTIAL_SUCCESS': '#3E75C8',
    }
  
    const ReportTypesKeys = [
      'Refunds', 
      'Wallet Deduction', 
      'Settlement', 
      // 'Settlement Transaction', 
      'Order MIS', 
      'Order Line Item MIS', 
      'Abandoned Cart',
      'Traffic Acquisition'
    ];
  
    const ReportTypesMapping: any = {
      'Refunds': 'refunds',
      'Wallet Deduction': 'wallet_deduction',
      'Settlement': 'settlement',
      // 'Settlement Transaction': 'settlement_transaction',
      'Order MIS': 'orders',
      'Order Line Item MIS': 'order_line',
      'Abandoned Cart': 'abandoned_cart',
      'Traffic Acquisition': 'traffic_acquisition'
    }




const Reports = () => {
  const [reportsData, setReportsData] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showReportsTypeFilter, setShowReportsTypeFilter] = useState<boolean>(false);
  const [reportTypeFilters, setReportTypeFilters] = useState<string>('');    


  const { data, isLoading } = useQuery<any>(['reportsData', {pageNumber, reportTypeFilters}], fetchReportsData);  

 

  const columns: any = [
    {
      Header: <span className='pl-2'>Generated On</span>,
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle className='pl-2'>{value? moment(value).format('Do MMMM YYYY'): '-'}</TextStyle>
      )
    },
    {
      Header: 'Report Name',
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ cell: { value }, row: { original, index } }: any) => (
        /*// @ts-ignore */
        <TextStyle className='min-w-24 tracking-wide cursor-pointer'>
          <div className='flex items-center' data-tip data-tooltip-id={original?.id} >
          <div className='block max-w-[180px] overflow-hidden text-ellipsis break-normal whitespace-nowrap'>
            {value ? value : '-'}
          </div>
          <div style={{ visibility: index === hoveredRowIndex ? 'visible' : "hidden" }}>
            <DownIconTable />
          </div>
          </div>
          
          <ReactTooltip id={original?.id} border={'1px solid #EAEAEA'} place='bottom-start' opacity={1} noArrow clickable={true} style={{ background: "white", borderRadius: '12px', boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)" }}>
            <div className="w-fit-content h-fit-content text-clear pr-2" onClick={(e) => {
              e.stopPropagation();
            }}>

              <div className="font-normal text-searchColor">{value || '-'}</div>
            </div>
          </ReactTooltip>
        </TextStyle>
      )
    },
    {
      Header: <span className='flex items-center relative'>
      Report Type <span onClick={() => { setShowReportsTypeFilter(!showReportsTypeFilter) }} className='cursor-pointer'> <FilterIcon /></span>
      {showReportsTypeFilter && <div className='absolute' style={{ top: "35px", left: '-10px' }}><FilterNew filters={ReportTypesKeys} filterKey={reportTypeFilters} setFilterKey={setReportTypeFilters} multipleSelect={false} setIsOpen={setShowReportsTypeFilter} setPageNumber={setPageNumber} mapping={ReportTypesMapping} /></div>}

  </span>,
      accessor: 'reportType',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle>{value ? startCase(value) : '-'}</TextStyle>
      )
    },
    {
      Header: 'Generated By',
      accessor: 'createdBy',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <TextStyle>{value ? value : '-'}</TextStyle>
      )
    },
    {
      Header: 'Duration',
      accessor: 'startDate',
      disableSortBy: true,
      Cell: ({ cell: { value }, row: { original } }: any) => {        
        return (
          <TextStyle className='my-3'>{original?.startDate ? `${moment(original?.startDate).format('Do MMMM YYYY')} - ${moment(original?.endDate).format('Do MMMM YYYY')}` : '-'}</TextStyle>

        )

      }
    },
    {
      Header: 'Status',
      accessor: 'reportGenerationStatus',
      disableSortBy: true,
      Cell: ({ value }: any) => {
        return (
          <>
            <span className="capitalize pl-4 min-w-24">
              {value &&
                <ul className='list-disc text-xl'>
                  <li style={{ color: ReportStatusColor[value] }}><ReportStatusTag>
                    <span>{value?.toLowerCase().replace(/_/g, ' ')}</span>
                  </ReportStatusTag>
                  </li>
                </ul>
              }
            </span>
          </>
        )

      }
    },
    {
      Header: 'Action',
      accessor: 'reportLink',
      disableSortBy: true,
      Cell: ({ value }: any) => (

        value ? <a href={value} onClick={() => toast.success('Report downloaded successfully', {
          icon: ToastSuccessTick
        })}><DownloadIcon /></a> : ''


      )
    },

  ]

  useEffect(() => {
    setReportsData(data?.reports ? data?.reports : []);
  }, [data])


  return (
    <div className='relative mr-[-0.5rem] min-h-screen'>
      <TableContainerTw>
        <FlexRowTw style={{justifyContent: "space-between", alignItems: "center"}}>
          <div className='text-lg font-bold'>All Reports</div>
          <GenerateReportButton onClick={()=>{setShowGenerateModal(true)}}>Generate new report</GenerateReportButton>
        </FlexRowTw>

        <CustomReactTable
          data={reportsData || []}
          loading={isLoading}
          columns={columns}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          totalPages={data?.totalRecords}
          checkboxes={false}
          onSelectAll={() => { }}
          pageSize={20}
          loadingRows={10}
          selectedRowsKeys={[]}
          defaultSortBy={[{ id: '', desc: false }]}
          newState={''}
          handleRowSelect={() => { }}
          isChecked={false}
          setHoveredRowIndex={setHoveredRowIndex}
          tableMinHeight='300px'
        />

      </TableContainerTw>

      {showGenerateModal && <GenerateReportModal setShowGenerateModal={setShowGenerateModal} setPageNumber={setPageNumber}/>}



    </div>
  )
}

export default Reports