import React, { useState, KeyboardEvent, useEffect, useContext } from "react";
import Stepper from "./Stepper";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import { DataContext } from "context/DataProvider";
import SelectStoreType from "./SelectStoreType";
import ENUMS from "utils/enums";
import OnboardingSuccessScreen from "./SuccessScreen";
import { useMutation } from "react-query";
import { onboardSeller } from "./script";
import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const SRCForm = ({setShowLanding}:any) => {

    const cid = localStorage?.getItem('cid') ?? null
    const user_email = localStorage?.getItem('user_email') ?? null
    const user_name = localStorage?.getItem('user_name') ?? null
    const user_phone = localStorage?.getItem('user_phone') ?? null

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [error, setError] = useState("");
    const [isOnboardingLoading, setIsOnboardingLoading] = useState<boolean>(false);
    const {
        shopifyUrl,
        setShopifyUrl,
        approveRequest,
        setApproveRequest,
        accessCode,
        setAccessCode,
        shareRequest,
        setShareRequest
    } = useContext(DataContext);
    const [channel, setChannel] = useState<any>({});
    const [orderVolume, setOrderVolume] = useState<string>("0-10");
    const [onboardingDone, setOnboardingDone] = useState<boolean>(false);

    const {
        sellerConfig,
    } = useContext(DataContext);

    useEffect(()=>{
        if (sellerConfig?.domain_name){
            setShopifyUrl(sellerConfig?.domain_name)
        }
        if (sellerConfig?.collab_code){
            setAccessCode(sellerConfig?.collab_code)
        }
    },[])

    const onboardOtherSeller = useMutation(onboardSeller, {
        onMutate: (variables) => {
            return { id: 1 };
        },
        onError: (response: any) => {
            setIsOnboardingLoading(false)
            notification.error({message: 'Something went wrong. Please try again.'})
        },
        onSuccess: (response) => {
            localStorage.setItem('onboardingType', 'DONE')
            setIsOnboardingLoading(false)
            setOnboardingDone(true)
        },
    });

    useEffect(() => {
        if(localStorage?.getItem('onboardingType') === "DONE") {
            setOnboardingDone(true)
        }
    }, [localStorage?.getItem('onboardingType')])

    const handleNext = () => {

        //if API in progress
        if(isOnboardingLoading) {
            return
        }

        //step 0
        if(currentStep === 0 && !channel.key) {
            setError('Please select platform')
            return
        }

        //!shopify channel handler
        if(currentStep === 1 && channel.key !== ENUMS.SHOPIFY) {
            if(shopifyUrl && orderVolume) {
                setIsOnboardingLoading(true)
                const postData = { 
                    domain_name: `${shopifyUrl}`, 
                    company_id: cid, 
                    email_id: user_email, 
                    utm_param: {
                        utm_medium: localStorage?.getItem('utm_medium') ? localStorage?.getItem('utm_medium') : null
                    }, 
                    user_name: user_name,
                    order_volume: orderVolume
                }
                onboardOtherSeller.mutate({
                    ...postData,
                });
            }
            return
        }

        //shopify handler
        if (currentStep < 2){
            setError("")
            setCurrentStep(prev => prev + 1)
        }
    }

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1)
        }
        else{
            setShowLanding(true)
        }
    }

    const steps = [{ label: 'Connect Your Store', sublabel: 'Seemlessly link your Shopify store' }, { label: 'Payment Setup', sublabel: 'Configure to start accepting secure payments ' }, { label: 'Review & Go Live', sublabel: 'Review and grow your sales with us' }]

    return <>
        <div className="bg-white shadow-xl p-10 h-max rounded-md w-2/5">

            {!onboardingDone &&
            <div>
            {
                currentStep > 0 && channel.key === ENUMS.SHOPIFY && false &&
                <Stepper steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} />
            }
            {
                currentStep == 0 &&
                <SelectStoreType 
                    channel={channel}
                    setChannel={setChannel}
                />
            }
            {
                currentStep == 1 && 
                <FormStep1 
                    shopifyUrl={shopifyUrl} 
                    setShopifyUrl={setShopifyUrl} 
                    setAccessCode={setAccessCode} 
                    handleNext={handleNext} 
                    accessCode={accessCode} 
                    accountStatus={sellerConfig?.account_status?.toLowerCase()}
                    approveRequest={approveRequest} 
                    setApproveRequest={setApproveRequest} 
                    setShowLanding={setShowLanding} 
                    shareRequest={shareRequest} 
                    setShareRequest={setShareRequest}
                    channel={channel}
                    orderVolume={orderVolume}
                    setOrderVolume={setOrderVolume}
                />
            }
            {
                currentStep == 2 && 
                <FormStep2
                    accountStatus={sellerConfig?.account_status?.toLowerCase()}
                    channel={channel}
                 />
            }
            {
                currentStep == 3 && 
                <FormStep3 
                    accountStatus={sellerConfig?.account_status?.toLowerCase()}
                    channel={channel}
                />
            }
            {
                error && !channel.key &&
                <p style={{color: 'red'}}>{error}</p>
            }
            <div className="flex gap-2 mt-3">
                <button type="button" className="mt-3 flex gap-2 focus:outline-none border text-purpleText border-backPurple bg-backPurple focus:ring-0 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-backPurple dark:hover:bg-backPurple disabled:bg-greytext disabled:text-black" onClick={() => { handleBack() }}>Back</button>
                <button 
                    type="button" 
                    className="mt-3 flex gap-2 focus:outline-none border border-purpleText text-white bg-purpleText focus:ring-0 font-medium rounded-lg text-sm px-16 py-2.5 dark:bg-purpleText dark:hover:bg-purpleText" 
                    onClick={() => {handleNext()}}>
                    {   
                        isOnboardingLoading ?     <Spin indicator={<LoadingOutlined spin style={{color:"white"}}/>} /> :
                        ((currentStep === 3 && channel.key === ENUMS.SHOPIFY) ||  (currentStep === 1 && channel.key !== ENUMS.SHOPIFY)) ?
                        'Submit' :
                        'Next'
                    }
                </button>
            </div>
            </div>
            }

            {
                onboardingDone &&
                <OnboardingSuccessScreen 
                    channel={channel}
                    userPhone={user_phone}
                    userEmail={user_email}
                    setShowLanding={setShowLanding}
                />
            }
        </div>
    </>
}
export default SRCForm