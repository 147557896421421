import { Button, Input, Modal, Select } from "antd";
import styled from "styled-components";
import { InputNumber } from 'antd';

const { TextArea } = Input;

export const Heading = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #0c3784;
`;
export const SuccessHeading = styled.h1`
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  color: #333;
`;
export const SuccessSubHeading = styled.h1`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 40px;
  color: #333333;
`;

export const SuccessCustomModal = styled(Modal)`
  .ant-modal-body {
    border-radius: 20px;
    overflow: hidden;
  }
  .ant-modal-content {
    border-radius: 10px !important;
  }
`;

export const CancelButton = styled.button`
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.07em;
  background: none;
  color: #0c3784;
  cursor: pointer;
`;

export const SuccessButton = styled.button`
  border: 1px solid #0c64db;
  border-radius: 8px;
  background: white;
  width: 150px;
  height: 42px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #0c64db;
  cursor: pointer;
`;

export const ClearDateButton = styled.h5`
  cursor: pointer;
  margin-top: 10px;
  color: #0c3784;
  margin-left: 4px;
`;

// create discount
export const ModalLeftSide = styled.div`
  width: 240px;
  height: 560px;
  background: #f0f6fd;
  padding: 30px 20px;
`;

export const ModalLeftSideHeading = styled.div`
  color: #000b34;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.03em;
  margin-bottom: 16px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 128.5%;
  color: #000b34;
  opacity: 0.6;
`;

export const StepsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const StepsFlexBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`;

export const StepsText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000b34;
`;

export const CheckboxIcon = styled.div`
  height: 20px;
  width: 20px;
  background: ${(props) => (props.color ? props.color : "#E5E9EE")};
  border-radius: 50%;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    inset: 0%;
    height: 10px;
    width: 7px;
    border: 1.5px solid #ffffff;
    border-top: transparent;
    border-left: transparent;
    transform: rotateZ(45deg) translate(6.2px, -2px);
  }
`;

// Modal right side
// create discount
export const ModalRightSide = styled.div`
  width: calc(100% - 200px);
  height: 560px;
  background: #fffff;
  padding: 30px 36px;
`;

export const DiscountHeading = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #000b34;
`;
export const ContentContainer = styled.div`
  height: 380px;
  width: 100%;
  margin: 24px auto 40px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

export const NextButton = styled(Button)`
  background: #0c64db !important;
  border-radius: 8px;
  color: #ffffff !important;
  width: 136px;
  height: 40px;
`;

export const DiscountTypeContainer = styled.div`
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .mt-35 {
    margin-top: 35px !important;
  }
  img {
    margin: -2px 0px 0px 3px;
  }
`;
export const SubHeading = styled.h6<{ size?: string }>`
  font-weight: 700;
  font-size: ${(props: any) => (props?.size ? props?.size : "16px")};
  line-height: 22px;
  color: #38446d;
  margin-bottom: 0.2rem;
`;
export const CustomInput = styled(Input)`
  border-radius: 8px;
  height: 38px;
`;

export const LabelText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: #38446d;
  margin: 6px 0;
`;

export const ErrorText = styled.div`
  color: #e40000;
  font-size: 12px;
  margin-top: 8px;
`;

export const AddButton = styled(Button)`
  margin-top: 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-decoration-line: underline;
  color: #0c64db;
  cursor: pointer;
`;

export const StatusContainer = styled.div`
  font-size: 11px;
  color: #38446d;
  display:flex;
  gap: 8px;
  align-items: flex-start
  margin-bottom:8px
`;

export const BulletIcon = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background:#38446d;
  margin-top:4px;
`

export const SummaryHeading = styled.div`
  font-size:12px;
  margin-bottom: 5px;
  font-weight: bold
`

export const ModalContainer = styled.div`
  display: flex;
  .ant-select-single: not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 8px;
    height: 38px !important;
    // background-color: #f2f4f7;
    width: 100%;
    align-items: center;
  }
  
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    height: 38px !important;
    border-radius: 8px;
  }
  
  .ant-switch-checked {
    background: #0051bf;
  }

  .ant-switch {
    height: 20px;
    line-height: 20px;
    max-width: 42px;
  }
  .ant-switch-handle {
    height: 16px;
    width: 17px;
  }
`;

export const ConfigurationContainer = styled.div`
  .ant-form-item {
    margin-bottom: 8px;
  }
`;

export const ProductItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0;
`;

export const ProductHeading = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #38446d;
  text-transform: capitalize;
`;
export const VariantSelected = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #bcbcbc;
  margin-left: 13px;
  margin-top: 4px;
  text-transform: capitalize;
`;
export const ButtonEdit = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #0c64db;
  cursor: pointer;
`;

export const ButtonClose = styled.div`
  color: #38446d;
  font-weight: bold;
  cursor: pointer;
`;

export const RestButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ff5b51;
  cursor: pointer;
`;

export const CustomFont = styled.div<{
  size?: string;
  color?: string;
  weight?: string;
}>`
  font-size: ${(props) => (props?.size ? props?.size : "16px")};
  color: ${(props: any) => (props?.color ? props?.color : "#38446D")};
  font-weight: ${(props: any) => (props?.weight ? props?.weight : "500")};
  letter-spacing: -0.03em;
  line-height: 14px;
`;

export const CustomTextArea = styled(TextArea)`
  border-radius: 8px;
`;

export const DownloadButton = styled(Button)`
  color: #0c64db;
  height: 35px;
  width: 125px;
  text-align: center;
  background: #0c64db1a;
  border-radius: 10px;
  outline: none;
  border: none;
`;

export const DiscountTypeFlex = styled.div`
  display: flex;
  gap: 20px;
`;

export const NumberContainer = styled.div`
  background: #f2f4f7;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  color: #002659;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
`;

export const CustomNumber = styled(InputNumber)`
  border-radius: 8px;
  height: 38px;
  overflow:hidden;
  line-height: 2.4;
`;
