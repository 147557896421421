import React, { useEffect, useState } from "react";
import { CustomInput, RestButton } from "../styled";
import { FlexBox, PickrrButton, PickrrHeading, SpaceBetweenContainer } from "components/UIElements";
import { HeadingText, MessageText } from "./styled";
import SelectedPhoneNumbers from "./SelectedPhoneNumbers";
import { Alert, Upload, notification } from "antd";
import {EnterOutlined,UploadOutlined, DownloadOutlined} from '@ant-design/icons'
import SelectedPinCode from "./SelectedPinCode";
import axios from "axios";
import { Text } from "components/Settings/CreateRuleModal/AddCriteria/style";

const PinCodeSearch = ({
  selectedPinCode,
  setSelectedPinCode,
}: any) => {
  const [input, setInput] = useState("");
  const [isError, setIsError] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorPincodesList, setErrorPincodesList] = useState([]);
  
  const Regex = /^[1-9][0-9]{5}$/;
  const numberExists = (value: any) =>
    selectedPinCode.some((user: any) => user.number === value);

  useEffect(() => {
    if (input) {
      if (Regex.test(input)) {
        setIsError(false);
      } else {
        setIsError(true);
      }
      } else {
        setIsError(false);
      }
    }, [input]);

  useEffect(() => {
    const uploadCSV = async () => {
      try {
      setLoading(true);
      const formData = new FormData();
      formData.append('pincodes', uploadFile[0]);
      const response = await axios.post(
        `${process.env.REACT_APP_PICKRR_CHECKOUT}/rules/upload-csv/`,
        formData
      );
      setLoading(false);
      if (response.data.success) {
        if (response.data.data.err_list.length > 0) {
          notification.error({
            message: 'Invalid pincodes found.',
          });
        } else {
          notification.success({
            message: 'File uploaded successfully.',
          });
        }
        // response.data.data.list.map((pincode)=>{
        //   setSelectedPinCode([
        //     ...selectedPinCode,
        //     {
        //       number: pincode,
        //       id: Math.floor(Math.random() * 1000),
        //     },
        //   ])
        // })
        setSelectedPinCode(...selectedPinCode,response.data.data.list.map((number:any)=>({number,id:Math.floor(Math.random()*1000)})));
        setErrorPincodesList(response.data.data.err_list);
        setUploadFile([]);
      } else {
        notification.error({
          message: response.data.message,
        });
        setUploadFile([]);
      } 
    } catch (e) {
      notification.error({
        message: 'Something went wrong.',
      });
      setLoading(false)
    }
    };
    if (uploadFile.length === 1) {
      uploadCSV();
    }
  }, [setSelectedPinCode, uploadFile]);

    const uploadProps = {
      onRemove: (file:any) => {
        setUploadFile([]);
      },
      beforeUpload: (file:any) => {
      if(file.type !== 'text/csv'){
        notification.error({
          message: 'You can only upload csv file!',
        });
      }
      else{
        setUploadFile([file]);
      }
      return false;
    },
    };

  return (
    <>
      <HeadingText>
        <SpaceBetweenContainer>
          Enter PIN Code.
          <RestButton
            onClick={() => {
              setInput("");
              setSelectedPinCode([]);
            }}
          >
            Reset
          </RestButton>
        </SpaceBetweenContainer>
      </HeadingText>
      <MessageText>
        Enter a valid PIN Code and press enter to add.
      </MessageText>

      <CustomInput
        placeholder="Enter PIN Code."
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        onPressEnter={(e: any) => {
          if (numberExists(input)) {
            notification.error({
              message: "This pin code is already present.",
            });
          } else if (e.target.value && Regex.test(input)) {
            setSelectedPinCode([
              ...selectedPinCode,
              {
                number: `${e.target.value}`,
                id: Math.floor(Math.random() * 1000),
              },
            ]);

            setInput("");
            setIsError(false);
          } else {
            setIsError(true);
          }
        }}
        suffix = {<EnterOutlined />}
      />
      {isError && (
        <>
          <h1 style={{ color: "red", fontSize: "14px" }}>
            Please enter a valid pin code.
          </h1>
        </>
      )}

      {selectedPinCode && (
        <SelectedPinCode
          selectedPinCode={selectedPinCode}
          setSelectedPinCode={setSelectedPinCode}
        />
      )}
      {errorPincodesList.length > 0 && (
                <Alert
                  message={`${
                    errorPincodesList.length
                  } pincode(s) found invalid. Please check the following pincode(s): ${errorPincodesList.join(
                    ','
                  )}`}
                  type="error"
                />
              )}
      <b>or upload pincode list</b>
      <FlexBox className="mb-16">
        <Upload {...uploadProps} showUploadList={false}>
          <PickrrButton
            color="primary"
            style={{ textTransform: 'none' }}
            icon={<UploadOutlined />}
            className="mr-12"
            loading={loading}
          >
            Upload CSV
          </PickrrButton>
        </Upload>
        <a
          href="https://d10srchmli830n.cloudfront.net/1632747241375_Sample PinCode File.csv"
          download
        >
          <Text>
            <DownloadOutlined className="mr-8" /> Download Sample CSV File
          </Text>
        </a>
      </FlexBox>
    </>
  );
};

export default PinCodeSearch;
