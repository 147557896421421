import React from 'react';
import { Col, Row, Divider, Input, Switch, Checkbox, Button } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import {
  Container,
  Card,
  Heading,
  SubHeading,
  SelectionCard,
  DescriptionText,
  SwitchText,
  CustomPickrrButton,
  SelectedAddressText,
} from './style';
import {
  FlexBox,
  SpaceBetweenContainer,
  FlexColContainer,
  PickrrButton,
  FlexEndContainer,
} from 'components/UIElements';

const AddressContainer = () => {
  const { Search } = Input;
  return (
    <Container>
      <Card>
        <SpaceBetweenContainer
          className="mb-24"
          style={{ alignItems: 'center' }}
        >
          <FlexBox style={{ alignItems: 'center' }} className="mr-8">
            <img
              src="https://d10srchmli830n.cloudfront.net/1637151033755_Group-11209.svg"
              alt=""
              className="mr-8"
              style={{ width: '18px', marginBottom: '24px' }}
            />
            <Heading className="heading">Shipping Address</Heading>
          </FlexBox>
          <PickrrButton
            color="grey"
            icon={<PlusSquareOutlined />}
            className="body-element btn-color"
          >
            Add New Address
          </PickrrButton>
        </SpaceBetweenContainer>
        <SubHeading className="mb-16 heading">
          Search Shipping Address
        </SubHeading>
        <Search className="mb-16" allowClear={true} disabled={true} />

        <Row
          gutter={[16, 16]}
          style={{ maxHeight: '250px', overflowY: 'auto' }}
        >
          <Col span={24}>
            <SelectionCard selected={true}>
              <FlexBox
                style={{
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <img
                  src="https://d10srchmli830n.cloudfront.net/1637221315784_radio-button.svg"
                  alt=""
                  className="mr-12"
                />
                <FlexColContainer style={{ width: '100%' }}>
                  <SpaceBetweenContainer
                    style={{
                      alignItems: 'flex-start',
                      width: '100%',
                    }}
                    className="mb-8"
                  >
                    <SubHeading className="body-element">Home</SubHeading>

                    <FlexBox>
                      <img
                        src="https://d10srchmli830n.cloudfront.net/1637227283309_Group-8394.svg"
                        alt="edit"
                        className="mr-8"
                      />
                      <img
                        src="https://d10srchmli830n.cloudfront.net/1637227199179_Group-8395.svg"
                        alt="delete"
                        // onClick={(e) => e.stopPropagation()}
                      />
                    </FlexBox>
                  </SpaceBetweenContainer>
                  <DescriptionText className="mb-8 body-element">
                    jayesh sharma, 77/1 gurrugram haryana 221001
                  </DescriptionText>

                  <DescriptionText className="mb-8 body-element">
                    Landmark : barber shop
                  </DescriptionText>

                  <FlexBox
                    style={{ alignItems: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Switch checked={true} className="mr-8" />
                    <SwitchText className="body-element">
                      Default address
                    </SwitchText>
                  </FlexBox>
                </FlexColContainer>
              </FlexBox>
            </SelectionCard>
          </Col>
        </Row>
        <Divider />
        <SpaceBetweenContainer
          className="mb-24"
          style={{ alignItems: 'center' }}
        >
          <Heading className="mr-8 heading body-element">
            Billing Address
          </Heading>
          <PickrrButton
            color="white"
            icon={<PlusSquareOutlined />}
            // onClick={() => setAddBillingAddress(true)}
            className="body-element links"
            style={{ boxShadow: 'none' }}
          >
            Add New Address
          </PickrrButton>
          {/* )
                  )} */}
        </SpaceBetweenContainer>

        <>
          <Row
            gutter={[16, 16]}
            style={{ maxHeight: '250px', overflowY: 'auto' }}
            className="mb-16"
          >
            <Col span={24}>
              <SelectionCard
                selected={true}
                // onClick={() => setSelectedBillingAddress(add)}
              >
                <FlexBox
                  style={{
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <img
                    src="https://d10srchmli830n.cloudfront.net/1637221315784_radio-button.svg"
                    alt=""
                    className="mr-12"
                  />
                  <FlexColContainer style={{ width: '100%' }}>
                    <SpaceBetweenContainer
                      style={{
                        alignItems: 'flex-start',
                        width: '100%',
                      }}
                      className="mb-8"
                    >
                      {/* {add.address_tag ? ( */}
                      <SubHeading className="body-element">Office</SubHeading>
                      <FlexBox>
                        <img
                          src="https://d10srchmli830n.cloudfront.net/1637227283309_Group-8394.svg"
                          alt="edit"
                          className="mr-8"
                        />

                        <img
                          src="https://d10srchmli830n.cloudfront.net/1637227199179_Group-8395.svg"
                          alt="delete"
                          // onClick={(e) => e.stopPropagation()}
                        />
                      </FlexBox>
                    </SpaceBetweenContainer>
                    <DescriptionText className="mb-8 body-element">
                      jayesh sharma, 77/1 gurrugram haryana 221001
                    </DescriptionText>
                    {/* {add.landmark && ( */}
                    <DescriptionText className="mb-8 body-element">
                      Landmark : barber shop
                    </DescriptionText>
                  </FlexColContainer>
                </FlexBox>
              </SelectionCard>
            </Col>
            {/* ))} */}
          </Row>
          {/* </>
                    )} */}
          <Checkbox checked={true}>
            <SubHeading className="body-element">
              Same as shipping address
            </SubHeading>
          </Checkbox>
        </>
        {/* )} */}
        <Divider />
        <FlexEndContainer>
          <CustomPickrrButton
            size="large"
            color="primary"
            className="btn-color body-element"
          >
            Next
          </CustomPickrrButton>
        </FlexEndContainer>
      </Card>

      <Card>
        <SpaceBetweenContainer
          className="mb-16"
          style={{ alignItems: 'flex-start' }}
        >
          <FlexBox style={{ alignItems: 'center' }}>
            <img
              src="https://d10srchmli830n.cloudfront.net/1638880105360_Group-11209-(1).svg"
              alt=""
              // className="mr-8"
              style={{
                width: '18px',
                marginBottom: '24px',
                marginRight: '5px',
              }}
            />
            <Heading className="heading">Shipping </Heading>
          </FlexBox>
          <Button
            type="link"
            style={{ color: '#292A2C', paddingRight: 0 }}
            className="body-element"
          >
            Change Shipping Speed
          </Button>
        </SpaceBetweenContainer>
        <FlexColContainer>
          <SelectedAddressText className="body-element">
            Fastrr Delivery
          </SelectedAddressText>
          <SelectedAddressText className="body-element">
            Get it by 04 Feb 2022
          </SelectedAddressText>
        </FlexColContainer>
      </Card>
    </Container>
  );
};

export default AddressContainer;
