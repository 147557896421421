import styled from 'styled-components'

export const InfoBoxContentTop = styled.div`
    width: calc(80vw/3);
    height: 330px;
    border-right: 1px dashed #DFE0EB;
    padding: 5px 0px 0px 10px;

    &:first-child {
        padding: 5px 0px 0px 0px;
    }
`

export const HeadingText = styled.h5`
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #000B34;
    opacity: 0.9;
`

export const CountText = styled.h5`
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.03em;
    color: #0C3784;
    margin-top: 20px;
`

export const PercentageText = styled.h6`    
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: -0.03em;
    color: rgba(0, 11, 52, 0.6);
`