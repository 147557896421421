import { Input } from "antd"
import styled from "styled-components"

export const TableContainer = styled.div`
    background: #FFF;
    height: auto;
    border-radius: 6.5px;
    padding: 20px;

    .ant-input-group-addon {
        background: #F0F6FD;
        border-top-left-radius: 6.5px;
        border-bottom-left-radius: 6.5px;
    }

    .ant-select-selection-item {
        color: #0C3784 !important;
        font-weight: 600;
        opacity: 1;
    }

    .ant-select-arrow {
        color: #0C3784 !important;
    }

    .ant-input {
        border-radius: 6.5px;
    }

    .ant-table-row {
        vertical-align: baseline;
    }
`

export const CustomInput = styled(Input)`
    border-radius: 10px! important;
`

export const Text = styled.h6`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #0C3784;
`