import React, { useState } from "react";
import { Row, Col, Form, Select, Radio, Space } from "antd";
import { SubHeading, CustomInput, LabelText } from "../styled";
import ProductSearch from "./ProductSearch";
import type { RadioChangeEvent } from "antd";

const { Option } = Select;

const UpSellingConfig = ({
  customerBuysItems,
  setCustomerBuysItems,
  customerGetsItems,
  setCustomerGetsItems,
  configCustomerTypes,
  setConfigCustomerTypes,
  discountType,
  selectTypes,
  setSelectTypes,
  form,
  sellerConfigValues,
}: any) => {
  const regex = new RegExp(/^(((?!0)[0-9]+[.]?[0-9]*))$/);
  const percentRegex = new RegExp(
    /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?)(\.[0-9]{1,2})?$)/
  );

  const [value, setValue] = useState<string | number>("flat");

  const onChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ configValue: "" });
    setSelectTypes({ ...selectTypes, configType: e.target.value });
    setValue(e.target.value);
  };
  const input_name =
    selectTypes?.configType == "percent" ? "aftersellPercentage" : "flat";

  return (
    <Row align={"middle"} gutter={[20, 0]}>
      <Col span={24}>
        <SubHeading>Discount Configuration</SubHeading>
      </Col>
      <Col span={24}>
        <Form.Item name="configDiscountType" initialValue={"flat"}>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={"flat"}>Flat /-</Radio>
              <Radio value={"percent"}>Percentage %</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={input_name}
          label={"Discount Value"}
          rules={[
            {
              required: true,
              pattern:
                selectTypes?.configType === "percent" ? percentRegex : regex,
              message: "Enter valid value",
            },
          ]}
        >
          <CustomInput
            placeholder={"Enter the value"}
            maxLength={selectTypes?.configType === "flat" ? 10 : 4}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <SubHeading size={"14px"} style={{ marginBottom: 10 }}>
          Customer Buys 1 or more item form
        </SubHeading>
      </Col>
      <Col span={12}>
        <Form.Item
          name="customerBuysListType"
          label="Form"
          rules={[
            {
              // required: true,
              message: "please select form type",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="---select type---"
            allowClear
            showArrow={true}
            onChange={(value: string) => {
              if (value) {
                setConfigCustomerTypes({
                  ...configCustomerTypes,
                  buysType: value,
                });
                setCustomerBuysItems([]);
              }
            }}
          >
            <Option value="product">Product</Option>
            <Option value="collection">Collection</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <ProductSearch
          selectedItems={customerBuysItems}
          setSelectedItems={setCustomerBuysItems}
          type={configCustomerTypes.buysType}
          discountType={discountType}
          form={form}
          sellerConfigValues={sellerConfigValues}
          bundleKey={Math.random() * 1001}
          showReset
        />
      </Col>

      <Col span={24}>
        <SubHeading size={"14px"} style={{ marginTop: 10, marginBottom: 10 }}>
          Customer will be shown
        </SubHeading>
      </Col>

      <Col span={24}>
        <ProductSearch
          selectedItems={customerGetsItems}
          setSelectedItems={setCustomerGetsItems}
          type={"product"}
          discountType={discountType}
          sellerConfigValues={sellerConfigValues}
          bundleKey={Math.random() * 1002}
        />
      </Col>

      {selectTypes.enableLimit && (
        <Col span={24}>
          <Form.Item
            name="maxLimit"
            label="Max Limit"
            rules={[
              {
                required: selectTypes.enableLimit,
                pattern: new RegExp(/^(([1-9]?[0-9]*))$/),
                message: "Enter the valid value",
              },
            ]}
          >
            <CustomInput
              maxLength={6}
              placeholder="Enter maximum number of uses per order"
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default UpSellingConfig;
