import { Col, Row} from 'antd';
import React from 'react';
import {CardHeading, TransactionsHeadingText, TransactionsDetailsText, PaymentStatus} from './styled'
import moment from "moment";


const TransactionsDetails = ({transactionDetails}:any) => {
   
    const refundTextColor:any = {
        'success':'#399F50',
        'pending':'#125CCC',
        'Failed':'#D65745'
    }
    
    const refundBackgroundColor:any = {
        'success':'#ecfaef',
        'pending':'#125ccc1a',
        'failed':'#fff0ee'
      }

    return (
        <>
            <CardHeading>Transactions Details</CardHeading>
            <Row gutter={[20,20]}>
                <Col xl={4} lg={4}>
                    <TransactionsHeadingText>Payment<br/> Gateway</TransactionsHeadingText>
                </Col>
                <Col xl={5} lg={4}>
                    <TransactionsHeadingText>Transaction<br/> Type</TransactionsHeadingText>
                </Col>
                <Col xl={5} lg={5}>
                    <TransactionsHeadingText>Transaction<br/> Date</TransactionsHeadingText>
                </Col>
                <Col xl={5} lg={4}>
                    <TransactionsHeadingText>Transaction<br/> Amount</TransactionsHeadingText>
                </Col>
                <Col xl={5} lg={5}>
                    <TransactionsHeadingText>Transaction<br/> Status</TransactionsHeadingText>
                </Col>
            </Row>
            <div style={{maxHeight: '280px', overflowY: 'scroll'}}>
            {(transactionDetails && transactionDetails?.length > 0) && transactionDetails?.map((value: any) => {
                return (
                <Row gutter={[20,20]}>
                    <Col xl={4} lg={4}>
                        <TransactionsDetailsText>{value?.gateway?.toUpperCase()==='POTLEE'?'Settle':value?.gateway}</TransactionsDetailsText>
                    </Col>
                    <Col xl={5} lg={4}>
                        <TransactionsDetailsText>{value?.transaction_type}</TransactionsDetailsText>
                    </Col>
                    <Col xl={5} lg={5}>
                        <TransactionsDetailsText>
                        {moment(value?.transaction_date).format('DD-MMMM-YYYY')}
                        </TransactionsDetailsText>
                    </Col>
                    <Col xl={5} lg={4}>
                        <TransactionsDetailsText>{value?.transaction_amount}</TransactionsDetailsText>
                    </Col>
                    <Col xl={5} lg={5}>
                        <PaymentStatus 
                            textColor= {refundTextColor[value?.status?.toLowerCase()]}
                            bgColor= {refundBackgroundColor[value?.status?.toLowerCase()]}
                        >
                            {value?.status?.toLocaleLowerCase()}
                        </PaymentStatus>
                    </Col>
                </Row>
            )
            })}
            </div>

        </>
    )
}

export default TransactionsDetails;