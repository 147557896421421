import styled from "styled-components";

export const FlexColContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    hr {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`

export const Container = styled.div`
    width: 50%;
    margin: 40px 40px;
`

export const ContainerDisableShipping = styled.div`
    margin: 40px 40px;
`

export const ContainerDiscount = styled.div`
    /* width: 50%; */
    margin: 40px 20px;
`