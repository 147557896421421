import { Modal, Table } from "antd";
import react, { useEffect, useState } from "react";
import { ModalHeading, ExportButton, CustomTable } from "./style";
import { fetchOthersResponse, downloadResponse } from "../script";
import { useQuery } from "react-query";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { FlexContainer } from "components/UIElements";

const ModalView = ({ isModalOpen, setIsModalOpen, fromDate, toDate, sellerId }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState();

  const { data, isLoading } = useQuery(
    ["exitCheckoutResponse", { fromDate, toDate, pageNumber, sellerId}],
    fetchOthersResponse
  );

  useEffect(() => {
    setTableData(data?.exitCheckoutOtherDatas)
  }, [data, isLoading])

  


  

  const columns = [
    {
      title: "User response",
      dataIndex: "response",
      key: "response",
      align: "left",
    },

    {
      title: "Cart ID",
      dataIndex: "cartId",
      key: "cartId",
      align: "left",
    },
  ];


  const handleCancel = () => {
    setIsModalOpen(false);
  };



 console.log(tableData, 'checking table data')
  return (
    <Modal
      title={
        <ModalHeading>
          <FlexContainer style={{ justifyContent: "space-between" }}>
            <div>
              <img src="https://pickrr.s3.amazonaws.com:443/2024-01-04T08:03:44.485211_exit.svg" />
              <span className="seeResponseHeading">See responses</span>
            </div>
            <ExportButton
              icon={<CloudDownloadOutlined />}
              onClick={() => downloadResponse(fromDate, toDate)}
            >
              Export
            </ExportButton>
          </FlexContainer>
        </ModalHeading>
      }
      visible={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
      keyboard= {true}
    >
      <CustomTable
        loading={isLoading}
        dataSource={tableData}
        columns={columns}
        // style={{marginTop:'20px',}}
        rowKey = {(record)=>record?.cartId}
        pagination={{
            pageSize: 10,
            showSizeChanger: false,
            total: data?.total,
            current: pageNumber,
            onChange: (page) => {
              setPageNumber(page);
            },
          }}
      />
    </Modal>
  );
};

export default ModalView;
