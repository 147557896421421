import React from 'react'
import { ChangeBox, ChangeText } from 'components/UIElements/StatisticsBox/styled'
import { FlexRow } from 'components/Settings/Discount/styled'
import { InfoBoxContentTop, HeadingText, CountText, PercentageText } from './styled'
import { IConversion } from 'pages/Dashboard/data'
import { Tooltip } from 'antd'
import moment from 'moment'

interface IProps {
    stageData: IConversion
    fromDate: string
    toDate: string
}

const StageInfoView = ({stageData, fromDate, toDate} : IProps) => {

    const dateDifference = moment(toDate).diff(moment(fromDate), 'days')

    return (
        <>
            <InfoBoxContentTop>
                <HeadingText>{stageData?.title}</HeadingText>
                <CountText>
                    {stageData?.curValue} <span style={{ fontSize: '12px', fontWeight: '500', color: '#A1A1A2' }}>Users</span>
                </CountText>
                {/* {
                    stageData?.title === 'Cart Initiated' ?
                    <PercentageText style={{visibility:'hidden'}}>{'Hidden'}</PercentageText> :
                    <PercentageText>
                        {
                            stageData?.curTotal > 0 ?
                            `${((stageData?.curValue/stageData?.curTotal)*100).toFixed(0)}% of ${stageData?.curTotal}` :
                            "-"
                        }
                    </PercentageText>
                } */}
                {/* <Tooltip
                    color="#F3F8FF"
                    placement="right"
                    overlayStyle={{ maxWidth: '300px' }}
                    title={
                        <div style={{ padding: '5px' }}>
                            <h5 style={{ color: '#0C3784' }}>
                                was <span style={{fontWeight:'600'}}>{stageData?.preValue}{' '}</span>
                               {
                                   dateDifference === 0 ?
                                   <>
                                    the previous day<br/>
                                    <span style={{fontWeight:'600'}}>
                                        ({moment(fromDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')})
                                    </span>
                                   </> :
                                   <>
                                    in the preceeding window <br/>
                                    <span style={{fontWeight:'600'}}>
                                        (
                                        {moment(fromDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')}{' '}
                                        to{' '}
                                        {moment(toDate).subtract(dateDifference + 1, 'days').format('DD-MM-YYYY')}
                                        )
                                    </span>
                                   </>
                               }
                            </h5>
                        </div>
                    }
                >
                {
                toDate !== moment().format('YYYY-MM-DD') &&
                <ChangeBox 
                    style={{ marginTop: '20px' }}
                    color={
                        stageData?.delta < 0 ? '#FFEBEB' : '#E7F8EA'
                    }
                >
                    <FlexRow style={{ width: '100%' }}>
                    <img 
                        src={
                            stageData?.delta < 0 ?
                            "https://d10srchmli830n.cloudfront.net/1652870375607_bf565686-d737-436e-9e10-f5be4724de2d_VectordecreaseDown.svg" :
                            "https://d10srchmli830n.cloudfront.net/1651222531941_VectorPercentageIncrease.svg"
                        }
                            alt="increase-sign"
                        />
                        <ChangeText
                            color={
                                stageData?.delta < 0 ? '#B90E0E' : '#0EB929'
                            }
                        >
                            {Math.abs(stageData?.delta)}%
                        </ChangeText>
                    </FlexRow>
                </ChangeBox>
                }
                </Tooltip> */}
            </InfoBoxContentTop>
        </>
    )
}

export default StageInfoView